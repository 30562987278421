// import React, { useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import useRouter from "utils/useRouter";
// import {
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   Grid,
//   Typography,
// } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100% ",
//   },
// }));
// const DropdownType = (props) => {
//   const {
//     ans,
//     setAns,
//     gridNum,
//     options,
//     selectedValue,
//     setSelectedValue,
//   } = props;
//   const classes = useStyles();
//   const router = useRouter();
//   //   const [radioSelectedValue, setRadioSelectedValue] = React.useState("");
//   // const [selectedValue, setSelectedValue] = React.useState("");
//   // console.log("selectedValue", selectedValue);

//   useEffect(() => {
//     if (ans) setSelectedValue(ans);
//   }, [ans]);

//   const handleDropdownChange = (e) => {
//     setSelectedValue(e.target.value);
//     setAns([e.target.value]);
//   };

//   return (
//     <div className={classes.root}>
//       <Grid fullWidth container>
//         <Grid item fullWidth xs={12} sm={12} md={12}>
//           <FormControl
//             fullWidth
//             variant="outlined"
//             sx={{ width: "50% !important" }}
//           >
//             <Select
//               //   sx={{ width: "50%" }}
//               labelId="dropdown-label"
//               id="dropdown"
//               value={selectedValue || ""}
//               onChange={handleDropdownChange}
//               displayEmpty
//               renderValue={(selected) => {
//                 if (selected.length === 0) {
//                   return <Typography>Select Country</Typography>;
//                 }
//                 return selected;
//               }}
//             >
//               <MenuItem disabled value="">
//                 <Typography>Select Country</Typography>
//               </MenuItem>
//               {options.map((value) => (
//                 <MenuItem key={value} value={value}>
//                   {value}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default DropdownType;

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useRouter from "utils/useRouter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100% ",
  },
  noPadding: {
    "& .MuiInputBase-root": {
      padding: "0px !important", // Remove padding
    },
  },
}));

const DropdownType = (props) => {
  const { ans, setAns, options,lstAns,currentIndex,setHideRoommateAnswer } = props;


console.log("ans----------------------1",ans)

console.log("ans----------------------3",lstAns)
console.log("ans----------------------4",currentIndex)


  const classes = useStyles();
  const router = useRouter();

  if(currentIndex == 1){
    // Here on index 1 we will hide the roomate answer segment.
    setHideRoommateAnswer(false);
  }
  
  const [radioSelectedValue, setRadioSelectedValue] = React.useState("");  

  useEffect(() => {
    console.log("radioSelectedValue ans",ans);
    if (ans)
      setRadioSelectedValue(ans)

    console.log("radioSelectedValue",radioSelectedValue);
  }, [ans]);

  const handleAutocompleteChange = (newval) => {

    console.log("ansansansansans---currentIndex",currentIndex);
    console.log("ansansansansans---newval",newval);
    console.log("ansansansansans---lstAns",lstAns);

    
    setRadioSelectedValue(newval);
    setAns([newval])
  };


  return (
    <div className={classes.root}>
      <Grid fullWidth container>
        <Grid item fullWidth xs={12} sm={12} md={12}>
          <Autocomplete
            fullWidth
            value={radioSelectedValue || ""}
            // onChange={handleAutocompleteChange}
            onChange={(event, newValue) => {
              // Handle the selected value
              if (newValue) {
                handleAutocompleteChange(newValue); // Assuming setRadioSelectedValue is the setter function
              } else {
                handleAutocompleteChange(null); // Clear value if the selected value is null
              }
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                // label=""

                className={classes.noPadding}
                placeholder="Select Country"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DropdownType;
