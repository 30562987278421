import { makeStyles } from '@material-ui/styles';
import React from 'react'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    position: "relative",
    display: "inline-block",
    borderBottom: "1px dotted black",
    display: "flex",
    justifyContent: "center",
    "&:hover ": {
      "& .show": {
        visibility: "visible",
      },
    },
  },
  tooltiptext: {
    visibility: "hidden",
    // width: "fit-",
    backgroundColor: "#00000080",
    color: "#fff",
    textAlign: "center",
    borderRadius: "4px",
    padding: "2px 3px",
    fontSize: "10px",
    fontWeight: "600",
    /* Position the tooltip */
    position: "absolute",
    zIndex: 1,
    bottom: "100%",
    left: "50%",
    marginLeft: "-60px",
  }
}));

export const TooltipCustom = (props) => {
  const { code, title, placement } = props

  const classes = useStyles();

  return (
    <div className={classes.tooltip} >Hover over me
      <div className={`${classes.tooltiptext} show`}>Tooltip text</div>
    </div>
  )
}
