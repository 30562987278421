import React, { useContext, useEffect } from "react";
import { Switch, Redirect } from "react-router-dom";
import AboutUs from "views/AboutUs";
import ChatTheme from "views/ChatTheme";
import ContactUs from "views/ContactUs";
import EditProfile from "views/EditProfile";
import HowItWorkDetail from "views/HowItWork/component/HowItWorkDetail";
import MatchesView from "views/MatchesView";
import FavMatchesView from "views/FavMatchesView";
import MyFavourite from "views/MyFavourite";
import MyGroup from "views/MyGroup";
import QuizMatching from "views/QuizMatching/QuizMatching";
import QuizMatchingSoulMates from "views/QuizMatchingSoulmates/QuizMatching";
import SelecteUniversity from "views/SelecteUniversity/SelecteUniversity";
import ResetPassword from "views/ResetPassword";
import Notification from "views/Notification";
import ForgotResetPassword from "views/ForgotResetPassword";
import SubscriptionPlan from "views/SubscriptionPlan";
import ViewYourProfile from "views/ViewYourProfile";
import MatchesFavouritViewRoommate from "views/MatchesFavouritViewRoommate";

import TermsOfUse from "views/TermsOfUse";
import PrivacyPolicy from "views/PrivacyPolicy";
import Faq from "views/Faq";

import { RouteWithLayout } from "./common";
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Questionares as QuestionaresLayout,
  SignupDetails as SignupDetailsLayout,
} from "./layouts";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
// import Profile from "./layouts"
import {
  Home as HomeView,
  SignupSimple as SignupSimpleView,
  NotFound as NotFoundView,
  Matches,
} from "./views";
import MatchesRoommate from "views/MatchesRoommate";
import MatchesFavouritRoommate from "views/MatchesFavouritRoommate";
import MatchesViewRoommate from "views/MatchesViewRoommate";
import PropertyInfo from "views/PropertyInfo";
import MyGroupRoommate from "views/MyGroupRoommate";
import ChatThemeRoommate from "views/ChatThemeRoommate";
import ViewYourProfileRoommate from "views/ViewYourProfileRoommate";
import HomeMain from "layouts/HomeMain";
import AppDataContext from "Helper/AppDataContext";
import MatchesSoulMates from "views/MatchesSoulmates";
import MyFavouriteSoulmate from "views/MyFavouriteSoulmate";
import MyBlock from "views/MyBlock";
import PersonalDetails from "views/PersonalDetails/component/PersonalDetails";
import Comminsoon from "views/Comminsoon/Comminsoon";
import FaqMain from "views/FaqMain";
import FaqRoommate from "views/FaqRoommate";
import FaqTeammate from "views/FaqTeammate";
import FaqSoulmate from "views/FaqSoulmate";
import SignupDetail from "views/SignupDetails/SignupDetails";
import useRouter from "utils/useRouter";
import Unsubscribe from "views/Unsubscribe";
import ViewYourProfileSol from "views/ViewYourProfileSol";
import PropertyInfoSingle from "views/PropertyInfoSingle";
import AuthanticateRouter from "AuthanticateRouter";
import PropertyInfoSingleUnAuth from "views/PropertyInfoSingleUnAuth";
import ChatThemeSoulmate from "views/ChatThemeSoulmate";
import ViewMatchesSol from "views/ViewMatchesSol";
import SelecteModule from "views/SelecteModule";
import PropertyLanding from "views/PropertyLanding";
import HomeMainProperty from "layouts/HomeMainProperty";
import DormmateLanding from "views/DormmateLanding";
import RoommateLanding from "views/RoommateLanding";
import SelecteModuleRoommate from "views/SelecteModuleRoommate";
import PropertySignupDetails from "views/PropertySignupDetails/component/PropertySignupDetails";
import MatchesTeam from "views/MatchesTeam";
import FavMatchesTeam from "views/FavMatchesTeam";
import AddNewGroup from "views/AddNewGroup/AddNewGroup";
import AddGroupMember from "views/AddGroupMember/AddGroupMember";
import AddNewGroupInfo from "views/AddNewGroupInfo/AddNewGroupInfo";
import GroupDisplay from "views/GroupDisplay/GroupDisplay";
import GroupTeam from "views/GroupTeam";
import GroupChat from "views/GroupChat/GroupChat";
import Group from "views/GroupChat/index";
import ViewMatchesTeam from "views/ViewMatchesTeam";
import ShareGroup from "views/ShareGroup/ShareGroup";
import GroupInfo from "views/GroupInfo/GroupInfo";

import SoulmateLanding from "views/SoulmateLanding";
import TeammateLanding from "views/TeammateLanding";
import Blog from "views/Blog";
import BlogDetails from "views/BlogDetails";
import AddNewGroupNew from "views/AddNewGroupNew/AddNewGroupNew";
import AddNewGroupInfoNew from "views/AddNewGroupInfoNew/AddNewGroupInfoNew";
import VerifyEmailForGroupDetails from "views/VerifyEmailForGroup/VerifyEmailForGroupDetails";
import Chatroom from "views/Chatroom/chatroom";
import ChatroomTest from "views/ChatroomTest/chatroom";
import AddGroupMem from "views/AddGroupMem/AddGroupMem";
import WhoLikesyou from "views/WhoLikesyou";
import YourSubscription from "views/YourSubscription";
import CancellationPolicy from "views/CancellationPolicy";
import CurrentPlan from "views/CurrentPlan";
import Boostsubscriptions from "views/BoostSubscription/component/Boostsubscriptions";
import YourBoostSubscription from "views/YourBoostSubscription";
import BillingHistory from "views/BillingHistory";
import BoostCurrentPlan from "views/BoostCurrentPlan";
import Disclaimer from "views/Disclaimer/component/Disclaimer";

const Routes = () => {
  const router = useRouter();
  const { appContext, setAppContext } = useContext(AppDataContext);
  const [currentModule, setCurrentModule] = React.useState("");
  useEffect(() => {
    onLoad();
  }, []);
  const onLoad = async () => {
    const getcurrentModule = await AsyncLocalStorage.getItem("currentModule");
    setCurrentModule(getcurrentModule);
  };
  useEffect(() => {
    appContext?.currentModule && setCurrentModule(appContext.currentModule);
  }, [appContext]);

  useEffect(() => {
    console.log("router", router);
  }, [router]);

  return (
    <Switch>
      {/* <Redirect exact from="/" to="/home" /> */}
      {/* "/",
    "/property/:id",
    "/comminsoon",
    "/how-it-work",
    "/contact-us",
    "/about-us",
    "/terms-of-use",
    "/privacy-policy",
    "/newsletter-unsubscribe",
    "/faq",
    // "FaqRoommate",
    // "FaqTeammate",
    // "FaqSoulmate",
    // "FaqMain",
    "NotFoundView" */}

      {/* Open page */}
      <RouteWithLayout component={HomeView} exact layout={HomeMain} path="/" />

      {/* Open page */}
      <RouteWithLayout
        component={HomeView}
        exact
        layout={HomeMain}
        path="/home/:token"
      />

      {/* Open page */}
      <RouteWithLayout
        component={DormmateLanding}
        exact
        // layout={HomeMainProperty}
        layout={HomeMain}
        path="/dormmate"
      />

      {/* Open page */}
      <RouteWithLayout
        component={RoommateLanding}
        exact
        layout={HomeMainProperty}
        path="/roommate"
      />

      {/* Open page */}
      <RouteWithLayout
        component={SoulmateLanding}
        exact
        layout={HomeMain}
        path="/soulmate"
      />

      {/* Open page */}
      <RouteWithLayout
        component={TeammateLanding}
        exact
        layout={HomeMain}
        path="/teammate"
      />

      {/* Open page */}
      <RouteWithLayout
        component={Comminsoon}
        exact
        layout={QuestionaresLayout}
        path="/comminsoon"
      />

      {/* Open page */}
      <RouteWithLayout
        component={PropertyInfoSingle}
        exact
        layout={MainLayout}
        path="/property/:id"
      />

      {/* Open page */}
      <RouteWithLayout
        component={GroupDisplay}
        exact
        layout={MainLayout}
        path="/group/:id"
      />

      {/* <RouteWithLayout
        component={ViewYourProfileSol}
        exact
        layout={MainLayout}
        path="/your-profile-sol"
      /> */}

      {/* Open page */}
      <RouteWithLayout
        component={SelecteUniversity}
        exact
        layout={SignupDetailsLayout}
        // layout={QuestionaresLayout}
        path="/select-university"
      />

      {/* Open page */}
      <RouteWithLayout
        component={PersonalDetails}
        exact
        layout={SignupDetailsLayout}
        // layout={QuestionaresLayout}
        path="/personal-details"
      />

      {/* Open page */}
      <RouteWithLayout
        component={SignupDetail}
        exact
        layout={SignupDetailsLayout}
        path="/signup-details"
      />
      <RouteWithLayout
        component={Disclaimer}
        exact
        layout={SignupDetailsLayout}
        path="/disclaimer"
      />

      {/* Open page */}
      <RouteWithLayout
        component={VerifyEmailForGroupDetails}
        exact
        layout={SignupDetailsLayout}
        path="/teammate-verify/:id"
      />

      {/* Open page */}
      <RouteWithLayout
        component={PropertySignupDetails}
        exact
        layout={SignupDetailsLayout}
        path="/property-signup-details"
      />

      {/* Open page */}
      <RouteWithLayout
        component={SelecteModule}
        exact
        layout={SignupDetailsLayout}
        path="/selecte-module"
      />

      {/* Open page */}
      <RouteWithLayout
        component={SelecteModuleRoommate}
        exact
        layout={SignupDetailsLayout}
        path="/selecte-roommate-module"
      />

      {/* Open page */}
      <RouteWithLayout
        component={HowItWorkDetail}
        exact
        layout={HomeMain}
        path="/how-it-work"
      />

      {/* Open page */}
      <RouteWithLayout
        component={ContactUs}
        exact
        layout={HomeMain}
        path="/contact-us"
      />

      {/* Open page */}
      <RouteWithLayout component={Blog} exact layout={HomeMain} path="/blog" />

      {/* Open page */}
      <RouteWithLayout
        component={BlogDetails}
        exact
        layout={HomeMain}
        path="/blog/:id"
      />

      {/* Open page */}
      <RouteWithLayout
        component={AboutUs}
        exact
        layout={HomeMain}
        path="/about-us"
      />

      {/* Open page */}
      <RouteWithLayout
        component={TermsOfUse}
        exact
        layout={HomeMain}
        path="/terms-of-use"
      />

      {/* Open page */}
      <RouteWithLayout
        component={PrivacyPolicy}
        exact
        layout={HomeMain}
        path="/privacy-policy"
      />

      {/* Open page */}
      <RouteWithLayout
        component={Unsubscribe}
        exact
        layout={HomeMain}
        path="/newsletter-unsubscribe"
      />

      {/* Open page */}
      <RouteWithLayout
        component={
          router?.location?.hash === "#dormmate"
            ? Faq
            : router?.location?.hash === "#roommate"
            ? FaqRoommate
            : router?.location?.hash === "#teammate"
            ? FaqTeammate
            : router?.location?.hash === "#soulmate"
            ? FaqSoulmate
            : FaqMain
        }
        exact
        layout={HomeMain}
        path="/faq"
      />

      <RouteWithLayout
        component={EditProfile}
        exact
        layout={MainLayout}
        path="/edit-profile"
      />

      <RouteWithLayout
        component={PropertyInfoSingle}
        exact
        layout={MainLayout}
        path="/messages/property/:id"
      />

      <RouteWithLayout
        component={
          currentModule === "Roommates"
            ? MatchesFavouritRoommate
            : currentModule === "Dormmates"
            ? MyFavourite
            : currentModule === "Teammates"
            ? FavMatchesTeam
            : currentModule === "Soulmates"
            ? MyFavouriteSoulmate
            : MyFavourite
        }
        exact
        layout={MainLayout}
        path="/my-favourite"
      />
      {/* <RouteWithLayout
        component={
          currentModule === "Roommates"
            ? MatchesFavouritRoommate
            : currentModule === "Dormmates"
              ? MyFavourite
              : currentModule === "Teammates"
                ? FavMatchesTeam
                : currentModule === "Soulmates"
                  ? MyFavouriteSoulmate
                  : MyFavourite
        }
        exact
        layout={MainLayout}
        path="/who-likesyou"
      /> */}
      <RouteWithLayout
        component={WhoLikesyou}
        exact
        layout={MainLayout}
        path="/who-likesyou"
      />
      <RouteWithLayout
        component={BillingHistory}
        exact
        layout={MainLayout}
        path="/Billing-History"
      />
      <RouteWithLayout
        component={YourSubscription}
        exact
        layout={MainLayout}
        path="/Your-Subscription"
      />
      <RouteWithLayout
        component={YourBoostSubscription}
        exact
        layout={MainLayout}
        path="/Your-Boost-Subscription"
      />
      <RouteWithLayout
        component={CancellationPolicy}
        exact
        layout={MainLayout}
        path="/Cancellation-Policy"
      />
      <RouteWithLayout
        component={CurrentPlan}
        exact
        layout={MainLayout}
        path="/Current-Plan"
      />
      <RouteWithLayout
        component={BoostCurrentPlan}
        exact
        layout={MainLayout}
        path="/Boost-Current-Plan"
      />
      <RouteWithLayout
        component={
          currentModule === "Roommates"
            ? MatchesRoommate
            : currentModule === "Dormmates"
            ? Matches
            : currentModule === "Soulmates"
            ? MatchesSoulMates
            : currentModule === "Teammates"
            ? MatchesTeam
            : Matches
        }
        // currentModule === "SoulMates" ? MatchesSoulMates : ""}
        exact
        layout={MainLayout}
        path="/your-matches"
      />

      <RouteWithLayout
        component={AddNewGroup}
        exact
        layout={MainLayout}
        path="/addNewGroupMember"
      />

      <RouteWithLayout
        component={AddNewGroupNew}
        exact
        layout={MainLayout}
        path="/addNewGroupMemberNew"
      />

      <RouteWithLayout
        // component={AddGroupMember}
        component={AddGroupMem}
        exact
        layout={MainLayout}
        path="/addNewGroupMember/:id"
      />

      <RouteWithLayout
        component={ShareGroup}
        exact
        layout={MainLayout}
        path="/shareGourp/:id"
      />

      <RouteWithLayout
        component={AddNewGroupInfo}
        exact
        layout={MainLayout}
        path="/addNewGroupInfo"
      />

      <RouteWithLayout
        component={AddNewGroupInfoNew}
        exact
        layout={MainLayout}
        path="/addNewGroupInfoNew"
      />

      <RouteWithLayout
        component={GroupInfo}
        exact
        layout={MainLayout}
        path="/groupDetail/:id"
      />

      <RouteWithLayout
        component={
          currentModule === "Roommates"
            ? MatchesViewRoommate
            : currentModule === "Dormmates"
            ? MatchesView
            : currentModule === "Soulmates"
            ? ViewMatchesSol
            : currentModule === "Teammates"
            ? ViewMatchesTeam
            : MatchesView
        }
        exact
        layout={MainLayout}
        path="/your-matches/view/:id"
      />

      <RouteWithLayout
        component={
          currentModule === "Roommates"
            ? MatchesFavouritViewRoommate
            : FavMatchesView
        }
        exact
        layout={MainLayout}
        path="/favourit/view/:id"
      />
      <RouteWithLayout
        component={SubscriptionPlan}
        exact
        layout={MainLayout}
        path="/subscription-plan"
      />
      <RouteWithLayout
        component={Boostsubscriptions}
        exact
        layout={MainLayout}
        path="/Boostsubscriptions-list"
      />
      <RouteWithLayout
        component={
          currentModule === "Roommates"
            ? ViewYourProfileRoommate
            : currentModule === "Dormmates"
            ? ViewYourProfile
            : currentModule === "Soulmates"
            ? ViewYourProfileSol
            : ViewYourProfile
        }
        // component={currentModule === "Roommates" ? ViewYourProfileRoommate : ViewYourProfile}
        exact
        layout={MainLayout}
        path="/my-profile"
      />

      {/* <RouteWithLayout
        component={SelecteModule}
        exact
        layout={SignupDetailsLayout}
        path="/signup-details"
      /> */}

      <RouteWithLayout
        //  component={currentModule === "Roommates" ? QuizMatching :
        //  currentModule === "Dormmates" ? QuizMatching : QuizMatchingSoulMates}
        component={
          currentModule === "Roommates"
            ? QuizMatching
            : currentModule === "Dormmates"
            ? QuizMatching
            : currentModule === "Soulmates"
            ? QuizMatchingSoulMates
            : QuizMatching
        }
        exact
        layout={QuestionaresLayout}
        path="/matching-quiz"
      />
      <RouteWithLayout
        component={Chatroom}
        exact
        layout={MainLayout}
        path="/chatroom"
      />

      <RouteWithLayout
        component={Chatroom}
        exact
        layout={MainLayout}
        path="/chatroom/:id"
      />

      <RouteWithLayout
        component={ChatroomTest}
        exact
        layout={MainLayout}
        path="/chatroomTest"
      />

      <RouteWithLayout
        component={ChatroomTest}
        exact
        layout={MainLayout}
        path="/chatroomTest/:id"
      />

      <RouteWithLayout
        component={
          currentModule === "Roommates"
            ? ChatThemeRoommate
            : currentModule === "Dormmates"
            ? ChatTheme
            : currentModule === "Teammates"
            ? ChatroomTest
            : currentModule === "Soulmates"
            ? ChatThemeSoulmate
            : ChatTheme
        }
        exact
        layout={MainLayout}
        path="/messages"
      />
      <RouteWithLayout
        component={
          currentModule === "Roommates"
            ? ChatThemeRoommate
            : currentModule === "Dormmates"
            ? ChatTheme
            : currentModule === "Teammates"
            ? ChatroomTest
            : currentModule === "Soulmates"
            ? ChatThemeSoulmate
            : ChatTheme
        }
        exact
        layout={MainLayout}
        path="/messages/:uid"
      />
      <RouteWithLayout
        component={ResetPassword}
        exact
        layout={MainLayout}
        path="/reset-password"
      />
      <RouteWithLayout
        component={Notification}
        exact
        layout={MainLayout}
        path="/notifications"
      />
      <RouteWithLayout
        component={ForgotResetPassword}
        exact
        layout={MainLayout}
        path="/resetpwd/:token"
      />
      <RouteWithLayout
        component={
          currentModule === "Roommates"
            ? MyGroupRoommate
            : currentModule === "Teammates"
            ? GroupTeam
            : currentModule === "Drommates"
            ? MyGroup
            : MyGroup
        }
        exact
        layout={MainLayout}
        path="/my-group"
      />
      <RouteWithLayout
        component={MyBlock}
        exact
        layout={MainLayout}
        path="/settings"
      />

      <RouteWithLayout
        component={PropertyLanding}
        exact
        layout={HomeMainProperty}
        path="/property"
      />
      {/* <RouteWithLayout
        component={FaqRoommate}
        exact
        layout={HomeMain}
        path="/faq-roommate"
      />
      <RouteWithLayout
        component={FaqTeammate}
        exact
        layout={HomeMain}
        path="/faq-teammate"
      />
      <RouteWithLayout
        component={FaqSoulmate}
        exact
        layout={HomeMain}
        path="/faq-soulmate"
      /> */}
      {/* <RouteWithLayout
        component={SignupSimpleView}
        exact
        layout={MainLayout}
        path="/signup-simple"
      /> */}
      <RouteWithLayout
        component={NotFoundView}
        // exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={NotFoundView}
        // exact
        layout={MinimalLayout}
        // path="/not-found"
      />
      {/* <Redirect to="/not-found" status="404" /> */}
    </Switch>
  );
};

export default Routes;
