import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import HowItsWork from "../HowItsWork";
import TopMember from "../TopMember";
import SoulmateLandingTrust from "../SoulmateLandingTrust";
import SoulmateLandingQuestion from "../SoulmateLandingQuestion";
import SoulmateBest from "../SoulmateBest";
import SignupModel from "components/AuthModel/SignupModel";
import LoginModel from "components/AuthModel/LoginModel";
import Forgotpassword from "components/AuthModel/Forgotpassword";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./../../../../../src/index.css"

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiGrid-spacing-xs-2": {
      marginTop: 0,
    },
  },
  propertyImage: {
    width: "100vw",
    filter: "brightness(0.8)",
    height: "650px",
    objectFit: 'cover',
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
  },
  Findahome: {
    fontSize: "36px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    color: "#FFFFFF",
    fontWeight: 600,
    margin: 0,
  },
  slider: {
    padding: "0px !important"
  },
  Findahome_Detail: {
    fontSize: "18px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    color: "#FFFFFF",
    fontWeight: 500,
    margin: "15px 0",
  },
  propertyImageLabel: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%, -50%)",
    width: "100%",
  },
  cardHeading: {
    color: "#FE4B6B",
    fontSize: 32,
    fontWeight: 600,
  },
  input_label: {
    fontSize: 16,
    color: "#000000",
    fontWeight: 500,
    marginTop: 10,
  },
  learMoreButton: {
    textTransform: "capitalize",
    width: "100%",
    height: "45px",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    fontWeight: 400,
    marginTop: 14,
    borderRadius: 8,
  },
  learMoreDetailButton: {
    textTransform: "capitalize",
    width: "25%",
    height: "45px",
    fontWeight: 400,
    marginTop: 14,
    color: "#FFFFFF",
    borderColor: "#FFFFFF",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },  
  form__input: {
    marginTop: 14,
    background: "#FFFFFF",
    borderRadius: 9,
    height: 45,
    width: "100%",
  },
  radioGroup: {
    "& .MuiFormControlLabel-root": {
      marginLeft: 0,
      width: "100%",
      height: 45,
      background: "#FFFFFF",
      borderRadius: 8,
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      backgroundColor: "#fff;",
    },
  },
  gridRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 25px",
    [theme.breakpoints.down("xs")]: {
      padding: "17px 25px",
    },
  },
}));

const SoulmateLandingDetails = (props) => {
  const classes = useStyles();
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [forgotpasswordOpen, setForgotpasswordOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const onLoginOpen = () => {
    setLoginOpen(true);
    setForgotpasswordOpen(false);
    setOpen(false);
  };

  const onForgotpasswordOpen = () => {
    setLoginOpen(false);
    setForgotpasswordOpen(true);
  };

  const onSignupOpen = () => {
    setLoginOpen(false);
    setOpen(true);
  };

  const handleClickOpenLogin = () => {
    setLoginOpen(true);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <>
      <div>
        <Grid container>
          {/* <Grid item xl={12} lg={12} xs={12} style={{ position: "relative" }}>
            
          </Grid> */}

          <Grid item xl={12} lg={12} xs={12}>
            <div className="custom-slick-slider">
              <Slider {...sliderSettings} className={classes.slider} style={{ paddingTop: "10px !important" }} >
                <div>
                  <Grid item xl={12} lg={12} xs={12} style={{ position: "relative" }}>
                    <img
                      src="/images/matchUp/soulmateLanding.jpg"
                      alt="soulmateLanding"
                      className={classes.propertyImage}
                    />
                    <div className={classes.propertyImageLabel}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={5}
                        className={classes.gridRoot}

                      >
                        <h1 className={classes.Findahome} data-aos="fade-up" data-aos-once="true" data-aos-delay="100" data-aos-duration="1500">
                          Meet new and interesting people.
                        </h1>
                        <Typography className={classes.Findahome_Detail} data-aos="fade-up" data-aos-once="true" data-aos-delay="200" data-aos-duration="1500">
                          Join MatchUpMates, where you could meet anyone, anywhere!
                        </Typography>
                        <p
                          className={classes.learMoreDetailButton}
                          style={{ color: "#FFFFFF", width: "25%", height: 45 , border: "1px solid white" , cursor: "pointer" }}
                          onClick={handleClickOpenLogin} data-aos="fade-up" data-aos-once="true" data-aos-delay="300" data-aos-duration="1500"
                        >
                          Find Soulmate
                        </p>
                      </Grid>
                    </div>
                  </Grid>
                </div>

                {/* ================ */}
                <div>
                  <img
                    src="/images/matchUp/banner2.jpg"
                    alt="soulmateLanding"
                    className={classes.propertyImage}
                  />
                  <div className={classes.propertyImageLabel}>
                    <Grid container justify="center">
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={5}
                        className={classes.gridRoot}

                      >
                        <h1 className={classes.Findahome} data-aos="fade-up" data-aos-once="true" data-aos-delay="100" data-aos-duration="1500">
                          Meet new and interesting people.
                        </h1>
                        <Typography className={classes.Findahome_Detail} data-aos="fade-up" data-aos-once="true" data-aos-delay="200" data-aos-duration="1500">
                          Join MatchUpMates, where you could meet anyone, anywhere!
                        </Typography>
                        <p
                          className={classes.learMoreDetailButton}
                          style={{ color: "#FFFFFF", width: "25%", height: 45 , border: "1px solid white" , cursor: "pointer" }}
                          onClick={handleClickOpenLogin} data-aos="fade-up" data-aos-once="true" data-aos-delay="300" data-aos-duration="1500"
                        >
                          Find Soulmate
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </div>

                {/* ==================== */}
                <div>
                <img
                    src="/images/matchUp/banner3.jpg"
                    alt="soulmateLanding"
                    className={classes.propertyImage}
                  />
                  <div className={classes.propertyImageLabel}>
                    <Grid container justify="center">
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={5}
                        className={classes.gridRoot}

                      >
                        <h1 className={classes.Findahome} data-aos="fade-up" data-aos-once="true" data-aos-delay="100" data-aos-duration="1500">
                          Meet new and interesting people.
                        </h1>
                        <Typography className={classes.Findahome_Detail} data-aos="fade-up" data-aos-once="true" data-aos-delay="200" data-aos-duration="1500">
                          Join MatchUpMates, where you could meet anyone, anywhere!
                        </Typography>
                        <p
                          className={classes.learMoreDetailButton}
                          style={{ color: "#FFFFFF", width: "25%", height: 45 , border: "1px solid white" , cursor: "pointer" }}
                          onClick={handleClickOpenLogin} data-aos="fade-up" data-aos-once="true" data-aos-delay="300" data-aos-duration="1500"
                        >
                          Find Soulmate
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </div>

              </Slider>
            </div>
          </Grid>


          <Grid item xl={12} lg={12} xs={12} data-aos="fade-up" data-aos-once="true" data-aos-delay="500" data-aos-duration="1500">
            <HowItsWork />
          </Grid>
          {/* <Grid item xl={12} lg={12} xs={12} data-aos="fade-up" data-aos-once="true" data-aos-delay="500" data-aos-duration="1500">
            <TopMember />
          </Grid> */}
          <Grid item xl={12} lg={12} xs={12} data-aos="fade-up" data-aos-once="true" data-aos-delay="500" data-aos-duration="1500">
            <SoulmateLandingTrust />
          </Grid>
          <Grid item xl={12} lg={12} xs={12} data-aos="fade-up" data-aos-once="true" data-aos-delay="500" data-aos-duration="1500">
            <SoulmateLandingQuestion />
          </Grid>
          <Grid item xl={12} lg={12} xs={12} data-aos="fade-up" data-aos-once="true" data-aos-delay="500" data-aos-duration="1500">
            <SoulmateBest />
          </Grid>
        </Grid>
        <SignupModel open={open} setOpen={setOpen} setOpenLogin={onLoginOpen} />
        <LoginModel
          loginOpen={loginOpen}
          setLoginOpen={setLoginOpen}
          setOpenSignUp={onSignupOpen}
          setForgotpasswordOpen={onForgotpasswordOpen}
        />
        <Forgotpassword
          loginOpen={forgotpasswordOpen}
          setLoginOpen={onLoginOpen}
          setOpenSignUp={onSignupOpen}
        />
      </div>
    </>
  );
};

export default SoulmateLandingDetails;
