import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Api from "../../../../Helper/ApiHandler";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import MuiAlert from "@material-ui/lab/Alert";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import validate from "validate.js";
import AppDataContext from "Helper/AppDataContext";
import firebase from "firebase/app";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import useRouter from "utils/useRouter";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import SignupForm from "../SignupForm";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const lstGender = [
  { id: "male", value: "Male" },
  { id: "female", value: "Female" },
  { id: "intersex", value: "Intersex" },
  { id: "trans", value: "Trans" },
  { id: "noPreference", value: "Other" },
];

const schema = {
  first_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  last_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  g: {
    presence: { allowEmpty: false, message: "is required" },
  },
  zodiac: {
    presence: { allowEmpty: false, message: "is required" },
  },
  location: {
    presence: { allowEmpty: false, message: "is required" },
  },
  date_of_birth: {
    presence: { allowEmpty: false, message: "is required" },
  },
};
const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#E5E5E5",
    padding: "35px 0",
    height: "100vh",
  },
  questionsText: {
    fontSize: "20px",
    padding: "20px",
    color: "white",
    fontWeight: "bold",
    margin: "0",
  },
  questionsBox: {
    background: "#0071CE",
    borderRadius: "10px 10px 0px 0px",
  },
  startQuizBox: {
    background: "#fff",
    borderRadius: "10px 10px 0px 0px",
    textAlign: "center",
    marginBottom: "50px",
  },
  startText: {
    color: "black",
    fontSize: "22px",
    // padding: "20px",
    fontWeight: "600",
    margin: "0",
  },
  startsecondaryText: {
    color: "#3F444F",
    fontSize: "13px",
    // padding: "20px",
    fontWeight: "400",
    margin: "0",
  },
  answerBox: {
    background: "white",
    borderRadius: "0px 0px 10px 10px",
    // padding: "20px"
  },
  selectOptionIn: {
    //fontFamily: "Gilroy !important",
    "& .MuiOutlinedInput-notchedOutline": {
      // borderWidth: 0
    },
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon": {
      padding: "10.5px",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
      // fontSize: "12px"
    },
    // backgroundColor: "#e9edf0"
  },
  headingAns: {
    margin: "0",
    padding: "20px",
    paddingBottom: "0",
    fontSize: "18px",
    fontWeight: "600",
    marginLeft: "10px",
  },
  chipUserDetail: {
    margin: "6px 5px",
    fontSize: "14px",
    padding: "0 10px",
    //fontFamily: "Gilroy !important",
    [theme.breakpoints.down("xs")]: {
      padding: "0 7px",
      fontSize: "14px",
      margin: "4px 3px",
    },
  },
  chipInputEdit: {
    // margin: "3px 20px",
    "& .MuiAutocomplete-tag": {
      borderRadius: "5px !important",
      background: "rgb(204 227 245)",
    },
    //fontFamily: "Gilroy !important",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
  },
  chip: {
    background: "#d5606045",
    //fontFamily: "Gilroy !important",
    borderRadius: "5px",
    margin: "5px 5px",
    padding: "0 0",
    height: "28px",
  },
  chipGruop: {
    padding: "10px 20px",
  },
  // autocompletChip: {
  //   background: "white",
  //   '& .MuiChip-root': {
  //     borderRadius: "5px",
  //     background: "#d5606045",
  //     height: "auto",
  //   },
  //   '& .MuiChip-outlined': {
  //     border: "none",
  //     color: "#FE4B6B",
  //     padding: "2px"
  //   },
  //   '& .MuiFilledInput-root': {
  //     background: "white"
  //   }
  // },
  lineHorizontal: {
    width: "95%",
    // borderBottom: "2px solid #000000",
    opacity: "0.1",
    margin: "0 auto",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 25px",
  },
  nextButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    background: "rgba(0, 113, 206, 0.2)",
    width: "80px",
    height: "30px",
  },
  skipButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    textDecoration: "underline",
    marginTop: "5px",
    cursor: "pointer",
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
  },
  buttonStart: {
    width: "300px",
    fontSize: "14px",
    textTransform: "capitalize",
    margin: "20px 0px",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
  },
  mainStartQuixBox: {
    textAlign: "center",
    padding: "15px 15px",
  },
  textNewStep: {
    fontSize: "16px !important",
    fontWeight: "900",
    width: "60%",
    textAlign: "center",
    margin: "19px auto",
  },
  startQuizMain: {
    marginBottom: "20px",
    padding: "50px 50px 70px 50px",
    backgroundColor: "white",
    borderRadius: "10px",
  },
  autocompletChip: {
    padding: "10px",
    background: "white",
    "& .MuiChip-root": {
      borderRadius: "5px",
      background: "#d5606045",
      height: "auto",
    },
    "& .MuiChip-outlined": {
      border: "none",
      color: "#FE4B6B",
      padding: "2px",
    },
    "& .MuiFilledInput-root": {
      background: "white",
    },
  },
  chipIcon: {
    width: "13px",
    height: "13px",
    color: "#FE4B6B",
  },
  editIconPro: {
    position: "absolute",
    top: "80px",
    left: "53.5%",
    [theme.breakpoints.down("xs")]: {
      left: "57.5%",
    },
    cursor: "pointer",
  },
  profilePhotoEdit: {
    position: "relative",
    marginBottom: "10px",
  },
  checkboxDetail: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "35px",
    },
  },
  genderPrefranceHeading: {
    fontSize: "18px",
    fontWeight: "500",
    marginLeft: "12px",
    marginRight: "18px",
  },
  genderSelectBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  textFiledBox: {
    width: "90%",
    margin: "20px auto",
    "& .MuiFormControl-marginNormal": {
      marginTop: "0px",
      marginBottom: "0px",
    },
  },

  googleSelecte: {
    width: "90%",
    margin: "20px auto",
    "& .css-yk16xz-control": {
      // borderRadius: "10px",
      // padding: "8px"
    },
    "& .css-1pahdxg-control": {
      // borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      // padding: "8px"
    },
    "& .css-1pahdxg-control:hover": {
      // borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      // padding: "8px"
    },
    "& .css-1uccc91-singleValue": {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
    },
    "& .css-1wa3eu0-placeholder": {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
    },
    "& div": {
      fontFamily: "'Poppins', sans-serif",
      backgroundColor: "white",
      zIndex: 11,
    },
  },
  startedButton: {
    // fontSize: "20px",
    // height: "50px",
    height: "35px",
    fontSize: "18px",
    width: "90%",
    // background: "#0071CE",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    // borderRadius: "10px",
    textTransform: "capitalize",
    fontWeight: "400",
    marginTop: "20px",
  },
  banaer: {
    width: "100%",
  },
}));

const PropertySignupDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const router = useRouter();

  const [p_answer, set_P_Answer] = React.useState([]);
  const [s_answer, set_S_Answer] = React.useState([]);
  const [sessionMessage, setSessionMessage] = React.useState(
    props.sessionPropsMessage
  );
  const [lstP_University, setLst_P_University] = React.useState([]);
  const [lstS_University, setLst_S_University] = React.useState([]);
  const [lstPlannedActivities, setLstPlannedActivities] = React.useState([]);
  const [lstInterests, setLstInterests] = React.useState([]);
  const [uploadImagePath, setUploadImagePath] = React.useState("");
  const [preferenceGender, setPreferenceGender] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [disabledLogin, setDisabledLogin] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [lstZodiac, setLstZodiac] = React.useState([]);
  const [access, setAccess] = React.useState(true);
  const [dob, setDob] = React.useState();
  const [email, setEmail] = React.useState("");

  const { appContext, setAppContext } = useContext(AppDataContext);
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const dob_Change = (date) => {
    let changeDate = convertDate(date);
    if (moment().subtract(18, "years") >= moment(date)) {
      setDob(date);
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          date_of_birth: changeDate,
        },
      }));
    } else {
      setDob("");
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          date_of_birth: "",
        },
      }));
    }
  };
  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    // return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('/')
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  useEffect(() => {
    getZodiac();
    getEmail();
  }, []);

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        date_of_birth: convertDate(moment().subtract(18, "years")),
      },
    }));
  }, []);

  const getZodiac = async () => {
    // Api Call For Fetch Zodiac List
    api
      .get(`zodiaclist`)
      .then((result) => {
        if (result.success === "true") {
          setLstZodiac(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const getEmail = async () => {
    let E = await AsyncLocalStorage.getItem("email");
    setEmail(E);
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleFieldChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    getPlannedActivites();
    getInterests();
  }, []);

  const setLocation = (value) => {
    if (value) {
      geocodeByAddress(value.value.description)
        .then((results) => {
          console.error("valuevaluevalue", results);
          console.error("valuevaluevalue", results[0].geometry.location.lat());
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              location: value,
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng(),
            },
          }));
        })
        .catch((error) => console.error("valuevaluevalue", error));
    } else {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          location: "",
          latitude: "",
          longitude: "",
        },
      }));
    }
  };

  // Get Interests
  const getInterests = async () => {
    // Api Call For Fetch Interests List
    api
      .get(`interestlist`)
      .then((result) => {
        if (result.success === "true") {
          setLstInterests(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const getPlannedActivites = async () => {
    // Api Call For Fetch Planned activites List
    api
      .get(`planactvlist`)
      .then((result) => {
        if (result.success === "true") {
          setLstPlannedActivities(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabledLogin(true);
    setLoading(true);
    let device_token = await AsyncLocalStorage.getItem("device_token");
    let currentModule = await AsyncLocalStorage.getItem("currentModule");

    // firebase.auth().createUserWithEmailAndPassword(formState.values.email, formState.values.password)
    //   .then((userCredential) => {
    // Signed in
    // var user = userCredential.user;
    if (formState.isValid === true) {
      api
        .post("user/singupapp", {
          data: {
            ...formState.values,
            gender: formState.values.g.id,
            device_token,
            location: formState.values.location.label,
            email: email ? email : "",
            phone: "",
            type: "web",
            module_name: currentModule,
          },
          skipAuth: true,
        })
        .then((result) => {
          if (result.success === "true") {
            setSessionMessage({
              message: result.message,
              type: "success",
              open: true,
            });
            setFormState({
              isValid: false,
              values: {},
              touched: {},
              errors: {},
            });
            let userName = result.data.first_name + " " + result.data.last_name;
            AsyncLocalStorage.setItem("authToken", result.data.accessToken);
            AsyncLocalStorage.setItem("senderId", result.data.firebase_uid);
            AsyncLocalStorage.setItem("userName", userName);
            AsyncLocalStorage.setItem("userProfile", "null");
            AsyncLocalStorage.setItem("from_id", result.data.user_id);
            AsyncLocalStorage.setItem("university", result.data.university);
            AsyncLocalStorage.setItem(
              "puniversity_lat",
              result.data.puniversity_lat
            );
            AsyncLocalStorage.setItem(
              "puniversity_long",
              result.data.puniversity_long
            );
            AsyncLocalStorage.setItem("from_slug", result.data.slug);
            // AsyncLocalStorage.setItem("currentModule", "Dormmates")
            // router.history.push("/select-university")
            if (
              currentModule === "Roommates" ||
              currentModule === "Dormmates"
            ) {
              // window.location.replace("/select-university");
              router.history.push("/select-university");
            } else {
              // window.location.replace("/personal-details");
              router.history.push("/personal-details");
            }
            // setOpenLogin()
            // setOpen(false)
          } else {
            setSessionMessage({
              message: result.message,
              type: "error",
              open: true,
            });
          }
          setDisabledLogin(false);
          setLoading(false);
        })
        .catch((err) => {
          setDisabledLogin(false);
          setLoading(false);
          setSessionMessage({
            message: err.message,
            type: "error",
            open: true,
          });
        });
    }
    // ...
    // })
    // .catch((error) => {
    //   setDisabledLogin(false)
    //   setLoading(false)
    //   var errorCode = error.code;
    //   var errorMessage = error.message;
    //   // ..
    // });
  };

  // Start The Quiz
  const StartTheQuizHandler = () => {
    if (!formState.values.planned_activities?.length) {
      setSessionMessage({
        message: "Please selecte liked Activities",
        type: "error",
        open: true,
      });
      return;
    }
    if (!formState.values.interests?.length) {
      setSessionMessage({
        message: "Please selecte interests",
        type: "error",
        open: true,
      });
      return;
    }
    if (!preferenceGender.length) {
      setSessionMessage({
        message: "Please selecte preference gender",
        type: "error",
        open: true,
      });
      return;
    }

    let planned_activities = formState.values.planned_activities;
    let interests = formState.values.interests;
    let preference_gender = [...preferenceGender];

    let data = {
      planned_activities: planned_activities,
      interests: interests,
      preference_gender: preference_gender,
    };
    console.log(data, "data");
    // Api Call For personal details
    api
      .post("user/addactivitiesandinterests", {
        data: data,
      })
      .then((result) => {
        if (result.success === "true") {
          history.push("/matching-quiz");
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const handleAutocompletChange = (event, value, name) => {
    if (name === "p_university") {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value.value,
          },
        }));
      }
    } else if (name === "s_university") {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value.value,
          },
        }));
      }
    } else if (name === "room_type") {
      if (value !== null) {
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value.value,
          },
          touched: {
            ...formState.touched,
            [name]: true,
          },
        }));
      }
    } else {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value,
          },
        }));
      }
    }
  };

  return (
    <>
      <div className={classes.bgProfilePage}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        <Container maxWidth="lg">
          <Grid container style={{ marginTop: "90px" }}>
            <Grid
              item
              xl={7}
              lg={7}
              xs={7}
              style={{
                display: "flex",
                alignItems: "flex-end",
                paddingRight: "50px",
              }}
            >
              <img
                className={classes.banaer}
                src={"/images/matchUp/propertySignup.svg"}
                alt={"Alt Image"}
              />
            </Grid>
            <Grid item xl={5} lg={5} xs={5}>
              <Box className={classes.startQuizMain}>
                <Box className={classes.startQuizBox}>
                  <p className={classes.startText}>Welcome to MatchUpMates</p>
                  <p className={classes.startsecondaryText}>
                    it’s time to choose your best matches
                  </p>
                </Box>
                <SignupForm />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default PropertySignupDetails;
