import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  Hidden,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import SliderModel from "components/Slider/SliderModel";
import Compressor from "compressorjs";

// import Fancybox from "../../../fancybox.js";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { Alert, Skeleton } from "@material-ui/lab";
import Api from "../../../../Helper/ApiHandler";
import Fancybox from "components/fancybox";
var api = new Api();

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    // background: "#E5E5E5",
    background: "#E9EDF0",
    padding: "35px 0",
    minHeight: "calc(100vh - 125px)",
    // marginBottom: "60px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  detailBox: {
    background: "white",
    borderRadius: "10px",
    // padding: "20px 0px",
    paddingBottom: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
  },
  textUserDetail: {
    // margin: "0",
    color: "gray",
    margin: "5px 0",
    wordBreak: "break-all",
    display: "-webkit-box",
    "-webkitLineClamp": 3,
    "-webkitBoxOrient": "vertical",
    [theme.breakpoints.down("sm")]: {
      "-webkitLineClamp": 2,
    },
    [theme.breakpoints.down("xs")]: {
      "-webkitLineClamp": 4,
    },
    overflow: "hidden",
  },
  viewMore: {
    textAlign: "end",
    color: "#FE4B6B",
    margin: "5px 0",
    fontSize: 12,
    wordBreak: "break-all",
    overflow: "hidden",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  showtext: {
    color: "gray",
    margin: "5px 0",
    wordBreak: "break-all",
    overflow: "hidden",
  },

  directMessageBtn: {
    // marginTop: "30px",
    // bottom: "10px",
    // position: "absolute",
    textTransform: "capitalize",
    background: "#d5606045",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      width: "130px",
    },
  },
  varticalLine: {
    height: "180px",
    width: "1px",
    background: "gray",
    borderBottom: "1px solid #000000",
    opacity: "0.3",
    marginBottom: "15px",
  },
  detailGridBox: {
    position: "relative",
    paddingRight: 50,
    // paddingLeft: 20,
  },
  educationDetail: {
    display: "flex",
    fontSize: "14px !important",
  },
  textEducation: {
    margin: "0",
    marginLeft: "10px",
    fontSize: "14px !important",
  },
  imgBoxView: {
    // marginTop: "10px",
  },
  picsGrid: {
    background: "white",
    borderRadius: "10px",
    padding: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
      "& .MuiGrid-grid-xs-12": {
        maxWidth: "inherit",
      },
      overflowX: "scroll",
    },
  },
  lifeStyleHeading: {
    color: "gray",
    fontSize: "15px !important",
    margin: "5px 10px",
    fontWeight: "400",
  },
  imageLifStyleRoot: {
    width: "112px",
    height: "112px",
    borderRadius: "50%",
    border: "2px solid #FE4B6B",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "5px",
  },
  imageLifStyle: {
    "& .react-fancybox .thumbnail img": {
      borderRadius: "50%",
      objectFit: "cover",
      width: "100px",
      height: "100px",
      marginTop: "4px",
      [theme.breakpoints.down("xs")]: {
        overflowX: "scroll",
      },
      cursor: "pointer",
    },
    // borderRadius: "100%",
    // width: "100%",
    // height: "100%",
  },
  imageBoxStyle: {
    border: "2px solid #22346E",
    borderRadius: "100%",
    textAlign: "center",
    padding: "3px",
    width: "90px",
    height: "90px",
    [theme.breakpoints.down("xs")]: {
      width: "70px",
      height: "70px",
    },
  },
  aboutHeading: {
    color: "grey",
    margin: "8px 0",
    marginLeft: "10px",
    fontSize: "14px !important",
    fontWeight: "400",
  },
  textParagraph: {
    marginLeft: "10px",
    fontSize: "16px",
    fontWeight: "500",
  },
  imageQuote: {
    textAlign: "right",
  },
  quotedImage: {
    width: "80px",
    height: "50px",
  },
  chipUserDetail: {
    margin: "6px 5px",
    color: "#FE4B6B",
    fontWeight: "500",
    background: "#d5606045",
    //fontFamily: "Gilroy !important",
  },
  chipGruop: {
    marginBottom: "20px",
  },
  yourImageFix: {
    [theme.breakpoints.down("xs")]: {
      width: "120px",
      height: "120px",
    },
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    objectFit: "cover",
    objectPosition: "top",
  },
  boxListDetail: {
    marginTop: "10px",
  },
  headingPage: {
    fontWeight: "600 !important",
    fontSize: "18px",
    margin: "10px 10px 10px 20px",
    // fontWeight: "700"
  },
  iconTapBorder: {
    width: "110px",
    height: "110px",
    border: "2px solid #FE4B6B",
    borderRadius: "50%",
    // margin: "5px 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  nameUserView: {
    fontWeight: "600",
    fontSize: "22px",
    margin: "0",
    // whiteSpace: "nowrap",
    width: "100%",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px !important",
    },
  },
  hrLine: {
    borderBottom: "2px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  // responsiveHide: {
  //   [theme.breakpoints.down("sm")]: {
  //     display: "none",
  //   },
  // },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
      paddingLeft: "15px !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
      paddingLeft: "15px !important",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
      paddingLeft: "15px !important",
    },
  },
  userDetailMain: {
    padding: "0px 5px 0px 10px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
  sidelistAns: {
    margin: "0px",
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
  sidelistlabel: {
    margin: "0px",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    width: "80px",
  },
  hadding: {
    margin: "20px",
  },
  tableHadding: {
    backgroundColor: "lightGray",
    color: "black",
    fontWeight: "bold",
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
  },
  hadding: {
    margin: "20px",
    padding: "30px 0px 10px 0px",
  },
  answers: {
    // marginLeft: 15,
  },
  university: {
    margin: "0px",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    // width: "150px",
  },
  imgViewDetail: {
    width: "20px",
  },
  chip: {
    whiteSpace: "nowrap",
    width: "70px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "2px 5px",
    color: "#FE4B6B",
    fontWeight: "500",
    fontSize: "12px",
    borderColor: "#FE4B6B",
  },
  universityRoot: {
    marginTop: "15px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    marginLeft: "10px",
  },
}));

const MyProfileDetail = (props) => {
  const { sessionPropsMessage } = props;

  const classes = useStyles();
  // const theme = useTheme();
  const [userData, setUserData] = React.useState({});
  const [propertyData, setPropertyData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [sliderOpen, setSliderOpen] = useState(false);
  const [sessionMessage, setSessionMessage] = React.useState(
    sessionPropsMessage
  );
  const [lstLifeStyleImage, setLstLifeStyleImage] = React.useState([]);
  const [loadingLifestyle, setLoadingLifestyle] = useState(false);
  const [hideText, setHideText] = React.useState(false);

  const handleShowText = () => {
    setHideText(true);
  };
  const handleHideText = () => {
    setHideText(false);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const onLoad = () => {
    setIsLoading(true);
    // Api Call For Fetch The User Data
    api
      .get(`user/viewprofile`)
      .then((result) => {
        if (result.success === "true") {
          setUserData(result.data);
          if (result.data.lifestyle_photos) {
            setLstLifeStyleImage(result.data.lifestyle_photos);
          }
          setIsLoading(false);
        } else {
          console.log(result, "error");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
        setIsLoading(false);
      });

    // property for my profile
    api
      .get(`user/propertyformyprofile`)
      .then((result) => {
        if (result.success === "true") {
          setPropertyData(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };
  const handleSliderClose = () => {
    setSliderOpen(false);
  };

  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const onLifeStyleChange = async (event) => {
    if (event.target.files.length + lstLifeStyleImage.length > 5) {
      setSessionMessage({
        message: `You can select maximum ${5 - lstLifeStyleImage.length} image`,
        type: "error",
        open: true,
      });
      return;
    }
    const lengthUploadFile = event.target.files.length;
    // let lstTmp = lstLifeStyleImage?.length ? [...lstLifeStyleImage] : []
    const data = new FormData();
    for (let i = 0; i < lengthUploadFile; i++) {
      const file = event.target.files[i];
      data.append("files", file);
    }

    api
      .post("user/uploadlifestylepic", {
        data: data,
      })
      .then((result) => {
        if (result.success === "true") {
          let lstTmp = lstLifeStyleImage.concat(result.data);
          setLstLifeStyleImage([]);
          setLstLifeStyleImage(lstTmp);
          // onLoad()
        } else {
          console.log(result, "success === false");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  const newUpload = async (event) => {
    const files = event.target.files;
    if (event.target.files.length + lstLifeStyleImage.length > 5) {
      setSessionMessage({
        message: `You can select maximum ${5 - lstLifeStyleImage.length} image`,
        type: "error",
        open: true,
      });
      return;
    }
    setLoadingLifestyle(true);
    const formData = new FormData();
    async function compress(files) {
      for (const file of files) {
        await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.6,
            success(result) {
              formData.append("files", result);
              resolve();
            },
            error(err) {
              reject();
            },
          });
        });
      }
    }

    compress(files)
      .then((result) => {
        console.log(formData, "formData");
        console.log(result, "Compress success");
      })
      .catch((err) => {
        console.log("Compress error");
      })
      .finally((result) => {
        api
          .post("user/uploadlifestylepic", {
            data: formData,
          })
          .then((result) => {
            if (result.success === "true") {
              console.log(lstLifeStyleImage, "lstLifeStyleImage");
              console.log(
                lstLifeStyleImage.concat(result.data),
                "result.data[0]"
              );
              let lstTmp = lstLifeStyleImage.concat(result.data);
              setLstLifeStyleImage([]);
              setLstLifeStyleImage(lstTmp);
              // onLoad()
            } else {
              console.log(result, "success === false");
            }
            setLoadingLifestyle(false);
          })
          .catch((err) => {
            console.log(err, "error catch");
            setLoadingLifestyle(false);
          });
        console.log(result, "Compress complete");
      });
  };

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgProfilePage}>
        <Container maxWidth="lg">
          <Grid spacing={2} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid className={classes.container} item>
              {isLoading ? (
                <SkeletonLoading />
              ) : (
                <>
                  <Grid className={classes.detailBox} container>
                    <Grid item xs={12} lg={12} xl={12}>
                      <h5 className={classes.headingPage}> My Profile</h5>
                    </Grid>
                    <Grid item xs={5} lg={3} xl={3} md={3} sm={3}>
                      <div
                        className={classes.imgBoxView}
                        // style={{ marginLeft: "20px" }}
                        style={{ textAlign: "center" }}
                      >
                        <Fancybox>
                          <a
                            data-fancybox="Images"
                            href={
                              userData.profile_picture
                                ? userData.profile_picture
                                : "/images/male_defualt.svg"
                            }
                          >
                            <img
                              className={classes.yourImageFix}
                              src={
                                userData.profile_picture
                                  ? userData.profile_picture
                                  : "/images/male_defualt.svg"
                              }
                              alt="userPhoto Load"
                              // style={{
                              //   width: "100px",
                              //   height: "100px",
                              //   borderRadius: "50%",
                              //   top: 2,
                              //   position: "relative",
                              //   objectFit: "cover",
                              // }}
                            />
                          </a>
                        </Fancybox>
                        {/* <img
                          className={classes.yourImageFix}
                          src={
                            userData.profile_picture
                              ? userData.profile_picture
                              : "/images/male_defualt.svg"
                          }
                          alt="userPhoto Load"
                        /> */}
                      </div>
                    </Grid>
                    <Grid
                      className={classes.detailGridBox}
                      item
                      xs={7}
                      xl={4}
                      lg={4}
                      md={9}
                      sm={9}
                    >
                      <h3 className={classes.nameUserView}>
                        {" "}
                        {capitalizeFirstLetter(userData.first_name)}{" "}
                        {capitalizeFirstLetter(userData.last_name)}{" "}
                      </h3>
                      {/* <p className={classes.textUserDetail}> {userData.bio} </p> */}
                      {userData.bio && (
                        <p
                          className={
                            hideText === true
                              ? classes.showtext
                              : classes.textUserDetail
                          }
                        >
                          <Hidden smUp>
                            {hideText === false
                              ? userData.bio.slice(0, 40)
                              : userData.bio}
                          </Hidden>
                          <Hidden xsDown>
                            {hideText === false
                              ? userData.bio.slice(0, 100)
                              : userData.bio}
                          </Hidden>
                          {hideText ? (
                            <p
                              className={classes.viewMore}
                              onClick={handleHideText}
                            >
                              Show Less
                            </p>
                          ) : (
                            <span
                              style={{ marginRight: 15 }}
                              className={classes.viewMore}
                              onClick={handleShowText}
                            >
                              {userData.bio.length >= 40 ? "....View More" : ""}
                            </span>
                          )}
                        </p>
                      )}
                      {/* <p className={classes.textUserDetail}> {capitalizeFirstLetter(userData.gender)} </p> */}
                      <Button
                        variant="outlined"
                        color="primary"
                        to="edit-profile"
                        component={Link}
                        className={classes.directMessageBtn}
                        // startIcon={
                        //   <img
                        //     src="/images/matchUp/EditporfileIcon.svg"
                        //     alt="userPhoto Load"
                        //   />
                        // }
                      >
                        <img
                          src="/images/matchUp/EditporfileIcon.svg"
                          alt="userPhoto Load"
                          style={{ width: "15px", marginRight: "5px" }}
                        />
                        Edit Profile
                      </Button>

                      {/* <Box position="absolute" top={-15} right={15}>
                        <CircularProgress
                          variant="static"
                          value={100}
                          size={40}
                        />
                        <Box
                          top={0}
                          left={0}
                          bottom={8}
                          right={0}
                          position="absolute"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography
                            variant="caption"
                            component="div"
                            color="textSecondary"
                            style={{ fontSize: "12px", paddingTop: "5px" }}
                          >{`100%`}</Typography>
                        </Box>
                      </Box> */}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      xl={5}
                      lg={5}
                      style={{ display: "flex" }}
                    >
                      <Divider orientation="vertical" />
                      <Box className={classes.userDetailMain}>
                        <Box my={1} className={classes.educationDetail}>
                          <div>
                            <img
                              className={classes.imgViewDetail}
                              src={
                                userData.gender === "male"
                                  ? "/images/matchUp/man.png"
                                  : userData.gender === "female"
                                  ? "/images/matchUp/woman.png"
                                  : "/images/matchUp/rainbow.png"
                              }
                              alt="degree_view"
                            />
                          </div>
                          &nbsp;&nbsp;
                          {/* <h5 className={classes.sidelistlabel}>Primary university</h5>&nbsp;&nbsp; */}
                          <h5 className={classes.sidelistlabel}>Gender</h5>
                          &nbsp;&nbsp;
                          <h5 className={classes.sidelistAns}>
                            {userData.gender
                              ? capitalizeFirstLetter(userData.gender)
                              : "N/A"}
                          </h5>
                        </Box>

                        <Box my={1} className={classes.educationDetail}>
                          <div>
                            <img
                              className={classes.imgViewDetail}
                              src="/images/matchUp/home_view.png"
                              alt="degree_view"
                              width={"20px"}
                            />
                          </div>
                          &nbsp;&nbsp;
                          <h5 className={classes.sidelistlabel}>Hometown</h5>
                          &nbsp;&nbsp;
                          <h5 className={classes.sidelistAns}>
                            {userData.location ? userData.location : "N/A"}
                          </h5>
                        </Box>

                        <Box my={1} className={classes.educationDetail}>
                          <div>
                            <img
                              className={classes.imgViewDetail}
                              src="/images/matchUp/zodiac.png"
                              alt="degree_view"
                              width={"20px"}
                            />
                          </div>
                          &nbsp;&nbsp;
                          <h5 className={classes.sidelistlabel}>Zodiac</h5>
                          &nbsp;&nbsp;
                          <h5 className={classes.sidelistAns}>
                            {userData.zodiac ? userData.zodiac : "N/A"}
                          </h5>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid className={classes.picsGrid} container>
                    <Grid item xs={12} xl={12} lg={12}>
                      <h5 className={classes.lifeStyleHeading}>
                        {" "}
                        LifeStyle Pics{" "}
                      </h5>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={classes.lifeStyleBox}
                      >
                        {/* <ReactFancyBox > */}
                        {lstLifeStyleImage.length ? (
                          <>
                            {lstLifeStyleImage.map((value, index) => {
                              return (
                                <div className={classes.imageLifStyleRoot}>
                                  <div className={classes.imageLifStyle}>
                                    <Fancybox>
                                      <a data-fancybox="gallery" href={value}>
                                        <img
                                          src={value}
                                          alt="Property Image"
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: "50%",
                                            top: 2,
                                            position: "relative",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </a>
                                    </Fancybox>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          ""
                        )}
                        {lstLifeStyleImage.length <= 4 && (
                          <Box className={classes.changeImageTap}>
                            <Box className={classes.iconTapBorder}>
                              <input
                                accept="image/*"
                                style={{ display: "none" }}
                                id="life-style-1"
                                type="file"
                                multiple
                                disabled={loadingLifestyle}
                                // onChange={(e) => { onLifeStyleChange(e) }}
                                onChange={(e) => {
                                  newUpload(e);
                                }}
                              />
                              <label htmlFor="life-style-1">
                                <Box className={classes.iconTapBorder}>
                                  {loadingLifestyle ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <AddIcon style={{ color: "#FE4B6B" }} />
                                  )}
                                </Box>
                              </label>
                            </Box>
                          </Box>
                        )}
                        {/* </ReactFancyBox> */}
                      </Box>
                    </Grid>
                  </Grid>

                  {/* propertyData */}
                  <Grid className={classes.picsGrid} container>
                    <Grid item xs={12} xl={12} lg={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5 className={classes.lifeStyleHeading}>
                          {" "}
                          Property Name
                        </h5>
                        {propertyData ? (
                          <Chip
                            variant="outlined"
                            className={classes.chip}
                            label={propertyData && propertyData.property_type}
                          />
                        ) : (
                          ""
                        )}
                        {/* <div className={classes.chip}>
                          {propertyData && propertyData.property_type}
                        </div> */}
                      </div>
                      {propertyData ? (
                        <a
                          target="_blank"
                          href={`/property/${
                            propertyData ? propertyData.slug : ""
                          }`}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <Box display="flex" alignItems="center">
                            {/* {userData.lifestyle_photos ? */}
                            <div
                              style={{
                                display: "flex",
                                marginLeft: "10px",
                                padding: "10px",
                              }}
                            >
                              <div>
                                <img
                                  // className={classes.quotedImage}
                                  src={
                                    propertyData
                                      ? propertyData.property_photos
                                      : "/images/matchUp/property1.png"
                                  }
                                  style={{ width: "80px", height: "80px" }}
                                  alt="property1"
                                />
                              </div>
                              <div style={{ marginLeft: "10px" }}>
                                <h6 className={classes.heading}>
                                  {propertyData &&
                                    capitalizeFirstLetter(
                                      propertyData.property_name
                                    )}
                                </h6>
                                <p className={classes.headingAddress}>
                                  <img
                                    src="/images/matchUp/location.svg"
                                    alt="location"
                                  />
                                  {propertyData && propertyData.address}
                                </p>
                              </div>
                            </div>
                          </Box>
                        </a>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <img
                            src="/images/matchUp/notFoundMatches.svg"
                            alt="favAlt"
                            width={"200px"}
                          />
                          <Typography
                            variant="caption"
                            component="primary"
                            color="primary"
                            style={{
                              fontSize: "20px",
                              color: "black",
                              fontWeight: "600",
                            }}
                          >
                            No Property Yet!
                          </Typography>
                          <Typography
                            variant="caption"
                            component="primary"
                            color="primary"
                            style={{
                              fontSize: "14px",
                              color: "gray",
                              fontWeight: "400",
                            }}
                          >
                            Search your Property and always have them here.
                          </Typography>
                        </div>
                      )}
                    </Grid>
                  </Grid>

                  <Grid className={classes.picsGrid} container>
                    <Grid item xs={12}>
                      <h3 className={classes.aboutHeading}> University </h3>
                      <div className={classes.chipGruop}>
                        <div
                          className={classes.universityRoot}
                          // style={{
                          //   marginTop: "15px",
                          //   display: "flex",
                          //   marginLeft: "10px",
                          // }}
                        >
                          <h5 className={classes.university}>
                            Primary university:
                          </h5>
                          <h5 className={classes.sidelistAns}>
                            {userData.p_university
                              ? userData.p_university
                              : "N/A"}
                          </h5>
                        </div>

                        <div
                          className={classes.universityRoot}
                          // style={{
                          //   marginTop: "5px",
                          //   display: "flex",
                          //   marginLeft: "10px",
                          // }}
                        >
                          <h5 className={classes.university}>
                            Secondary university:
                          </h5>
                          <h5 className={classes.sidelistAns}>
                            {userData.s_university
                              ? userData.s_university
                              : "N/A"}
                          </h5>
                        </div>
                      </div>
                      <div className={classes.hrLine} />
                    </Grid>
                    <Grid item xs={12}>
                      <h3 className={classes.aboutHeading}>
                        {" "}
                        Liked Activities{" "}
                      </h3>
                      <div className={classes.chipGruop}>
                        {userData.planned_activities ? (
                          userData.planned_activities.map((item, index) => (
                            <Chip
                              className={classes.chipUserDetail}
                              label={item}
                            />
                          ))
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Typography
                              variant="caption"
                              component="primary"
                              color="primary"
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                fontWeight: "400",
                              }}
                            >
                              No Liked Activities found
                            </Typography>
                          </div>
                        )}
                      </div>
                      <div className={classes.hrLine} />
                    </Grid>

                    <Grid item xs={12}>
                      <h3 className={classes.aboutHeading}> Interests </h3>
                      <div className={classes.chipGruop}>
                        {userData.interests ? (
                          userData.interests.map((item, index) => (
                            <Chip
                              className={classes.chipUserDetail}
                              label={item}
                            />
                          ))
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Typography
                              variant="caption"
                              component="primary"
                              color="primary"
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                fontWeight: "400",
                              }}
                            >
                              No Interests found
                            </Typography>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  {userData.userCriteria && userData.userCriteria.length ? (
                    <Grid className={classes.picsGrid} container>
                      <div className={classes.card}>
                        <h3 className={classes.aboutHeading}>
                          {" "}
                          Questionnaire{" "}
                        </h3>
                        <Grid container>
                          {userData.userCriteria &&
                          userData.userCriteria.length ? (
                            userData.userCriteria.map((obj, index) => {
                              return (
                                <Grid item xs={12} width={"100%"}>
                                  <Divider className={classes.divider} />
                                  <Box display="flex" alignItem="center" p={2}>
                                    <Box>
                                      <Typography variant="h6" component="h6">
                                        {index + 1}. {obj.question}
                                      </Typography>
                                      <Typography
                                        variant="subtitle2"
                                        component="subtitle2"
                                        className={classes.answers}
                                      >
                                        <ArrowForwardIcon
                                          style={{ fontSize: "12px" }}
                                        />
                                        {obj.answer !== "N/A"
                                          ? obj.answer.join(",")
                                          : obj.answer}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              );
                            })
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              <Typography
                                variant="caption"
                                component="primary"
                                color="primary"
                                style={{
                                  fontSize: "14px",
                                  color: "gray",
                                  padding: "20px",
                                }}
                              >
                                No Questionaries found
                              </Typography>
                            </div>
                          )}
                        </Grid>
                      </div>
                    </Grid>
                  ) : (
                    ""
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <SliderModel
        open={sliderOpen}
        onClose={handleSliderClose}
        lifestyle_photos={userData.lifestyle_photos}
      />
    </>
  );
};
const SkeletonLoading = () => {
  return (
    <Grid item xs={12} md={12} style={{ marginLeft: "10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} md={4} lg={2} xl={3}>
          <Skeleton variant="circle" width={100} height={100} />
        </Grid>
        <Grid item xs={8} sm={8} md={8} lg={5} xl={4}>
          <Skeleton variant="rect" height={30} style={{ margin: "5px" }} />
          <Skeleton
            variant="rect"
            width={150}
            height={20}
            style={{ margin: "5px" }}
          />
          <Skeleton
            variant="rect"
            width={250}
            height={20}
            style={{ margin: "5px" }}
          />
          <Skeleton
            variant="rect"
            width={200}
            height={30}
            style={{ margin: "5px" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
          <div>
            <Skeleton variant="rect" height={30} style={{ margin: "5px" }} />
            <Skeleton
              variant="rect"
              width={150}
              height={20}
              style={{ margin: "5px" }}
            />
            <Skeleton
              variant="rect"
              width={300}
              height={20}
              style={{ margin: "5px" }}
            />
            <Skeleton
              variant="rect"
              width={200}
              height={30}
              style={{ margin: "5px" }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div style={{ padding: "30px 10px" }}>
            <div>
              <Skeleton
                variant="rect"
                width={150}
                height={20}
                style={{ margin: "5px" }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <Skeleton
                variant="circle"
                width={75}
                height={75}
                style={{ margin: "5px" }}
              />
              <Skeleton
                variant="circle"
                width={75}
                height={75}
                style={{ margin: "5px" }}
              />
              <Skeleton
                variant="circle"
                width={75}
                height={75}
                style={{ margin: "5px" }}
              />
              <Skeleton
                variant="circle"
                width={75}
                height={75}
                style={{ margin: "5px" }}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rect" height={150} style={{ margin: "5px" }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton
            variant="rect"
            width={150}
            height={20}
            style={{ margin: "5px" }}
          />
          <Skeleton variant="rect" height={250} style={{ margin: "5px" }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton
            variant="rect"
            width={150}
            height={20}
            style={{ margin: "5px" }}
          />
          <div style={{ display: "flex" }}>
            <Skeleton
              variant="rect"
              width={50}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
            <Skeleton
              variant="rect"
              width={60}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
            <Skeleton
              variant="rect"
              width={70}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
            <Skeleton
              variant="rect"
              width={50}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton
            variant="rect"
            width={150}
            height={20}
            style={{ margin: "5px" }}
          />
          <div style={{ display: "flex" }}>
            <Skeleton
              variant="rect"
              width={50}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
            <Skeleton
              variant="rect"
              width={60}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
            <Skeleton
              variant="rect"
              width={70}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
            <Skeleton
              variant="rect"
              width={65}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
            <Skeleton
              variant="rect"
              width={50}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default MyProfileDetail;
