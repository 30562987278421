import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  colors,
  Container,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import { useEffect } from "react";
import validate from "validate.js";
import MuiAlert from "@material-ui/lab/Alert";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Api from "../../../../Helper/ApiHandler";
import useRouter from "utils/useRouter";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { comppressImage, resizeFile } from "utils/utils";
import { CheckCircleOutline, Payment } from "@material-ui/icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import NoRecordFound from "views/NoRecordFound/index.js";
import Pagination from "@material-ui/lab/Pagination";

var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  bgEditProfile: {
    //fontFamily: "Gilroy !important",
    minHeight: "calc(100vh - 125px)",
    background: "#E9EDF0",
    padding: "35px 0",
    // marginBottom: "60px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      marginBottom: "10px",
    },
    "& .MuiContainer-root": {
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        overflowX: "hidden",
      },
    },
  },
  whiteCardProfile: {
    background: "white",
    borderRadius: "10px",
    // paddingBottom: "60px",
    // marginLeft: "16px",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "8px",
    },
  },
  changeHeading: {
    color: "yellow",
    fontSize: "15px !important",
    margin: "1px 5px",
    fontWeight: "400",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  changeHeadingTotal: {
    color: "black",
    fontSize: "18px !important",
    margin: "2px 2px ",
    marginBottom: "13px",
    fontWeight: "500",
  },
  changeHeadingTotalplan: {
    color: "#8E8E93",
    fontSize: "12px !important",
    margin: "5px 2px",
    // marginBottom: "13px",
    fontWeight: "500",
  },
  formControl: {
    width: "100%",
  },
  chip: {
    background: "#d5606045",
    //fontFamily: "Gilroy !important",
    borderRadius: "5px",
    margin: "5px 5px",
    padding: "0 0",
    height: "28px",
  },
  // genderOptionRoot: {
  //   paddingLeft: "15px",
  // },
  confirmButton: {
    //fontFamily: "Gilroy !important",
    margin: "0 5px",
    textTransform: "capitalize",
    padding: "5px 70px",
    fontSize: "18px",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 40px",
      margin: "5px",
    },
    color: "rgba(255, 59, 48, 1)",
    // width: "100%",
    height: "30px",
  },
  hrLine: {
    borderBottom: "2px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },

  EditProfilLable: {
    padding: "0 0 0px 10px",
    fontSize: "18px",
    fontWeight: "600 !important",
    margin: "15px 0px",
  },
  backRoot: {
    maxWidth: "fit-content",
    cursor: "pointer",
    marginLeft: "10px",
    // marginBottom: "20px",
    // marginTop: "45px",
    alignItems: "center",
    display: "flex",
  },
}));

const PlannedActivities = [
  { name: "communiction Services", id: 1 },
  { name: "student Organization", id: 2 },
  { name: "real Estate", id: 3 },
  { name: "stock Trading", id: 4 },
  { name: "photoGraphy", id: 5 },
];

const Interests = [
  { name: "shoping", id: 1 },
  { name: "Coding", id: 2 },
  { name: "fishing", id: 3 },
  { name: "streetwear", id: 4 },
  { name: "meditation", id: 5 },
  { name: "video Games", id: 6 },
];

const YourBoostCurrentPlan = (props) => {
  const { className, ...rest } = props;
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();
  const [chipData, setChipData] = React.useState([]);
  const [chipData2, setChipData2] = React.useState([]);
  const [lstPaymentLog, setLstPaymentLog] = React.useState([]);
  const [currant_module, setCurrant_module] = React.useState("");
  const [expired, setexpired] = React.useState(0);
  // Define the missing state variables
  const [lstSub, setLstSub] = useState([]);
  const [lstSubscription, setLstSubscription] = useState({});
  const [planExpire, setPlanExpire] = useState("");
  const [questionNo, setQuestionNo] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [loader, setLoader] = useState("");
  const [permission, setPermission] = React.useState("");

  useEffect(() => {
    setChipData(PlannedActivities);
    setChipData2(Interests);
  }, []);

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };
  useEffect(() => {
    onLoad();
    onLoadPermission();
  }, []);
  const onLoad = () => {
    // api/user/paymentlog
    setLoader(true);
    api
      .get(`user/boosterpaymentlog`)
      .then((result) => {
        if (result.success === "true") {
          setLstPaymentLog(result.data.user);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      })
      .finally(() => {
        setLoader(false); // Stop loading
      });

    // api
    //   .get(`user/getcurrentplan`)
    //   .then((result) => {
    //     if (result.success === "true") {
    //       setexpired(result.data);
    //     } else {
    //       console.log(result, "error");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err, "catch error");
    //   });

    api
      .get(`user/getcurrentboosterplan`)
      .then((result) => {
        if (result.success === "true") {
          setexpired(result.data);
          setIsSuccess(true);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        setIsSuccess(false);
        console.log(err, "catch error");
      });

    // Api Call For Fetch The User Data
    api
      .get(`user/getsubscrlist`)
      .then((result) => {
        if (result.success === "true") {
          let lstTmp = [];
          setLstSub(lstTmp);
          setLstSubscription(result.data);
          setPlanExpire(result.data.subscription_date);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };
  const onPaginationChange = (event, page) => {
    setQuestionNo(page - 1);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const onLoadPermission = async () => {
    // Api Call For Fetch The User Data
    await api
      .get(`user/viewprofile`)
      .then((result) => {
        if (result.success === "true") {
          setPermission(result?.data);
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
  };
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = lstPaymentLog.slice(startIndex, endIndex);

  return (
    <>
      {sessionMessage.open && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div>
        <Grid container item>
          <Grid container item className={classes.whiteCardProfile}>
            <Grid
              item
              xs={12}
              style={{
                border: "2px solid #E5E5EA",
                borderRadius: "10px",
                padding: "10px",
                margin: "10px",
              }}
            >
              {isSuccess && (
                <Box className={classes.genderOptionRoot}>
                  {planExpire !== "trial" && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "5px",
                      }}
                    >
                      {permission?.subscription_data?.match_options && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ fontSize: "16px", fontWeight: "400" }}>
                            Your plan will be expired on
                          </div>
                          &nbsp;&nbsp;
                          <div
                            style={
                              moment(planExpire).diff(moment(), "days") <= 7
                                ? {
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "red",
                                  }
                                : {
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "green",
                                  }
                            }
                          >
                            <span>
                              {expired.subscription_date &&
                                moment(expired.subscription_date).format(
                                  "DD/MM/YYYY"
                                )}
                            </span>
                          </div>
                        </div>
                      )}
                    </Grid>
                  )}
                </Box>
              )}
              {loader ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100px"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Grid item xs={12} className={classes.tableRoot}>
                    <PerfectScrollbar style={{ overflowX: "auto" }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Plan</TableCell>
                            <TableCell>Transaction id</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell align="right">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paginatedData.length ? (
                            paginatedData.map((transaction) => (
                              <TableRow hover key={transaction.id}>
                                <TableCell>
                                  {transaction?.subscription_data?.title}
                                </TableCell>
                                <TableCell>
                                  {transaction.transaction_id || ""}
                                </TableCell>
                                <TableCell>
                                  {moment(transaction.payment_date).format(
                                    "LL"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {transaction?.currency === "INR" ? "₹" : "$"}
                                  {transaction.amount}
                                </TableCell>
                                <TableCell>
                                  {formatTime(transaction.payment_date)}
                                </TableCell>
                                {/* <TableCell
                                  align="right"
                                  style={{ color: "#07ad12" }}
                                >
                                  {transaction.status}
                                </TableCell> */}
                                <TableCell
                                  className={
                                    (transaction.status == "Completed" &&
                                      classes.statusCompleted) ||
                                    (transaction.status == "Pending" &&
                                      classes.statusPending) ||
                                    (transaction.status == "Processed" &&
                                      classes.statusProcessing)
                                  }
                                  align="right"
                                  style={{
                                    color: "#07ad12",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {transaction.status}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <NoRecordFound loading={false} noOfColumn={6} />
                          )}
                        </TableBody>
                      </Table>
                    </PerfectScrollbar>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          className={classes.paginationContainer}
        >
          {lstPaymentLog.length > rowsPerPage && (
            <Pagination
              count={Math.ceil(lstPaymentLog.length / rowsPerPage)}
              shape="rounded"
              size="small"
              page={page}
              onChange={handleChange}
            />
          )}
        </Grid>
      </div>
    </>
  );
};

export default YourBoostCurrentPlan;
