import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { Paper, CssBaseline } from '@material-ui/core';
import AOS from 'aos';
import theme from './theme';
import Routes from './Routes';
// import firebase from 'firebase/app';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'aos/dist/aos.css';
import Notification from './firebaseNotifications/Notification'
import AppDataContext from './Helper/AppDataContext'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { isAndroid, isIOS } from "react-device-detect";
import CookiesNotification from 'components/CookiesNotification';
import Api from '../src/Helper/ApiHandler';
var api = new Api();

const browserHistory = createBrowserHistory();

browserHistory.listen(location => {
  // Use setTimeout to make sure this runs after React Router's own listener
  setTimeout(() => {
    // Keep default behavior of restoring scroll position when user:
    // - clicked back button
    // - clicked on a link that programmatically calls `history.goBack()`
    // - manually changed the URL in the address bar (here we might want
    // to scroll to top, but we can't differentiate it from the others)
    if (location.action === 'POP') {
      return;
    }
    // In all other cases, scroll to top
    window.scrollTo(0, 0);
  });
});



// firebase.initializeApp({
//   apiKey: "AIzaSyCfYW_QEh48kj5l2LmEQSOYS7KOYnSIoCs",
//   authDomain: "matchupmates-8fd80.firebaseapp.com",
//   projectId: "matchupmates-8fd80",
//   storageBucket: "matchupmates-8fd80.appspot.com",
//   messagingSenderId: "364213936595",
//   appId: "1:364213936595:web:db8c280a82e5ac11460f26",
//   measurementId: "G-MQXPEBGBGD"
// });



const App = () => {
  AOS.init({
    once: true,
    delay: 50,
    duration: 500,
    easing: 'ease-in-out',
  });
  const [appContext, setAppContext] = useState({});
  const [isMaintance, setIsMaintance] = useState(0);
  const value = { appContext, setAppContext };


  useEffect(() => {
    onLoad()
  }, []);

  const onLoad = async () => {
    // Api Call For Fetch The User Data
    api.get(`maintenancedata`)
      .then((result) => {
        if (result.success === "true") {
          setAppContext({ ...appContext, objAuth: result.data })
          setIsMaintance(result.data.IS_MAINTENANACE)
          // "DORMMATE": 1,
          // "ROOMMATE": 1,
          // "SOULMATE": 1,
          // "TEAMMATE": 0,
          // "IS_MAINTENANACE": 0
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  };

  useEffect(() => {
    console.log(appContext, "appContext")
  }, [appContext]);

  // useEffect(() => {
  //   if (isAndroid) {
  //     const url =
  //       "intent://matchupmates.com/#Intent;scheme=https;package=com.matchupmates.android;end";
  //     window.location.replace(url);
  //   } else if (isIOS) {
  //     window.location.replace("matchupmates://");

  //     setTimeout(() => {
  //       window.location.replace(
  //         "https://apps.apple.com/us/app/matchupmates/id389801252"
  //       );
  //     }, 10000);
  //   }
  //   //  else {
  //   //   window.location.replace(process.env.REACT_APP_URL);
  //   // }
  // }, []);


  return (
    <ThemeProvider theme={theme}>
      {isMaintance ?
        <div>
          Website undermaintance
        </div>
        :
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AppDataContext.Provider value={value}>
            <CssBaseline />
            <Paper elevation={0}>
              <Router history={browserHistory}>
                <Routes />
                <CookiesNotification />
              </Router>
            </Paper>
            <Notification />
          </AppDataContext.Provider>
        </MuiPickersUtilsProvider>
      }
    </ThemeProvider>
  );
}

export default App;