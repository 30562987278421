import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Api from "../../../../Helper/ApiHandler";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import MuiAlert from "@material-ui/lab/Alert";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import validate from "validate.js";
import AppDataContext from "Helper/AppDataContext";
import Cookies from "js-cookie";
import { size } from "lodash-es";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const schema = {};
const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#E5E5E5",
    padding: "35px 0",
    // minHeight: "100vh",
    minHeight: "calc(100vh - 125px)",
    [theme.breakpoints.up("xs")]: {
      paddingTop: "90px",
    },
  },
  questionsText: {
    fontSize: "20px",
    padding: "20px",
    color: "white",
    fontWeight: "bold",
    margin: "0",
  },
  questionsBox: {
    background: "#0071CE",
    borderRadius: "10px 10px 0px 0px",
  },
  startQuizBox: {
    background: "#fff",
    borderRadius: "10px 10px 0px 0px",
    textAlign: "center",
    marginBottom: "50px",
  },
  startText: {
    color: "#FD3576",
    fontSize: "22px",
    // padding: "20px",
    fontWeight: "600",
    margin: "0",
  },
  textFiledBoxchecked: {
    marginTop: "10px",
  },
  startsecondaryText: {
    color: "#3F444F",
    fontSize: "13px",
    // padding: "20px",
    fontWeight: "400",
    margin: "0",
  },
  answerBox: {
    background: "white",
    borderRadius: "0px 0px 10px 10px",
  },
  headingAns: {
    margin: "0",
    padding: "20px",
    paddingBottom: "0",
    fontSize: "18px",
    fontWeight: "600",
    marginLeft: "10px",
  },
  chipUserDetail: {
    margin: "6px 5px",
    fontSize: "14px",
    padding: "0 10px",
    //fontFamily: "Gilroy !important",
    [theme.breakpoints.down("xs")]: {
      padding: "0 7px",
      fontSize: "14px",
      margin: "4px 3px",
    },
  },
  chipInputEdit: {
    // margin: "3px 20px",
    "& .MuiAutocomplete-tag": {
      borderRadius: "5px !important",
      background: "rgb(204 227 245)",
    },
    //fontFamily: "Gilroy !important",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
  },
  chip: {
    background: "#d5606045",
    //fontFamily: "Gilroy !important",
    borderRadius: "5px",
    margin: "5px 5px",
    padding: "0 0",
    height: "28px",
  },
  chipGruop: {
    padding: "10px 20px",
  },
  // autocompletChip: {
  //   background: "white",
  //   '& .MuiChip-root': {
  //     borderRadius: "5px",
  //     background: "#d5606045",
  //     height: "auto",
  //   },
  //   '& .MuiChip-outlined': {
  //     border: "none",
  //     color: "#FE4B6B",
  //     padding: "2px"
  //   },
  //   '& .MuiFilledInput-root': {
  //     background: "white"
  //   }
  // },
  lineHorizontal: {
    width: "95%",
    // borderBottom: "2px solid #000000",
    opacity: "0.1",
    margin: "0 auto",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 25px",
  },
  nextButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    background: "rgba(0, 113, 206, 0.2)",
    width: "80px",
    height: "30px",
  },
  skipButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    textDecoration: "underline",
    marginTop: "5px",
    cursor: "pointer",
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
  },
  buttonStart: {
    // width: "90%",
    width: "95%",
    fontSize: "14px",
    textTransform: "capitalize",
    margin: "20px 0px",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
  },
  mainStartQuixBox: {
    // textAlign: "center",
    // padding: "15px 15px",
    width: "90%",
    margin: "20px auto",
    "& .MuiFormControl-marginNormal": {
      marginTop: "0px",
      marginBottom: "0px",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      padding: "4px",
      borderRadius: "5px",
    },
  },
  textNewStep: {
    fontSize: "16px !important",
    fontWeight: "900",
    width: "60%",
    textAlign: "center",
    margin: "19px auto",
  },
  startQuizMain: {
    marginBottom: "20px",
    padding: "50px 50px 70px 50px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    },
    backgroundColor: "white",
    borderRadius: "10px",
  },
  autocompletChip: {
    padding: "10px",
    background: "white",
    "& .MuiChip-root": {
      borderRadius: "5px",
      background: "#d5606045",
      height: "auto",
    },
    "& .MuiChip-outlined": {
      border: "none",
      color: "#FE4B6B",
      padding: "2px",
    },
    "& .MuiFilledInput-root": {
      background: "white",
    },
  },
  chipIcon: {
    width: "13px",
    height: "13px",
    color: "#FE4B6B",
  },
  editIconPro: {
    position: "absolute",
    top: "80px",
    left: "58.5%",
    [theme.breakpoints.down("xs")]: {
      left: "60.5%",
    },
    cursor: "pointer",
  },
  profilePhotoEdit: {
    position: "relative",
    marginBottom: "10px",
  },
  checkboxDetail: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "35px",
    },
  },
  genderPrefranceHeading: {
    fontSize: "18px",
    fontWeight: "500",
    marginLeft: "12px",
    marginRight: "18px",
  },
  genderSelectBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  banaer: {
    width: "100%",
  },
  banaerRoot: {
    display: "flex",
    alignItems: "center",
    paddingRight: "30px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const Disclaimer = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { teamToken, step4, sessionPropsMessage } =
    (props.location && props.location.state) || {};

  const [sessionMessage, setSessionMessage] = React.useState(
    props.sessionPropsMessage
  );
  const [isPolicyChecked, setIsPolicyChecked] = React.useState(false);
  const [preferenceGender, setPreferenceGender] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const { appContext, setAppContext } = useContext(AppDataContext);
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    onLoad();
  }, []);
  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };
  // Start The Quiz
  const StartTheQuizHandler = async () => {
    const profileData = await api
      .get(`user/viewprofile`)
      .then((result) => {
        if (result.success === "true") {
          return result.data;
        } else {
          throw new Error("Failed to fetch profile data");
        }
      })
      .catch((error) => {
        console.log(error);
        return null;
      });

    if (!profileData) {
      setSessionMessage({
        message: "Error fetching user profile.",
        type: "error",
        open: true,
      });
      return;
    }

    // Check if the user is 18 or older
    const age = calculateAge(profileData.date_of_birth);
    if (age < 18) {
      setSessionMessage({
        message: "You must be 18 years or older to participate.",
        type: "error",
        open: true,
      });
      return;
    }

    // Continue with existing validations
    if (!isPolicyChecked) {
      setSessionMessage({
        message: "Please agree to the Privacy Policy.",
        type: "error",
        open: true,
      });
      return;
    }
    let isQuiz = await AsyncLocalStorage.getItem("quiz");
    let currentModule = await AsyncLocalStorage.getItem("currentModule");

    // console.log(isQuiz, "isQuiz");
    // if (!formState.values.planned_activities?.length) {
    //   setSessionMessage({
    //     message: "Please selecte liked Activities",
    //     type: "error",
    //     open: true,
    //   });
    //   return;
    // }
    // if (!formState.values.interests?.length) {
    //   setSessionMessage({
    //     message: "Please selecte interests",
    //     type: "error",
    //     open: true,
    //   });
    //   return;
    // }
    // // if (!formState.values.gender?.length) {
    // //   setSessionMessage({
    // //     message: 'Please selecte preference gender',
    // //     type: 'error',
    // //     open: true
    // //   });
    // //   return
    // // }

    let planned_activities = formState.values.planned_activities;
    let interests = formState.values.interests;
    let preference_gender = formState.values.gender;

    let data = {
      planned_activities: planned_activities,
      interests: interests,
      preference_gender: preference_gender,
    };
    console.log(data, "data");
    setLoading(true);
    let apiUrl = teamToken
      ? `user/addactivitiesandinterests?teamtoken=${teamToken}`
      : `user/addactivitiesandinterests`;
    // Api Call For personal details
    api
      .post(apiUrl, {
        data: data,
      })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem("personalDeatils", true);
          if (currentModule === "Teammates") {
            let teammateRedirectTo = Cookies.get("teammateRedirectTo");
            if (teammateRedirectTo) {
              Cookies.remove("teammateRedirectTo");
              history.push(teammateRedirectTo);
            } else {
              history.push("/your-matches");
            }
          } else {
            if (isQuiz == "true") {
              let roommateRedirectTo = Cookies.get("roommateRedirectTo");
              if (roommateRedirectTo) {
                Cookies.remove("roommateRedirectTo");
                history.push(roommateRedirectTo);
              }
              history.push("/your-matches");
            } else {
              history.push("/Personal-Details");
            }
          }
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setLoading(false);
      });
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const onLoad = async () => {
    // setLoadingStart(true)
    // Api Call For Fetch The User Data
    await api
      .get(`user/viewprofile`)
      .then((result) => {
        if (result.success === "true") {
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              gender: result.data.preference_gender
                ? result.data.preference_gender
                : [],
              interests: result.data.interests,
              planned_activities: result.data.planned_activities,
            },
          }));
          // setUploadImagePath(result.data.profile_picture);
        } else {
          console.log(result, "error");
        }
        // setLoadingStart(false)
      })
      .catch((err) => {
        console.log(err, "catch error");
        // setLoadingStart(false)
      });
  };
  const handleCheckboxChange = (event) => {
    setIsPolicyChecked(event.target.checked);
  };

  return (
    <>
      <div className={classes.bgProfilePage}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} lg={7} xl={7} className={classes.banaerRoot}>
              <img
                className={classes.banaer}
                src={"/images/matchUp/personalDetail.svg"}
                alt={"Alt Image"}
              />
            </Grid>
            <Grid item xs={12} xl={5} lg={5}>
              <Box className={classes.startQuizMain}>
                <Box className={classes.startQuizBox}>
                  <p className={classes.startText}>Please Confirm</p>
                  <p className={classes.startsecondaryText}>Disclaimer</p>
                </Box>
                {/* <div className={classes.hrLine} /> */}
                <Box className={classes.answerBox}>
                  <Box className={classes.mainStartQuixBox}>
                    <Box
                      style={{
                        height: "350px",
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                      }}
                    >
                      <Typography
                        style={{ margin: "7px 0px", fontSize: "15px" }}
                      >
                        As material consideration of participating in one or
                        more of MatchupMates social referral programs you must
                        agree to be bound by the terms and conditions set forth
                        below. BY CLICKING “I ACCEPT” YOU WILL BE BOUND BY THE
                        FOLLOWING TERMS AND CONDITIONS WHICH MAY AFFECT YOUR
                        LEGAL RIGHTS. BEFORE DOING SO MAKE SURE THAT YOU HAVE
                        READ AND UNDERSTOOD THE FOLLOWING.
                      </Typography>
                      <Typography
                        style={{ margin: "7px 0px", fontSize: "15px" }}
                      >
                        In consideration of participating in one or more of
                        MatchupMates programs I hereby represent that I am at
                        least 18 years age, single; either never married,
                        divorced, widowed or separated (divorce pending and not
                        currently living with my spouse).
                      </Typography>
                      <Typography
                        style={{ margin: "7px 0px", fontSize: "15px" }}
                      >
                        I agree that MatchupMates may disclose information
                        regarding me that I provide to it for the purpose of
                        matching me with a prospective date(s) such as providing
                        such individuals with my personal profiles, photographs,
                        and contact information. I understand and agree that
                        MatchupMates may also disseminate my personal
                        information if it is required to do so by law; or if it
                        has a good faith belief that disclosure is necessary to
                        comply with the law or with legal process served upon
                        it; or to protect and defend its rights or property; or
                        to act in an emergency to protect the safety of
                        another(s).
                      </Typography>
                      <Typography
                        style={{ margin: "7px 0px", fontSize: "15px" }}
                      >
                        I understand and agree that MatchupMates may terminate
                        my involvement in any program(s) with or without cause
                        at any time.
                      </Typography>
                      <Typography
                        style={{ margin: "7px 0px", fontSize: "15px" }}
                      >
                        I understand and agree that MatchupMates may terminate
                        my involvement in any program(s) with or without cause
                        at any time.
                      </Typography>
                      <Typography
                        style={{ margin: "7px 0px", fontSize: "15px" }}
                      >
                        The terms and conditions of this agreement inure to and
                        are binding on my heirs, administrators, executors and
                        representatives. I understand that MatchupMates carries
                        no public liability insurance and makes no
                        representations or warranties, either express or implied
                        as to the accuracy or authenticity of the information
                        furnished by its members/clients. MatchupMates makes
                        absolutely no representations as to the fitness of any
                        individual for any purpose whatsoever. I understand and
                        agree that the information regarding other Clients that
                        may be provided to me has not been verified by
                        MatchupMates. Because MatchupMates makes no
                        representations whatsoever regarding any individual, I
                        understand that I must make every effort to satisfy
                        myself that the individual with whom I plan or agree to
                        interact with is safe, trustworthy and acceptable to me.
                        I hereby expressly, voluntarily, and knowingly agree to
                        fully and completely assume the risk of interacting with
                        any individual(s) with whom I come in contact either
                        directly or indirectly by virtue of my use of
                        MatchupMates services. In further consideration of
                        participating in one or more of MatchupMate programs I
                        agree to fully and completely indemnify and hold
                        harmless MatchupMates, its principals, owners, officers,
                        directors, heirs, successors and assigns, from any and
                        all damages (including, but not limited to costs of
                        defense, attorneys fees and costs of court), claims,
                        causes of action, suits, demands and liabilities of
                        whatever nature whatsoever, including, but not limited
                        to damages for personal injuries and emotional distress
                        that arise either directly or indirectly as the result
                        of any service(s) provided by, any action or failure to
                        act on the part of MatchupMates, whether during the term
                        of this Agreement or at any time thereafter. The
                        foregoing extends to any claim(s) for the improper or
                        negligent dissemination of private, unauthorized or
                        inaccurate information regarding me, that it placed me
                        in a false light, or that I relied to your detriment on
                        any representation, information, opinion, or advice
                        provided by MatchupMates.{" "}
                      </Typography>
                      <Typography
                        style={{ margin: "7px 0px", fontSize: "15px" }}
                      >
                        I agree to submit any dispute arising under this
                        agreement, except a dispute alleging criminal
                        violations, to arbitration before a mutually acceptable
                        neutral arbitrator. In the event that an arbitrator
                        cannot be agreed upon, MatchupMates will refer the
                        matter to a recognized alternative dispute resolution
                        center in the North Carolina area. The case
                        administrator will select a neutral arbitrator, who
                        shall preside over the dispute between the parties. The
                        ruling of the arbitrator shall be final, binding upon
                        the parties and enforceable as a final judgment pursuant
                        to the laws of the State of NC. In the event of a
                        dispute, the prevailing party shall be entitled to
                        reasonable attorney’s fees and costs. This agreement to
                        Arbitration affects important legal rights. I understand
                        that by agreeing to this provision I will not be able to
                        initiate a lawsuit in court.
                      </Typography>
                      <Typography
                        style={{ margin: "7px 0px", fontSize: "15px" }}
                      >
                        This Client Agreement is the entire understanding
                        between you and MatchupMates and no oral representations
                        by either party shall in any way affect this Client
                        Agreement. You represent that you are not acting in
                        reliance upon any agreements, promises or
                        representations not expressly set forth herein. BY
                        CLICKING “I ACCEPT” BELOW I REPRESENT THAT I HAVE READ,
                        UNDERSTOOD AND AGREED TO BE BOUND BY THE FOREGOING TERMS
                        AND CONDITIONS.
                      </Typography>
                    </Box>
                    <Box className={classes.textFiledBoxchecked}>
                      <Checkbox
                        icon={icon}
                        checked={isPolicyChecked} // Bind the state to the checkbox
                        onChange={handleCheckboxChange}
                        // style={{ marginTop: '10px' }}
                        // checked={selected}
                        color="primary"
                        label="chacke"
                      />
                      I Agree to the Privacy Policy
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonStart}
                      endIcon={
                        loading ? (
                          <>
                            &nbsp;
                            <CircularProgress
                              size={20}
                              style={{ color: "white" }}
                            />
                          </>
                        ) : (
                          <img
                            src="/images/matchUp/startAerrow.png"
                            alt="startAerrow"
                          />
                        )
                      }
                      onClick={StartTheQuizHandler}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default Disclaimer;
