import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Button, Box, Grid, Chip } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";

import Api from "../../../../Helper/ApiHandler";
import Slider from "@material-ui/core/Slider";

var api = new Api();

const Zodiac = [
  "Aquarius",
  "Capricorn",
  "Sagittarius",
  "scorpio",
  "libra",
  "virgo",
  "leo",
  "cancer",
  "Gemini",
  "Sagittarius",
  "Taurus",
  "Arias",
];

const Activities = [
  "Shopping",
  "Partying",
  "Travel",
  "cooking",
  "Entrepreneurship",
  "PhotoGraphy",
  "Volunterring",
  "coading",
  "On Campus Event",
  "Stock Trading",
  "Culter Events",
  "Real Eastes",
];
const lifeStyle = [
  "Fitness",
  "Food",
  "Playing",
  "Music",
  "Bargian Hunting",
  "Activism",
  "Make Up",
  "Fashion",
  "Street Wear",
  "Stock Trading",
  "Culter Events",
  "Real Eastes",
];

const useStyles = makeStyles((theme) => ({
  root: {},
  headerModel: {
    height: "70px",
    width: "100%",
    // background: "#0071CE",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
  },
  resultHead: {
    margin: "0",
    padding: "0px 30px",
    color: "white",
    lineHeight: "70px",
    fontWeight: 500,
    fontSize: "20px !important",
    //fontFamily: "Gilroy !important",
  },
  subHeading: {
    margin: "0",
    padding: "10px 40px",
    fontWeight: "600",
    fontSize: "16px !important",
  },
  chipUserDetail: {
    margin: "5px 5px",
    padding: "0 5px",
    color: "#22346E",
    fontWeight: "400",
    //fontFamily: "Gilroy !important",
  },
  chipGruop: {
    padding: "5px 30px",
  },
  chipBoxTotal: {
    padding: "20px 0",
    overflowY: "scroll",
    height: "350px",
  },
  startedButtonBox: {
    textAlign: "center",
    marginTop: "14px",
    marginBottom: "14px",
  },
  startedButton: {
    width: 150,
    height: "40px",
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
  },
  socialIcon: {
    position: "absolute",
    // right: "35px",
    right: "20px",
    top: "15px",
    color: "white",
  },
  rootModel: {
    "& .MuiDialog-paperScrollPaper": {
      height: "500px",
    },
  },
  closeButton: {
    width: 150,
    height: "40px",
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    // background: 'linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)'
  },
  SliderRoot: {
    display: "flex",
    justifyContent: "center",
    "& .MuiSlider-root": {
      width: "80%",
    },
  },
}));

const FilterModel = ({ filterOpen, setFilterOpen, setMaltiFilter }) => {
  const classes = useStyles();
  const [selectedZodiac, setSelectedZodiac] = React.useState([]);
  const [
    selecteOutdoorActivities,
    setSelecteOutdoorActivities,
  ] = React.useState([]);
  const [selectedActivities, setSelectedActivities] = React.useState([]);
  const [selectedLifeStyle, setSelectedLifeStyle] = React.useState([]);
  const [selectedSports, setSelectedSports] = React.useState([]);
  const [lstZodiac, setLstZodiac] = React.useState([]);
  const [lstPlannedActivities, setLstPlannedActivities] = React.useState([]);
  const [lstInterests, setLstInterests] = React.useState([]);
  const [lstOutdoorActivities, setOutdoorActivities] = React.useState([]);
  const [lstSports, setSports] = React.useState([]);
  const [range, setRange] = React.useState(25);

  useEffect(() => {
    getRefineList();
  }, []);
  // -----------------------------------
  // Get Refine List
  const getRefineList = async () => {
    // Api Call For Fetch Refine List List
    api
      .get(`refinelist`)
      .then((result) => {
        if (result.success === "true") {
          for (let obj of result.data) {
            if (obj.type === "Zodiac") setLstZodiac(obj.value);
            else if (obj.type === "Activities")
              setLstPlannedActivities(obj.value);
            else if (obj.type === "Lifestyle") setLstInterests(obj.value);
            else if (obj.type === "Outdoor Activities")
              setOutdoorActivities(obj.value);
            else if (obj.type === "Sports") setSports(obj.value);
          }
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  // Select Zodiac
  const onZodiacChange = (item) => {
    let selected = [...selectedZodiac];
    const index = selected.indexOf(item);
    if (selected.includes(item)) {
      selected.splice(index, 1);
    } else {
      selected.push(item);
    }
    setSelectedZodiac(selected);
  };

  // Select Activities
  const onActivitiesChange = (item) => {
    let selected = [...selectedActivities];
    const index = selected.indexOf(item);
    if (selected.includes(item)) {
      selected.splice(index, 1);
    } else {
      selected.push(item);
    }
    setSelectedActivities(selected);
  };

  // Select LifeStyle
  const onLifeStyleChange = (item) => {
    let selected = [...selectedLifeStyle];
    const index = selected.indexOf(item);
    if (selected.includes(item)) {
      selected.splice(index, 1);
    } else {
      selected.push(item);
    }
    setSelectedLifeStyle(selected);
  };

  // Select Outdoor Activities
  const onOutdoorActivities = (item) => {
    let selected = [...selecteOutdoorActivities];
    const index = selected.indexOf(item);
    if (selected.includes(item)) {
      selected.splice(index, 1);
    } else {
      selected.push(item);
    }
    setSelecteOutdoorActivities(selected);
  };

  // Select Sports
  const onSports = (item) => {
    let selected = [...selectedSports];
    const index = selected.indexOf(item);
    if (selected.includes(item)) {
      selected.splice(index, 1);
    } else {
      selected.push(item);
    }
    setSelectedSports(selected);
  };

  // Apply
  const onApply = () => {
    // let z = [...selectedZodiac].join()
    // let a = [...selectedActivities].join()
    // let l = [...selectedLifeStyle].join()
    // let o = [...selecteOutdoorActivities].join()
    // let s = [...selectedSports].join()
    // let obj = {
    //   z: z,
    //   a: a,
    //   l: l,
    //   o: o,
    //   sp: s
    // }
    let obj = {};
    if (selectedZodiac.length > 0) {
      obj.z = [...selectedZodiac].join();
    }
    if (selectedActivities.length > 0) {
      obj.a = [...selectedActivities].join();
    }
    if (selectedLifeStyle.length > 0) {
      obj.l = [...selectedLifeStyle].join();
    }
    if (selecteOutdoorActivities.length > 0) {
      obj.o = [...selecteOutdoorActivities].join();
    }
    if (selectedSports.length > 0) {
      obj.sp = [...selectedSports].join();
    }
    // if (selectedGender.length > 0) {
    //   obj.g = [...selectedGender].join()
    // }
    if (range) {
      obj.radius = range;
    }
    setMaltiFilter(obj);
    setFilterOpen(false);
  };

  // -----------------------------------

  const handleCloseSignUp = () => {
    setFilterOpen(false);
  };

  const closeHandlerModel = () => {
    setFilterOpen(false);
  };

  const clearFilter = () => {
    setSelectedZodiac([]);
    setSelectedActivities([]);
    setSelectedLifeStyle([]);
    setSelecteOutdoorActivities([]);
    setSelectedSports([]);

    let obj = {
      z: "",
      a: "",
      l: "",
      o: "",
      sp: "",
      radius: "",
    };
    setFilterOpen(false);
    setMaltiFilter(obj);
  };
  function valuetext(value) {
    setRange(value);
    return `${value}°C`;
  }

  const checkswitch = localStorage.getItem("location");
  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={filterOpen}
        onClose={handleCloseSignUp}
        aria-labelledby="max-width-dialog-title"
        className={classes.rootModel}
      >
        <Grid container>
          <Grid item sm={12} xs={12}>
            <div className={classes.headerModel}>
              {/* <IconButton
                onClick={closeHandlerModel}
                className={classes.socialIcon}
              >
                <CloseIcon />
              </IconButton> */}
              <Button
                className={classes.socialIcon}
                onClick={clearFilter}
                variant="outlined"
                // size="large"
                // color="primary"
              >
                Clear
              </Button>
              <h2 className={classes.resultHead}> Refine Your Result </h2>
            </div>
          </Grid>
          <div className={classes.chipBoxTotal}>
            {checkswitch === "true" && (
              <Grid item sm={12} xs={12}>
                <h3 className={classes.subHeading}> Range (miles)</h3>
                <div className={classes.SliderRoot}>
                  <Slider
                    defaultValue={range}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="on"
                    step={10}
                    // marks={marks}
                    min={10}
                    max={100}
                  />
                </div>
              </Grid>
            )}

            <Grid item sm={12} xs={12}>
              <div>
                <h3 className={classes.subHeading}> Zodiac </h3>
                <div className={classes.chipGruop}>
                  {lstZodiac.map((item, index) => (
                    <Chip
                      className={classes.chipUserDetail}
                      label={item}
                      onClick={() => {
                        onZodiacChange(item);
                      }}
                      style={{
                        background: selectedZodiac.includes(item)
                          ? "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)"
                          : "lightgray",
                        color: selectedZodiac.includes(item)
                          ? "white"
                          : "black",
                      }}
                    />
                  ))}
                </div>
              </div>
            </Grid>
            <Grid item sm={12} xs={12}>
              <div>
                <h3 className={classes.subHeading}> Activities </h3>
                <div className={classes.chipGruop}>
                  {lstPlannedActivities.map((item, index) => (
                    <Chip
                      className={classes.chipUserDetail}
                      label={item}
                      onClick={() => {
                        onActivitiesChange(item);
                      }}
                      style={{
                        background: selectedActivities.includes(item)
                          ? "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)"
                          : "lightgray",
                        color: selectedActivities.includes(item)
                          ? "white"
                          : "black",
                      }}
                    />
                  ))}
                </div>
              </div>
            </Grid>
            <Grid item sm={12} xs={12}>
              <div>
                <h3 className={classes.subHeading}> LifeStyle </h3>
                <div className={classes.chipGruop}>
                  {lstInterests.map((item, index) => (
                    <Chip
                      className={classes.chipUserDetail}
                      label={item}
                      onClick={() => {
                        onLifeStyleChange(item);
                      }}
                      style={{
                        background: selectedLifeStyle.includes(item)
                          ? "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)"
                          : "lightgray",
                        color: selectedLifeStyle.includes(item)
                          ? "white"
                          : "black",
                      }}
                    />
                  ))}
                </div>
              </div>
            </Grid>
            <Grid item sm={12} xs={12}>
              <div>
                <h3 className={classes.subHeading}> Outdoor Activities </h3>
                <div className={classes.chipGruop}>
                  {lstOutdoorActivities.map((item, index) => (
                    <Chip
                      className={classes.chipUserDetail}
                      label={item}
                      onClick={() => {
                        onOutdoorActivities(item);
                      }}
                      style={{
                        background: selecteOutdoorActivities.includes(item)
                          ? "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)"
                          : "lightgray",
                        color: selecteOutdoorActivities.includes(item)
                          ? "white"
                          : "black",
                      }}
                    />
                  ))}
                </div>
              </div>
            </Grid>
            <Grid item sm={12} xs={12}>
              <div>
                <h3 className={classes.subHeading}> Sports </h3>
                <div className={classes.chipGruop}>
                  {lstSports.map((item, index) => (
                    <Chip
                      className={classes.chipUserDetail}
                      label={item}
                      onClick={() => {
                        onSports(item);
                      }}
                      style={{
                        background: selectedSports.includes(item)
                          ? "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)"
                          : "lightgray",
                        color: selectedSports.includes(item)
                          ? "white"
                          : "black",
                      }}
                    />
                  ))}
                </div>
              </div>
            </Grid>
          </div>
          <Grid item sm={12} xs={12}>
            <hr />
            <Box mb={3} mt={3} className={classes.startedButtonBox}>
              <Button
                className={classes.closeButton}
                onClick={closeHandlerModel}
                variant="outlined"
                // size="large"
                // color="primary"
              >
                Close
              </Button>
              &nbsp;&nbsp;
              <Button
                className={classes.startedButton}
                variant="contained"
                size="large"
                color="primary"
                disabled={
                  !range &&
                  !selectedZodiac.length &&
                  !selectedActivities.length &&
                  !selectedLifeStyle.length &&
                  !selecteOutdoorActivities.length &&
                  !selectedSports.length
                    ? true
                    : false
                }
                onClick={() => {
                  onApply();
                }}
              >
                Apply
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default FilterModel;
