import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Snackbar,
  Tab,
  InputAdornment,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import FilterModel from "../FilterModel";
import { Link } from "react-router-dom";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import MuiAlert from "@material-ui/lab/Alert";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import InfiniteScroll from "react-infinite-scroll-component";
import { withStyles } from "@material-ui/styles";
import Header from "../Header";
import FilterModelRoommate from "../FilterModelRoommate";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import { useGeolocated } from "react-geolocated";
import axios from "axios";

import HomeIcon from "@material-ui/icons/Home";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import useRouter from "utils/useRouter";

import Api from "../../../../Helper/ApiHandler";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import ImageWithLoading from "components/ImageWithLoading/ImageWithLoading";
import UserSkeletonCard from "components/Skeleton/UserSkeletonCard";
var api = new Api();

const lstGender = [
  { id: "male", value: "Male" },
  { id: "female", value: "Female" },
  { id: "intersex", value: "Intersex" },
  { id: "trans", value: "Trans" },
  { id: "noPreference", value: "Other" },
];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #c4c4c4",
  },
  indicator: {
    backgroundColor: "#FE4B6B",
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: "40%",
    fontSize: "18px",
    fontWeight: "500px",
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#FE4B6B",
      opacity: 1,
    },
    "&$selected": {
      color: "#FE4B6B",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#FE4B6B",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  rootTab: {
    width: "100%",
    paddingLeft: "17px",
    // marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      // marginTop: "0px",
    },
  },
  mainRoot: {
    // backgroundColor: "E9EDF0 !important",
    background: "#E9EDF0",
    paddingTop: "35px",
    minHeight: "90vh",
    // minHeight: "calc(100vh - 125px)"
  },
  root: {
    width: "100%",
    height: "110px",
    background: "white",
    paddingBottom: "10px !important",
    [theme.breakpoints.down("xs")]: {
      height: "210px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "210px",
    },
  },
  root1: {
    width: "100%",
    height: "110px",
    background: "white",
    paddingBottom: "10px !important",
    [theme.breakpoints.down("xs")]: {
      // height: "210px",
    },
    [theme.breakpoints.down("sm")]: {
      // height: "210px",
    },
  },
  labelSearch: {
    margin: "0",
    marginTop: "10px",
    marginBottom: "5px",
    fontWeight: "500",
  },
  searchInput: {
    // width: "350px",
    // marginRight: "10px",
    background: "#E9EDF0",
    // [theme.breakpoints.down("xs")]: {
    //   width: "240px",
    // },
    "& .MuiOutlinedInput-notchedOutline": {
      // borderWidth: 0
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "14px",
    },
  },
  formControl: {
    width: "100%",
    background: "#E9EDF0",
    "& .MuiAutocomplete-inputRoot": {
      padding: "11px",
    },
  },
  dateInput: {
    width: "200px",
    background: "#E9EDF0",
  },
  buttonFilter: {
    textAlign: "center",
    height: 39, // marginTop: "35px",
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    background: "#E9EDF0",
    boxShadow: "none",
    fontWeight: "500",
    minWidth: "100%",
  },
  filterIconBtn: {
    // marginLeft: "10px",
    // "& .MuiButton-outlined": {
    //   padding: "10px 15px",
    // },
  },
  listBg: {
    background: "#E9EDF0",
  },
  matchesHead: {
    margin: "0",
    padding: "20px 30px",
    paddingBottom: "10px",
    fontWeight: "600 !important",
    fontSize: "18px",
  },
  cardUser: {
    background: "white",
    padding: "20px 10px",
    [theme.breakpoints.down("xs")]: {
      padding: "55px 10px",
    },
    paddingBottom: "10px",
    textAlign: "center",
    margin: "7px",
    borderRadius: "15px",
    height: "260px",
  },
  nameTextCard: {
    color: "black",
    marginBottom: "0",
    marginTop: "0",
    fontSize: "18px",
    fontWeight: "400",
  },
  textCityName: {
    marginTop: "5px",
    color: "#6C6C6C",
    fontSize: "14px",
    maxHeight: "40px",
    minHeight: "22px",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  chipUserDetail: {
    whiteSpace: "nowrap",
    width: "70px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "2px 5px",
    color: "#FE4B6B",
    fontWeight: "500",
    height: "20px",
    fontSize: "12px",
    background: "#FFEDF0",
    //fontFamily: "Gilroy !important",
  },
  cardMatched: {
    position: "relative",
  },
  cardBoxGrid: {
    // paddingBottom: "60px",
    // padding: "10px",
  },
  imageMatches: {
    width: "120px",
    height: "120px",
    [theme.breakpoints.down("xs")]: {
      width: "80px",
      height: "80px",
    },
    borderRadius: "50%",
    objectFit: "cover",
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  bottom: {
    color: theme.palette.grey[300],
  },
  top: {
    // color: '#1a90ff',
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
  filterSearchBtn: {
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    fontWeight: "300",
    // padding: "10px"
    borderRadius: "50%",
  },
  menuItemSelect: {
    //fontFamily: "Gilroy !important",
  },
  selectOptionInGender: {
    width: "210px",
    //fontFamily: "Gilroy !important",
    "& .MuiOutlinedInput-notchedOutline": {
      // borderWidth: 0
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
      fontSize: "12px",
    },
    backgroundColor: "#e9edf0",
  },
  selectOptionIn: {
    // width: "350px",
    // [theme.breakpoints.down("sm")]: {
    //   width: "300px",
    // },
    //fontFamily: "Gilroy !important",
    "& .MuiOutlinedInput-notchedOutline": {
      // borderWidth: 0
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
      fontSize: "14px",
    },
    backgroundColor: "#e9edf0",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  rootFilter: {
    alignItems: "center",
    marginTop: "0px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "25px",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  rootAFilter: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-around",
      marginTop: "10px",
    },
    justifyContent: "flex-end",
  },
  rootA1Filter: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      paddingLeft: 7,
    },
    // display: "flex",
    // alignItems: "center",
  },
  rootBFilter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },
  rootB1Filter: {
    display: "flex",
    alignItems: "center",
  },
  googleSelecte: {
    display: "flex",
    alignItems: "center",
    // marginRight: "10px",
    "& .css-yk16xz-control": {
      // borderRadius: "10px",
      // padding: "8px",
      width: "300px",
      backgroundColor: "#e9edf0",
    },
    "& .css-1pahdxg-control": {
      // borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      // padding: "8px"
      width: "300px",
    },
    "& .css-1pahdxg-control:hover": {
      // borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      // padding: "8px"
      backgroundColor: "#e9edf0",
    },
    "& .css-1uccc91-singleValue": {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
    },
    "& .css-1wa3eu0-placeholder": {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
    },
    "& div": {
      fontFamily: "'Poppins', sans-serif",
      // backgroundColor: "white",
      // backgroundColor: "#e9edf0",
      zIndex: 11,
    },
  },
  propertyAutocomplet: {
    display: "flex",
    // marginRight: "10px",
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      padding: "11px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  },
  carosalImageBox: {
    height: "120px",
    width: "120px",
    margin: "auto",
    backgroundColor: "gray",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "50%",
    [theme.breakpoints.down("xs")]: {
      width: 80,
      height: 80,
    },
  },
}));

const MatchesList = (props) => {
  const { sessionPropsMessage } = props;
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();

  const [filterOpen, setFilterOpen] = React.useState(false);
  const [filterPropertyOpen, setFilterPropertyOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingScroll, setLoadingScroll] = React.useState(false);
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [filter, setFilter] = React.useState({});
  const [filterProperty, setFilterProperty] = React.useState({});
  const [matches, setMatches] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [searchMatch, setSearchMatch] = React.useState("");
  const [sessionMessage, setSessionMessage] = React.useState(
    sessionPropsMessage
  );
  const [lstUniversity, setLstUniversity] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(12);
  const [totalRecored, setTotalRecored] = React.useState(0);
  const [value, setValue] = React.useState(0);
  const [isGoogleSearchShow, setIsGoogleSearchShow] = React.useState(true);
  const [lstProperty, setLstProperty] = React.useState([]);
  const [lstLocationWithProperty, setLstLocationWithProperty] = React.useState(
    []
  );
  const [locationValue, setLocationValue] = React.useState("");

  // const [propertyLat, setPropertyLat] = React.useState(undefined);
  // const [propertyLng, setPropertyLng] = React.useState(undefined);
  const [objPropertyLtLg, setobjPropertyLtLg] = React.useState({
    lat: undefined,
    lng: undefined,
    // lat: localStorage.getItem('puniversity_lat'),
    // lng: localStorage.getItem('puniversity_lag')
  });
  const [objMatchesLtLg, setobjMatchesLtLg] = React.useState({
    lat: undefined,
    lng: undefined,
  });
  console.log(objPropertyLtLg, "objPropertyLtLg");

  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
    AsyncLocalStorage.setItem("tabValue", newValue);
    // MatchesRoommate
  };

  const onCheckIsQuiz = () => {
    api
      .get(`user/getquizstatus`)
      .then((result) => {
        if (result.success === "true") {
          console.log(result.data.quiz, "success");
          if (!result.data.quiz) {
            router.history.push("/matching-quiz");
          }
        } else {
          console.log(result, "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "catch error");
      });
  };

  useEffect(() => {
    getCurrantTab();
    getProperty();
    onCheckIsQuiz();
  }, []);

  const getProperty = async () => {
    // Api Call For Fetch Property List
    api
      .get(`allproperty`)
      .then((result) => {
        if (result.success === "true") {
          setLstProperty(result.data);
          console.log(result.data, "result.data");
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const getCurrantTab = async () => {
    let tmp = await AsyncLocalStorage.getItem("tabValue");
    setValue(parseInt(tmp));
  };

  const fetchMoreMatches = () => {
    if (matches.length >= totalRecored) {
      setHasMore(false);
      return;
    }

    // setTimeout(() => {
    // setItems(items.concat(Array.from({ length: 20 })))
    setPage(page + 1);
    // onMore()
    // }, 500);
  };

  const handleClickOpenSignUp = () => {
    setFilterOpen(true);
  };

  // for filter change
  const onFilterChange = (value, key) => {
    if (key === "s") {
      setPage(0);
    }
    let ftr = { ...filter };
    ftr[key] = value;
    setFilter(ftr);
  };

  // for filter change
  const onFilterPropertyChange = (value, key) => {
    if (key === "s") {
      setPage(0);
    }
    let ftr = { ...filter };
    ftr[key] = value;
    setFilterProperty(ftr);
  };

  const setMaltiFilter = (obj) => {
    let ftr = { ...filter };
    Object.keys(obj).forEach(function(key) {
      ftr[key] = obj[key];
    });
    setFilter(ftr);
  };

  const setMaltiFilterProperty = (obj) => {
    let ftr = { ...filterProperty };
    Object.keys(obj).forEach(function(key) {
      ftr[key] = obj[key];
    });
    setFilterProperty(ftr);
  };

  useEffect(() => {
    // onLoad()
    if (page === 0 && objMatchesLtLg.lat)
      (() => {
        // console.log(coords, "coords")
        setLoading(true);
        let filterFinal = filter.radius ? filter : { ...filter, radius: 25 };
        // Api Call For Fetch The Our Matches
        api
          .get(
            `user/yourroommatematch?latitude=${objMatchesLtLg.lat}&longitude=${objMatchesLtLg.lng}&page=${page}&size=${rowsPerPage}`,
            { params: filterFinal }
          )
          .then((result) => {
            if (result.success === "true") {
              setMatches(result.data.user);
              setTotalRecored(result.data.totalItems);
            } else {
              console.log(result, "error");
            }
            setLoading(false);
            setInitialLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            setInitialLoading(false);
            console.log(err, "catch error");
          });
      })();
  }, [filter, rowsPerPage, page, objMatchesLtLg]);

  useEffect(() => {
    setPage(0);
    getUniversity();
  }, [filter]);

  // const onLoad = () => {
  //   setLoading(true)
  //   // Api Call For Fetch The Our Matches
  //   api.get(`user/yourroommatematch?page=${page}&size=${rowsPerPage}`, { params: filter })
  //     .then((result) => {
  //       if (result.success === "true") {
  //         setMatches(result.data.user)
  //         setTotalRecored(result.data.totalItems)
  //       } else {
  //         console.log(result, "error")
  //       }
  //       setLoading(false)
  //     })
  //     .catch((err) => {
  //       setLoading(false)
  //       console.log(err, "catch error")
  //     });
  // };

  useEffect(() => {
    // onMore()
    if (page !== 0 && objMatchesLtLg.lat) {
      (() => {
        setLoadingScroll(true);
        // Api Call For Fetch The Our Matches
        let filterFinal = filter.radius ? filter : { ...filter, radius: 25 };
        api
          .get(
            `user/yourroommatematch?latitude=${objMatchesLtLg.lat}&longitude=${objMatchesLtLg.lng}&page=${page}&size=${rowsPerPage}`,
            { params: filterFinal }
          )
          .then((result) => {
            if (result.success === "true") {
              // console.log(result, 'result.data')
              const lstTmp = matches.concat(result.data.user);
              setMatches(lstTmp);
              setTotalRecored(result.data.totalItems);
            } else {
              console.log(result, "error");
            }
            setLoadingScroll(false);
          })
          .catch((err) => {
            setLoadingScroll(false);
            console.log(err, "catch error");
          });
      })();
    }
  }, [page, rowsPerPage, filter]);

  // const onMore = () => {
  //   setLoadingScroll(true)
  //   // Api Call For Fetch The Our Matches

  //   api.get(`user/yourroommatematch?page=${page}&size=${rowsPerPage}`, { params: filter })
  //     .then((result) => {
  //       if (result.success === "true") {
  //         // console.log(result, 'result.data')
  //         const lstTmp = matches.concat(result.data.user);
  //         setMatches(lstTmp)
  //         setTotalRecored(result.data.totalItems)
  //       } else {
  //         console.log(result, "error")
  //       }
  //       setLoadingScroll(false)
  //     })
  //     .catch((err) => {
  //       setLoadingScroll(false)
  //       console.log(err, "catch error")
  //     });
  // };

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleAutocompletChange = (event, value, name) => {
    if (name === "u") {
      if (value !== null) {
        let ftr = { ...filter };
        ftr["u"] = value.value;
        setFilter(ftr);
      }
    } else if (name === "g") {
      console.log(value, "value");
      if (value !== null) {
        let ftr = { ...filter };
        ftr["g"] = value.value;
        setFilter(ftr);
      } else {
        let ftr = { ...filter };
        ftr["g"] = null;
        setFilter(ftr);
      }
    } else if (name === "propertySearch") {
      console.log(value, "call");
      if (value) {
        setobjPropertyLtLg({
          lat: value.latitude,
          lng: value.longitude,
        });
        // setPropertyLat(value.latitude)
        // setPropertyLng(value.longitude)
      }
    } else if (name === "lstLocationWithProperty") {
      console.log(value, "call lstLocationWithProperty");
      if (value) {
        setLocationValue(value);
        setobjPropertyLtLg({
          lat: value.latitude,
          lng: value.longitude,
        });
      }
    }
  };
  // Get University
  const getUniversity = async (search) => {
    // Api Call For Fetch University List
    api
      .get(`universitylist?s=` + search)
      .then((result) => {
        if (result.success === "true") {
          console.log(result.data, "result.data");
          setLstUniversity(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const changeOptionBaseOnValue = (e) => {
    getUniversity(e.target.value);
  };

  const setLocation = (value) => {
    if (value) {
      geocodeByAddress(value.value.description)
        .then((results) => {
          setobjPropertyLtLg({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
          console.log(results[0].geometry.location.lat(), "call lat");
          console.log(results[0].geometry.location.lng(), "call lat");
        })
        .catch((error) => console.error("valuevaluevalue", error));
    } else {
      setobjPropertyLtLg({
        lat: undefined,
        lng: undefined,
      });
    }
  };

  useEffect(() => {
    latLngUniOnLoad();
  }, []);

  const latLngUniOnLoad = async () => {
    let university = await AsyncLocalStorage.getItem("university");
    let puniversity_lat = await AsyncLocalStorage.getItem("puniversity_lat");
    let puniversity_long = await AsyncLocalStorage.getItem("puniversity_long");
    // getLocationList(university)
    setobjPropertyLtLg({
      lat: puniversity_lat,
      lng: puniversity_long,
    });
    setobjMatchesLtLg({
      lat: puniversity_lat,
      lng: puniversity_long,
    });
  };

  const latLngUniOnLoadReset = async () => {
    let university = await AsyncLocalStorage.getItem("university");
    let puniversity_lat = await AsyncLocalStorage.getItem("puniversity_lat");
    let puniversity_long = await AsyncLocalStorage.getItem("puniversity_long");
    // getLocationList(university)
    setLstLocationWithProperty([]);
    setLocationValue("");
    setobjPropertyLtLg({
      lat: puniversity_lat,
      lng: puniversity_long,
    });
    setobjMatchesLtLg({
      lat: puniversity_lat,
      lng: puniversity_long,
    });
  };

  const changeOptionBaseOnValueLocation = (e) => {
    getLocationList(e.target.value);
  };

  // Get Location List
  const getLocationList = async (locationText) => {
    // Api Call For Fetch Location List
    console.log("call api");
    api
      .get(`allproperty?s=` + locationText)
      .then((result) => {
        if (result.success === "true") {
          console.log(result.data, "result.data");
          setLstLocationWithProperty(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  return (
    <>
      <div className={classes.mainRoot}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid item className={classes.container}>
              <div className={classes.listBg}>
                {/* <Container maxWidth="lg"> */}
                <Grid container item xs={12} justify="center">
                  <Box width={"100%"} className={classes.rootFilter}>
                    <Grid item xs={12} sm={6}>
                      <Box width={"fit-content"}>
                        <AntTabs
                          value={value}
                          className={classes.tabHade}
                          onChange={handleChange}
                          aria-label="ant example"
                          style={{ marginLeft: "25px", width: "90%" }}
                        >
                          <AntTab label="Properties" />
                          <AntTab label="Find Matches" />
                        </AntTabs>
                      </Box>
                    </Grid>
                    <Grid
                      container
                      spacing={1}
                      item
                      xs={12}
                      sm={6}
                      className={classes.rootA1Filter}
                    >
                      {value === 0 && (
                        <>
                          {/* <Box className={classes.rootBFilter}> */}
                          <Grid item xs={9} sm={10}>
                            {isGoogleSearchShow ? (
                              <div className={classes.propertyAutocomplet}>
                                <Autocomplete
                                  options={lstLocationWithProperty}
                                  // value={getSelectedUniversityItem()}
                                  onInputChange={(e) => {
                                    if (e.target.value) {
                                      changeOptionBaseOnValueLocation(e);
                                    }
                                    //  else {
                                    //   latLngUniOnLoadReset()
                                    // }
                                  }}
                                  onBlur={(e, value) => {
                                    console.log(
                                      e.target.value,
                                      value,
                                      "call--"
                                    );
                                    if (!e.target.value) {
                                      latLngUniOnLoadReset();
                                    }
                                  }}
                                  autoHighlight
                                  getOptionLabel={(lstLocationWithProperty) =>
                                    lstLocationWithProperty.value
                                  }
                                  fullWidth
                                  noOptionsText={"Type for search..."}
                                  name="lstLocationWithProperty"
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                      style={{ display: "flex" }}
                                    >
                                      <div style={{ marginRight: "5px" }}>
                                        {props.type === "Property" ? (
                                          <HomeIcon />
                                        ) : (
                                          <LocationOnIcon />
                                        )}
                                      </div>
                                      <div>{props.value}</div>
                                      {/* {props.value} */}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Search..."
                                      // label="Search"
                                      autoFocus={false}
                                      InputLabelProps={{
                                        classes: {
                                          asterisk: classes.asterisk,
                                        },
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                      }}
                                      variant="outlined"
                                      // name="university"
                                      fullWidth
                                      className={classes.selectOptionIn}
                                    />
                                  )}
                                  // onChange={(e) => { onFilterChange(e.target.value, 'u') }}
                                  onChange={(e, value) => {
                                    handleAutocompletChange(
                                      e,
                                      value,
                                      "lstLocationWithProperty"
                                    );
                                  }}
                                />
                              </div>
                            ) : (
                              <Box className={classes.propertyAutocomplet}>
                                <Autocomplete
                                  options={lstProperty}
                                  // value={getSelectedUniversityItem()}
                                  // onClose={(e) => {
                                  //   handleAutocompletChange(e, "", "g")
                                  // }}
                                  // onInputChange={(e) => {
                                  //   changeOptionBaseOnValue(e);
                                  // }}
                                  autoHighlight
                                  noOptionsText={"Type for search..."}
                                  getOptionLabel={(lstProperty) =>
                                    lstProperty.property_name
                                  }
                                  fullWidth
                                  name="searchProperty"
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      {props.property_name}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Search Property"
                                      autoFocus={false}
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                      }}
                                      variant="outlined"
                                      fullWidth
                                      className={classes.selectOptionIn}
                                    />
                                  )}
                                  onChange={(e, value) => {
                                    handleAutocompletChange(
                                      e,
                                      value,
                                      "propertySearch"
                                    );
                                    // onFilterChange(e.target.value, 'g')
                                  }}
                                />

                                <div
                                  style={{
                                    cursor: "pointer",
                                    border: "1px solid #FE4B6B",
                                    padding: "5px 5px 0px 5px",
                                    marginLeft: "10px",
                                    borderRadius: "5px",
                                  }}
                                  onClick={() => {
                                    setIsGoogleSearchShow(true);
                                  }}
                                >
                                  <HomeIcon style={{ color: "#FE4B6B" }} />
                                </div>
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={3} sm={2}>
                            <Box className={classes.filterIconBtn}>
                              <Button
                                fullWidth
                                variant="outlined"
                                color="default"
                                className={classes.buttonFilter}
                                onClick={() => setFilterPropertyOpen(true)}
                                // startIcon={<img src="/images/matchUp/filter.png" alt="filter" />}
                              >
                                <img
                                  src="/images/matchUp/filter.png"
                                  alt="filter"
                                />
                              </Button>
                            </Box>
                          </Grid>
                          {/* </Box> */}
                        </>
                      )}
                      {value === 1 && (
                        <>
                          {/* <Box className={classes.rootAFilter}> */}
                          <Grid item xs={9} sm={10}>
                            <form
                              onSubmit={(e) => e.preventDefault()}
                              // className={classes.formControl}
                            >
                              {/* <p className={classes.labelSearch}> Search </p> */}
                              <Box style={{ display: "flex" }}>
                                <TextField
                                  // id="outlined-basic"
                                  placeholder="Search..."
                                  variant="outlined"
                                  size="small"
                                  value={searchMatch}
                                  className={classes.searchInput}
                                  fullWidth
                                  onChange={(e) => {
                                    setSearchMatch(e.target.value);
                                    // onFilterChange(e.target.value, 's')
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <SearchIcon
                                          style={{ cursor: "pointer" }}
                                          color="primary"
                                          onClick={() => {
                                            onFilterChange(searchMatch, "s");
                                          }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                {/* <Button
                            variant="contained"
                            className={classes.filterSearchBtn}
                            color="primary"
                            onClick={() => { onFilterChange(search, 's') }}
                          >
                            Search
                          </Button> */}
                              </Box>
                            </form>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            sm={2}
                            // className={classes.filterIconBtn}
                          >
                            <Button
                              fullWidth
                              variant="outlined"
                              color="default"
                              className={classes.buttonFilter}
                              onClick={handleClickOpenSignUp}
                            >
                              <img
                                src="/images/matchUp/filter.png"
                                alt="filter"
                              />
                            </Button>
                          </Grid>
                          {/* </Box> */}
                        </>
                      )}
                    </Grid>
                  </Box>

                  <div className={classes.rootTab}>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                      <Header
                        filterProperty={filterProperty}
                        objPropertyLtLg={objPropertyLtLg}
                        locationValue={locationValue}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                      <InfiniteScroll
                        dataLength={matches.length}
                        // next={fetchMoreData}
                        next={fetchMoreMatches}
                        hasMore={true}
                        loader={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "95%",
                              margin: "10px",
                            }}
                          ></div>
                        }
                        endMessage={<p style={{ paddingBottom: "60px" }}></p>}
                      >
                        <Grid
                          justifyContent="center"
                          alignItems="center"
                          className={classes.cardBoxGrid}
                          container
                        >
                          {/* > */}
                          {/* {items.map((i, index) => (
                        <div style={style} key={index}>
                          div - #{index}
                        </div>
                      ))} */}
                          <Grid
                            justifyContent="center"
                            alignItems="center"
                            className={classes.cardBoxGrid}
                            container
                          >
                            {!initialLoading && !loading ? (
                              <>
                                {matches.length ? (
                                  matches.map((item, index) => (
                                    <Grid
                                      key={index}
                                      item
                                      xs={6}
                                      sm={6}
                                      xl={3}
                                      lg={3}
                                      className={classes.cardMatched}
                                    >
                                      <Link
                                        style={{ textDecoration: "none" }}
                                        to={`/your-matches/view/${item.slug}`}
                                      >
                                        <Tooltip
                                          title={`${capitalizeFirstLetter(
                                            item.first_name
                                          )} and you  match ${
                                            item.matchPercent !== "NaN"
                                              ? item.matchPercent
                                              : 0
                                          }%`}
                                          placement="top"
                                        >
                                          <Box
                                            position="absolute"
                                            top={20}
                                            right={20}
                                          >
                                            <CircularProgress
                                              variant="static"
                                              value={100}
                                              size={35}
                                              thickness={4}
                                              className={classes.bottom}
                                            />
                                            <CircularProgress
                                              variant="static"
                                              initialAnimation={true}
                                              value={
                                                item.matchPercent ||
                                                item.matchPercent !== "NaN"
                                                  ? item.matchPercent
                                                  : 0
                                              }
                                              size={35}
                                              thickness={4}
                                              className={classes.top}
                                              style={{
                                                transform: "rotate(270deg)",
                                              }}
                                            />
                                            <Box
                                              top={0}
                                              left={0}
                                              bottom={8}
                                              right={0}
                                              position="absolute"
                                              display="flex"
                                              alignItems="center"
                                              justifyContent="center"
                                            >
                                              <Typography
                                                variant="caption"
                                                component="div"
                                                color="textSecondary"
                                                style={{
                                                  fontSize: "10px",
                                                  paddingTop: "2px",
                                                }}
                                              >
                                                {item.matchPercent &&
                                                item.matchPercent !== "NaN"
                                                  ? item.matchPercent
                                                  : 0}
                                                %
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </Tooltip>

                                        <div className={classes.cardUser}>
                                          {/* <ImageWithLoading
                                            url={item.profile_picture ? item.profile_picture : "/images/male_defualt.svg"}
                                            // style={classes.imageMatches}
                                            className={classes.imageMatches}
                                          /> */}
                                          <div
                                            className={classes.carosalImageBox}
                                          >
                                            <img
                                              src={
                                                item.profile_picture
                                                  ? item.profile_picture
                                                  : "/images/male_defualt.svg"
                                              }
                                              alt="profile_picture"
                                              className={classes.imageMatches}
                                            />
                                          </div>

                                          <Tooltip
                                            title={
                                              capitalizeFirstLetter(
                                                item.first_name
                                              ) +
                                              "  " +
                                              capitalizeFirstLetter(
                                                item.last_name
                                              )
                                            }
                                            placement="top"
                                          >
                                            <h4
                                              className={classes.nameTextCard}
                                            >
                                              {capitalizeFirstLetter(
                                                item.first_name
                                              )}
                                            </h4>
                                          </Tooltip>
                                          <Tooltip
                                            title={"Location"}
                                            placement="top"
                                          >
                                            <p className={classes.textCityName}>
                                              {item.location}
                                            </p>
                                          </Tooltip>
                                          {item.gender && item.zodiac && (
                                            <div className={classes.hrLine} />
                                          )}
                                          {item.gender && (
                                            <>
                                              {!item.zodiac && (
                                                <div
                                                  className={classes.hrLine}
                                                />
                                              )}
                                            </>
                                          )}
                                          {item.zodiac && (
                                            <>
                                              {!item.gender && (
                                                <div
                                                  className={classes.hrLine}
                                                />
                                              )}
                                            </>
                                          )}
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {item.gender && (
                                              <Tooltip
                                                title={capitalizeFirstLetter(
                                                  item.gender
                                                )}
                                                placement="top"
                                              >
                                                <Chip
                                                  className={
                                                    classes.chipUserDetail
                                                  }
                                                  label={capitalizeFirstLetter(
                                                    item.gender
                                                  )}
                                                />
                                              </Tooltip>
                                            )}
                                            {item.zodiac && (
                                              <Tooltip
                                                title={item.zodiac}
                                                placement="top"
                                              >
                                                <Chip
                                                  className={
                                                    classes.chipUserDetail
                                                  }
                                                  label={capitalizeFirstLetter(
                                                    item.zodiac
                                                  )}
                                                />
                                              </Tooltip>
                                            )}
                                          </div>
                                        </div>
                                      </Link>
                                    </Grid>
                                  ))
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                      marginTop: "50px",
                                    }}
                                  >
                                    <img
                                      src="/images/matchUp/notFoundMatches.svg"
                                      alt="favAlt"
                                    />
                                    <Typography
                                      variant="caption"
                                      component="primary"
                                      color="primary"
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                        fontWeight: "600",
                                      }}
                                    >
                                      No Matches Yet!
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      component="primary"
                                      color="primary"
                                      style={{
                                        fontSize: "14px",
                                        color: "gray",
                                        fontWeight: "400",
                                      }}
                                    >
                                      Mark your favorite profile and always have
                                      them here.
                                    </Typography>
                                  </div>
                                )}
                              </>
                            ) : (
                              <SkeletonLoading />
                            )}
                            {loadingScroll && <SkeletonLoading />}
                          </Grid>
                        </Grid>
                      </InfiniteScroll>
                    </TabPanel>

                    {/* </SwipeableViews> */}
                  </div>
                </Grid>
                {/* <h5 className={classes.matchesHead}> Your Matches Roommate</h5> */}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <FilterModel
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        setMaltiFilter={setMaltiFilter}
      />
      <FilterModelRoommate
        filterOpen={filterPropertyOpen}
        setFilterOpen={setFilterPropertyOpen}
        setMaltiFilter={setMaltiFilterProperty}
      />
    </>
  );
};

const SkeletonLoading = () => {
  const classes = useStyles();
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      className={classes.cardBoxGrid}
      container
    >
      {[0, 1, 2, 3, 4, 5, 6, 7].map((obj) => {
        return (
          <Grid item xs={6} sm={6} xl={3} lg={3}>
            <UserSkeletonCard />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default MatchesList;
