import React from 'react'
import { makeStyles } from "@material-ui/styles";
import moment from 'moment';
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import useRouter from 'utils/useRouter';
import firebase from "firebase/app";
import Name from './Name';
import ProfilepictureForGroup from './ProfilepictureForGroup';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "5px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "10px",
    },
  },
  bgProfilePage: {
    background: "#e9edf0",
    [theme.breakpoints.down('xs')]: {
      padding: "0",
    },
  },
  inboxRoot: {
    display: "flex",
    padding: "5px",
    cursor: "pointer"
  },
  inboxRootSelected: {
    display: "flex",
    padding: "5px",
    cursor: "pointer",
    backgroundColor: "#f9dade"
  },
  inboxImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover"
  },
  inboxNameRoot: {
    marginLeft: "10px"
  },
  inboxName: {
    fontSize: "14px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inboxLastMsg: {
    fontSize: "11px",
    fontWeight: "400",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inboxTime: {
    fontSize: "11px",
    fontWeight: "400",
  },
  inboxCountRoot: {
    "& .MuiBadge-badge": {
      fontSize: "10px",
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      right: "auto"
    },
    marginLeft: "auto",
    marginRight: "10px"
  }
}));

const Inbox = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const { receiverId, lstInbox } = props
  const [lstInboxLocal, setLstInboxLocal] = useState([]);

  // Base path of firebase
  const database = firebase.firestore();
  let dbR = database.collection("testRoom")
  let db = database.collection("testUsers")

  let onClickGroupInbox = () => {
    // router.history.push(`/chatroom/${objInbox.receiverId}`)
  }
  useEffect(() => {
    onload();
  }, []);

  const uuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  let onload = async () => {
    let senderId = await AsyncLocalStorage.getItem("senderId");
    let userName = await AsyncLocalStorage.getItem("userName");

    var timestamp = new Date();
    let messageId = uuid();

    // Get inbox data
    const inboxRef = db.doc(senderId)

    inboxRef.onSnapshot((doc) => {
      // console.log("Current data: ", doc.data().group);
      if (doc.data()) {
        setLstInboxLocal(doc.data().group)
      }
    });


    // inboxRef.onSnapshot((querySnapshot) => {
    //   let temp = [];
    //   querySnapshot.forEach((doc) => {
    //     temp.push(doc.data());
    //   });
    //   console.log(temp, "temp")
    // });

    // dbR.doc("100003").collection("messages").doc(messageId).set({
    //   displayName: userName,
    //   text: "1",
    //   timestamp: timestamp,
    //   uid: senderId
    // })

    // db.doc(senderId).set({
    //   timestamp: timestamp,
    //   displayName: userName,
    //   group: ["100001"],
    //   uid: senderId
    // })

  }

  let query = useLocation();

  return (
    <div className={classes.bgProfilePage}>
      {lstInboxLocal.length && lstInboxLocal.map((objInbox) => {
        return (
          <div
            className={classes.inboxRoot}
            onClick={() => {
              const prev_receiverId = query.pathname.split("/")[2];
              Cookies.set('prev_receiverId', prev_receiverId, { expires: 365 });
              Cookies.set('curr_receiverId', objInbox, { expires: 365 });
              router.history.push(`/chatroom/${objInbox}`)
            }}
          >
            {objInbox}
          </div>
        )
      })}
    </div>
  )
}

export default Inbox