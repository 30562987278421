import React, { useEffect, useState } from "react";
import { makeStyles, styled } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import PropTypes from "prop-types";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Api from "../../../../Helper/ApiHandler";
import YourCurrentPlan from "views/CurrentPlan/component/YourCurrentPlan";
import YourBoostCurrentPlan from "views/BoostCurrentPlan/component/YourBoostCurrentPlan";
// import AcUnitIcon from '@material-ui/icons/AcUnit';
var api = new Api();

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#e9edf0",
    minHeight: "90vh",
    padding: "35px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  headingPage: {
    fontWeight: "600 !important",
    fontSize: "18px",
    margin: "30px 10px 1px 10px",
  },
  mainAppTab: {
    backgroundColor: "#E5E5E5",
    color: "black",
    boxShadow: "none",
    textTransform: "capitalize",
    borderBottom: `1px solid gray`,
  },
  tabNameLabel: {
    textTransform: "capitalize",
    fontWeight: "bold",
    minWidth: "100px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "120px",
    },
  },
  mainCardDetail: {
    background: "white",
    position: "relative",
    // height: "350px",
    width: "100%",
    height: "100%",
    border: "1px solid #E5E5EA",
    borderRadius: "10px",
    padding: "20px",
  },
  halfColourDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "25px",
    // margin: "10px 10px",
  },
  headingPlan: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#FE4B6B",
    textTransform: "capitalize",
    // marginTop: "0px",
  },
  headingtime: {
    textTransform: "capitalize",
    fontWeight: "400",
    height: "25px",
    Width: "100%",
    textWrap: "nowrap",
    borderRadius: "50px",
    border: "none",
    backgroundColor: "#FE4B6B1A",
    color: "#FE4B6B",
  },
  whitePaperDetail: {
    margin: "10px 0px 0",
  },
  priceMates: {
    margin: "0",
    marginTop: "5px",
    marginBottom: "5px",
    display: "flex",
  },
  timeValueSubs: {
    margin: "0",
    color: "gray",
    fontSize: "14px",
  },
  detailCardSubs: {
    marginTop: "20px",
    fontSize: "14px",
    padding: "5px 0px",
  },
  deatilBox: {
    textAlign: "left",
    paddingTop: "10px",
    paddingLeft: "8px",
  },
  imageDetail: {
    marginRight: "5px",
  },
  lastBtnFlex: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    bottom: "-7px",
    width: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    justifyContent: "center",
    color: "#36D860",
  },
  buttonCardSelect: {
    width: "100%",
    color: "#36D860",
    textAlign: "center",
    marginTop: "25px",
  },
  buttonSelect: {
    textTransform: "capitalize",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    fontWeight: "400",
    height: "45px",
    width: "100%",
    borderRadius: "50px",
    fontSize: "14px",
    boxShadow: "none",
  },
  buttonBilling: {
    textTransform: "capitalize",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    fontWeight: "400",
    height: "30px",
    Width: "100%",
    textWrap: "nowrap",
    top: "25px",
  },
  Cancel: {
    textTransform: "capitalize",
    background: "#e9edf0",
    fontWeight: "400",
    height: "30px",
    Width: "100%",
    textWrap: "nowrap",
    border: "1px solid black",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "30% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "25%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "25%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      // paddingLeft: "28px !important",
      width: "69%",
    },
    [theme.breakpoints.only("lg")]: {
      // paddingLeft: "28px !important",
      width: "74%",
    },
    [theme.breakpoints.only("xl")]: {
      // paddingLeft: "28px !important",
      width: "74%",
    },
  },
  bottomLastCard: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
    },
  },
  EditProfilLableorder: {
    margin: "40px 12px 15px 12px",
    fontWeight: "900px",
  },
  socialIcon: {
    position: "absolute",
    right: "-10px",
    top: "-24px",
    cursor: "pointer",
  },
  tableRoot: {
    "& .MuiTable-root": {
      border: "1px solid gray",
      width: "100%",
      display: "table",
      // borderRadius: "10px",
      borderSpacing: "inherit",
      borderCollapse: "inherit",
    },
  },
  Subscription_heading: {
    color: "#FF695B",
    textAlign: "center",
    margin: "0px",
  },
  title: {
    color: "#FE4B6B",
    fontWeight: "700",
    marginTop: "10px",
  },
  card: {
    minHeight: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardActions: {
    justifyContent: "center",
  },
  planPrice: {
    color: "red",
  },
  timebutton: {
    borderRadius: "20px",
  },
  speretsection: {
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "8px",
  },
  hrLine: {
    borderBottom: "2px solid #E5E5EA",
    // opacity: "0.1",
    margin: "20px 0px",
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Billing = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.bgProfilePage}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid
              className={classes.container}
              item
              style={{
                marginTop: "22px",
                backgroundColor: "white",
                borderRadius: "10px",
              }}
            >
              <div className={classes.dialogContent}>
                <DialogActions style={{ justifyContent: "center" }}>
                  <Typography
                    variant="h3"
                    align="center"
                    className={classes.title}
                  >
                    Billing History
                  </Typography>
                </DialogActions>
                {/* <div className={classes.hrLine} /> */}
                
                    <Typography
                      variant="h5"
                      className={classes.EditProfilLableorder}
                    >
                      Subscription Plan
                    </Typography>
                    <Box>
                      <YourCurrentPlan />
                    </Box>
               
               
                    {/* <div className={classes.hrLine} /> */}
                    <Typography
                      variant="h5"
                      className={classes.EditProfilLableorder}
                    >
                     Boost Profile
                    </Typography>
                    <Box>
                      <YourBoostCurrentPlan />
                    </Box>
              
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Billing;
