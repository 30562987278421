import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Api from "../../../../Helper/ApiHandler";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import MuiAlert from "@material-ui/lab/Alert";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import validate from "validate.js";
import AppDataContext from "Helper/AppDataContext";
import firebase from "firebase/app";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import useRouter from "utils/useRouter";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import EventIcon from "@material-ui/icons/Event";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const lstGender = [
//   { id: "male", value: "Male" },
//   { id: "female", value: "Female" },
//   { id: "intersex", value: "Intersex" },
//   { id: "trans", value: "Trans" },
//   { id: "noPreference", value: "No Preference" },
// ]

const lstGender = ["male", "female", "intersex", "trans", "Other"];

const schema = {
  first_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  last_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  phone: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20, message: "must be less than 20 characters" },
    format: {
      pattern: "^[0-9+\\- ]*$",
      message:
        "can only contain numbers, spaces, and the characters '+' or '-'",
    },
  },
  gender: {
    presence: { allowEmpty: false, message: "is required" },
  },
  zodiac: {
    presence: { allowEmpty: false, message: "is required" },
  },
  location: {
    presence: { allowEmpty: false, message: "is required" },
  },
  date_of_birth: {
    presence: { allowEmpty: false, message: "is required" },
    // datetime: {
    //   dateOnly: true,
    //   latest: moment('MM/DD/YYYY').subtract(18, 'years'),
    //   message: "^You need to be at least 18 years old"
    // }
  },
};
const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#E5E5E5",
    padding: "35px 0",
    // minHeight: "100vh",
    minHeight: "calc(100vh - 124px)",
    [theme.breakpoints.up("xs")]: {
      paddingTop: "90px",
    },
  },
  questionsText: {
    fontSize: "20px",
    padding: "20px",
    color: "white",
    fontWeight: "bold",
    margin: "0",
  },
  questionsBox: {
    background: "#0071CE",
    borderRadius: "10px 10px 0px 0px",
  },
  startQuizBox: {
    background: "#fff",
    borderRadius: "10px 10px 0px 0px",
    textAlign: "center",
    marginBottom: "50px",
  },
  startText: {
    color: "black",
    fontSize: "22px",
    // padding: "20px",
    fontWeight: "600",
    margin: "0",
  },
  startsecondaryText: {
    color: "#3F444F",
    fontSize: "13px",
    // padding: "20px",
    fontWeight: "400",
    margin: "0",
  },
  answerBox: {
    background: "white",
    borderRadius: "0px 0px 10px 10px",
    // padding: "20px"
  },
  selectOptionIn: {
    //fontFamily: "Gilroy !important",
    "& .MuiOutlinedInput-notchedOutline": {
      // borderWidth: 0
    },
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon": {
      padding: "10.5px",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
      // fontSize: "12px"
    },
    // backgroundColor: "#e9edf0"
  },
  headingAns: {
    margin: "0",
    padding: "20px",
    paddingBottom: "0",
    fontSize: "18px",
    fontWeight: "600",
    marginLeft: "10px",
  },
  chipUserDetail: {
    margin: "6px 5px",
    fontSize: "14px",
    padding: "0 10px",
    //fontFamily: "Gilroy !important",
    [theme.breakpoints.down("xs")]: {
      padding: "0 7px",
      fontSize: "14px",
      margin: "4px 3px",
    },
  },
  chipInputEdit: {
    // margin: "3px 20px",
    "& .MuiAutocomplete-tag": {
      borderRadius: "5px !important",
      background: "rgb(204 227 245)",
    },
    //fontFamily: "Gilroy !important",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
  },
  chip: {
    background: "#d5606045",
    //fontFamily: "Gilroy !important",
    borderRadius: "5px",
    margin: "5px 5px",
    padding: "0 0",
    height: "28px",
  },
  chipGruop: {
    padding: "10px 20px",
  },
  // autocompletChip: {
  //   background: "white",
  //   '& .MuiChip-root': {
  //     borderRadius: "5px",
  //     background: "#d5606045",
  //     height: "auto",
  //   },
  //   '& .MuiChip-outlined': {
  //     border: "none",
  //     color: "#FE4B6B",
  //     padding: "2px"
  //   },
  //   '& .MuiFilledInput-root': {
  //     background: "white"
  //   }
  // },
  lineHorizontal: {
    width: "95%",
    // borderBottom: "2px solid #000000",
    opacity: "0.1",
    margin: "0 auto",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 25px",
  },
  nextButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    background: "rgba(0, 113, 206, 0.2)",
    width: "80px",
    height: "30px",
  },
  skipButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    textDecoration: "underline",
    marginTop: "5px",
    cursor: "pointer",
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
  },
  buttonStart: {
    width: "300px",
    fontSize: "14px",
    textTransform: "capitalize",
    margin: "20px 0px",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
  },
  mainStartQuixBox: {
    textAlign: "center",
    padding: "15px 15px",
  },
  textNewStep: {
    fontSize: "16px !important",
    fontWeight: "900",
    width: "60%",
    textAlign: "center",
    margin: "19px auto",
  },
  startQuizMain: {
    marginBottom: "20px",
    padding: "50px 50px 70px 50px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    },
    backgroundColor: "white",
    borderRadius: "10px",
  },
  autocompletChip: {
    padding: "10px",
    background: "white",
    "& .MuiChip-root": {
      borderRadius: "5px",
      background: "#d5606045",
      height: "auto",
    },
    "& .MuiChip-outlined": {
      border: "none",
      color: "#FE4B6B",
      padding: "2px",
    },
    "& .MuiFilledInput-root": {
      background: "white",
    },
  },
  chipIcon: {
    width: "13px",
    height: "13px",
    color: "#FE4B6B",
  },
  editIconPro: {
    position: "absolute",
    top: "80px",
    left: "53.5%",
    [theme.breakpoints.down("xs")]: {
      left: "57.5%",
    },
    cursor: "pointer",
  },
  profilePhotoEdit: {
    position: "relative",
    marginBottom: "10px",
  },
  checkboxDetail: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "35px",
    },
  },
  genderPrefranceHeading: {
    fontSize: "18px",
    fontWeight: "500",
    marginLeft: "12px",
    marginRight: "18px",
  },
  genderSelectBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  textFiledBox: {
    width: "90%",
    margin: "20px auto",
    "& .MuiFormControl-marginNormal": {
      marginTop: "0px",
      marginBottom: "0px",
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      textTransform: "capitalize",
    },
  },
  textFiledBoxchecked: {
    width: "100%",
    margin: "0px 0px",
    // "& .MuiFormControl-marginNormal": {
    //   marginTop: "0px",
    //   marginBottom: "0px",
    // },
    // "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
    //   textTransform: "capitalize",
    // },
  },

  googleSelecte: {
    width: "90%",
    margin: "20px auto",
    "& .css-yk16xz-control": {
      // borderRadius: "10px",
      // padding: "8px"
    },
    "& .css-1pahdxg-control": {
      // borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      // padding: "8px"
    },
    "& .css-1pahdxg-control:hover": {
      // borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      // padding: "8px"
    },
    "& .css-1uccc91-singleValue": {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
    },
    "& .css-1wa3eu0-placeholder": {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
    },
    "& div": {
      fontFamily: "'Poppins', sans-serif",
      backgroundColor: "white",
      zIndex: 11,
    },
  },
  startedButton: {
    // fontSize: "20px",
    // height: "50px",
    height: "35px",
    fontSize: "18px",
    width: "90%",
    // background: "#0071CE",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    // borderRadius: "10px",
    textTransform: "capitalize",
    fontWeight: "400",
    marginTop: "20px",
  },
  banaer: {
    width: "100%",
  },
  banaerRoot: {
    display: "flex",
    alignItems: "flex-end",
    paddingRight: "30px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const SignupDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const router = useRouter();
  const initialValues = {
    name: "",
    tag: "",
    tags: ["Full-Time", "ReactJS"],
    startDate: moment(),
    endDate: moment().add(1, "day"),
  };

  const [p_answer, set_P_Answer] = React.useState([]);
  const [s_answer, set_S_Answer] = React.useState([]);
  const [sessionMessage, setSessionMessage] = React.useState(
    props.sessionPropsMessage
  );
  const [lstP_University, setLst_P_University] = React.useState([]);
  const [lstS_University, setLst_S_University] = React.useState([]);
  const [lstPlannedActivities, setLstPlannedActivities] = React.useState([]);
  const [lstInterests, setLstInterests] = React.useState([]);
  const [uploadImagePath, setUploadImagePath] = React.useState("");
  const [preferenceGender, setPreferenceGender] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [disabledLogin, setDisabledLogin] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [lstZodiac, setLstZodiac] = React.useState([]);
  const [accessDate, setAccessDate] = React.useState(false);
  const [dob, setDob] = React.useState(moment().subtract(18, "years"));
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const [calendarTrigger, setCalendarTrigger] = useState(null);
  const [values, setValues] = useState({ ...initialValues });

  const { appContext, setAppContext } = useContext(AppDataContext);
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const calendarOpen = Boolean(calendarTrigger);

  const dob_Change = (date) => {
    let changeDate = convertDate(date);
    console.log(date, changeDate, "date");
    if (moment().subtract(18, "years") >= moment(date)) {
      setAccessDate(true);
    } else {
      setAccessDate(false);
    }
    setDob(date);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        date_of_birth: changeDate,
      },
    }));
    // } else {
    //   setDob("");
    //   setFormState((formState) => ({
    //     ...formState,
    //     values: {
    //       ...formState.values,
    //       date_of_birth: "",
    //     }
    //   }));
    // }
  };
  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    // return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('/')
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  useEffect(() => {
    getZodiac();
    getEmail();
    onLoad();
  }, []);

  const onLoad = async () => {
    let E = await AsyncLocalStorage.getItem("email");
    let P = await AsyncLocalStorage.getItem("phone");
    if (E && E != "null") {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          email: E,
          // phone: P,
          date_of_birth: convertDate(moment().subtract(18, "years")),
        },
      }));
    }

    if (P && P != "null") {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          phone: P,
        },
      }));
    }
    // setLoadingStart(true)
    // Api Call For Fetch The User Data
    await api
      .get(`user/getuserdetails`)
      .then((result) => {
        if (result.success === "true") {
          // setUserData(result.data)
          // set location
          geocodeByAddress(result.data.location)
            .then((results) => {
              // let location = result.data.city + " " + result.data.state + " " + result.data.country
              setFormState((formState) => ({
                ...formState,
                values: {
                  ...formState.values,
                  location: {
                    label: result.data.location,
                    value: results[0],
                  },
                },
              }));
            })
            .catch((error) => console.error(error, "new result error"));

          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              first_name: result.data.first_name,
              last_name: result.data.last_name,
              phone: result.data.phone,
              linkdin_link: result.data.linkdin_link,
              fb_link: result.data.fb_link,
              email: result.data.email,
              phone: result.data.phone,
              hometown: result.data.hometown,
              // university: result.data.university,
              // bio: result.data.bio,
              gender: result.data.gender,
              // preference_gender: result.data.preference_gender ? result.data.preference_gender : [],
              zodiac: result.data.zodiac,
              // interests: result.data.interests,
              // planned_activities: result.data.planned_activities,
              date_of_birth: result.data.date_of_birth
                ? result.data.date_of_birth
                : convertDate(moment().subtract(18, "years")),
            },
          }));
          // setP_University(result.data.p_university)
          // setS_University(result.data.s_university)
          // change_p_OptionBaseOnValue(result.data.p_university)
          // change_s_OptionBaseOnValue(result.data.s_university)
          dob_Change(
            result.data.date_of_birth
              ? result.data.date_of_birth
              : moment().subtract(18, "years")
          );
          // setDob(result.data.date_of_birth)
          setEmail(result.data.email);
          setPhone(result.data.phone);
          setUploadImagePath(result.data.profile_picture);
          // if (result.data.lifestyle_photos)
          //   setLstLifeStyleImage(result.data.lifestyle_photos)
        } else {
          console.log(result, "error");
        }
        // setLoadingStart(false)
      })
      .catch((err) => {
        console.log(err, "catch error");
        // setLoadingStart(false)
      });
  };

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        date_of_birth: convertDate(moment().subtract(18, "years")),
      },
    }));
  }, []);

  const getZodiac = async () => {
    api
      .get(`zodiaclist`)
      .then((result) => {
        if (result.success === "true") {
          setLstZodiac(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const getEmail = async () => {
    let E = await AsyncLocalStorage.getItem("email");
    let P = await AsyncLocalStorage.getItem("phone");
    console.log(P, "P");
    setEmail(E);
    setPhone(P);
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleFieldChange = (event) => {
    event.persist();

    const inputValue = event.target.value;

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: inputValue,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    getPlannedActivites();
    getInterests();
  }, []);

  const setLocation = (value) => {
    if (value) {
      geocodeByAddress(value.value.description)
        .then((results) => {
          console.error("valuevaluevalue", results);
          console.error("valuevaluevalue", results[0].geometry.location.lat());
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              location: value,
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng(),
            },
          }));
        })
        .catch((error) => console.error("valuevaluevalue", error));
    } else {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          location: "",
          latitude: "",
          longitude: "",
        },
      }));
    }
  };

  // Get Interests
  const getInterests = async () => {
    // Api Call For Fetch Interests List
    api
      .get(`interestlist`)
      .then((result) => {
        if (result.success === "true") {
          setLstInterests(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  // const selecteGender = async () => {
  //   const item = lstGender.find((opt) => {
  //     if (opt.id == formState.values.gender)
  //       return opt;
  //   })
  //   if (item)
  //     setFormState((formState) => ({
  //       ...formState,
  //       values: {
  //         ...formState.values,
  //         gender: item
  //       }
  //     }));
  // }
  const getPlannedActivites = async () => {
    // Api Call For Fetch Planned activites List
    api
      .get(`planactvlist`)
      .then((result) => {
        if (result.success === "true") {
          setLstPlannedActivities(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabledLogin(true);
    setLoading(true);

    // Calculate the user's age based on DOB
    const userAge = moment().diff(moment(dob), "years");

    if (userAge < 18) {
      setSessionMessage({
        message: "You must be at least 18 years old to sign up.",
        type: "error",
        open: true,
      });
      setDisabledLogin(false);
      setLoading(false);
      return;
    }

    let device_token = await AsyncLocalStorage.getItem("device_token");
    let currentModule = await AsyncLocalStorage.getItem("currentModule");

    // firebase.auth().createUserWithEmailAndPassword(formState.values.email, formState.values.password)
    //   .then((userCredential) => {
    // Signed in
    // var user = userCredential.user;
    if (formState.isValid === true) {
      api
        .post("user/singupapp", {
          data: {
            ...formState.values,
            gender: formState.values.gender,
            device_token,
            location: formState.values.location.label,
            email: formState.values.email,
            phone: formState.values.phone,
            // phone: phone && phone != "null" ? phone : "",
            type: "web",
            module_name: currentModule,
          },
          // skipAuth: true
        })
        .then((result) => {
          if (result.success === "true") {
            setSessionMessage({
              message: result.message,
              type: "success",
              open: true,
            });
            setFormState({
              isValid: false,
              values: {},
              touched: {},
              errors: {},
            });
            let userName = result.data.first_name + " " + result.data.last_name;
            console.log(result.data.accessToken, "result.data.accessToken");
            AsyncLocalStorage.setItem("authToken", result.data.accessToken);
            AsyncLocalStorage.setItem("senderId", result.data.firebase_uid);
            AsyncLocalStorage.setItem("userName", userName);
            AsyncLocalStorage.setItem(
              "userProfile",
              result.data.profile_picture
            );
            AsyncLocalStorage.setItem("from_id", result.data.user_id);
            AsyncLocalStorage.setItem("university", result.data.university);
            AsyncLocalStorage.setItem(
              "puniversity_lat",
              result.data.puniversity_lat
            );
            AsyncLocalStorage.setItem(
              "puniversity_long",
              result.data.puniversity_long
            );
            AsyncLocalStorage.setItem("from_slug", result.data.slug);
            AsyncLocalStorage.setItem(
              "isOrgUser",
              result.data.isOrgUser ? result.data.isOrgUser : false
            );
            AsyncLocalStorage.setItem("signupDeatils", true);
            // AsyncLocalStorage.setItem("currentModule", "Dormmates")
            // router.history.push("/select-university")
            if (
              currentModule === "Roommates" ||
              currentModule === "Dormmates"
            ) {
              // window.location.replace("/select-university");
              // router.history.push("/select-university")
              router.history.push({
                pathname: `/select-university`,
                search: "",
                state: {
                  step3: true,
                },
              });
            } else {
              // window.location.replace("/personal-details");
              // router.history.push("/personal-details")
              router.history.push({
                // pathname: `/personal-details`,
                pathname: `/disclaimer`,
                search: "",
                state: {
                  step4: true,
                  teamToken: props.teamToken,
                },
              });
            }
            // setOpenLogin()
            // setOpen(false)
          } else {
            setSessionMessage({
              message: result.message,
              type: "error",
              open: true,
            });
          }
          setDisabledLogin(false);
          setLoading(false);
        })
        .catch((err) => {
          setDisabledLogin(false);
          setLoading(false);
          setSessionMessage({
            message: err.message,
            type: "error",
            open: true,
          });
        });
    }
    // ...
    // })
    // .catch((error) => {
    //   setDisabledLogin(false)
    //   setLoading(false)
    //   var errorCode = error.code;
    //   var errorMessage = error.message;
    //   // ..
    // });
  };
  console.log(props.teamToken, "props.teamToken");
  // Start The Quiz
  const StartTheQuizHandler = () => {
    if (!formState.values.planned_activities?.length) {
      setSessionMessage({
        message: "Please selecte liked Activities",
        type: "error",
        open: true,
      });
      return;
    }
    if (!formState.values.interests?.length) {
      setSessionMessage({
        message: "Please selecte interests",
        type: "error",
        open: true,
      });
      return;
    }
    if (!preferenceGender.length) {
      setSessionMessage({
        message: "Please selecte preference gender",
        type: "error",
        open: true,
      });
      return;
    }

    let planned_activities = formState.values.planned_activities;
    let interests = formState.values.interests;
    let preference_gender = [...preferenceGender];

    let data = {
      planned_activities: planned_activities,
      interests: interests,
      preference_gender: preference_gender,
    };
    console.log(data, "data");
    // Api Call For personal details
    api
      .post("user/addactivitiesandinterests", {
        data: data,
      })
      .then((result) => {
        if (result.success === "true") {
          history.push("/matching-quiz");
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const handleAutocompletChange = (event, value, name) => {
    if (name === "p_university") {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value.value,
          },
        }));
      }
    } else if (name === "s_university") {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value.value,
          },
        }));
      }
    } else if (name === "room_type") {
      if (value !== null) {
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value.value,
          },
          touched: {
            ...formState.touched,
            [name]: true,
          },
        }));
      }
    } else {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value,
          },
        }));
      }
    }
  };

  const handleCalendarOpen = (trigger) => {
    setCalendarTrigger(trigger);
  };

  const handleCalendarAccept = (date) => {
    setValues((values) => ({
      ...values,
      [calendarTrigger]: date,
    }));
  };

  const handleCalendarClose = () => {
    setCalendarTrigger(false);
  };
  const disabled = formState.isValid;
  console.log("phone", phone);

  const handleFieldChangePhone = (event) => {
    const { name, value } = event.target;

    const numericValue = value.replace(/[^0-9]/g, "");

    setFormState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: numericValue,
      },
      errors: {
        ...prevState.errors,
        [name]:
          numericValue.length < 10
            ? "Phone number must be at least 10 digits."
            : null,
      },
    }));
  };

  // const handleFieldChangePhone = (event) => {
  //   const { name, value } = event.target;

  //   // Allow only numbers
  //   const numericValue = value.replace(/[^0-9]/g, "");

  //   setFormState((prevState) => ({
  //     ...prevState,
  //     values: {
  //       ...prevState.values,
  //       [name]: numericValue,
  //     },
  //   }));
  // };
  return (
    <>
      <div className={classes.bgProfilePage}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} lg={7} xl={7} className={classes.banaerRoot}>
              <img
                className={classes.banaer}
                src={"/images/matchUp/signupDetail.svg"}
                alt={"Alt Image"}
              />
            </Grid>
            <Grid item xs={12} xl={5} lg={5}>
              <Box className={classes.startQuizMain}>
                <Box className={classes.startQuizBox}>
                  <p className={classes.startText}>Welcome to MatchUpMates</p>
                  <p className={classes.startsecondaryText}>
                    it’s time to choose your best matches
                  </p>
                </Box>
                {/* {!email || email == "null" ? (
                  <Box className={classes.textFiledBox}>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      fullWidth
                      size="small"
                      variant="outlined"
                      onChange={handleFieldChange}
                      name="email"
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.asterisk,
                        },
                        shrink: true,
                      }}
                      required
                      error={hasError("email")}
                      helperText={
                        hasError("email") ? formState.errors.email[0] : null
                      }
                    />
                  </Box>
                ) : (
                  ""
                )} */}
                {/* <div className={classes.hrLine} /> */}
                <Box className={classes.answerBox}>
                  <form onSubmit={(event) => handleSubmit(event)}>
                    <Box className={classes.textFiledBox}>
                      <TextField
                        autoComplete="off"
                        id="outlined-basic"
                        label="First Name"
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={formState.values.first_name || ""}
                        onChange={handleFieldChange}
                        name="first_name"
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.asterisk,
                          },
                          shrink: true,
                        }}
                        required
                        error={hasError("first_name")}
                        helperText={
                          hasError("first_name")
                            ? formState.errors.first_name[0]
                            : null
                        }
                      />
                    </Box>
                    <Box className={classes.textFiledBox}>
                      <TextField
                        autoComplete="off"
                        id="outlined-basic"
                        label="Last Name"
                        name="last_name"
                        fullWidth
                        size="small"
                        value={formState.values.last_name || ""}
                        variant="outlined"
                        onChange={handleFieldChange}
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.asterisk,
                          },
                          shrink: true,
                        }}
                        required
                        error={hasError("last_name")}
                        helperText={
                          hasError("last_name")
                            ? formState.errors.last_name[0]
                            : null
                        }
                      />
                    </Box>
                    <Box className={classes.textFiledBox}>
                      <TextField
                        id="outlined-basic"
                        label="Phone Number"
                        name="phone"
                        type="text"
                        fullWidth
                        className={classes.inputProfileEdit}
                        size="small"
                        value={formState.values.phone || ""}
                        variant="outlined"
                        onChange={handleFieldChangePhone}
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.asterisk,
                          },
                          shrink: true,
                        }}
                        required
                        error={hasError("phone")}
                        helperText={
                          hasError("phone") ? formState.errors.phone[0] : null
                        }
                        disabled={
                          phone === "null" || phone === "" ? false : true
                        }
                      />
                    </Box>

                    <Box className={classes.textFiledBox}>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        className={classes.inputProfileEdit}
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.asterisk,
                          },
                          shrink: true,
                        }}
                        required
                        error={hasError("email")}
                        helperText={
                          hasError("email") ? formState.errors.email[0] : null
                        }
                        label="Email"
                        name="email"
                        value={formState.values.email || ""}
                        onChange={handleFieldChange}
                        variant="outlined"
                        fullWidth
                        disabled={email === "null" ? false : true}
                      />
                    </Box>
                    <Box
                      className={classes.googleSelecte}
                      style={{ position: "relative" }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          fontSize: "12px",
                          position: "absolute",
                          left: "10px",
                          top: "-8px",
                          zIndex: "12",
                          padding: "0px 5px",
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                      >
                        Location<span style={{ color: "red" }}>*</span>{" "}
                      </div>
                      <GooglePlacesAutocomplete
                        autocompletionRequest={{
                          types: ["(cities)"],
                        }}
                        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        label="City / state"
                        selectProps={{
                          // placeholder: (
                          //   <>
                          //     <span>Location</span>
                          //   </>
                          // ),
                          isClearable: true,
                          value: formState.values.location || "",
                          onChange: (val) => {
                            setLocation(val);
                            console.log("close", val);
                          },
                        }}
                      />
                      {hasError("location") && (
                        <label className={classes.googleAutoLabel}>
                          {formState.errors.location[0]}
                        </label>
                      )}
                    </Box>
                    <Box className={classes.textFiledBox}>
                      <Autocomplete
                        noOptionsText={"Type for search..."}
                        options={lstGender}
                        autoHighlight
                        // getOptionLabel={(lstGender) => lstGender.value}
                        getOptionLabel={(lstGender) => lstGender}
                        fullWidth
                        name="gender"
                        value={formState.values.gender || ""}
                        // value={selecteGender()}

                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                            style={{ textTransform: "capitalize" }}
                          >
                            {props}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // placeholder="Select Gender"
                            required
                            label="Select Gender"
                            autoFocus={false}
                            InputLabelProps={{
                              classes: {
                                asterisk: classes.asterisk,
                              },
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                              autoComplete: "off",
                            }}
                            variant="outlined"
                            fullWidth
                            className={classes.selectOptionIn}
                          />
                        )}
                        onChange={(e, value) => {
                          handleAutocompletChange(e, value, "gender");
                          // onFilterChange(e.target.value, 'g')
                        }}
                      />
                    </Box>
                    <Box className={classes.textFiledBox}>
                      <Autocomplete
                        noOptionsText={"Type for search..."}
                        options={lstZodiac}
                        autoHighlight
                        getOptionLabel={(lstZodiac) => lstZodiac}
                        fullWidth
                        value={formState.values.zodiac || ""}
                        name="zodiac"
                        required
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {props}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // placeholder="Star Sign"
                            label="Star Sign"
                            autoFocus={false}
                            InputLabelProps={{
                              classes: {
                                asterisk: classes.asterisk,
                              },
                              shrink: true,
                            }}
                            required
                            error={hasError("zodiac")}
                            helperText={
                              hasError("zodiac")
                                ? formState.errors.zodiac[0]
                                : null
                            }
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                              autoComplete: "off",
                            }}
                            variant="outlined"
                            name="zodiac"
                            fullWidth
                            className={classes.selectOptionIn}
                          />
                        )}
                        onChange={(e, value) => {
                          handleAutocompletChange(e, value, "zodiac");
                        }}
                      />
                    </Box>
                    <Box className={classes.textFiledBox}>
                      <DatePicker
                        fullWidth
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.asterisk,
                          },
                          shrink: true,
                        }}
                        label="Date of birth"
                        autoOk
                        className={classes.datePicker}
                        size="small"
                        maxDate={moment().subtract(18, "years")}
                        name="current_date"
                        inputVariant="outlined"
                        format="DD/MM/YYYY"
                        value={dob || moment().subtract(18, "years")}
                        onChange={dob_Change}
                        InputProps={{
                          className: classes.forminput,
                          endAdornment: (
                            <InputAdornment>
                              <EventIcon style={{ cursor: "pointer" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/* <KeyboardDatePicker
                        placeholder="DD/MM/YYYY"
                        variant="inline"
                        // maxDate={access ? new Date("01/01/2011") : undefined}
                        maxDate={moment().subtract(18, "years")}
                        format="DD/MM/YYYY"
                        margin="normal"
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.asterisk
                          }, shrink: true
                        }}
                        required
                        // disabled
                        name="dob"
                        label="Date of birth"
                        onClick={() => handleCalendarOpen('dob')}
                        value={dob || moment().subtract(18, "years")}
                        defaultValue={moment().subtract(18, "years")}
                        onChange={dob_Change}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        inputVariant="outlined"
                        fullWidth
                        autoOk
                        className={classes.datePicker}
                        size="small"
                        maxDateMessage="You must 18 years old"
                      // inputProps={{ readOnly: true }}

                      /> */}
                    </Box>
                    <Box className={classes.textFiledBox}>
                      <TextField
                        autoComplete="off"
                        id="outlined-basic"
                        label="Your Profile Linkedin Link"
                        name="linkdin_link"
                        fullWidth
                        size="small"
                        value={formState.values.linkdin_link || ""}
                        variant="outlined"
                        onChange={handleFieldChange}
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.asterisk,
                          },
                          shrink: true,
                        }}
                        // required
                        error={hasError("linkdin_link")}
                        helperText={
                          hasError("linkdin_link")
                            ? formState.errors.linkdin_link[0]
                            : null
                        }
                      />
                    </Box>
                    <Box className={classes.textFiledBox}>
                      <TextField
                        autoComplete="off"
                        id="outlined-basic"
                        label="Your Profile Facebook Link"
                        name="fb_link"
                        fullWidth
                        size="small"
                        value={formState.values.fb_link || ""}
                        variant="outlined"
                        onChange={handleFieldChange}
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.asterisk,
                          },
                          shrink: true,
                        }}
                        s
                        // required
                        error={hasError("fb_link")}
                        helperText={
                          hasError("fb_link")
                            ? formState.errors.fb_link[0]
                            : null
                        }
                      />
                    </Box>
                    <Box display={"flex"} justifyContent={"center"}>
                      <Button
                        className={classes.startedButton}
                        variant="contained"
                        size="large"
                        color="primary"
                        style={{
                          backgroundColor: disabled
                            ? "blue"
                            : "rgba(0, 0, 0, 0.12)",
                          color: disabled ? "white" : "white",
                          boxShadow: disabled ? "none" : "",
                        }}
                        // type="submit"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                        disabled={formState.isValid ? disabledLogin : true}
                      >
                        Next
                        {loading && (
                          <>
                            &nbsp;
                            <CircularProgress
                              size={20}
                              style={{
                                color: "white",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            />
                          </>
                        )}
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      <DatePicker
        maxDate={moment().subtract(18, "years")}
        onAccept={handleCalendarAccept}
        onChange={dob_Change}
        onClose={handleCalendarClose}
        open={calendarOpen}
        style={{ display: "none" }} // Temporal fix to hide the input element
        value={dob}
        variant="dialog"
      />
    </>
  );
};
export default SignupDetails;
