import { Container, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { makeStyles } from "@material-ui/styles";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import Inbox from './Inbox';
import Message from './Message';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import firebase from "firebase/app";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "5px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "10px",
    },
  },
  bgProfilePage: {
    background: "#e9edf0",
    padding: "35px 0",
    height: "calc(100vh - 125px)",
    // marginBottom: "60px",
    [theme.breakpoints.down('xs')]: {
      padding: "0",
    },
  },
  responsiveHide: {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
    [theme.breakpoints.only('md')]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only('lg')]: {
      width: "28%",
    },
    [theme.breakpoints.down('xl')]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    [theme.breakpoints.only('md')]: {
      width: "66.67%",
    },
    [theme.breakpoints.only('lg')]: {
      width: "72%",
    },
    [theme.breakpoints.only('xl')]: {
      width: "72%",
    },
  }
}));

const Chatroom = () => {
  const classes = useStyles();
  let query = useLocation();
  const [senderId, setSenderId] = useState("")
  const [lstInbox, setLstInbox] = useState([]);
  const [lstMessage, setLstMessage] = useState([]);
  const [lstTmpInbox, setLstTmpInbox] = useState([])
  const [userName, setUserName] = useState([])
  const [group_members, setgGroup_members] = useState([])

  const receiverId = query.pathname.split("/")[2]

  // Base path of firebase
  const database = firebase.firestore();
  let db = database.collection("teammate").doc("chatRooms")

  useEffect(() => {
    onload();
    get_group_members()
  }, []);

  useEffect(() => {
    getMessage()
  }, [receiverId]);

  // useEffect(() => {
  //   inboxGet();
  // }, [lstTmpInbox]);

  let get_group_members = async () => {
    // Get group members
    var groupRef = db.collection("group").doc(receiverId);
    groupRef.get().then((doc) => {
      if (doc.exists) {
        setgGroup_members(doc.data().group_members)
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }

  // let inboxGet = async () => {
  //   if (lstTmpInbox.length) {
  //     const lstTmp = [];
  //     const unresolved = lstTmpInbox.map(async (mainobj) => {
  //       if (mainobj.chatType === "group") {
  //         var groupRef = db.collection("group").doc(mainobj.receiverId);
  //         await groupRef.get().then((groupDoc) => {
  //           if (groupDoc.exists) {
  //             lstTmp.push({ ...groupDoc.data(), messageCreatedAt: mainobj.createdAt, ...mainobj, createdAt: groupDoc.data().createdAt })
  //           }
  //         }).catch((error) => {
  //           console.log("Error getting document:", error);
  //         })
  //       }
  //       else {
  //         console.log("Personal chat")
  //       }
  //     })
  //     await Promise.all(unresolved)
  //     setLstInbox(lstTmp)
  //   }
  // }

  let onload = async () => {
    let senderId = await AsyncLocalStorage.getItem("senderId");
    let userName = await AsyncLocalStorage.getItem("userName");

    setSenderId(senderId)
    setUserName(userName)

    // // Get inbox data
    // const inboxRef = db.collection("message").doc(senderId).collection("inbox")
    // inboxRef.orderBy("createdAt", "desc").onSnapshot((querySnapshot) => {
    //   let temp = [];
    //   querySnapshot.forEach((doc) => {
    //     temp.push(doc.data());
    //   });
    //   // setLstTmpInbox(temp);
    //   setLstInbox(temp)
    // });

  }

  let getMessage = async () => {
    // Get message data
    const messageRef = db.collection("messageBox").doc(receiverId).collection("messages")
    messageRef.orderBy("createdAt", "asc").onSnapshot((querySnapshot) => {
      let temp = [];
      querySnapshot.forEach((doc) => {
        temp.push(doc.data());
      });
      setLstMessage(temp)
    });
  }
  return (
    <div className={classes.bgProfilePage}>
      <Container maxWidth="lg">
        <Grid spacing={3} container>
          <Grid className={classes.responsiveHide} item>
            <ListMenuSideBar />
          </Grid>
          <Grid className={classes.container} item style={{ marginTop: "10px", paddingLeft: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Inbox senderId={senderId} receiverId={receiverId} lstInbox={lstInbox} />
              </Grid>
              <Grid item xs={12} md={8}>
                {receiverId && <Message group_members={group_members} receiverId={receiverId} lstMessage={lstMessage} senderId={senderId} userName={userName} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Chatroom