import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Hidden,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import { Link as browserLink, useLocation, useParams } from "react-router-dom";
import firebase from "firebase/app";
import { useFirestoreQuery } from "../../../../hooks";
import { useHistory } from "react-router-dom";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import RequestModel from "components/RequestModel/RequestModel";
import RequestModelRoomamte from "components/RequestModelRoomamte/RequestModelRoomamte";
import MuiAlert from "@material-ui/lab/Alert";
import ReactFancyBox from "react-fancybox";
import { async } from "validate.js";
import HomeIcon from "@material-ui/icons/Home";
import axios from "axios";

import Api from "../../../../Helper/ApiHandler";
import Fancybox from "components/fancybox";
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const date = new Date()

const dummyListData = [
  {
    name: "Jonathan G",
    message: "Hi I'm Fred H. How are you?",
  },
  {
    name: "Hally R",
    message: "Hi I'm Fred H. How are you?",
  },
  {
    name: "Tom E",
    message: "Hi I'm Fred H. How are you?",
  },
  {
    name: "Ellise Z",
    message: "Hi I'm Fred H. How are you?",
  },
  {
    name: "Rock C",
    message: "Hi I'm Fred H. How are you?",
  },
  {
    name: "Jonathan M",
    message: "Hi I'm Fred H. How are you?",
  },
  {
    name: "Abbott N",
    message: "Hi I'm Fred H. How are you?",
  },
  {
    name: "Allla A",
    message: "Hi I'm Fred H. How are you?",
  },
  {
    name: "Abram G",
    message: "Hi I'm Fred H. How are you?",
  },
  {
    name: "Ethan F",
    message: "Hi I'm Fred H. How are you?",
  },
  {
    name: "Nicholas B",
    message: "Hi I'm Fred H. How are you?",
  },
  {
    name: "Phil D",
    message: "Hi I'm Fred H. How are you?",
  },
];

const useStyles = makeStyles((theme) => ({
  // "@global": {
  //   "*::-webkit-scrollbar": {
  //     width: "5px",
  //   },
  //   "*::-webkit-scrollbar-track": {
  //     "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
  //   },
  //   "*::-webkit-scrollbar-thumb": {
  //     backgroundColor: "rgba(0,0,0,.1)",
  //     borderRadius: "10px",
  //   },
  // },
  bgMessagePage: {
    background: "#e9edf0",
    padding: "35px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
    minHeight: "90vh",
    // overflowY:"scroll"
  },
  headingList: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  roomMateheading: {
    fontSize: "16px !important",
  },
  headingListMobile: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "20px",
    fontWeight: "400 !important",
  },
  listGroupMain: {
    background: "white",
    // padding: "5px 10px",
    height: "calc(100vh - 200px)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 110px)",
    },
    // height: "680px",
    borderRadius: "5px",
    "& *::-webkit-scrollbar": {
      width: "100%",
    },
  },
  nameChatHead: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    justifyContent: "space-between",
  },
  chatImage: {
    width: "50px",
    height: "50px",
    marginRight: "10px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  horizontalLine: {
    borderTop: "1px solid lightGray",
    margin: 0,
  },
  listPeopleChat: {
    // height: "87%",
    height: "calc(100vh - 270px)",
    // height: "750px",
    overflowY: "auto",
    overflowX: "hidden",
    marginTop: "5px",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 245px)",
    },
  },
  listPeopleImage: {
    width: "45px",
    height: "45px",
    borderRadius: "50%",
    objectFit: "cover",
    objectPosition: "top",
  },
  flexItemDetail: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "7px 5px",
    cursor: "pointer",
  },
  listNameMen: {
    margin: "0",
    fontSize: "16px !important",
    fontWeight: "500",
  },
  messageLast: {
    margin: "0",
    color: "gray",
    fontSize: "12px",
    whiteSpace: "nowrap",
    width: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  timeListPeople: {
    color: "gray",
    fontSize: "10px",
    margin: 0,
  },
  unreadCount: {
    color: "white",
    fontSize: "11px",
    margin: 0,
    height: "15px",
    width: "15px",
    backgroundColor: "#FE4B6B",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5px",
  },
  imageBoxList: {
    display: "flex",
    alignItems: "center",
  },
  msgAndNameDisplay: {
    marginLeft: "15px",
  },

  chatMessageScreen: {
    // height: "79% !important",
    height: "calc(100vh - 350px) !important",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 250px) !important",
    },
    // [theme.breakpoints.down('sm')]: {
    //   height: "81% !important",
    // },
    position: "relative",
    overflowY: "scroll",
    marginTop: "10px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  mainInputMessage: {
    width: "98%",
    marginLeft: "10px",
    marginRight: "10px",
  },
  mainInputMessageSmallDevice: {
    width: "98%",
  },
  iconSendBg: {
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    borderRadius: "100%",
    width: "35px",
    height: "35px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
    color: "white",
    cursor: "pointer",
  },
  iconSendBgSmallDevice: {
    background: "#FF695B",
    borderRadius: "100%",
    width: "34px",
    height: "34px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    marginTop: "2px",
  },
  inputBoxWrite: {
    width: "100%",
    height: "60px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "50px",
    },
    display: "flex",
    alignItems: "center",
  },
  msgBoxFriend: {},
  nameInscreen: {
    margin: "0",
    color: "gray",
    fontSize: "10px",
  },
  msgText: {
    width: "max-content",
    background: "rgb(25 118 210)",
    borderRadius: "5px",
    padding: "4px 8px",
    fontSize: "14px",
    color: "white",
  },
  myMsgBox: {
    // width: "70%",
    float: "right",
  },
  myNameInscreen: {
    margin: "0",
    color: "gray",
    textAlign: "right",
    fontSize: "9px",
  },
  receiverNameInscreen: {
    margin: "0px 0px 0px 5px",
    color: "gray",
    textAlign: "left",
    fontSize: "9px",
  },
  myMsgText: {
    background: "#FF695B16",
    borderRadius: "10px",
    padding: "8px",
  },
  mainMsgTheme: {
    marginTop: "10px",
  },
  confirmMsg: {
    textAlign: "center",
  },
  diclineButton: {
    width: "208px",
    height: "50px",
    margin: "5px",
    textTransform: "capitalize",
    fontSize: "16px",
    borderRadius: "10px",
  },
  acceptButton: {
    width: "208px",
    height: "50px",
    margin: "5px",
    textTransform: "capitalize",
    fontSize: "16px",
    borderRadius: "10px",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
  },
  startedButton: {
    // fontSize: "20px",
    // height: "50px",
    height: "23px",
    fontSize: "14px",
    width: "138px",
    // background: "#0071CE",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    borderRadius: "10px",
    textTransform: "capitalize",
    fontWeight: "400",
    marginTop: "10px",
  },
  buttonGroupConfirm: {
    textAlign: "center",
  },
  confirmMatesMessage: {
    margin: "20px 0",
  },
  headingEdit: {
    fontSize: "18px",
    fontWeight: "600",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
    padding: "12px",
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  backAerrow: {
    alignSelf: "center",
    [theme.breakpoints.down("xs")]: {
      marginRight: "5px",
    },
  },
  smBottom: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "15px",
    },
  },
  sendIcon: {
    color: "#FE4B6B",
    cursor: "pointer",
  },
  imageLifStyle: {
    "& .react-fancybox .thumbnail img": {
      borderRadius: "50%",
      width: "45px",
      height: "45px",
      marginTop: "4px",
      [theme.breakpoints.down("xs")]: {
        overflowX: "scroll",
      },
      cursor: "pointer",
      objectFit: "cover",
    },
    // borderRadius: "100%",
    // width: "100%",
    // height: "100%",
  },
}));

const MessageTheme = (props) => {
  const { className, ...rest } = props;
  let { uid } = useParams();
  const listInnerRef = useRef();
  const router = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [nameChat, setNameChat] = useState("");
  const [reciverImage, setReciverImage] = useState("");
  const [requestStatus, setRequestStatus] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [senderId, setSenderId] = useState("");
  const [nameMobileChat, setNameMobileChat] = useState();
  const [message, setMessage] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [from_slug, setFrom_slug] = useState("");
  const [to_slug, setTo_slug] = useState("");
  const [lstMessage, setLstMessage] = useState([]);
  const [lstCount, setLstCount] = useState([]);
  const [lstInbox, setLstInbox] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [acceptLoading, setAcceptLoading] = React.useState(false);
  const [diclineLoading, setDiclineLoading] = React.useState(false);
  const [disabledAD, setdisabledAD] = React.useState(false);
  const [receiverCount, setReceiverCount] = React.useState(0);
  const [userBlocked, setUserBlocked] = React.useState(false);
  const [mateBlocked, setMateBlocked] = React.useState(false);
  const [properySlug, setProperySlug] = React.useState("");
  const [isSelect, setIsSelect] = React.useState(false);

  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  const messagesRef = React.useRef(null);
  // const scrollToBottom = () => {
  //   messagesRef.current.scrollIntoView({
  //     behavior: "smooth",
  //     block: "nearest",
  //     inline: "start"
  //   });
  // };

  // React.useEffect(() => {
  //   if (messagesRef.current) {
  //     scrollToBottom();
  //   }
  // }, [messagesRef]);

  const handleRetackClose = () => {
    setOpen(false);
  };
  const onOpenSetRetack = () => {
    setOpen(true);
  };

  // firebase data base connection
  const db = firebase.firestore();
  const CollectionData = db.collection("roommateChatRooms");

  // // firebase data base connection
  // const db = firebase.firestore();

  // const chatRoom = db.collection('dormmate')
  // // dormmate->senderid->receiverid->messageid->data

  //#####################################################################################################################################################################
  let query = useLocation();

  useEffect(() => {
    if (!uid) {
      if (lstInbox.length) {
        if (!isSelect) {
          let item = lstInbox[0];
          onInboxClick(
            item.fullName,
            item.imageUrl,
            item.request_id,
            item.status,
            item.id,
            item.receiverId,
            item.slug,
            item
          );
          setIsSelect(true);
          let scr = document.getElementById("chat");
          scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
        }
      }
    }
  }, [lstInbox]);

  useEffect(() => {
    console.log(senderId, receiverId, "chat count--");
    if (senderId !== "" && receiverId !== "")
      CollectionData.doc(senderId)
        .collection("inbox")
        .doc(receiverId)
        .get()
        .then((snapshot) => {
          if (snapshot.data()) {
            setRequestStatus(snapshot.data().status);
          } else {
            console.log("call snapshot else");
            // setReceiverCount(0)
            // sendMessagePre(event)
          }
        });
  }, [receiverId, senderId]);

  useEffect(() => {
    onload();
    getPropertyID();
  }, []);

  let getPropertyID = async () => {
    let to_slug = await AsyncLocalStorage.getItem("to_slug");
    // console.log("result")
    api
      .get(`user/propertyforroommate/${to_slug}`)
      .then((result) => {
        if (result.success === "true") {
          console.log(result, "result");
          setProperySlug(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  let onload = async () => {
    let senderId = await AsyncLocalStorage.getItem("senderId");

    if (senderId === "") return;
    setSenderId(senderId);

    let receiverName = await AsyncLocalStorage.getItem("receiverName");
    setNameMobileChat(receiverName);
    let receiverImg = await AsyncLocalStorage.getItem(
      "receiverProfilePicuture"
    );
    let request_status = await AsyncLocalStorage.getItem("pending");
    let request_id = await AsyncLocalStorage.getItem("request_id");
    let from_slug = await AsyncLocalStorage.getItem("from_slug");
    let to_slug = await AsyncLocalStorage.getItem("to_slug");
    setFrom_slug(from_slug);
    setTo_slug(to_slug);
    onIsBlock(to_slug);

    let inboxRef = CollectionData.doc(senderId).collection("inbox");
    inboxRef.orderBy("timestamp", "desc").onSnapshot((querySnapshot) => {
      let temp = [];
      querySnapshot.forEach((doc) => {
        temp.push(doc.data());
      });
      setLstInbox(temp);
    });

    if (!uid && uid === undefined) {
      return null;
    } else setReceiverId(uid);

    async function getDoc() {
      const snapshot = await inboxRef.doc(uid).get();
      const data = snapshot.data();

      if (data) {
        if (data.status == null) {
          setRequestStatus("pending");
        } else {
          setRequestStatus(data.status);
        }
        setRequestId(data.request_id);
      } else {
        setRequestStatus("pending");
        setRequestId(null);
      }
    }

    getDoc();

    let messagesRef = await CollectionData.doc(senderId).collection(uid);
    setSenderId(senderId);
    setReceiverName(receiverName);
    setNameChat(receiverName);
    setReciverImage(receiverImg);
    // setRequestId(request_id)
    // inbox list

    // message list
    messagesRef.orderBy("timestamp", "asc").onSnapshot((querySnapshot) => {
      let temp = [];
      querySnapshot.forEach((doc) => {
        temp.push(doc.data());
      });
      setLstMessage(temp);
      setLstMessage(temp);
      let scr = document.getElementById("chat");
      scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
      // scrollToBottom();
      // if (uid == receiverId) {
      //   console.log("YOu are received new message")
      //   const statusRef = CollectionData.doc(senderId).collection('inbox').doc(receiverId);
      //   statusRef.update({ count: 0 });
      // }
    });

    console.log("callll thisi kljlgkj");

    // const statusRef = CollectionData.doc(senderId).collection('inbox').doc(receiverId);
    // statusRef.update({ count: 0 });
  };
  //#####################################################################################################################################################################
  const onInboxClick = async (
    name,
    profile_picture,
    request_id,
    status,
    id,
    receiverId,
    slug,
    item
  ) => {
    console.log("item1", item);
    if (item.count) {
      AsyncLocalStorage.setItem("count", item.count);
    } else {
      AsyncLocalStorage.setItem("count", 0);
    }
    AsyncLocalStorage.setItem("receiverName", name);
    AsyncLocalStorage.setItem("receiverProfilePicuture", profile_picture);
    AsyncLocalStorage.setItem("request_id", request_id);
    AsyncLocalStorage.setItem("request_status", status);
    AsyncLocalStorage.setItem("to_id", id);
    AsyncLocalStorage.setItem("to_slug", slug);
    onIsBlock(slug);

    const statusRef = CollectionData.doc(senderId)
      .collection("inbox")
      .doc(receiverId);
    statusRef.update({ count: 0 });

    // const statusRef2 = CollectionData.doc(receiverId).collection('inbox').doc(senderId);
    // const resp2 = await statusRef2.update({ isCurrent: true });
    setNameChat(name);
    router.push(`/messages/${receiverId}`);
    await onload();
    let scr = document.getElementById("chat");
    scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
  };

  useEffect(() => {
    onload();
  }, [nameChat]);

  const mobileOpenMessageHandler = (name) => {
    setNameMobileChat(name);
  };
  const uuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const aceptDeclineRequest = async (status) => {
    // let from_id = await AsyncLocalStorage.getItem("from_id")
    let to_id = await AsyncLocalStorage.getItem("to_id");
    // "from_id":78,
    // "senderId":"0mHld8w4rQXacsj2M1Vws5C1tet2",
    // "receiverId":"luksOaD9PSWmF98Je2J8uwubygR2",
    // "status":"accept"
    let data = {
      from_id: to_id,
      senderId: senderId,
      receiverId: receiverId,
      status: status,
    };
    setdisabledAD(true);
    if (status === "accept") setAcceptLoading(true);
    else setDiclineLoading(true);
    api
      .post("user/acceptrequest", {
        data: data,
      })
      .then(async (result) => {
        if (result.success === "true") {
          if (status === "accept") {
            AsyncLocalStorage.setItem("request_status", "accept");
            setRequestStatus("accept");
          } else if (status === "decline") {
            AsyncLocalStorage.setItem("request_status", "decline");
            setRequestStatus("decline");
          }
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setAcceptLoading(false);
        setDiclineLoading(false);
        setdisabledAD(false);
      })
      .catch((err) => {
        setAcceptLoading(false);
        setDiclineLoading(false);
        setdisabledAD(false);
      });
  };

  useEffect(() => {
    console.log(receiverCount, "call UseEffect");
    // if (receiverCount !== 0) {
    console.log("call UseEffect Function");
    sendMessagePre();
    // }
  }, [receiverCount]);

  useEffect(() => {
    if (receiverId !== "" && senderId !== "")
      CollectionData.doc(receiverId)
        .collection("inbox")
        .doc(senderId)
        .get()
        .then((snapshot) => {
          if (snapshot.data()) {
            console.log(snapshot.data().count, "call count");
            // setReceiverCount(snapshot.data().count + 1)
          } else {
            console.log("call snapshot else");
          }
        });
  }, []);

  const onBlock = () => {
    api
      .post(`user/blockprofile/${to_slug}`, {
        data: { is_blocked: false },
      })
      .then((result) => {
        if (result.success === "true") {
          // onLoad()
          setUserBlocked(false);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  const onIsBlock = (toSlug) => {
    api
      .get(`user/getblockuser/${toSlug}`)
      .then(async (result) => {
        if (result.success === "true") {
          setMateBlocked(result.data.mateBlocked);
          setUserBlocked(result.data.userBlocked);
        } else {
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const sendMessage = async (event) => {
    console.log("call");
    event.preventDefault();
    CollectionData.doc(receiverId)
      .collection("inbox")
      .doc(senderId)
      .get()
      .then((snapshot) => {
        if (snapshot.data()) {
          console.log(snapshot.data().count + 1, "call snapshot is");
          setReceiverCount(snapshot.data().count + 1);
        } else {
          console.log("call snapshot else");
          setReceiverCount(0);
          sendMessagePre(event);
        }
      });
    // if (receiverCount === 0)
    //   sendMessagePre()
  };

  const sendMessagePre = async (event) => {
    // event.preventDefault();
    if (message === "") return;
    let receiverName = await AsyncLocalStorage.getItem("receiverName");
    let receiverProfilePicuture = await AsyncLocalStorage.getItem(
      "receiverProfilePicuture"
    );
    let senderId = await AsyncLocalStorage.getItem("senderId");
    let userProfilePicture = await AsyncLocalStorage.getItem("userProfile");
    let userName = await AsyncLocalStorage.getItem("userName");
    let to_id = await AsyncLocalStorage.getItem("to_id");
    let from_id = await AsyncLocalStorage.getItem("from_id");
    // let receiverCount = await AsyncLocalStorage.getItem("count")

    let uuid1 = uuid();
    var date = new Date();
    // var timestamp = date.toUTCString();
    var timestamp = new Date();

    let sendMessageData = {
      chatID: uuid1,
      type: "text",
      senderId: senderId,
      receiverId: receiverId,
      message: message.trim(),
      mediaUrl: "",
      fileName: "",
      seen: false,
      timestamp: timestamp,
    };
    CollectionData.doc(senderId)
      .collection(receiverId)
      .doc(uuid1)
      .set(sendMessageData);
    CollectionData.doc(receiverId)
      .collection(senderId)
      .doc(uuid1)
      .set(sendMessageData);

    // Inbox
    let senderInbox = {
      lastMessage: message.trim(),
      mediaUrl: "",
      senderId: receiverId,
      receiverId: senderId,
      seen: false,
      type: "text",
      timestamp: timestamp,
      archive: false,
      fullName: userName,
      imageUrl: userProfilePicture,
      status: requestStatus,
      id: from_id,
      request_id: requestId ? requestId : "",
      slug: from_slug,
      count: receiverCount,
    };

    let receiverInbox = {
      lastMessage: message.trim(),
      mediaUrl: "",
      senderId: senderId,
      receiverId: receiverId,
      seen: false,
      type: "text",
      timestamp: timestamp,
      archive: false,
      fullName: receiverName,
      imageUrl: receiverProfilePicuture,
      status: requestStatus,
      id: to_id,
      request_id: requestId ? requestId : "",
      slug: to_slug,
      count: 0,
    };

    CollectionData.doc(senderId)
      .collection("inbox")
      .doc(receiverId)
      .set(receiverInbox);
    CollectionData.doc(receiverId)
      .collection("inbox")
      .doc(senderId)
      .set(senderInbox);
    AsyncLocalStorage.setItem("count", parseInt(receiverCount) + 1);
    setMessage("");
    // sendNotification()
    const snapshotNotification = await db
      .collection("users")
      .doc(receiverId)
      .get();
    const data = snapshotNotification.data();
    // console.log(data.deviceToken, "data")
    data?.deviceToken?.length &&
      data.deviceToken.map((value) => {
        var argument = {
          receiverId: senderId,
          fullName: userName,
          profilePicture: userProfilePicture,
          to_id: from_id,
          slug: from_slug,
          intentType: "inbox",
          click_action: "FLUTTER_NOTIFICATION_CLICK",
          module_name: "Roommates",
        };
        console.log(argument, "argument");
        return sendNotification(value, userName, message.trim(), argument);
      });
  };

  const sendNotification = (token, senderName, message, argument) => {
    // var axios = require('axios');

    var config = {
      method: "post",
      url: "https://fcm.googleapis.com/fcm/send",
      headers: {
        "content-type": "application/json",
        Authorization:
          "key=AAAAVMzXodM:APA91bG6UT_Vvio04E22WyVB60Has8n8RQmGcT68wyqmox6uWiezc9mUVF_sCsuwy6Qh4gyhpdxfOUaauOVMIJEoC14mhbJ8yQhpyRER1xFZUDGbxRvkDsId3H1cb3rkUkvtJRwRFH50",
      },
      data: {
        to: token,
        notification: {
          title: senderName,
          body: message,
          click_action: "FLUTTER_NOTIFICATION_CLICK",
        },
        data: argument,
      },
    };

    axios(config)
      .then(function(response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  const onProfile = () => {
    router.push(`/your-matches/view/${to_slug}`);
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  function timeAgo(time) {
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: (number) => number + "s ago",
        ss: "%ds ago",
        m: "1m ago",
        mm: "%dm ago",
        h: "1h ago",
        hh: "%dh ago",
        d: "1d ago",
        dd: "%dd ago",
        M: "a month ago",
        MM: "%d months ago",
        y: "a year ago",
        yy: "%d years ago",
      },
    });

    let secondsElapsed = moment().diff(time, "seconds");
    let dayStart = moment("2018-01-01")
      .startOf("day")
      .seconds(secondsElapsed);

    if (secondsElapsed > 300) {
      return moment(time).fromNow(true);
    } else if (secondsElapsed < 60) {
      return dayStart.format("s") + " sec ago";
    } else {
      return dayStart.format("m:ss") + "min ago";
    }
  }

  const onScroll = () => {
    // console.log(listInnerRef.current, "call")
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log("reached bottom");
        // hasMoreMessageEnd()
      }
      if (!scrollTop) {
        console.log("reached top");
        // hasMoreMessageStart()
      }
    }
  };

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleErorrClose}
        >
          <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}

      {!uid ? (
        <div className={classes.bgMessagePage}>
          <Container maxWidth="lg">
            <Grid spacing={3} container>
              <Grid className={classes.responsiveHide}>
                <ListMenuSideBar />
              </Grid>
              <Grid
                className={classes.container}
                item
                style={{ paddingTop: "25px" }}
              >
                <div
                  className={classes.listGroupMain}
                  style={{ marginLeft: "16px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      background: "white",
                      // height: "680px"
                      height: "calc(100vh - 200px)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <img
                        src="/images/matchUp/notFoundMatches.svg"
                        alt="favAlt"
                      />
                      <Typography
                        variant="caption"
                        component="primary"
                        color="primary"
                        style={{
                          fontSize: "20px",
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        No Conversation
                      </Typography>
                      <Typography
                        variant="caption"
                        component="primary"
                        color="primary"
                        style={{
                          fontSize: "14px",
                          color: "gray",
                          fontWeight: "400",
                        }}
                      >
                        You didn't made any Conversation yet
                      </Typography>

                      <Button
                        className={classes.startedButton}
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={(event) => {
                          router.push("/your-matches");
                          localStorage.setItem("tabValue", 1);
                        }}
                      >
                        Find matches
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <>
          <Hidden mdUp>
            <div className={classes.bgMessagePage}>
              <Container maxWidth="lg">
                <Grid spacing={3} container>
                  <Grid
                    className={classes.mainMsgTheme}
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={9}
                  >
                    <Grid container spacing={2}>
                      {nameMobileChat ? (
                        <Grid className={classes.smBottom} item xs={12}>
                          <div className={classes.listGroupMain}>
                            <div className={classes.nameChatHead}>
                              <div style={{ display: "flex" }}>
                                <span
                                  onClick={() => setNameMobileChat("")}
                                  className={classes.backAerrow}
                                >
                                  <ArrowBackIcon />
                                </span>
                                <div className={classes.imageLifStyle}>
                                  <Fancybox>
                                    <a
                                      data-fancybox="Images"
                                      href={
                                        reciverImage && reciverImage !== "null"
                                          ? reciverImage
                                          : "/images/male_defualt.svg"
                                      }
                                    >
                                      <Avatar
                                        // className={classes.yourImageFix}
                                        src={
                                          reciverImage &&
                                          reciverImage !== "null"
                                            ? reciverImage
                                            : "/images/male_defualt.svg"
                                        }
                                        alt={nameChat}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "50%",
                                          position: "relative",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </a>
                                  </Fancybox>
                                  {/* <ReactFancyBox
                                thumbnail={
                                  reciverImage && reciverImage !== "null"
                                    ? reciverImage
                                    : "/images/male_defualt.svg"
                                }
                                image={
                                  reciverImage && reciverImage !== "null"
                                    ? reciverImage
                                    : "/images/male_defualt.svg"
                                }
                                defaultThumbnailWidth={"100px"}
                                defaultThumbnailHeight={"100px"}
                              /> */}
                                </div>
                                {/* <img
                              className={classes.chatImage}
                              src={reciverImage && reciverImage !== "null" && reciverImage !== "" ? reciverImage : "/images/male_defualt.svg"}
                            /> */}
                                <h5
                                  style={{ margin: "10px", cursor: "pointer" }}
                                  onClick={() => {
                                    onProfile();
                                  }}
                                >
                                  {" "}
                                  {nameChat}{" "}
                                </h5>
                              </div>
                              {/* {!mateBlocked && !userBlocked &&
                            <div>
                              {requestStatus === "pending" &&
                                <Tooltip title="Send Request" placement="top">
                                  <PersonAddIcon className={classes.sendIcon} onClick={() => { onOpenSetRetack() }} />
                                </Tooltip>
                              }
                            </div>
                          } */}
                              <div>
                                <Tooltip title="View property" placement="top">
                                  <a
                                    target="_blank"
                                    href={`property/${properySlug}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <HomeIcon className={classes.sendIcon} />
                                  </a>
                                </Tooltip>
                              </div>
                            </div>
                            {/* <hr className={classes.horizontalLine} /> */}
                            <Divider />
                            <div
                              className={classes.chatMessageScreen}
                              id="chat"
                              onScroll={onScroll}
                              ref={listInnerRef}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {lstMessage.map((obj) => {
                                  if (obj.senderId === senderId) {
                                    return (
                                      <div style={{ alignSelf: "end" }}>
                                        <div
                                          style={{
                                            backgroundColor: "#d5606045",
                                            padding: "5px",
                                            margin: "3px",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {obj.message}
                                        </div>
                                        <div style={{ marginRight: "3px" }}>
                                          <p className={classes.myNameInscreen}>
                                            {timeAgo(
                                              new Date(
                                                obj.timestamp.seconds * 1000
                                              )
                                            )}
                                            {/* {moment(new Date(obj.timestamp.seconds * 1000)).fromNow()} */}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div style={{ alignSelf: "start" }}>
                                        <div
                                          style={{
                                            backgroundColor: "#FF695B",
                                            color: "white",
                                            padding: "5px",
                                            margin: "3px",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {obj.message}
                                        </div>
                                        <div style={{ marginRight: "3px" }}>
                                          <p
                                            className={
                                              classes.receiverNameInscreen
                                            }
                                          >
                                            {timeAgo(
                                              new Date(
                                                obj.timestamp.seconds * 1000
                                              )
                                            )}
                                            {/* {moment(new Date(obj.timestamp.seconds * 1000)).fromNow()} */}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                                {/* <div ref={messagesRef} /> */}
                              </div>
                              {requestId !== senderId &&
                                requestStatus === "sent" && (
                                  <div className={classes.confirmMatesMessage}>
                                    <p className={classes.confirmMsg}>
                                      Accept dormmates partner request from{" "}
                                      <span style={{ fontWeight: "bold" }}>
                                        {nameChat}?
                                      </span>
                                    </p>
                                    <div className={classes.buttonGroupConfirm}>
                                      <Button
                                        className={classes.acceptButton}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          aceptDeclineRequest("accept");
                                        }}
                                        disabled={disabledAD}
                                      >
                                        Accept
                                        {acceptLoading && (
                                          <>
                                            &nbsp;
                                            <CircularProgress
                                              size={20}
                                              style={{ color: "white" }}
                                            />
                                          </>
                                        )}
                                      </Button>
                                      <Button
                                        className={classes.diclineButton}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                          aceptDeclineRequest("decline");
                                        }}
                                        disabled={disabledAD}
                                      >
                                        Decline
                                        {diclineLoading && (
                                          <>
                                            &nbsp;
                                            <CircularProgress
                                              size={20}
                                              style={{ color: "white" }}
                                            />
                                          </>
                                        )}
                                      </Button>
                                    </div>
                                  </div>
                                )}
                            </div>
                            <hr className={classes.horizontalLine} />
                            <form onSubmit={sendMessage}>
                              <div className={classes.inputBoxWrite}>
                                <Grid
                                  container
                                  style={{
                                    display: "flex",
                                    // justifyContent: "center",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={10}
                                    sm={10}
                                    md={10}
                                    lg={11}
                                    xl={11}
                                  >
                                    <TextField
                                      className={classes.mainInputMessage}
                                      fullWidth
                                      size="small"
                                      id="outlined-basic"
                                      placeholder="Message..."
                                      variant="outlined"
                                      value={message}
                                      onChange={(e) => {
                                        setMessage(e.target.value);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                    <div className={classes.iconSendBg}>
                                      <SendOutlinedIcon
                                        type="button"
                                        onClick={(e) => {
                                          sendMessage(e);
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </form>
                          </div>
                          {/* <div className={classes.listGroupMain}>
                        <div className={classes.nameChatHead}>
                          <span
                            onClick={() => setNameMobileChat("")}
                            className={classes.backAerrow}
                          >
                            <ArrowBackIcon />
                          </span>
                          <img
                            className={classes.chatImage}
                            src="/images/male_defualt.svg"
                          />
                          <h4> {nameMobileChat} </h4>
                        </div>
                        <hr className={classes.horizontalLine} />
                        <div className={classes.chatMessageScreen}>
                          <div className={classes.messagePrint}>
                            <Box className={classes.msgBoxFriend}>
                              <p className={classes.nameInscreen}>
                                {nameMobileChat} 9:14 AM
                              </p>
                              <Box className={classes.msgText}>
                                Hi Fred H is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy.
                              </Box>
                            </Box>
                            <Box my={2} className={classes.myMsgBox}>
                              <p className={classes.myNameInscreen}>9:14 AM</p>
                              <Box className={classes.myMsgText}>
                                Hi Fred H is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy.
                              </Box>
                            </Box>
                          </div>
                        </div>
                        <div className={classes.confirmMatesMessage}>
                          <p className={classes.confirmMsg}>
                            Accept dormmates partner request from
                            <span style={{ fontWeight: "bold" }}>
                              {nameMobileChat}
                            </span>
                          </p>
                          <div className={classes.buttonGroupConfirm}>
                            <Button
                              className={classes.confirmButton}
                              variant="contained"
                              color="primary"
                            >
                              Accept
                            </Button>
                            <Button
                              className={classes.confirmButton}
                              variant="outlined"
                              color="primary"
                            >
                              Decline
                            </Button>
                          </div>
                        </div>
                        <div className={classes.inputBoxWrite}>
                          
                          <Grid container style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <Grid item xs={10} sm={11} md={10} lg={11} xl={11}>
                              <TextField
                                className={classes.mainInputMessageSmallDevice}
                                size="small"
                                id="outlined-basic"
                                placeholder="Message..."
                                variant="outlined"
                                value={message}
                                onChange={(e) => { setMessage(e.target.value) }}
                              />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                              <div className={classes.iconSendBgSmallDevice} >
                                <SendOutlinedIcon onClick={() => { sendMessage() }} />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div> */}
                        </Grid>
                      ) : (
                        <Grid className={classes.smBottom} item xs={12}>
                          <div className={classes.listGroupMain}>
                            <div className={classes.headingListMobile}>
                              <h2 style={{ fontWeight: "600" }}>
                                Messages RoomMates
                              </h2>
                            </div>
                            <hr className={classes.horizontalLine} />
                            <div className={classes.listPeopleChat}>
                              {lstInbox &&
                                lstInbox.map((item, index) => (
                                  <>
                                    <div
                                      onClick={() => {
                                        onInboxClick(
                                          item.fullName,
                                          item.imageUrl,
                                          item.request_id,
                                          item.status,
                                          item.id,
                                          item.receiverId,
                                          item.slug,
                                          item
                                        );
                                        mobileOpenMessageHandler(item.fullName);
                                      }}
                                      style={
                                        item.receiverId === receiverId
                                          ? { backgroundColor: "#d5606045" }
                                          : {}
                                      }
                                      className={classes.flexItemDetail}
                                    >
                                      <div className={classes.imageBoxList}>
                                        <Avatar
                                          className={classes.listPeopleImage}
                                          src={
                                            item.imageUrl &&
                                            item.imageUrl !== null &&
                                            item.imageUrl !== undefined
                                              ? item.imageUrl
                                              : "/images/male_defualt.svg"
                                          }
                                          alt={item.fullName}
                                        />
                                        <div
                                          className={classes.msgAndNameDisplay}
                                        >
                                          <h5 className={classes.listNameMen}>
                                            {item.fullName}
                                          </h5>
                                          <p className={classes.messageLast}>
                                            {item.lastMessage}&nbsp;&nbsp;
                                            {item.request_id !==
                                              item.senderId &&
                                              item.status === "sent" && (
                                                <img
                                                  src={
                                                    "/images/matchUp/sendReqStatus.svg"
                                                  }
                                                  alt="img"
                                                  style={{ paddingTop: "5px" }}
                                                />
                                              )}
                                            {item.request_id ===
                                              item.senderId &&
                                              item.status === "sent" && (
                                                <img
                                                  src={
                                                    "/images/matchUp/reciveReqStatus.svg"
                                                  }
                                                  alt="img"
                                                  style={{ paddingTop: "5px" }}
                                                />
                                              )}
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "flex-end",
                                          justifyContent: "flex-end",
                                          height: "45px",
                                        }}
                                      >
                                        {item.count && item.count !== 0 ? (
                                          <>
                                            {item.receiverId !== receiverId ? (
                                              <p
                                                className={classes.unreadCount}
                                              >
                                                {item.count}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <p className={classes.timeListPeople}>
                                          {timeAgo(
                                            new Date(
                                              item.timestamp.seconds * 1000
                                            )
                                          )}
                                          {/* {moment(new Date(item.timestamp.seconds * 1000)).fromNow()} */}
                                        </p>
                                      </div>
                                    </div>
                                    {dummyListData.length - 1 === index ? (
                                      ""
                                    ) : (
                                      <hr className={classes.horizontalLine} />
                                    )}
                                  </>
                                ))}
                            </div>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Hidden>

          <Hidden smDown>
            <div className={classes.bgMessagePage}>
              <Container maxWidth="lg">
                <Grid spacing={3} container>
                  <Grid className={classes.responsiveHide}>
                    <ListMenuSideBar />
                  </Grid>
                  <Grid className={classes.container} item>
                    {/* <h5 className={classes.headingEdit} style={{ marginLeft: "10px", marginBottom: "15px" }}> Messages </h5> */}
                    <Grid container spacing={2} style={{ marginTop: "8px" }}>
                      <Grid item xs={4}>
                        <div
                          className={classes.listGroupMain}
                          style={{ marginLeft: "10px" }}
                        >
                          <div className={classes.headingList}>
                            <h4
                              className={classes.roomMateheading}
                              style={{ fontWeight: "600", margin: "15px" }}
                            >
                              {" "}
                              Messages{" "}
                            </h4>
                            {/* <h4 style={{ fontWeight: "600", margin: "15px" }}> Messages RoomMates</h4> */}
                            {/* <PersonAddOutlinedIcon /> */}
                          </div>
                          <Divider />
                          {/* <hr className={classes.horizontalLine} /> */}
                          <div className={classes.listPeopleChat}>
                            {lstInbox &&
                              lstInbox.map((item, index) => (
                                <>
                                  <div
                                    onClick={() =>
                                      onInboxClick(
                                        item.fullName,
                                        item.imageUrl,
                                        item.request_id,
                                        item.status,
                                        item.id,
                                        item.receiverId,
                                        item.slug,
                                        item
                                      )
                                    }
                                    style={
                                      item.receiverId === receiverId
                                        ? { backgroundColor: "#d5606045" }
                                        : {}
                                    }
                                    className={classes.flexItemDetail}
                                  >
                                    <div className={classes.imageBoxList}>
                                      <Avatar
                                        className={classes.listPeopleImage}
                                        src={
                                          item.imageUrl &&
                                          item.imageUrl !== "null"
                                            ? item.imageUrl
                                            : "/images/male_defualt.svg"
                                        }
                                        alt={item.fullName}
                                      />
                                      <div
                                        className={classes.msgAndNameDisplay}
                                      >
                                        <h5 className={classes.listNameMen}>
                                          {item.fullName}
                                        </h5>
                                        <p className={classes.messageLast}>
                                          {item.lastMessage}&nbsp;&nbsp;
                                          {item.request_id !== item.senderId &&
                                            item.status === "sent" && (
                                              <img
                                                src={
                                                  "/images/matchUp/sendReqStatus.svg"
                                                }
                                                alt="img"
                                                style={{ paddingTop: "5px" }}
                                              />
                                            )}
                                          {item.request_id === item.senderId &&
                                            item.status === "sent" && (
                                              <img
                                                src={
                                                  "/images/matchUp/reciveReqStatus.svg"
                                                }
                                                alt="img"
                                                style={{ paddingTop: "5px" }}
                                              />
                                            )}
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                        justifyContent: "flex-end",
                                        height: "45px",
                                      }}
                                    >
                                      {item.count && item.count !== 0 ? (
                                        <>
                                          {item.receiverId !== receiverId ? (
                                            <p className={classes.unreadCount}>
                                              {item.count}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <p className={classes.timeListPeople}>
                                        {timeAgo(
                                          new Date(
                                            item.timestamp.seconds * 1000
                                          )
                                        )}
                                        {/* {moment(new Date(item.timestamp.seconds * 1000)).fromNow()} */}
                                      </p>
                                    </div>
                                  </div>
                                  {dummyListData.length - 1 === index ? (
                                    ""
                                  ) : (
                                    <Divider />
                                    // <hr className={classes.horizontalLine} />
                                  )}
                                </>
                              ))}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={8}>
                        {!uid ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              background: "white",
                              // height: "680px"
                              height: "calc(100vh - 200px)",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                marginTop: "50px",
                              }}
                            >
                              <img
                                src="/images/matchUp/notFoundMatches.svg"
                                alt="favAlt"
                              />
                              <Typography
                                variant="caption"
                                component="primary"
                                color="primary"
                                style={{
                                  fontSize: "20px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                No Message Yet
                              </Typography>
                              <Typography
                                variant="caption"
                                component="primary"
                                color="primary"
                                style={{
                                  fontSize: "14px",
                                  color: "gray",
                                  fontWeight: "400",
                                }}
                              >
                                Send message to your favourite matches
                              </Typography>
                            </div>
                          </div>
                        ) : (
                          <div className={classes.listGroupMain}>
                            <div className={classes.nameChatHead}>
                              <div style={{ display: "flex" }}>
                                {console.log(reciverImage, "reciverImage--")}
                                {senderId && (
                                  <div className={classes.imageLifStyle}>
                                    <Fancybox>
                                      <a
                                        data-fancybox="Images"
                                        href={
                                          reciverImage &&
                                          reciverImage !== "null"
                                            ? reciverImage
                                            : "/images/male_defualt.svg"
                                        }
                                      >
                                        <Avatar
                                          src={
                                            reciverImage &&
                                            reciverImage !== "null"
                                              ? reciverImage
                                              : "/images/male_defualt.svg"
                                          }
                                          alt={nameChat}
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "50%",
                                            position: "relative",
                                            objectFit: "cover",
                                            objectPosition: "top",
                                          }}
                                        />
                                      </a>
                                    </Fancybox>
                                  </div>
                                )}
                                <h5
                                  style={{ margin: "10px", cursor: "pointer" }}
                                  onClick={() => {
                                    onProfile();
                                  }}
                                >
                                  {" "}
                                  {nameChat}{" "}
                                </h5>
                              </div>

                              {properySlug != "" && (
                                <div>
                                  <Tooltip
                                    title="View property"
                                    placement="top"
                                  >
                                    <a
                                      target="_blank"
                                      href={`property/${properySlug}`}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <HomeIcon className={classes.sendIcon} />
                                    </a>
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                            <Divider />
                            <div
                              className={classes.chatMessageScreen}
                              id="chat"
                              onScroll={onScroll}
                              ref={listInnerRef}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {lstMessage.map((obj) => {
                                  if (obj.senderId === senderId) {
                                    return (
                                      <div style={{ alignSelf: "end" }}>
                                        <div
                                          style={{
                                            backgroundColor: "#d5606045",
                                            padding: "5px",
                                            margin: "3px",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {obj.message}
                                        </div>
                                        <div style={{ marginRight: "3px" }}>
                                          {/* <p className={classes.myNameInscreen}>{DateToHHMM(obj.timestamp)}</p> */}
                                          <p className={classes.myNameInscreen}>
                                            {timeAgo(
                                              new Date(
                                                obj.timestamp.seconds * 1000
                                              )
                                            )}
                                            {/* {moment(new Date(obj.timestamp.seconds * 1000)).fromNow()} */}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div style={{ alignSelf: "start" }}>
                                        <div
                                          style={{
                                            backgroundColor: "#FF695B",
                                            color: "white",
                                            padding: "5px",
                                            margin: "3px",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {obj.message}
                                        </div>
                                        <div style={{ marginRight: "3px" }}>
                                          <p
                                            className={
                                              classes.receiverNameInscreen
                                            }
                                          >
                                            {timeAgo(
                                              new Date(
                                                obj.timestamp.seconds * 1000
                                              )
                                            )}
                                            {/* {moment(new Date(obj.timestamp.seconds * 1000)).fromNow()} */}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                                {/* <div ref={messagesRef} /> */}
                              </div>
                              {requestId !== senderId &&
                                requestStatus === "sent" && (
                                  <div className={classes.confirmMatesMessage}>
                                    <p className={classes.confirmMsg}>
                                      Accept dormmates partner request from{" "}
                                      <span style={{ fontWeight: "bold" }}>
                                        {nameChat}?
                                      </span>
                                    </p>
                                    <div className={classes.buttonGroupConfirm}>
                                      <Button
                                        className={classes.acceptButton}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          aceptDeclineRequest("accept");
                                        }}
                                        disabled={disabledAD}
                                      >
                                        Accept
                                        {acceptLoading && (
                                          <>
                                            &nbsp;
                                            <CircularProgress
                                              size={20}
                                              style={{ color: "white" }}
                                            />
                                          </>
                                        )}
                                      </Button>
                                      <Button
                                        className={classes.diclineButton}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                          aceptDeclineRequest("decline");
                                        }}
                                        disabled={disabledAD}
                                      >
                                        Decline
                                        {diclineLoading && (
                                          <>
                                            &nbsp;
                                            <CircularProgress
                                              size={20}
                                              style={{ color: "white" }}
                                            />
                                          </>
                                        )}
                                      </Button>
                                    </div>
                                  </div>
                                )}
                            </div>
                            <hr className={classes.horizontalLine} />
                            <form onSubmit={sendMessage}>
                              <div className={classes.inputBoxWrite}>
                                {userBlocked ? (
                                  <Box display={"flex"} width={"100%"}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        width: "100%",
                                        borderRadius: "20px",
                                        background:
                                          "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
                                        textTransform: "capitalize",
                                      }}
                                      onClick={() => {
                                        onBlock();
                                      }}
                                    >
                                      Unblock
                                    </Button>
                                  </Box>
                                ) : (
                                  <>
                                    {mateBlocked ? (
                                      <Box display={"flex"} width={"100%"}>
                                        <Button
                                          variant="contained"
                                          style={{
                                            width: "100%",
                                            borderRadius: "20px",
                                            textTransform: "capitalize",
                                          }}
                                          disabled
                                        >
                                          You are block by {nameChat}
                                        </Button>
                                      </Box>
                                    ) : (
                                      <>
                                        {senderId && (
                                          <Grid
                                            container
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-evenly",
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={10}
                                              sm={10}
                                              md={10}
                                              lg={11}
                                              xl={11}
                                            >
                                              <TextField
                                                className={
                                                  classes.mainInputMessage
                                                }
                                                fullWidth
                                                size="small"
                                                placeholder="Message..."
                                                variant="outlined"
                                                value={message}
                                                onChange={(e) => {
                                                  setMessage(e.target.value);
                                                }}
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={1}
                                              sm={1}
                                              md={1}
                                              lg={1}
                                              xl={1}
                                            >
                                              <div
                                                className={classes.iconSendBg}
                                              >
                                                {/* <SendOutlinedIcon onClick={(e) => { sendMessage(e) }} /> */}
                                                <SendOutlinedIcon
                                                  type="button"
                                                  onClick={(e) => {
                                                    sendMessage(e);
                                                  }}
                                                />
                                              </div>
                                            </Grid>
                                          </Grid>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </form>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Hidden>
        </>
      )}
      <RequestModelRoomamte
        open={open}
        onClose={handleRetackClose}
        senderId={senderId}
        receiverId={receiverId}
        setRequestStatus={setRequestStatus}
        setRequestId={setRequestId}
        setSessionMessage={setSessionMessage}
      />
    </>
  );
};

export default MessageTheme;
