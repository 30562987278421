import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import AddIcon from "@material-ui/icons/Add";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import { useEffect } from "react";
import ChipInput from "material-ui-chip-input";
import validate from "validate.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import SliderModel from "../Slider/SliderModel";
import Api from "../../../../Helper/ApiHandler";
import useRouter from "utils/useRouter";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import AppDataContext from "Helper/AppDataContext";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import EditDialog from "components/EditModel/EditModel";
import { comppressImage, resizeFile } from "utils/utils";

import axios from "axios";
import Compressor from "compressorjs";

var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const schema = {
  first_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  last_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: { maximum: 254 },
  },
  zodiac: {
    presence: { allowEmpty: false, message: "is required" },
  },
  p_university: {
    presence: { allowEmpty: false, message: "is required" },
  },
  location: {
    presence: { allowEmpty: false, message: "is required" },
  },
  bio: {
    // presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 250 },
  },
  date_of_birth: {
    presence: { allowEmpty: false, message: "is required" },
  },
  gender: {
    presence: { allowEmpty: false, message: "is required" },
  },
  // preference_gender: {
  //   presence: { allowEmpty: false, message: "is required" },
  // }
};

const schemaSoul = {
  first_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  last_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: { maximum: 254 },
  },
  zodiac: {
    presence: { allowEmpty: false, message: "is required" },
  },
  location: {
    presence: { allowEmpty: false, message: "is required" },
  },
  bio: {
    // presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 250 },
  },
  date_of_birth: {
    presence: { allowEmpty: false, message: "is required" },
  },
  gender: {
    presence: { allowEmpty: false, message: "is required" },
  },
  preference_gender: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

// const lstRoomType = [
//   { value: "Off-campus" },
//   { value: "On-campus" },
// ]

const useStyles = makeStyles((theme) => ({
  bgEditProfile: {
    //fontFamily: "Gilroy !important",
    minHeight: "calc(100vh - 125px)",
    background: "#E9EDF0",
    padding: "35px 0",
    // marginBottom: "60px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      marginBottom: "10px",
    },
    "& .MuiContainer-root": {
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        overflowX: "hidden",
      },
    },
  },
  whiteCardProfile: {
    background: "white",
    borderRadius: "10px",
    // paddingBottom: "60px",
    marginLeft: "16px",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "8px",
    },
  },
  headingEdit: {
    padding: "0 0 0px 25px",
    fontSize: "18px",
    fontWeight: "600 !important",
    margin: "15px 0px",
  },
  imageEditProfile: {
    textAlign: "center",
    // margin: "15px 0",
    margin: "0",
  },
  changeHeading: {
    color: "gray",
    fontSize: "15px !important",
    margin: "5px 15px",
    fontWeight: "400",

    // color: "black",
    // fontSize: "18px !important",
    // margin: "-6px 20px",
    // marginBottom: "13px",
    // fontWeight: "500"
  },
  changeImageTap: {
    display: "flex",
  },
  iconTapBorder: {
    width: "60px",
    height: "60px",
    border: "2px solid #FE4B6B",
    borderRadius: "50%",
    // margin: "5px 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  buttonsBoxEdit: {
    textAlign: "center",
    margin: "25px 0",
  },
  editButtons: {
    width: "70%",
    borderRadius: "25px",
    textTransform: "capitalize",
    fontSize: "16px",
  },
  horizontalLine: {
    borderTop: "1px solid lightGray",
  },
  boxListDetail: {
    marginTop: "10px",
  },
  inputProfileEdit: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: "15px",
      paddingBottom: "15px",
    },
    //fontFamily: "Gilroy !important",
    // '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
    //   padding: "0px"
    // }
  },
  textRoot: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
  },
  chipInputEdit: {
    // margin: "3px 20px",
    "& .MuiAutocomplete-tag": {
      borderRadius: "5px !important",
      background: "rgb(204 227 245)",
    },
    //fontFamily: "Gilroy !important",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
  },
  shareAboutInput: {
    // margin: "3px 20px",
    borderRadius: "10px !important",
    fontWeight: "bold",
    //fontFamily: "Gilroy !important",
  },
  formControl: {
    width: "100%",
  },
  inputLabelEdit: {
    //fontFamily: "Gilroy !important",
    marginLeft: "30px",
    marginBottom: "2px",
    fontSize: "14px",
  },
  chip: {
    background: "#d5606045",
    //fontFamily: "Gilroy !important",
    borderRadius: "5px",
    margin: "5px 5px",
    padding: "0 0",
    height: "28px",
  },
  genderHeading: {
    fontSize: "16px",
    fontWeight: "500",
    // marginLeft: "12px",
    marginRight: "12px",
  },
  genderOptionRoot: {
    paddingLeft: "50px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  genderRoot: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  genderPrefranceHeading: {
    fontSize: "16px",
    fontWeight: "500",
    // marginLeft: "12px",
    marginRight: "18px",
  },
  confirmButton: {
    //fontFamily: "Gilroy !important",
    margin: "0 5px",
    textTransform: "capitalize",
    padding: "5px 70px",
    fontSize: "18px",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 40px",
      margin: "5px",
    },
    width: "220px",
  },
  buttonGroupConfirm: {
    textAlign: "center",
    margin: "10px 0px",
  },
  genderSelectBox: {
    display: "flex",
    alignItems: "center",
    // marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  checkboxDetail: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
    },
  },
  profilePhotoEdit: {
    position: "relative",
    marginBottom: "10px",
  },
  editIconPro: {
    position: "absolute",
    top: "80px",
    left: "53.5%",
    [theme.breakpoints.down("xs")]: {
      left: "57.5%",
    },
    cursor: "pointer",
  },
  selectOpation: {
    //fontFamily: "Gilroy !important",
  },
  hrLine: {
    borderBottom: "2px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  lifestyleRoot: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
      overflow: "scroll",
    },
    marginLeft: "20px",
    marginBottom: "10px",
    display: "flex",
  },
  chipIcon: {
    width: "13px",
    height: "13px",
    color: "#FE4B6B",
  },
  autocompletChip: {
    background: "white",
    "& .MuiChip-root": {
      borderRadius: "5px",
      background: "#d5606045",
      height: "auto",
    },
    "& .MuiChip-outlined": {
      border: "none",
      color: "#FE4B6B",
      padding: "2px",
    },
    "& .MuiFilledInput-root": {
      background: "white",
    },
  },
  googleSelecte: {
    "& .css-yk16xz-control": {
      borderRadius: "10px",
      padding: "8px",
    },
    "& .css-1pahdxg-control": {
      borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      padding: "8px",
    },
    "& .css-1pahdxg-control:hover": {
      borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      padding: "8px",
    },
    "& .css-1uccc91-singleValue": {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
    },
    "& .css-1wa3eu0-placeholder": {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
    },
    "& div": {
      fontFamily: "'Poppins', sans-serif",
      backgroundColor: "white",
      zIndex: 11,
      marginLeft: "-1px",
    },
  },
  asterisk: {
    color: "red",
    "&$error": {
      color: "red",
    },
  },
  EditProfilLable: {
    padding: "0 0 0px 10px",
    fontSize: "18px",
    fontWeight: "600 !important",
    margin: "15px 0px",
  },
  backRoot: {
    maxWidth: "fit-content",
    cursor: "pointer",
    marginLeft: "10px",
    // marginBottom: "20px",
    // marginTop: "45px",
    alignItems: "center",
    display: "flex",
  },
  datePickerRoot: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: "19px",
      paddingBottom: "19px",
    },
  },
  googleAutoLabel: {
    color: "#f44336",
    marginLeft: "14px",
    marginRight: "14px",
    margin: 0,
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: " left",
    fontFamily: "Poppins",
    fontweight: "400",
    lineHeight: "1.66",
  },
}));

const PlannedActivities = [
  { name: "communiction Services", id: 1 },
  { name: "student Organization", id: 2 },
  { name: "real Estate", id: 3 },
  { name: "stock Trading", id: 4 },
  { name: "photoGraphy", id: 5 },
];

const Interests = [
  { name: "shoping", id: 1 },
  { name: "Coding", id: 2 },
  { name: "fishing", id: 3 },
  { name: "streetwear", id: 4 },
  { name: "meditation", id: 5 },
  { name: "video Games", id: 6 },
];

const ProfileEdit = (props) => {
  const { className, ...rest } = props;
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();
  const { appContext, setAppContext } = useContext(AppDataContext);
  const [chipData, setChipData] = React.useState([]);
  const [chipData2, setChipData2] = React.useState([]);
  const [userData, setUserData] = React.useState({});
  const [uploadImagePath, setUploadImagePath] = React.useState("");
  const [lstLifeStyleImage, setLstLifeStyleImage] = React.useState([]);
  const [lstPlannedActivities, setLstPlannedActivities] = React.useState([]);
  const [lstInterests, setLstInterests] = React.useState([]);
  const [lstZodiac, setLstZodiac] = React.useState([]);
  const [lst_p_University, setLst_P_University] = React.useState([]);
  const [lst_s_University, setLst_S_University] = React.useState([]);
  const [lstCountry, setLstCountry] = React.useState([]);
  const [loadingLifestyle, setLoadingLifestyle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingStart, setLoadingStart] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [sliderOpen, setSliderOpen] = useState(false);
  const [p_university, setP_University] = useState(undefined);
  const [s_university, setS_University] = useState(undefined);
  const [access, setAccess] = React.useState(true);
  const [dob, setDob] = React.useState();
  const [isOrgUser, setIsOrgUser] = React.useState(false);
  const [countrycode, setCountrycode] = React.useState();
  const [phonecode, setphonecode] = React.useState();

  const [currant_module, setCurrant_module] = React.useState("");
  console.log("countrycode", countrycode);

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  const [error, setError] = React.useState();
  const [isEditModel, setIsEditModel] = React.useState(false);

  const [phoneValue, setPhoneValue] = useState("");

  useEffect(() => {
    setChipData(PlannedActivities);
    setChipData2(Interests);
  }, []);

  const handleEditClick = () => {
    setPhoneValue(formState.values.phone || ""); // Set the phone number in state
    setIsEditModel(true); // Open the EditDialog
  };

  const dob_Change = (date) => {
    let changeDate = convertDate(date);
    if (moment().subtract(18, "years") >= moment(date)) {
      setDob(date);
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          date_of_birth: changeDate,
        },
      }));
    } else {
      setDob("");
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          date_of_birth: "",
        },
      }));
    }
  };
  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join("/");
  }

  const handleAutocompletChange = (event, value, name) => {
    if (name === "p_university") {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value.value,
          },
        }));
      }
    } else if (name === "s_university") {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value.value,
          },
        }));
      }
    } else if (name === "room_type") {
      if (value !== null) {
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value.value,
          },
          touched: {
            ...formState.touched,
            [name]: true,
          },
        }));
      }
    } else {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value,
          },
        }));
      }
    }
  };

  const handleGenderChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        gender: value,
      },
    }));
  };

  const handle_Prefrance_GenderChange = (value) => {
    let preference_gender = [...formState.values.preference_gender];
    let index = preference_gender.indexOf(value);
    if (index > -1) {
      preference_gender.splice(index, 1);
    } else {
      preference_gender.push(value);
    }
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        preference_gender: preference_gender,
      },
    }));
  };

  const handleFieldChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handlePasswordChange = (event, value) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        phone: value,
      },
    }));
  };

  useEffect(() => {
    let tmpSch = currant_module !== "Soulmates" ? schema : schemaSoul;
    const errors = validate(formState.values, tmpSch);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values, currant_module]);

  useEffect(() => {
    onLoad();
    getPlannedActivites();
    getInterests();
    getZodiac();
    // comppressImageProfile()
  }, []);

  useEffect(() => {
    getSelected_P_UniversityItem();
  }, [lst_p_University]);

  useEffect(() => {
    getSelected_S_UniversityItem();
  }, [lst_s_University]);

  // Get planned activites
  const getPlannedActivites = async () => {
    // Api Call For Fetch Planned activites List
    api
      .get(`planactvlist`)
      .then((result) => {
        if (result.success === "true") {
          setLstPlannedActivities(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  // Get Interests
  const getInterests = async () => {
    // Api Call For Fetch Interests List
    api
      .get(`interestlist`)
      .then((result) => {
        if (result.success === "true") {
          setLstInterests(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  // Get Zodiac
  const getZodiac = async () => {
    // Api Call For Fetch Zodiac List
    api
      .get(`zodiaclist`)
      .then((result) => {
        if (result.success === "true") {
          setLstZodiac(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  // Get P_University
  const get_P_University = async (search) => {
    // Api Call For Fetch University List
    api
      .get(`universitylist?s=` + search)
      .then((result) => {
        if (result.success === "true") {
          setLst_P_University(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  // Get S_University
  const get_S_University = async (search) => {
    // Api Call For Fetch University List
    api
      .get(`universitylist?s=` + search)
      .then((result) => {
        if (result.success === "true") {
          setLst_S_University(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const handleEdit = async (event) => {
    console.log("formState", formState);
    if (!formState.isValid) {
      console.log("not valid");
      return;
    }
    console.log("valid");
    setLoading(false);
    setDisabled(true);
    api
      .put("user/editprofile", {
        data: {
          ...formState.values,
          location: formState.values.location.label,
          countryCode: countrycode,
          p_universityId: formState.values.p_university?.id,
          s_universityId: formState.values.s_university?.id,
        },
      })
      .then((result) => {
        if (result.success === "true") {
          let userName = result.data.first_name + " " + result.data.last_name;
          console.log("formState", formState);
          setAppContext({
            ...appContext,
            userProfile: result.data.profile_picture,
            userName: userName,
          });
          AsyncLocalStorage.setItem("userName", userName);
          // AsyncLocalStorage.setItem(
          //   "puniversity_lat",
          //   formState.values.p_university.latitude
          // );
          // AsyncLocalStorage.setItem(
          //   "puniversity_long",
          //   formState.values.p_university.longitude
          // );

          router.history.push({
            pathname: "/my-profile",
            search: "",
            state: {
              sessionPropsMessage: {
                message: result.message,
                type: "success",
                open: true,
              },
            },
          });
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setLoading(false);
        setDisabled(false);
      })
      .catch((err) => {
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        setLoading(false);
        setDisabled(false);
      });
  };

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const onUploadimageChange = async (event, name) => {
    const file = event.target.files[0];
    // Base64
    var reader = new FileReader();
    reader.onloadend = function() {
      setUploadImagePath(reader.result);
    };
    reader.readAsDataURL(file);

    const data = new FormData();
    data.append("file", file);
    // Upload image
    api
      .post("user/uploadprofile", {
        data: data,
      })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem("userProfile", result.data);
          setAppContext({ ...appContext, userProfile: result.data });
        } else {
          console.log(result, "success === false");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  const onLifestyleDelete = async (url) => {
    // lifestyleUrl
    api
      .delete("user/removelifestylepic", {
        data: { lifestyleUrl: url },
      })
      .then((result) => {
        if (result.success === "true") {
          onLoad();
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  const onLifeStyleChange = async (event) => {
    if (event.target.files.length + lstLifeStyleImage.length > 5) {
      setSessionMessage({
        message: `You can select maximum ${5 - lstLifeStyleImage.length} image`,
        type: "error",
        open: true,
      });
      return;
    }
    const lengthUploadFile = event.target.files.length;
    // let lstTmp = lstLifeStyleImage?.length ? [...lstLifeStyleImage] : []
    const data = new FormData();
    for (let i = 0; i < lengthUploadFile; i++) {
      const file = event.target.files[i];
      data.append("files", file);
    }

    api
      .post("user/uploadlifestylepic", {
        data: data,
      })
      .then((result) => {
        if (result.success === "true") {
          console.log(lstLifeStyleImage, "lstLifeStyleImage");
          console.log(lstLifeStyleImage.concat(result.data), "result.data[0]");
          let lstTmp = lstLifeStyleImage.concat(result.data);
          setLstLifeStyleImage([]);
          setLstLifeStyleImage(lstTmp);
          // onLoad()
        } else {
          console.log(result, "success === false");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  const onLoad = async () => {
    setLoadingStart(true);
    let currantModule = await AsyncLocalStorage.getItem("currentModule");
    let isOrgUser1 = await AsyncLocalStorage.getItem("isOrgUser");
    setIsOrgUser(isOrgUser1);
    setCurrant_module(currantModule);
    // Api Call For Fetch The User Data
    await api
      .get(`user/viewprofile`)
      .then((result) => {
        if (result.success === "true") {
          setUserData(result.data);

          setphonecode(result.data.phoneContryCode);
          // set location
          geocodeByAddress(result.data.location)
            .then((results) => {
              // let location = result.data.city + " " + result.data.state + " " + result.data.country
              setFormState((formState) => ({
                ...formState,
                values: {
                  ...formState.values,
                  location: {
                    label: result.data.location,
                    value: results[0],
                  },
                },
              }));
            })
            .catch((error) => console.error(error, "new result error"));

          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              first_name: result.data.first_name,
              last_name: result.data.last_name,
              // latitude: result.data.latitude,
              // longitude: result.data.longitude,
              email: result.data.email,
              phone: result.data.phone,
              hometown: result.data.hometown,

              // university: result.data.university,
              bio: result.data.bio,
              linkdin_link: result.data.linkdin_link,
              fb_link: result.data.fb_link,
              gender: result.data.gender,
              preference_gender: result.data.preference_gender
                ? result.data.preference_gender
                : [],
              zodiac: result.data.zodiac,
              interests: result.data.interests,
              planned_activities: result.data.planned_activities,
              date_of_birth: result.data.date_of_birth,
            },
          }));
          setP_University(result.data.p_university);
          setS_University(result.data.s_university);
          change_p_OptionBaseOnValue(result.data.p_university);
          change_s_OptionBaseOnValue(result.data.s_university);
          setDob(result.data.date_of_birth);
          setUploadImagePath(result.data.profile_picture);
          if (result.data.lifestyle_photos)
            setLstLifeStyleImage(result.data.lifestyle_photos);
        } else {
          console.log(result, "error");
        }
        setLoadingStart(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setLoadingStart(false);
      });
  };

  const getSelected_P_UniversityItem = () => {
    if (formState.values.p_university) {
      const item = lst_p_University.find((opt) => {
        if (opt.value == formState.values.p_university) return opt;
      });
      if (item)
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            p_university: item,
          },
        }));
      // return item || {};
    } else {
      const item = lst_p_University.find((opt) => {
        if (opt.value == p_university) return opt;
      });
      if (item)
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            p_university: item,
          },
        }));
      // return item || {};
    }
  };

  const getSelected_S_UniversityItem = () => {
    if (formState.values.s_university) {
      const item = lst_s_University.find((opt) => {
        if (opt.value == formState.values.s_university) return opt;
      });
      if (item)
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            s_university: item,
          },
        }));
      // return item || {};
    } else {
      const item = lst_s_University.find((opt) => {
        if (opt.value == s_university) return opt;
      });
      if (item)
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            s_university: item,
          },
        }));
      // return item || {};
    }
  };

  const handelSliderOpen = () => {
    setSliderOpen(true);
  };
  const change_p_OptionBaseOnValue = (value) => {
    if (value) get_P_University(value);
  };
  const change_s_OptionBaseOnValue = (value) => {
    if (value) get_S_University(value);
  };
  const setLocation = (value) => {
    if (value) {
      geocodeByAddress(value.value.description)
        .then((results) => {
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              location: value,
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng(),
            },
          }));
        })
        .catch((error) => console.error("valuevaluevalue", error));
    } else {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          location: "",
          latitude: "",
          longitude: "",
        },
      }));
    }
  };
  const hasError = (field) => (formState.errors[field] ? true : false);

  const handleEditClose = () => {
    setIsEditModel(false);
  };

  const comppressImageProfile = (e) => {
    const file = e?.target?.files[0];
    if (!file) {
      return;
    }
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const formData = new FormData();
        formData.append("file", result, result.name);
        onUploadimageComprace(e, formData, file);
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const onUploadimageComprace = async (e, data, file) => {
    // Base64
    var reader = new FileReader();
    reader.onloadend = function() {
      setUploadImagePath(reader.result);
    };
    reader.readAsDataURL(file);

    // Upload image
    api
      .post("user/uploadprofile", {
        data: data,
      })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem("userProfile", result.data);
          setAppContext({ ...appContext, userProfile: result.data });
        } else {
          console.log(result, "success === false");
          setUploadImagePath("");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  const newUpload = async (event) => {
    const files = event.target.files;
    if (event.target.files.length + lstLifeStyleImage.length > 5) {
      setSessionMessage({
        message: `You can select maximum ${5 - lstLifeStyleImage.length} image`,
        type: "error",
        open: true,
      });
      return;
    }
    setLoadingLifestyle(true);
    const formData = new FormData();
    async function compress(files) {
      for (const file of files) {
        await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.6,
            success(result) {
              formData.append("files", result);
              resolve();
            },
            error(err) {
              reject();
            },
          });
        });
      }
    }

    compress(files)
      .then((result) => {
        console.log(formData, "formData");
        console.log(result, "Compress success");
      })
      .catch((err) => {
        console.log("Compress error");
      })
      .finally((result) => {
        api
          .post("user/uploadlifestylepic", {
            data: formData,
          })
          .then((result) => {
            if (result.success === "true") {
              console.log(lstLifeStyleImage, "lstLifeStyleImage");
              console.log(
                lstLifeStyleImage.concat(result.data),
                "result.data[0]"
              );
              let lstTmp = lstLifeStyleImage.concat(result.data);
              setLstLifeStyleImage([]);
              setLstLifeStyleImage(lstTmp);
              // onLoad()
            } else {
              console.log(result, "success === false");
            }
            setLoadingLifestyle(false);
          })
          .catch((err) => {
            console.log(err, "error catch");
            setLoadingLifestyle(false);
          });
        console.log(result, "Compress complete");
      });
  };

  console.log(userData.phoneContryCode, "userData");

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgEditProfile}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            {loadingStart ? (
              <Grid
                className={classes.container}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
                item
              >
                <CircularProgress size={50} />
              </Grid>
            ) : (
              <Grid className={classes.container} item>
                {/* <Grid item xl={12} lg={12} xs={12}>
                </Grid> */}
                <Grid className={classes.whiteCardProfile} container>
                  <Grid item xs={12} xl={12} lg={12}>
                    <Box
                      onClick={() => router.history.goBack()}
                      className={classes.backRoot}
                    >
                      <IconButton
                        aria-label="delete"
                        style={{
                          color: "black",
                          marginRight: "5px",
                          cursor: "pointer",
                        }}
                        size="small"
                      >
                        <ArrowBackIcon fontSize="inherit" />
                      </IconButton>
                      <h5 className={classes.EditProfilLable}>
                        {" "}
                        Edit Profile{" "}
                      </h5>
                    </Box>
                  </Grid>
                  <Grid item xs={12} xl={12} lg={12}>
                    <div className={classes.imageEditProfile}>
                      <div className={classes.profilePhotoEdit}>
                        {/* <img src="/images/matchUp/editProfile.png" alt="image" /> */}
                        <img
                          src={
                            uploadImagePath
                              ? uploadImagePath
                              : "/images/male_defualt.svg"
                          }
                          alt="image"
                          style={{
                            width: "115px",
                            height: "115px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        />

                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          onChange={(e) => {
                            comppressImageProfile(e);
                          }}
                          // onChange={(e) => {
                          //   onUploadimageChange(e)
                          // }}
                        />
                        <label htmlFor="icon-button-file">
                          <img
                            className={classes.editIconPro}
                            src="/images/matchUp/editIcon.svg"
                            alt="image"
                          />
                        </label>
                      </div>
                      <div className={classes.hrLine} />
                    </div>
                  </Grid>
                  <Grid item lg={12} xl={12} xs={12}>
                    <h5 className={classes.changeHeading}>
                      Tap to change Pics
                    </h5>
                    <div className={classes.lifestyleRoot}>
                      {lstLifeStyleImage &&
                        lstLifeStyleImage.map((value) => {
                          return (
                            <Box
                              className={classes.changeImageTap}
                              onClick={() => {
                                handelSliderOpen();
                              }}
                            >
                              <Box className={classes.iconTapBorder}>
                                <img
                                  src={value}
                                  alt="image"
                                  style={{
                                    width: "56px",
                                    height: "56px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                />
                              </Box>
                              <CloseIcon
                                style={{
                                  cursor: "pointer",
                                  fontSize: "12px",
                                  position: "relative",
                                  right: "10px",
                                }}
                                onClick={() => {
                                  onLifestyleDelete(value);
                                }}
                              />
                            </Box>
                          );
                        })}
                      {lstLifeStyleImage.length <= 4 && (
                        <Box className={classes.changeImageTap}>
                          <Box className={classes.iconTapBorder}>
                            <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="life-style-1"
                              type="file"
                              multiple
                              disabled={loadingLifestyle}
                              // onChange={(e) => { onLifeStyleChange(e) }}
                              // onChange={(e) => { comppressImageLifstyle(e) }}
                              onChange={(e) => {
                                newUpload(e);
                              }}
                            />
                            <label htmlFor="life-style-1">
                              <Box className={classes.iconTapBorder}>
                                {loadingLifestyle ? (
                                  <CircularProgress size={15} />
                                ) : (
                                  <AddIcon style={{ color: "#FE4B6B" }} />
                                )}
                              </Box>
                            </label>
                          </Box>
                        </Box>
                      )}
                    </div>
                    <div className={classes.hrLine} />
                  </Grid>
                  <Grid item lg={12} xl={12} xs={12}>
                    <h5 className={classes.changeHeading}>
                      Update Basic Details
                    </h5>
                    {/* <div className={classes.hrLine} /> */}
                  </Grid>
                  <Grid item lg={12} xl={12} xs={12}>
                    <Grid container style={{ padding: "20px" }} spacing={3}>
                      <Grid
                        item
                        lg={6}
                        xl={6}
                        xs={12}
                        className={classes.textRoot}
                      >
                        <TextField
                          id="outlined-basic"
                          size="small"
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.asterisk,
                            },
                            shrink: true,
                          }}
                          required
                          error={hasError("first_name")}
                          helperText={
                            hasError("first_name")
                              ? formState.errors.first_name[0]
                              : null
                          }
                          className={classes.inputProfileEdit}
                          label="First name"
                          name="  "
                          value={formState.values.first_name || ""}
                          onChange={handleFieldChange}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        xl={6}
                        xs={12}
                        className={classes.textRoot}
                      >
                        <TextField
                          id="outlined-basic"
                          size="small"
                          name="last_name"
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.asterisk,
                            },
                            shrink: true,
                          }}
                          required
                          error={hasError("last_name")}
                          helperText={
                            hasError("last_name")
                              ? formState.errors.last_name[0]
                              : null
                          }
                          className={classes.inputProfileEdit}
                          label="Last name"
                          value={formState.values.last_name || ""}
                          onChange={handleFieldChange}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        xl={6}
                        xs={12}
                        className={classes.textRoot}
                      >
                        <TextField
                          id="outlined-basic"
                          size="small"
                          className={classes.inputProfileEdit}
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.asterisk,
                            },
                            shrink: true,
                          }}
                          required
                          error={hasError("email")}
                          helperText={
                            hasError("email") ? formState.errors.email[0] : null
                          }
                          label="Email"
                          name="email"
                          value={formState.values.email || ""}
                          onChange={handleFieldChange}
                          variant="outlined"
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        xl={6}
                        xs={12}
                        className={classes.textRoot}
                      >
                        {/* <TextField
                          id="outlined-basic"
                          size="small"
                          className={classes.inputProfileEdit}
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.asterisk,
                            },
                            shrink: true,
                          }}
                          required
                          error={hasError("phone")}
                          helperText={
                            hasError("phone") ? formState.errors.phone[0] : null
                          }
                          label="Phone"
                          name="phone"
                          value={formState.values.phone || ""}
                          disabled
                          // onChange={handleFieldChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  variant="contained"
                                  color={"primary"}
                                  size="small"
                                  style={{
                                    textTransform: "capitalize",
                                    background:
                                      "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
                                  }}
                                  onClick={(event) => {
                                    setIsEditModel(true);
                                  }}
                                >
                                  Edit
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        /> */}

                        <TextField
                          id="outlined-basic"
                          size="small"
                          className={classes.inputProfileEdit}
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.asterisk,
                            },
                            shrink: true,
                          }}
                          required
                          error={hasError("phone")}
                          helperText={
                            hasError("phone") ? formState.errors.phone[0] : null
                          }
                          label="Phone"
                          name="phone"
                          value={formState.values.phone || ""}
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  style={{
                                    textTransform: "capitalize",
                                    background:
                                      "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
                                  }}
                                  onClick={handleEditClick}
                                >
                                  Edit
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        xl={6}
                        xs={12}
                        className={classes.textRoot}
                      >
                        {/* <TextField
                        id="outlined-basic"
                        size="small"
                        className={classes.inputProfileEdit}
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.asterisk
                          },
                          shrink: true,
                        }}
                        required
                        type="date"
                        // maxDate={access ? new Date("01/01/2011") : undefinedy}
                        // max={new Date("01/01/2011")}
                        // max = "2014-01-01"
                        error={hasError('date_of_birth')}
                        helperText={hasError('date_of_birth') ? formState.errors.date_of_birth[0] : null}
                        label="Date of birth"
                        name="date_of_birth"
                        value={formState.values.date_of_birth || ""}
                        onChange={handleFieldChange}
                        variant="outlined"
                        fullWidth
                      /> */}
                        <Box mt={-2} className={classes.datePickerRoot}>
                          <KeyboardDatePicker
                            placeholder="DD/MM/YYYY"
                            // maxDate={access ? new Date("01/01/2011") : undefined}
                            maxDate={moment().subtract(18, "years")}
                            format="DD/MM/YYYY"
                            margin="normal"
                            InputLabelProps={{
                              classes: {
                                asterisk: classes.asterisk,
                              },
                              shrink: true,
                            }}
                            required
                            name="dob"
                            label="DOB"
                            value={dob}
                            defaultValue={dob}
                            onChange={dob_Change}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            inputVariant="outlined"
                            fullWidth
                            autoOk
                            className={classes.datePicker}
                            size="small"
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        xl={6}
                        xs={12}
                        className={classes.textRoot}
                      >
                        <Autocomplete
                          options={lstZodiac}
                          autoHighlight
                          getOptionLabel={(lstZodiac) => lstZodiac}
                          fullWidth
                          value={formState.values.zodiac || ""}
                          name="zodiac"
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {props}
                            </Box>
                          )}
                          noOptionsText={"Type for search..."}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Star Sign"
                              label="Star Sign"
                              autoFocus={false}
                              InputLabelProps={{
                                classes: {
                                  asterisk: classes.asterisk,
                                },
                                shrink: true,
                              }}
                              required
                              error={hasError("zodiac")}
                              helperText={
                                hasError("zodiac")
                                  ? formState.errors.zodiac[0]
                                  : null
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                              variant="outlined"
                              name="zodiac"
                              fullWidth
                              className={classes.inputProfileEdit}
                            />
                          )}
                          onChange={(e, value) => {
                            handleAutocompletChange(e, value, "zodiac");
                          }}
                        />
                      </Grid>
                      {isOrgUser == "false" &&
                        currant_module !== "Soulmates" &&
                        currant_module !== "Teammates" && (
                          <Grid
                            item
                            lg={6}
                            xl={6}
                            xs={12}
                            className={classes.textRoot}
                          >
                            <Autocomplete
                              options={lst_p_University}
                              onInputChange={(e, value) => {
                                change_p_OptionBaseOnValue(value);
                              }}
                              noOptionsText={"Type for search..."}
                              // value={getSelectedUniversityItem()}
                              value={formState.values.p_university || ""}
                              autoHighlight
                              getOptionLabel={(lst_p_University) =>
                                lst_p_University.value
                              }
                              fullWidth
                              name="p_university"
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  {props.value}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // placeholder="Select Primary University"
                                  label="Select Primary University"
                                  autoFocus={false}
                                  InputLabelProps={{
                                    classes: {
                                      asterisk: classes.asterisk,
                                    },
                                    shrink: true,
                                  }}
                                  required
                                  error={hasError("p_university")}
                                  helperText={
                                    hasError("p_university")
                                      ? formState.errors.p_university[0]
                                      : null
                                  }
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                  variant="outlined"
                                  name="p_university"
                                  fullWidth
                                  className={classes.inputProfileEdit}
                                />
                              )}
                              onChange={(e, value) => {
                                handleAutocompletChange(
                                  e,
                                  value,
                                  "p_university"
                                );
                              }}
                            />
                          </Grid>
                        )}

                      {isOrgUser == "false" &&
                        currant_module !== "Soulmates" &&
                        currant_module !== "Teammates" && (
                          <Grid
                            item
                            lg={6}
                            xl={6}
                            xs={12}
                            className={classes.textRoot}
                          >
                            <Autocomplete
                              options={lst_s_University}
                              onInputChange={(e, value) => {
                                change_s_OptionBaseOnValue(value);
                              }}
                              noOptionsText={"Type for search..."}
                              // value={getSelectedUniversityItem()}
                              value={formState.values.s_university || ""}
                              autoHighlight
                              getOptionLabel={(lst_s_University) =>
                                lst_s_University.value
                              }
                              fullWidth
                              name="s_university"
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  {props.value}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // placeholder="Select Secondary University"
                                  label="Select Secondary University"
                                  autoFocus={false}
                                  required
                                  error={hasError("s_university")}
                                  helperText={
                                    hasError("s_university")
                                      ? formState.errors.s_university[0]
                                      : null
                                  }
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                  variant="outlined"
                                  name="s_university"
                                  fullWidth
                                  className={classes.inputProfileEdit}
                                />
                              )}
                              onChange={(e, value) => {
                                handleAutocompletChange(
                                  e,
                                  value,
                                  "s_university"
                                );
                              }}
                            />
                          </Grid>
                        )}

                      <Grid
                        item
                        lg={12}
                        xl={12}
                        xs={12}
                        className={classes.googleSelecte}
                        style={{ position: "relative" }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            fontSize: "12px",
                            position: "absolute",
                            left: "23px",
                            top: "4px",
                            zIndex: "12",
                            padding: "0px 5px",
                            color: "rgba(0, 0, 0, 0.54)",
                          }}
                        >
                          Location<span style={{ color: "red" }}>* </span>
                        </div>

                        {console.log(
                          "process.env.REACT_APP_GOOGLE_API_KEY",
                          process.env.REACT_APP_GOOGLE_API_KEY
                        )}
                        <GooglePlacesAutocomplete
                          autocompletionRequest={{
                            types: ["(cities)"],
                          }}
                          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                          label="City / state"
                          selectProps={{
                            placeholder: (
                              <>
                                <span>Location</span>
                                <span style={{ color: "red" }}>*</span>{" "}
                              </>
                            ),
                            isClearable: true,
                            value: formState.values.location || "",
                            onChange: (val) => {
                              setLocation(val);
                              console.log("close", val);
                            },
                          }}
                        />
                        {hasError("location") && (
                          <label className={classes.googleAutoLabel}>
                            {formState.errors.location[0]}
                          </label>
                        )}
                      </Grid>

                      <Grid
                        item
                        lg={6}
                        xl={6}
                        xs={12}
                        className={classes.textRoot}
                      >
                        <TextField
                          placeholder="Enter your Linkedin Link"
                          id="outlined-basic"
                          size="small"
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.asterisk,
                            },
                            shrink: true,
                          }}
                          // required
                          error={hasError("linkdin_link")}
                          helperText={
                            hasError("linkdin_link")
                              ? formState.errors.linkdin_link[0]
                              : null
                          }
                          className={classes.inputProfileEdit}
                          label="Your Profile Linkedin Link"
                          name="linkdin_link"
                          value={formState.values.linkdin_link || ""}
                          onChange={handleFieldChange}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        xl={6}
                        xs={12}
                        className={classes.textRoot}
                      >
                        <TextField
                          placeholder="Enter your Facebook Link"
                          id="outlined-basic"
                          size="small"
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.asterisk,
                            },
                            shrink: true,
                          }}
                          // required
                          error={hasError("fb_link")}
                          helperText={
                            hasError("fb_link")
                              ? formState.errors.fb_link[0]
                              : null
                          }
                          className={classes.inputProfileEdit}
                          label="Your Profile Facebook Link"
                          name="fb_link"
                          value={formState.values.fb_link || ""}
                          onChange={handleFieldChange}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      {/* <Grid item lg={6} xl={6} xs={12} className={classes.textRoot}>
                      <Autocomplete
                        options={lstRoomType}
                        autoHighlight
                        value={getSelectedRoomTypeItem()}
                        getOptionLabel={(lstRoomType) => lstRoomType.value}
                        name="room_type"
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {props.value}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Room Type"
                            className={classes.inputProfileEdit}
                            InputLabelProps={{
                              classes: {
                                asterisk: classes.asterisk
                              }
                            }}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            variant="outlined"
                            name="room_type"
                          />
                        )}
                        onChange={(e, value) => {
                          handleAutocompletChange(e, value, "room_type")
                        }}
                      />
                    </Grid> */}
                      <Grid
                        item
                        lg={12}
                        xl={12}
                        xs={12}
                        className={classes.textRoot}
                      >
                        <TextField
                          id="outlined-basic"
                          size="small"
                          className={classes.shareAboutInput}
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.asterisk,
                            },
                            shrink: true,
                          }}
                          required
                          error={hasError("bio")}
                          helperText={
                            hasError("bio") ? formState.errors.bio[0] : null
                          }
                          label="About you"
                          multiline={true}
                          rows={3}
                          name="bio"
                          onChange={handleFieldChange}
                          value={formState.values.bio || ""}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={12} xl={12} xs={12}>
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          options={lstPlannedActivities.map((option) => option)}
                          noOptionsText={"Type for search..."}
                          name="planned_activities"
                          value={
                            lstPlannedActivities.length &&
                            formState.values.planned_activities
                              ? formState.values.planned_activities
                              : []
                          }
                          classes={{
                            root: classes.chipInputEdit,
                            chip: classes.chip,
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                                deleteIcon={
                                  <CloseIcon className={classes.chipIcon} />
                                }
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Liked Activities"
                              name="planned_activities"
                              className={classes.inputProfileEdit}
                            />
                          )}
                          className={classes.autocompletChip}
                          onChange={(e, value) => {
                            handleAutocompletChange(
                              e,
                              value,
                              "planned_activities"
                            );
                          }}
                          disableCloseOnSelect
                        />
                      </Grid>
                      <Grid item lg={12} xl={12} xs={12}>
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          options={lstInterests.map((option) => option)}
                          // freeSolo
                          noOptionsText={"Type for search..."}
                          value={
                            lstInterests.length && formState.values.interests
                              ? formState.values.interests
                              : []
                          }
                          name="interests"
                          classes={{
                            root: classes.chipInputEdit,
                            chip: classes.chip,
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                                deleteIcon={
                                  <CloseIcon className={classes.chipIcon} />
                                }
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Interests"
                              name="interests"
                            />
                          )}
                          className={classes.autocompletChip}
                          onChange={(e, value) => {
                            handleAutocompletChange(e, value, "interests");
                          }}
                          disableCloseOnSelect
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.genderRoot}>
                        <div className={classes.genderHeading}>
                          Gender<span style={{ color: "red" }}>* </span>{" "}
                          <span>:</span>
                        </div>
                        <Box className={classes.genderOptionRoot}>
                          <RadioGroup
                            label="gender"
                            name="gender"
                            value={formState.values.gender || ""}
                            onChange={handleFieldChange}
                            row
                          >
                            <FormControlLabel
                              value="male"
                              control={<Radio color="primary" />}
                              label="Male"
                            />
                            <FormControlLabel
                              value="female"
                              control={<Radio color="primary" />}
                              label="Female"
                            />
                            <FormControlLabel
                              value="intersex"
                              control={<Radio color="primary" />}
                              label="InterSex"
                            />
                            <FormControlLabel
                              value="trans"
                              control={<Radio color="primary" />}
                              label="Trans"
                            />
                            <FormControlLabel
                              value="non_conforming"
                              control={<Radio color="primary" />}
                              label="Other"
                            />
                          </RadioGroup>
                        </Box>
                      </Grid>
                      <Grid item lg={12} xl={12} xs={12}>
                        <div className={classes.genderSelectBox}>
                          <div className={classes.genderPrefranceHeading}>
                            Prefer Gender <span>:</span>
                          </div>
                          <div className={classes.checkboxDetail}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="Male"
                                  color="primary"
                                  onChange={() => {
                                    handle_Prefrance_GenderChange("male");
                                  }}
                                />
                              }
                              checked={
                                formState.values.preference_gender?.indexOf(
                                  "male"
                                ) !== -1
                                  ? true
                                  : false
                              }
                              label="Male"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="Female"
                                  color="primary"
                                  onChange={() => {
                                    handle_Prefrance_GenderChange("female");
                                  }}
                                />
                              }
                              checked={
                                formState.values.preference_gender?.indexOf(
                                  "female"
                                ) !== -1
                                  ? true
                                  : false
                              }
                              label="Female"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="Intersex"
                                  color="primary"
                                  onChange={() => {
                                    handle_Prefrance_GenderChange("intersex");
                                  }}
                                />
                              }
                              label="Intersex"
                              checked={
                                formState.values.preference_gender?.indexOf(
                                  "intersex"
                                ) !== -1
                                  ? true
                                  : false
                              }
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="Trans"
                                  color="primary"
                                  onChange={() => {
                                    handle_Prefrance_GenderChange("trans");
                                  }}
                                />
                              }
                              label="Trans"
                              checked={
                                formState.values.preference_gender?.indexOf(
                                  "trans"
                                ) !== -1
                                  ? true
                                  : false
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="No Preference"
                                  color="primary"
                                  onChange={() => {
                                    handle_Prefrance_GenderChange(
                                      "non_conforming"
                                    );
                                  }}
                                />
                              }
                              label="Other"
                              checked={
                                formState.values.preference_gender?.indexOf(
                                  "non_conforming"
                                ) !== -1
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                        {/* {hasError('preference_gender') && <label className={classes.googleAutoLabel}>{formState.errors.preference_gender[0]}</label>} */}
                      </Grid>
                      <Grid lg={12} xl={12} xs={12}>
                        <div className={classes.buttonGroupConfirm}>
                          <Button
                            variant="outlined"
                            className={classes.confirmButton}
                            color="primary"
                            onClick={() => {
                              router.history.push("/my-profile");
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className={classes.confirmButton}
                            variant="contained"
                            color="primary"
                            // disabled={formState.isValid ? disabled : true}
                            style={{
                              background:
                                "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
                            }}
                            onClick={() => {
                              handleEdit();
                            }}
                          >
                            Save
                            {loading && (
                              <>
                                &nbsp;
                                <CircularProgress
                                  size={20}
                                  style={{ color: "white" }}
                                />
                              </>
                            )}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
      </div>

      <EditDialog
        open={isEditModel}
        onClose={handleEditClose}
        handlePasswordChange={handlePasswordChange}
        phoneno={phoneValue} // Pass the phone value to the dialog
        setCountrycode={setCountrycode} // Pass the phone value to the dialog
        phonecode={phonecode} // Pass the phone value to the dialog
      />
    </>
  );
};

export default ProfileEdit;
