import React from 'react';
import { CircularProgress, TableCell, TableRow, Typography } from "@material-ui/core"

const NoRecordFound = (props) => {
  return (
    <TableRow>
      <TableCell 
      // colSpan={props.noOfColumn} 
      colSpan={props.noOfColumn ? props.noOfColumn : 6}
      style={{ textAlign: "center" }}>
        <Typography
          component="subtitle2"
          variant="subtitle2"
        >
          {props.loading ?
            <CircularProgress size={25} style={{ color: "white" }}/>
            :
            "No record found"
          }
        </Typography>
      </TableCell>
    </TableRow>
  )
}
export default NoRecordFound 