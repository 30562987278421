import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  InputAdornment,
  Popover,
  TextField,
  Typography,
  Snackbar,
} from "@material-ui/core";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import firebase from "firebase/app";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import useRouter from "utils/useRouter";
import { useRef } from "react";
import { indexOf } from "lodash-es";
import DoneIcon from "@material-ui/icons/Done";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SentimentSatisfiedOutlinedIcon from "@material-ui/icons/SentimentSatisfiedOutlined";
import EmojiPicker from "emoji-picker-react";
import Picker from "emoji-picker-react";
import S3 from "react-aws-s3";
import Fancybox from "components/fancybox";
import ufs from "url-file-size";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ClearChatDialog from "components/ClearChatModel/ClearChatModel";
import MuiAlert from "@material-ui/lab/Alert";
import Api from "../../Helper/ApiHandler";
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "5px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "10px",
    },
  },
  bgProfilePage: {
    // minHeight: "calc(100vh - 125px)",
    minHeight: "calc(100vh - 270px)",
    background: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      minHeight: "calc(100vh - 206px)",
    },
    borderRadius: "10px",
  },
  carosalImageBox: {
    height: "200px",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundColor: "gray",
    boxShadow: "rgb(0 0 0 / 70%) 0px -50px 36px -28px inset",
    backgroundRepeat: "no-repeat",
    width: "35%",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
    margin: "10px",
    position: "relative",
  },
  myMessageRoot: {
    display: "flex",
    justifyContent: "flex-end",
  },
  myMessage: {
    width: "fit-content",
    fontSize: "12px",
    backgroundColor: "#F4F4F4",
    borderRadius: "10px 0px",
    padding: "10px",
    margin: "3px 10px",
    color: "#3F444F",
    maxWidth: "50%",
    display: "flex",
    wordBreak: "break-all",
  },
  myMessageTime: {
    fontSize: "10px",
    fontWeight: "400",
    minWidth: "45px",
    marginLeft: "10px",
    alignSelf: "flex-end",
    color: "#A2A2AB",
    position: "relative",
  },
  yourMessageTime: {
    fontSize: "10px",
    fontWeight: "400",
    minWidth: "35px",
    marginLeft: "10px",
    alignSelf: "flex-end",
    color: "#A2A2AB",
    position: "relative",
  },
  myDoubleTick: {
    fontSize: "16px",
    bottom: "0px",
    position: "absolute",
    marginLeft: "5px",
  },

  yourMessageRoot: {
    display: "flex",
    justifyContent: "flex-start",
  },
  yourMessage: {
    display: "flex",
    width: "fit-content",
    fontSize: "12px",
    backgroundColor: "#F4F4F4",
    borderRadius: "10px 0px",
    padding: "10px",
    margin: "3px",
    color: "#3F444F",
    maxWidth: "50%",
    wordBreak: "break-all",
    flexDirection: "column",
  },
  yourMessageTime: {
    fontSize: "10px",
    fontWeight: "400",
    minWidth: "25px",
    marginLeft: "10px",
    alignSelf: "flex-end",
    color: "#A2A2AB",
  },
  yourName: {
    color: "#E84A62",
    fontSize: "12px",
    textTransform: "capitalize",
    marginBottom: "3px",
    fontWeight: "500",
  },
  inboxLastMsg: {
    fontSize: "11px",
    fontWeight: "400",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inboxTyping: {
    fontSize: "11px",
    fontWeight: "400",
    whiteSpace: "nowrap",
    // width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "green",
  },
  groupEditMsgRoot: {
    display: "flex",
    justifyContent: "center",
  },
  groupEditMsg: {
    width: "fit-content",
    fontSize: "12px",
    backgroundColor: "#FFF7F8",
    borderRadius: "5px",
    padding: "3px",
    margin: "3px",
    color: "#F24451",
  },

  mainInputMessage: {
    width: "98%",
    marginLeft: "10px",
    marginRight: "10px",
  },
  iconSendBg: {
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    borderRadius: "100%",
    width: "35px",
    height: "35px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px 10px",
    color: "white",
    cursor: "pointer",
  },
  iconAtechBg: {
    background: "linear-gradient(349.17deg, #787878 -34.22%, #d9d9d9 98.87%)",
    borderRadius: "100%",
    width: "35px",
    height: "35px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
    color: "white",
    cursor: "pointer",
    paddingTop: "5px",
  },
  iconSendBgSmallDevice: {
    background: "#FF695B",
    borderRadius: "100%",
    width: "34px",
    height: "34px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    marginTop: "2px",
  },
  inputBoxWrite: {
    background: "white",
    width: "100%",
    height: "60px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "50px",
    },
    display: "flex",
    alignItems: "center",
    borderRadius: "0px 0px 10px 10px",
    borderTop: "1px solid #d9d9d9",
  },
  inputBoxWrite: {
    background: "white",
    width: "100%",
    height: "60px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "50px",
    },
    display: "flex",
    alignItems: "center",
    borderRadius: "0px 0px 10px 10px",
    borderTop: "1px solid #d9d9d9",
  },
  menuRoot: {
    // marginRight: "10px",
  },
  menu: {
    cursor: "pointer",
    "& .MuiPaper-rounded": {
      borderRadius: "10px",
    },
  },
  messgeList: {
    overflow: "auto",
    height: "calc(100vh - 331px)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 266px)",
    },
  },
  typographyMenu: {
    fontSize: "14px",
    padding: "5px",
  },
  inboxRoot: {
    display: "flex",
    padding: "5px",
    cursor: "pointer",
  },
  inboxImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  inboxNameRoot: {
    marginLeft: "10px",
  },
  inboxName: {
    fontSize: "14px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  emojiRoot: {
    "& .EmojiPickerReact": {
      position: "absolute !important",
      bottom: "50px",
      left: "0px",
    },
  },
  myFile: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
    padding: "10px",
    margin: "10px",
    backgroundColor: "#d3d3d378",
    borderRadius: "10px 0px 10px 10px",
  },
  myFileName: {
    fontSize: "16px",
    fontWeight: "700",
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  myFileSize: {
    fontSize: "11px",
    color: "gray",
  },
  myFileType: {
    display: "flex",
    color: "gray",
    fontSize: "11px",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingTop: "10px",
  },
  myFileDownload: {
    color: "black",
    cursor: "pointer",
    display: "flex",
    fontSize: "14px",
    borderTop: "1px solid white",
    paddingTop: "8px",
    justifyContent: "center",
    marginTop: "10px",
    fontWeight: "600",
    textDecoration: "none",
  },
  carosalVideoBox: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "10px",
  },
  myPlayVideoRoot: {
    position: "absolute",
    top: "40%",
    left: "40%",
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    backgroundColor: "#d1c9c9a6",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  myPlayVideo: {
    height: "50px",
    width: "50px",
  },
  backIconRoot: {
    display: "flex",
    alignItems: "center",
    paddinBottom: "10px",
  },
  backIcon: {
    fontSize: "16px",
    cursor: "pointer",
  },
  exitnote: {
    color: "#E84A62",
    fontSize: "12px",
    width: "350px",
    margin: "auto",
    textAlign: "center",
    height: "60px",
    paddingTop: "10px",
  },
}));

const Message = (props) => {
  const classes = useStyles();
  const listInnerRef = useRef();
  const {
    isFromOtherPage,
    lstMessage,
    setLstMessage,
    chatType,
    setExitAt,
    exitAt,
    clearAt,
    setClearAt,
    nameMobileChat,
    setNameMobileChat,
    group_members,
    receiverId,
    userName,
    senderId,
  } = props;
  const [message, setMessage] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  let query = useLocation();

  // Base path of firebase
  const database = firebase.firestore();
  let db = database.collection("teammate").doc("chatRooms");
  let dbNew = database.collection("teammateInbox");

  useEffect(() => {
    // if (isFromOtherPage) {
    setNameMobileChat(true);
    setClearAt("");
    // }
  }, []);
  useEffect(() => {
    getMessage();
  }, [receiverId, clearAt, exitAt, chatType, nameMobileChat]);

  useEffect(() => {
    if (message !== null) {
      onMessageChange();
    }
  }, [message]);

  let getExitAt = async () => {
    if (!exitAt) {
      const exitAtRef = db
        .collection("messageBox")
        .doc(senderId)
        .collection("inbox")
        .doc(receiverId);
      exitAtRef.onSnapshot((doc) => {
        if (doc.exists) {
          var prev_receiverId = Cookies.get("prev_receiverId");
          var curr_receiverId = Cookies.get("curr_receiverId");
          if (doc.exists) {
            console.log("doc--==", doc.data().exitAt);
            if (prev_receiverId) {
              if (prev_receiverId && curr_receiverId == receiverId) {
                if (doc.data().exitAt) {
                  if (exitAt) {
                    if (exitAt?.seconds != doc.data().exitAt?.seconds) {
                      setExitAt(doc.data().exitAt);
                    }
                  } else {
                    if (doc.data().exitAt) {
                      setExitAt(doc.data().exitAt);
                    }
                  }
                } else {
                  setExitAt(undefined);
                }
              }
            } else {
              if (doc.data().exitAt) {
                if (exitAt) {
                  if (exitAt?.seconds != doc.data().exitAt?.seconds) {
                    setExitAt(doc.data().exitAt);
                  }
                } else {
                  if (doc.data().exitAt) {
                    setExitAt(doc.data().exitAt);
                  }
                }
              } else {
                setExitAt(undefined);
              }
            }

            if (doc.data().exitAt) {
              if (prev_receiverId) {
                if (prev_receiverId && curr_receiverId == receiverId) {
                  setExitAt(doc.data().exitAt);
                }
              } else {
                setExitAt(doc.data().exitAt);
              }
            }
          }
        } else {
          console.log("No such document!");
        }
      });
      // exitAtRef.get().then((doc) => {
      //   var prev_receiverId = Cookies.get('prev_receiverId');
      //   var curr_receiverId = Cookies.get('curr_receiverId');
      //   if (doc.exists) {
      //     console.log("doc--==", doc.data().exitAt)
      //     if (prev_receiverId) {
      //       if (prev_receiverId && curr_receiverId == receiverId) {
      //         if (doc.data().exitAt) {
      //           if (exitAt) {
      //             if (exitAt?.seconds != doc.data().exitAt?.seconds) {
      //               setExitAt(doc.data().exitAt)
      //             }
      //           } else {
      //             if (doc.data().exitAt) {
      //               setExitAt(doc.data().exitAt)
      //             }
      //           }
      //         } else {
      //           setExitAt(undefined)
      //         }
      //       }
      //     } else {
      //       if (doc.data().exitAt) {
      //         if (exitAt) {
      //           if (exitAt?.seconds != doc.data().exitAt?.seconds) {
      //             setExitAt(doc.data().exitAt)
      //           }
      //         } else {
      //           if (doc.data().exitAt) {
      //             setExitAt(doc.data().exitAt)
      //           }
      //         }
      //       } else {
      //         setExitAt(undefined)
      //       }
      //     }

      //     if (doc.data().exitAt) {
      //       if (prev_receiverId) {
      //         if (prev_receiverId && curr_receiverId == receiverId) {
      //           setExitAt(doc.data().exitAt)
      //         }
      //       } else {
      //         setExitAt(doc.data().exitAt)
      //       }
      //     }
      //   }
      // }).catch((error) => {
      //   console.log("Error getting document:", error);
      // });
    }
  };

  let onMessageChange = async () => {
    var prev_receiverId = Cookies.get("prev_receiverId");
    var curr_receiverId = Cookies.get("curr_receiverId");

    if (senderId && receiverId) {
      if (chatType == "group") {
        let groupRef = db.collection("group").doc(receiverId);
        if (message) {
          // Get group detail
          groupRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                let NewType = doc.data().typing ? doc.data().typing : [];
                let index = NewType.indexOf(userName);
                if (!NewType.includes(userName)) {
                  NewType.push(userName);
                }
                if (prev_receiverId) {
                  if (prev_receiverId && curr_receiverId == receiverId) {
                    groupRef.update({
                      typing: NewType,
                    });
                  }
                } else {
                  groupRef.update({
                    typing: NewType,
                  });
                }
              }
            })
            .catch((error) => {
              console.log("Error getting document:", error);
            });
        } else {
          // Get group detail
          groupRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                let NewType = doc.data().typing ? doc.data().typing : [];
                let index = NewType.indexOf(userName);
                if (NewType.includes(userName)) {
                  NewType.splice(index, 1);
                }
                groupRef.update({
                  typing: NewType,
                });
              }
            })
            .catch((error) => {
              console.log("Error getting document:", error);
            });
        }
      } else {
        let userRef = db
          .collection("messageBox")
          .doc(receiverId)
          .collection("inbox")
          .doc(senderId);
        userRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              if (message) {
                userRef.update({
                  typing: true,
                });
              } else {
                userRef.update({
                  typing: false,
                });
              }
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      }
    }
  };

  let getClearAt = async () => {
    let senderId = await AsyncLocalStorage.getItem("senderId");
    if (!clearAt) {
      const clearAtRef = db
        .collection("messageBox")
        .doc(senderId)
        .collection("inbox")
        .doc(receiverId);

      clearAtRef
        .get()
        .then((doc) => {
          var prev_receiverId = Cookies.get("prev_receiverId");
          var curr_receiverId = Cookies.get("curr_receiverId");
          if (doc.exists) {
            console.log("doc--==", doc.data().clearAt);
            if (prev_receiverId) {
              if (prev_receiverId && curr_receiverId == receiverId) {
                setClearAt(doc.data().clearAt);
              }
            } else {
              setClearAt(doc.data().clearAt);
            }
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  };

  let getMessage = async () => {
    if (nameMobileChat) {
      let senderId = await AsyncLocalStorage.getItem("senderId");
      if (senderId && receiverId) {
        // let chat_type = await AsyncLocalStorage.getItem("chat_type");
        // setChatType(chat_type)
        if (!chatType) {
          return;
        }
        if (!clearAt) {
          getClearAt();
          return;
        }
        // if (exitAt == null) {
        getExitAt();
        //   return
        // }
        if (exitAt == undefined || exitAt) {
          if (chatType == "group") {
            // Get message data group
            const messageRef = exitAt
              ? db
                  .collection("messageBox")
                  .doc(receiverId)
                  .collection("messages")
                  .where("createdAt", ">", clearAt)
                  .where("createdAt", "<", exitAt)
              : db
                  .collection("messageBox")
                  .doc(receiverId)
                  .collection("messages")
                  .where("createdAt", ">", clearAt);

            messageRef
              .orderBy("createdAt", "asc")
              .onSnapshot((querySnapshot) => {
                var prev_receiverId = Cookies.get("prev_receiverId");
                var curr_receiverId = Cookies.get("curr_receiverId");
                let temp = [];
                querySnapshot.forEach((doc) => {
                  temp.push(doc.data());
                  // seen message
                  let msgRefSeen = db
                    .collection("messageBox")
                    .doc(curr_receiverId)
                    .collection("messages")
                    .doc(doc.data().messageId);
                  let seenTmp = doc.data().seen;
                  let NewSeenTmp = seenTmp?.includes(senderId)
                    ? seenTmp
                    : [...seenTmp, senderId];
                  if (!seenTmp?.includes(senderId)) {
                    if (prev_receiverId) {
                      if (prev_receiverId && curr_receiverId == receiverId) {
                        msgRefSeen.update({ seen: NewSeenTmp });
                      }
                    } else {
                      msgRefSeen.update({ seen: NewSeenTmp });
                    }
                  }
                });
                if (prev_receiverId) {
                  if (prev_receiverId && curr_receiverId == receiverId) {
                    setLstMessage(temp);
                    let scr = document.getElementById("chat");
                    scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
                  }
                } else {
                  setLstMessage(temp);
                  let scr = document.getElementById("chat");
                  scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
                }
              });
          } else {
            // Get message data personal
            const messageRef = exitAt
              ? db
                  .collection("messageBox")
                  .doc(senderId)
                  .collection(receiverId)
                  .where("createdAt", ">", clearAt)
                  .where("createdAt", "<", exitAt)
              : db
                  .collection("messageBox")
                  .doc(senderId)
                  .collection(receiverId)
                  .where("createdAt", ">", clearAt);
            messageRef
              .orderBy("createdAt", "asc")
              .onSnapshot((querySnapshot) => {
                var prev_receiverId = Cookies.get("prev_receiverId");
                var curr_receiverId = Cookies.get("curr_receiverId");
                console.log(
                  `db.collection("messageBox").doc(${senderId}).collection(${receiverId}).where(createdAt, ">", ${clearAt})`,
                  "messageRef"
                );
                let temp = [];
                querySnapshot.forEach((doc) => {
                  temp.push(doc.data());

                  // seen message
                  let msgRefSeen = db
                    .collection("messageBox")
                    .doc(curr_receiverId)
                    .collection(senderId)
                    .doc(doc.data().messageId);
                  msgRefSeen
                    .get()
                    .then((doc) => {
                      if (doc.exists) {
                        if (prev_receiverId) {
                          if (
                            prev_receiverId &&
                            curr_receiverId == receiverId
                          ) {
                            msgRefSeen.update({ seen: true });
                          }
                        } else {
                          msgRefSeen.update({ seen: true });
                        }
                      }
                    })
                    .catch((error) => {
                      console.log("Error getting document:", error);
                    });
                });
                if (prev_receiverId) {
                  if (prev_receiverId && curr_receiverId == receiverId) {
                    setLstMessage(temp);
                    let scr = document.getElementById("chat");
                    scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
                  }
                } else {
                  setLstMessage(temp);
                  let scr = document.getElementById("chat");
                  scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
                }
              });
          }
        } else {
          getExitAt();
        }
      }
    }
  };

  const sendMessageGroup = async (event) => {
    event.preventDefault();
    let messageId = uuid();
    var timestamp = new Date();
    if (message.trim() === "") return;
    const messageBoxRef = db
      .collection("messageBox")
      .doc(receiverId)
      .collection("messages")
      .doc(messageId);

    const messageData = {
      createdAt: timestamp,
      createdBy: senderId,
      groupEdit: false,
      messageId: messageId,
      userName: userName,
      message: message,
      messageType: "text",
      chatType: "group",
      seen: [senderId],
    };
    messageBoxRef.set(messageData);

    // let lstUser = group_members

    group_members.map((f_uid) => {
      if (f_uid) {
        db.collection("messageBox")
          .doc(f_uid)
          .collection("inbox")
          .doc(receiverId)
          .update({
            createdAt: timestamp,
            lastMessage: message,
            createdBy: senderId,
            senderId: senderId,
            chatType: "group",
            receiverId: receiverId,
            messageType: "text",
          });
      }
    });
    setMessage("");

    let scr = document.getElementById("chat");
    scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
  };

  const uuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const sendFilePersonal = async (event, mType, msg, fileInfo) => {
    event.preventDefault();

    if (msg === "") return;
    let receiverName = await AsyncLocalStorage.getItem("receiverName");
    let receiverProfilePicuture = await AsyncLocalStorage.getItem(
      "receiverProfilePicuture"
    );
    let senderId = await AsyncLocalStorage.getItem("senderId");
    let userProfilePicture = await AsyncLocalStorage.getItem("userProfile");
    let userName = await AsyncLocalStorage.getItem("userName");
    let to_id = await AsyncLocalStorage.getItem("to_id");
    let from_id = await AsyncLocalStorage.getItem("from_id");
    let from_slug = await AsyncLocalStorage.getItem("from_slug");
    let to_slug = await AsyncLocalStorage.getItem("to_slug");
    let uuid1 = uuid();

    var timestamp = new Date();

    let sendMessageData = {
      createdAt: timestamp,
      createdBy: senderId,
      groupEdit: false,
      messageId: uuid1,
      userName: userName,
      message: msg,
      messageType: mType,
      chatType: "personal",
      seen: false,
      senderId: senderId,
      receiverId: receiverId,
      fileInfo: fileInfo,
    };
    db.collection("messageBox")
      .doc(senderId)
      .collection(receiverId)
      .doc(uuid1)
      .set(sendMessageData);
    db.collection("messageBox")
      .doc(receiverId)
      .collection(senderId)
      .doc(uuid1)
      .set(sendMessageData);

    // Inbox
    let senderInbox = {
      createdAt: timestamp,
      createdBy: senderId,
      chatType: "personal",
      lastMessage: msg,
      messageType: mType,
      senderId: receiverId,
      receiverId: senderId,
      seen: false,
      fullName: userName,
      imageUrl: userProfilePicture,
      id: from_id,
      slug: from_slug,
    };

    let receiverInbox = {
      createdAt: timestamp,
      createdBy: senderId,
      chatType: "personal",
      lastMessage: msg,
      messageType: mType,
      senderId: senderId,
      receiverId: receiverId,
      seen: false,
      fullName: receiverName,
      imageUrl: receiverProfilePicuture,
      id: to_id,
      slug: to_slug,
    };

    let senderInboxRef = db
      .collection("messageBox")
      .doc(senderId)
      .collection("inbox")
      .doc(receiverId);
    senderInboxRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          senderInboxRef.update(receiverInbox);
        } else {
          senderInboxRef.set(receiverInbox);
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    let reciverInboxRef = db
      .collection("messageBox")
      .doc(receiverId)
      .collection("inbox")
      .doc(senderId);
    reciverInboxRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          reciverInboxRef.update(senderInbox);
        } else {
          reciverInboxRef.set(senderInbox);
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    // db.collection("messageBox")
    //   .doc(senderId)
    //   .collection("inbox")
    //   .doc(receiverId)
    //   .update(receiverInbox);

    // db.collection("messageBox")
    //   .doc(receiverId)
    //   .collection("inbox")
    //   .doc(senderId)
    //   .update(senderInbox);
    // AsyncLocalStorage.setItem("count", parseInt(receiverCount) + 1)
  };

  const sendFileGroup = async (event, mType, msg, fileInfo) => {
    event.preventDefault();
    let messageId = uuid();
    var timestamp = new Date();
    if (msg === "") return;
    const messageBoxRef = db
      .collection("messageBox")
      .doc(receiverId)
      .collection("messages")
      .doc(messageId);

    const messageData = {
      createdAt: timestamp,
      createdBy: senderId,
      groupEdit: false,
      messageId: messageId,
      userName: userName,
      message: msg,
      messageType: mType,
      chatType: "group",
      seen: [senderId],
      fileInfo: fileInfo,
    };
    messageBoxRef.set(messageData);

    // let lstUser = group_members

    group_members.map((f_uid) => {
      if (f_uid) {
        db.collection("messageBox")
          .doc(f_uid)
          .collection("inbox")
          .doc(receiverId)
          .update({
            createdAt: timestamp,
            lastMessage: msg,
            createdBy: senderId,
            senderId: senderId,
            chatType: "group",
            receiverId: receiverId,
            messageType: mType,
          });
      }
    });
    setMessage("");

    let scr = document.getElementById("chat");
    scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
  };

  const sendMessagePersonal = async (event, mType) => {
    event.preventDefault();

    if (message.trim() === "") return;
    let receiverName = await AsyncLocalStorage.getItem("receiverName");
    let receiverProfilePicuture = await AsyncLocalStorage.getItem(
      "receiverProfilePicuture"
    );
    let senderId = await AsyncLocalStorage.getItem("senderId");
    let userProfilePicture = await AsyncLocalStorage.getItem("userProfile");
    let userName = await AsyncLocalStorage.getItem("userName");
    let to_id = await AsyncLocalStorage.getItem("to_id");
    let from_id = await AsyncLocalStorage.getItem("from_id");
    let from_slug = await AsyncLocalStorage.getItem("from_slug");
    let to_slug = await AsyncLocalStorage.getItem("to_slug");
    let uuid1 = uuid();

    var timestamp = new Date();
    const timestampOld = new Date(new Date().valueOf() - 3000);

    let sendMessageData = {
      createdAt: timestamp,
      createdBy: senderId,
      groupEdit: false,
      messageId: uuid1,
      userName: userName,
      message: message.trim(),
      messageType: mType,
      chatType: "personal",
      seen: false,
      senderId: senderId,
      receiverId: receiverId,
    };
    db.collection("messageBox")
      .doc(senderId)
      .collection(receiverId)
      .doc(uuid1)
      .set(sendMessageData);
    db.collection("messageBox")
      .doc(receiverId)
      .collection(senderId)
      .doc(uuid1)
      .set(sendMessageData);

    // Inbox
    let senderInbox = {
      createdAt: timestamp,
      createdBy: senderId,
      chatType: "personal",
      lastMessage: message.trim(),
      messageType: mType,
      senderId: receiverId,
      receiverId: senderId,
      seen: false,
      fullName: userName,
      imageUrl: userProfilePicture,
      id: from_id,
      slug: from_slug,
    };

    let receiverInbox = {
      createdAt: timestamp,
      createdBy: senderId,
      chatType: "personal",
      lastMessage: message.trim(),
      messageType: mType,
      senderId: senderId,
      receiverId: receiverId,
      seen: false,
      fullName: receiverName,
      imageUrl: receiverProfilePicuture,
      id: to_id,
      slug: to_slug,
    };
    let senderInboxRef = db
      .collection("messageBox")
      .doc(senderId)
      .collection("inbox")
      .doc(receiverId);
    senderInboxRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          senderInboxRef.update(receiverInbox);
        } else {
          senderInboxRef.set({ ...receiverInbox, clearAt: timestampOld });
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    let reciverInboxRef = db
      .collection("messageBox")
      .doc(receiverId)
      .collection("inbox")
      .doc(senderId);
    reciverInboxRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          reciverInboxRef.update(senderInbox);
        } else {
          reciverInboxRef.set({ ...senderInbox, clearAt: timestampOld });
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    // AsyncLocalStorage.setItem("count", parseInt(receiverCount) + 1)
    setMessage("");
  };

  function handleEnterKeyOnCards(e) {
    if (e.keyCode == 13) {
      // sendMessageGroup(e);
      if (chatType == "group") {
        sendMessageGroup(e, "text");
      } else {
        sendMessagePersonal(e, "text");
      }
    }
  }

  const onScroll = () => {
    // console.log(listInnerRef.current, "call")
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // hasMoreMessageEnd()
      }
      if (!scrollTop) {
        // hasMoreMessageStart()
      }
    }
  };

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  return (
    <div className={classes.bgProfilePage}>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "10px 10px 0px 0px",
          // height: "calc(100vh - 260px)",
          overflowY: "auto",
        }}
      >
        {localStorage.getItem("chat_type") === "personal" ? (
          <ChatHeadPersonal
            db={db}
            receiverId={receiverId}
            senderId={senderId}
            chatType={localStorage.getItem("chat_type")}
            onload={getClearAt}
            setNameMobileChat={setNameMobileChat}
            setClearAt={setClearAt}
          />
        ) : (
          ""
        )}

        {localStorage.getItem("chat_type") === "group" ? (
          <ChatHeadGroup
            db={db}
            exitAt={exitAt}
            receiverId={receiverId}
            senderId={senderId}
            chatType={localStorage.getItem("chat_type")}
            setNameMobileChat={setNameMobileChat}
            onload={getClearAt}
            setClearAt={setClearAt}
          />
        ) : (
          ""
        )}

        {/* Message list show */}
        <div
          className={classes.messgeList}
          // style={{ overflow: "auto", height: 'calc(100vh - 266px)' }}
          id="chat"
          onScroll={onScroll}
          ref={listInnerRef}
        >
          {lstMessage.length ? (
            lstMessage.map((objInbox, index) => {
              // console.log(moment(new Date(objInbox.createdAt * 1000)).format("dd"))

              // if (lstMessage[index - 1]) {
              //   console.log(moment(new Date(objInbox.createdAt * 1000)).isSame(new Date(lstMessage[index - 1].createdAt * 1000), 'day'), "Dekho date")
              //   if (!moment(new Date(objInbox.createdAt * 1000)).isSame(new Date(lstMessage[index - 1].createdAt * 1000), 'day')) {
              //     return (<DayChange date={moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")} />)
              //   }
              // }

              if (objInbox.chatType === "group") {
                if (objInbox.groupEdit == true) {
                  return (
                    <GroupEdit
                      senderId={senderId}
                      userName={userName}
                      objInbox={objInbox}
                      lstMessage={lstMessage}
                      index={index}
                    />
                  );
                } else {
                  if (objInbox.createdBy === senderId) {
                    return (
                      <SenderMessage
                        objInbox={objInbox}
                        group_members={group_members}
                        lstMessage={lstMessage}
                        index={index}
                      />
                    );
                  } else {
                    return (
                      <ReciverMessage
                        objInbox={objInbox}
                        lstMessage={lstMessage}
                        index={index}
                      />
                    );
                  }
                }
              } else {
                if (objInbox.senderId === senderId) {
                  return (
                    <SenderMessagePersonal
                      objInbox={objInbox}
                      lstMessage={lstMessage}
                      index={index}
                    />
                  );
                } else {
                  return (
                    <ReciverMessagePersonal
                      objInbox={objInbox}
                      lstMessage={lstMessage}
                      index={index}
                    />
                  );
                }
              }
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              {exitAt == "" && <CircularProgress size={20} />}
            </div>
          )}
          {fileLoading && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div className={classes.carosalImageBox}></div>
            </div>
          )}
        </div>
      </div>
      {exitAt == "" ? (
        <div
          style={{ textAlign: "center", width: "100%", height: "60px" }}
        ></div>
      ) : (
        <>
          {!exitAt ? (
            // {/* Send message */}
            <SendMessageBar
              setMessage={setMessage}
              message={message}
              sendMessageGroup={sendMessageGroup}
              chatType={localStorage.getItem("chat_type")}
              sendMessagePersonal={sendMessagePersonal}
              handleEnterKeyOnCards={handleEnterKeyOnCards}
              sendFilePersonal={sendFilePersonal}
              sendFileGroup={sendFileGroup}
              setSessionMessage={setSessionMessage}
              fileLoading={fileLoading}
              setFileLoading={setFileLoading}
            />
          ) : (
            <div style={{ textAlign: "center", width: "100%" }}>
              <Divider />
              <Typography className={classes.exitnote}>
                You can't send message to this group because you're no longer a
                participant
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
};

// message lable personal
const SenderMessagePersonal = (props) => {
  const classes = useStyles();
  const { objInbox, lstMessage, index } = props;
  return (
    <div>
      {index == 0 && (
        <>
          <DayChange
            date={moment(new Date(objInbox.createdAt.seconds * 1000)).format(
              "LL"
            )}
          />
        </>
      )}
      {lstMessage[index - 1] && (
        <>
          {!moment(new Date(objInbox.createdAt.seconds * 1000)).isSame(
            new Date(lstMessage[index - 1].createdAt.seconds * 1000),
            "day"
          ) && (
            <DayChange
              date={moment(new Date(objInbox.createdAt.seconds * 1000)).format(
                "LL"
              )}
            />
          )}
        </>
      )}
      <div className={classes.myMessageRoot}>
        {objInbox.messageType == "text" && (
          <div
            className={classes.myMessage}
            style={
              objInbox?.message?.length > 35
                ? { flexDirection: "column" }
                : { flexDirection: "row" }
            }
          >
            <div>{objInbox.message}</div>
            <div className={classes.myMessageTime}>
              {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
              {objInbox.seen ? (
                <DoneAllIcon className={classes.myDoubleTick} />
              ) : (
                <DoneIcon className={classes.myDoubleTick} />
              )}
              {/* <DoneAllIcon className={classes.myDoubleTick} /> */}
            </div>
          </div>
        )}

        {objInbox.messageType.includes("image/") && (
          <Fancybox>
            <a
              data-fancybox="Images"
              href={
                objInbox.message ? objInbox.message : "/images/male_defualt.svg"
              }
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                className={classes.carosalImageBox}
                style={{ backgroundImage: `url(${objInbox.message})` }}
              >
                <div
                  className={classes.myMessageTime}
                  style={{
                    position: "absolute",
                    zIndex: "1",
                    right: "10px",
                    bottom: "3px",
                    color: "white",
                  }}
                >
                  {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
                  {objInbox.seen ? (
                    <DoneAllIcon className={classes.myDoubleTick} />
                  ) : (
                    <DoneIcon className={classes.myDoubleTick} />
                  )}
                </div>
              </div>
            </a>
          </Fancybox>
        )}

        {objInbox.messageType.includes("video/") && (
          <Fancybox>
            <a
              data-fancybox="Images"
              href={
                objInbox.message ? objInbox.message : "/images/male_defualt.svg"
              }
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div className={classes.carosalImageBox}>
                <video
                  src={objInbox.message}
                  alt="ss"
                  className={classes.carosalVideoBox}
                />
                <div
                  className={classes.myMessageTime}
                  style={{
                    position: "absolute",
                    zIndex: "1",
                    right: "10px",
                    bottom: "3px",
                    color: "white",
                  }}
                >
                  {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
                  {objInbox.seen ? (
                    <DoneAllIcon className={classes.myDoubleTick} />
                  ) : (
                    <DoneIcon className={classes.myDoubleTick} />
                  )}
                </div>
                <div className={classes.myPlayVideoRoot}>
                  <img
                    src="/images/matchUp/playVideo.svg"
                    className={classes.myPlayVideo}
                  />
                </div>
              </div>
            </a>
          </Fancybox>
        )}

        {objInbox.messageType.includes("application/") && (
          <div className={classes.myFile}>
            <div className={classes.myFileName}>{objInbox.fileInfo.name}</div>
            <div className={classes.myFileSize}>
              {(objInbox.fileInfo.size / 1024).toFixed(2)}kb
            </div>
            <div className={classes.myFileType}>
              <div style={{ display: "flex" }}>
                <DescriptionOutlinedIcon />
                <span style={{ paddingTop: "5px" }}>File</span>
              </div>
              <div>
                <div className={classes.myMessageTime}>
                  {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
                  {objInbox.seen ? (
                    <DoneAllIcon className={classes.myDoubleTick} />
                  ) : (
                    <DoneIcon className={classes.myDoubleTick} />
                  )}
                </div>
              </div>
            </div>
            <a
              href={objInbox.message}
              download
              target="_blank"
              className={classes.myFileDownload}
            >
              Download
            </a>
          </div>
        )
        // <Fancybox>
        //   <a
        //     data-fancybox="Images"
        //     href={
        //       objInbox.message
        //         ? objInbox.message
        //         : "/images/male_defualt.svg"
        //     }
        //     style={{ width: "100%", height: "100%", display: "flex", justifyContent: "flex-end" }}
        //   >
        //     <div
        //       className={classes.carosalImageBox}
        //       style={{ backgroundImage: `url(${objInbox.message})` }}
        //     >
        //       <div className={classes.myMessageTime} style={{ position: "absolute", zIndex: "1", right: "10px", bottom: "3px", color: "white" }}>
        //         {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
        //         {objInbox.seen ?
        //           <DoneAllIcon className={classes.myDoubleTick} />
        //           :
        //           <DoneIcon className={classes.myDoubleTick} />
        //         }
        //       </div>
        //     </div>
        //   </a>
        // </Fancybox>
        }
      </div>
    </div>
  );
};

const ReciverMessagePersonal = (props) => {
  const classes = useStyles();
  const { objInbox, lstMessage, index } = props;
  return (
    <div>
      {index == 0 && (
        <>
          <DayChange
            date={moment(new Date(objInbox.createdAt.seconds * 1000)).format(
              "LL"
            )}
          />
        </>
      )}
      {lstMessage[index - 1] && (
        <>
          {!moment(new Date(objInbox.createdAt.seconds * 1000)).isSame(
            new Date(lstMessage[index - 1].createdAt.seconds * 1000),
            "day"
          ) && (
            <DayChange
              date={moment(new Date(objInbox.createdAt.seconds * 1000)).format(
                "LL"
              )}
            />
          )}
        </>
      )}
      <div className={classes.yourMessageRoot}>
        {/* <div className={classes.yourMessage}>
          <div className={classes.yourName}>
          
          </div>
          <div style={objInbox?.message?.length > 35 ? { display: "flex", justifyContent: "space-between", flexDirection: "column" } : { display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
            <div>
              {objInbox.message}
            </div>
            <div className={classes.yourMessageTime}> {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}</div>
          </div>
        </div> */}
        {objInbox.messageType == "text" && (
          <div
            className={classes.yourMessage}
            style={
              objInbox?.message?.length > 35
                ? { flexDirection: "column" }
                : { flexDirection: "row" }
            }
          >
            <div>{objInbox.message}</div>
            <div className={classes.yourMessageTime}>
              {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
            </div>
          </div>
        )}

        {objInbox.messageType.includes("image/") && (
          <Fancybox>
            <a
              data-fancybox="Images"
              href={
                objInbox.message ? objInbox.message : "/images/male_defualt.svg"
              }
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div
                className={classes.carosalImageBox}
                style={{ backgroundImage: `url(${objInbox.message})` }}
              >
                <div
                  className={classes.yourMessageTime}
                  style={{
                    position: "absolute",
                    zIndex: "1",
                    right: "0px",
                    bottom: "3px",
                    color: "white",
                  }}
                >
                  {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
                </div>
              </div>
            </a>
          </Fancybox>
        )}

        {objInbox.messageType.includes("video/") && (
          <Fancybox>
            <a
              data-fancybox="Images"
              href={
                objInbox.message ? objInbox.message : "/images/male_defualt.svg"
              }
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div className={classes.carosalImageBox}>
                <video
                  src={objInbox.message}
                  alt="ss"
                  className={classes.carosalVideoBox}
                />
                <div
                  className={classes.yourMessageTime}
                  style={{
                    position: "absolute",
                    zIndex: "1",
                    right: "0px",
                    bottom: "3px",
                    color: "white",
                  }}
                >
                  {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
                </div>
                <div className={classes.myPlayVideoRoot}>
                  <img
                    src="/images/matchUp/playVideo.svg"
                    className={classes.myPlayVideo}
                  />
                </div>
              </div>
            </a>
          </Fancybox>
        )}

        {objInbox.messageType.includes("application/") && (
          <div className={classes.myFile}>
            <div className={classes.myFileName}>{objInbox.fileInfo.name}</div>
            <div className={classes.myFileSize}>
              {(objInbox.fileInfo.size / 1024).toFixed(2)}kb
            </div>
            <div className={classes.myFileType}>
              <div style={{ display: "flex" }}>
                <DescriptionOutlinedIcon />
                <span style={{ paddingTop: "5px" }}>File</span>
              </div>
              <div>
                <div className={classes.yourMessageTime}>
                  {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
                </div>
              </div>
            </div>
            <a
              href={objInbox.message}
              download
              target="_blank"
              className={classes.myFileDownload}
            >
              Download
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

// message lable
const SenderMessage = (props) => {
  const classes = useStyles();
  const { objInbox, group_members, lstMessage, index } = props;
  return (
    <div>
      {index == 0 && (
        <>
          <DayChange
            date={moment(new Date(objInbox.createdAt.seconds * 1000)).format(
              "LL"
            )}
          />
        </>
      )}
      {lstMessage[index - 1] && (
        <>
          {!moment(new Date(objInbox.createdAt.seconds * 1000)).isSame(
            new Date(lstMessage[index - 1].createdAt.seconds * 1000),
            "day"
          ) && (
            <DayChange
              date={moment(new Date(objInbox.createdAt.seconds * 1000)).format(
                "LL"
              )}
            />
          )}
        </>
      )}
      <div className={classes.myMessageRoot}>
        {/* <div className={classes.myMessage}>
          <div>
            {objInbox.message}
          </div>
          <div className={classes.myMessageTime}>
            {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
            {objInbox.seen.length === group_members.length ?
              <DoneAllIcon className={classes.myDoubleTick} />
              :
              <DoneIcon className={classes.myDoubleTick} />
            }
          </div>
        </div> */}
        {objInbox.messageType == "text" && (
          <div
            className={classes.myMessage}
            style={
              objInbox?.message?.length > 35
                ? { flexDirection: "column" }
                : { flexDirection: "row" }
            }
          >
            <div>{objInbox.message}</div>
            <div className={classes.myMessageTime}>
              {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
              {objInbox.seen.length == group_members.length ? (
                <DoneAllIcon className={classes.myDoubleTick} />
              ) : (
                <DoneIcon className={classes.myDoubleTick} />
              )}
              {/* <DoneAllIcon className={classes.myDoubleTick} /> */}
            </div>
          </div>
        )}

        {objInbox.messageType.includes("image/") && (
          <Fancybox>
            <a
              data-fancybox="Images"
              href={
                objInbox.message ? objInbox.message : "/images/male_defualt.svg"
              }
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                className={classes.carosalImageBox}
                style={{ backgroundImage: `url(${objInbox.message})` }}
              >
                <div
                  className={classes.myMessageTime}
                  style={{
                    position: "absolute",
                    zIndex: "1",
                    right: "10px",
                    bottom: "3px",
                    color: "white",
                  }}
                >
                  {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
                  {objInbox.seen.length == group_members.length ? (
                    <DoneAllIcon className={classes.myDoubleTick} />
                  ) : (
                    <DoneIcon className={classes.myDoubleTick} />
                  )}
                </div>
              </div>
            </a>
          </Fancybox>
        )}

        {objInbox.messageType.includes("video/") && (
          <Fancybox>
            <a
              data-fancybox="Images"
              href={
                objInbox.message ? objInbox.message : "/images/male_defualt.svg"
              }
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div className={classes.carosalImageBox}>
                <video
                  src={objInbox.message}
                  alt="ss"
                  className={classes.carosalVideoBox}
                />
                <div
                  className={classes.myMessageTime}
                  style={{
                    position: "absolute",
                    zIndex: "1",
                    right: "10px",
                    bottom: "3px",
                    color: "white",
                  }}
                >
                  {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
                  {objInbox.seen.length == group_members.length ? (
                    <DoneAllIcon className={classes.myDoubleTick} />
                  ) : (
                    <DoneIcon className={classes.myDoubleTick} />
                  )}
                </div>
                <div className={classes.myPlayVideoRoot}>
                  <img
                    src="/images/matchUp/playVideo.svg"
                    className={classes.myPlayVideo}
                  />
                </div>
              </div>
            </a>
          </Fancybox>
        )}

        {objInbox.messageType.includes("application/") && (
          <div className={classes.myFile}>
            <div className={classes.myFileName}>{objInbox.fileInfo.name}</div>
            <div className={classes.myFileSize}>
              {(objInbox.fileInfo.size / 1024).toFixed(2)}kb
            </div>
            <div className={classes.myFileType}>
              <div style={{ display: "flex" }}>
                <DescriptionOutlinedIcon />
                <span style={{ paddingTop: "5px" }}>File</span>
              </div>
              <div>
                <div className={classes.myMessageTime}>
                  {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
                  {objInbox.seen.length == group_members.length ? (
                    <DoneAllIcon className={classes.myDoubleTick} />
                  ) : (
                    <DoneIcon className={classes.myDoubleTick} />
                  )}
                </div>
              </div>
            </div>
            <a
              href={objInbox.message}
              download
              target="_blank"
              className={classes.myFileDownload}
            >
              Download
            </a>
          </div>
        )
        // <Fancybox>
        //   <a
        //     data-fancybox="Images"
        //     href={
        //       objInbox.message
        //         ? objInbox.message
        //         : "/images/male_defualt.svg"
        //     }
        //     style={{ width: "100%", height: "100%", display: "flex", justifyContent: "flex-end" }}
        //   >
        //     <div
        //       className={classes.carosalImageBox}
        //       style={{ backgroundImage: `url(${objInbox.message})` }}
        //     >
        //       <div className={classes.myMessageTime} style={{ position: "absolute", zIndex: "1", right: "10px", bottom: "3px", color: "white" }}>
        //         {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
        //         {objInbox.seen.length == group_members.length?
        //           <DoneAllIcon className={classes.myDoubleTick} />
        //           :
        //           <DoneIcon className={classes.myDoubleTick} />
        //         }
        //       </div>
        //     </div>
        //   </a>
        // </Fancybox>
        }
      </div>
    </div>
  );
};

const ReciverMessage = (props) => {
  const classes = useStyles();
  const { objInbox, lstMessage, index } = props;
  return (
    <div>
      {index == 0 && (
        <>
          <DayChange
            date={moment(new Date(objInbox.createdAt.seconds * 1000)).format(
              "LL"
            )}
          />
        </>
      )}
      {lstMessage[index - 1] && (
        <>
          {!moment(new Date(objInbox.createdAt.seconds * 1000)).isSame(
            new Date(lstMessage[index - 1].createdAt.seconds * 1000),
            "day"
          ) && (
            <DayChange
              date={moment(new Date(objInbox.createdAt.seconds * 1000)).format(
                "LL"
              )}
            />
          )}
        </>
      )}
      <div className={classes.yourMessageRoot}>
        {/* <div className={classes.yourMessage}>
          <div className={classes.yourName}>
            {objInbox.userName}
          </div>
          <div style={objInbox?.message?.length > 35 ? { display: "flex", justifyContent: "space-between", flexDirection: "column" } : { display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
            <div>
              {objInbox.message}
            </div>
            <div className={classes.yourMessageTime}> {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}</div>
          </div>
        </div> */}
        {objInbox.messageType == "text" && (
          <div
            className={classes.yourMessage}
            style={
              objInbox?.message?.length > 35
                ? { flexDirection: "column" }
                : { flexDirection: "row" }
            }
          >
            <div>{objInbox.message}</div>
            <div className={classes.yourMessageTime}>
              {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
            </div>
          </div>
        )}

        {objInbox.messageType.includes("image/") && (
          <Fancybox>
            <a
              data-fancybox="Images"
              href={
                objInbox.message ? objInbox.message : "/images/male_defualt.svg"
              }
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div
                className={classes.carosalImageBox}
                style={{ backgroundImage: `url(${objInbox.message})` }}
              >
                <div
                  className={classes.yourMessageTime}
                  style={{
                    position: "absolute",
                    zIndex: "1",
                    right: "0px",
                    bottom: "3px",
                    color: "white",
                  }}
                >
                  {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
                </div>
              </div>
            </a>
          </Fancybox>
        )}

        {objInbox.messageType.includes("video/") && (
          <Fancybox>
            <a
              data-fancybox="Images"
              href={
                objInbox.message ? objInbox.message : "/images/male_defualt.svg"
              }
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div className={classes.carosalImageBox}>
                <video
                  src={objInbox.message}
                  alt="ss"
                  className={classes.carosalVideoBox}
                />
                <div
                  className={classes.yourMessageTime}
                  style={{
                    position: "absolute",
                    zIndex: "1",
                    right: "0px",
                    bottom: "3px",
                    color: "white",
                  }}
                >
                  {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
                </div>
                <div className={classes.myPlayVideoRoot}>
                  <img
                    src="/images/matchUp/playVideo.svg"
                    className={classes.myPlayVideo}
                  />
                </div>
              </div>
            </a>
          </Fancybox>
        )}

        {objInbox.messageType.includes("application/") && (
          <div className={classes.myFile}>
            <div className={classes.myFileName}>{objInbox.fileInfo.name}</div>
            <div className={classes.myFileSize}>
              {(objInbox.fileInfo.size / 1024).toFixed(2)}kb
            </div>
            <div className={classes.myFileType}>
              <div style={{ display: "flex" }}>
                <DescriptionOutlinedIcon />
                <span style={{ paddingTop: "5px" }}>File</span>
              </div>
              <div>
                <div className={classes.yourMessageTime}>
                  {moment(new Date(objInbox.createdAt * 1000)).format("HH:mm")}
                </div>
              </div>
            </div>
            <a
              href={objInbox.message}
              download
              target="_blank"
              className={classes.myFileDownload}
            >
              Download
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const DayChange = (props) => {
  const classes = useStyles();
  const { date } = props;
  return (
    <div>
      <div className={classes.groupEditMsgRoot}>
        <div className={classes.groupEditMsg}>{date}</div>
      </div>
    </div>
  );
};

const GroupEdit = (props) => {
  const classes = useStyles();
  const { senderId, userName, objInbox, lstMessage, index } = props;
  if (objInbox.createdBy == senderId) {
    return (
      <div>
        {index == 0 && (
          <>
            <DayChange
              date={moment(new Date(objInbox.createdAt.seconds * 1000)).format(
                "LL"
              )}
            />
          </>
        )}
        {lstMessage[index - 1] && (
          <>
            {!moment(new Date(objInbox.createdAt.seconds * 1000)).isSame(
              new Date(lstMessage[index - 1].createdAt.seconds * 1000),
              "day"
            ) && (
              <DayChange
                date={moment(
                  new Date(objInbox.createdAt.seconds * 1000)
                ).format("LL")}
              />
            )}
          </>
        )}
        <div className={classes.groupEditMsgRoot}>
          <div className={classes.groupEditMsg}>
            {objInbox.message.replace(userName, "you")}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        {index == 0 && (
          <>
            <DayChange
              date={moment(new Date(objInbox.createdAt.seconds * 1000)).format(
                "LL"
              )}
            />
          </>
        )}
        {lstMessage[index - 1] && (
          <>
            {!moment(new Date(objInbox.createdAt.seconds * 1000)).isSame(
              new Date(lstMessage[index - 1].createdAt.seconds * 1000),
              "day"
            ) && (
              <DayChange
                date={moment(
                  new Date(objInbox.createdAt.seconds * 1000)
                ).format("LL")}
              />
            )}
          </>
        )}
        <div className={classes.groupEditMsgRoot}>
          <div className={classes.groupEditMsg}>
            {objInbox.message.replace(userName, "you")}
          </div>
        </div>
      </div>
    );
  }
};

// Send message
const SendMessageBar = (props) => {
  const classes = useStyles();
  const fileRef = useRef();

  const {
    fileLoading,
    setFileLoading,
    setSessionMessage,
    handleEnterKeyOnCards,
    setMessage,
    message,
    sendMessageGroup,
    chatType,
    sendMessagePersonal,
    sendFilePersonal,
    sendFileGroup,
  } = props;
  const [isEmoji, setIsEmoji] = useState(false);

  const onEmojiClick = (emojiData, event) => {
    setMessage((prevInput) => prevInput + emojiData.emoji);
    // setIsEmoji(false);
  };

  const onUploadFile = (e) => {
    setFileLoading(true);
    const file = e.target.files[0];

    const data = new FormData();
    data.append("file", file);

    if (file) {
      if (file.size > 2097152) {
        setSessionMessage({
          message: `File size must be less than 2 mb`,
          type: "error",
          open: true,
        });
        setFileLoading(false);
        return;
      }
    }

    // Upload image
    api
      .post("user/teammate/uploadfileinchat", {
        data: data,
      })
      .then((result) => {
        if (result.success === "true") {
          // console.log(e, file.type, result.data.location, `${((e.target.files[0].size))}`, "result.data")
          if (chatType == "group") {
            sendFileGroup(e, file.type, result.data.location, {
              size: file.size,
              name: file.name,
              isDownload: false,
            });
          } else {
            sendFilePersonal(e, file.type, result.data.location, {
              size: file.size,
              name: file.name,
              isDownload: false,
            });
          }
        } else {
          console.log(result, "success === false");
        }
        setFileLoading(false);
      })
      .catch((err) => {
        console.log(err, "error catch");
        setFileLoading(false);
      });
  };

  return (
    <div className={classes.inputBoxWrite}>
      <Box display="flex" width="100%">
        <Box flexGrow={1}>
          <TextField
            className={classes.mainInputMessage}
            fullWidth
            size="small"
            placeholder="Message..."
            variant="outlined"
            onKeyDown={(e) => handleEnterKeyOnCards(e)}
            value={message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.emojiRoot}>
                  <SentimentSatisfiedOutlinedIcon
                    style={{ color: "#808080e8", cursor: "pointer" }}
                    onClick={() => setIsEmoji(!isEmoji)}
                  />
                  {/* <EmojiPicker /> */}

                  {isEmoji && (
                    <EmojiPicker
                      pickerStyle={{
                        width: "100%",
                        position: "absolute",
                        bottom: "50px",
                      }}
                      onEmojiClick={onEmojiClick}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
        </Box>
        <Box>
          <div className={classes.iconAtechBg}>
            <input
              accept="image/*, video/*, application/*"
              style={{ display: "none" }}
              id="icon-button-file"
              type="file"
              onChange={(e) => {
                onUploadFile(e);
              }}
              ref={fileRef}
            />
            <label htmlFor="icon-button-file">
              <AttachFileIcon style={{ fontSize: "18px" }} type="submit" />
            </label>
          </div>
        </Box>
        <Box>
          <div className={classes.iconSendBg}>
            <SendOutlinedIcon
              style={{ fontSize: "18px" }}
              type="submit"
              onClick={(e) => {
                if (chatType == "group") {
                  sendMessageGroup(e, "text");
                } else {
                  sendMessagePersonal(e, "text");
                }
              }}
            />
          </div>
        </Box>
      </Box>
    </div>
  );
};

// Heading Group
const ChatHeadGroup = (props) => {
  const classes = useStyles();
  let query = useLocation();
  const [objSelected, setObj] = useState("");
  const [typing, setTyping] = useState([]);
  const {
    exitAt,
    onload,
    setClearAt,
    senderId,
    setNameMobileChat,
    receiverId,
    db,
    chatType,
  } = props;
  const router = useRouter();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const idMenu = openMenu ? "simple-popover" : undefined;
  const [openClearChat, setOpenClearChat] = useState(false);
  const [activityRef, setActivityRef] = useState("");

  useEffect(() => {
    getTyping();
  }, [receiverId]);

  const onCloseClearChat = (objTostMessage) => {
    setOpenClearChat(false);
  };

  let getTyping = async () => {
    // get typing status
    if (chatType == "group") {
      db.collection("group")
        .doc(receiverId)
        .onSnapshot((doc) => {
          var prev_receiverId = Cookies.get("prev_receiverId");
          var curr_receiverId = Cookies.get("curr_receiverId");
          if (doc.data()) {
            if (doc.data().typing) {
              if (prev_receiverId) {
                if (prev_receiverId && curr_receiverId == receiverId) {
                  setTyping(doc.data().typing);
                }
              } else {
                setTyping(doc.data().typing);
              }
            }
          }
        });
    } else {
    }
  };

  useEffect(() => {
    getSelectedUser();
  }, [receiverId]);

  let getSelectedUser = async () => {
    // Get Selected User
    var groupRef = db.collection("group").doc(receiverId);
    groupRef.onSnapshot((doc) => {
      if (doc.exists) {
        var prev_receiverId = Cookies.get("prev_receiverId");
        var curr_receiverId = Cookies.get("curr_receiverId");

        if (doc.exists) {
          if (prev_receiverId) {
            if (prev_receiverId && curr_receiverId == receiverId) {
              setObj(doc.data());
            }
          } else {
            setObj(doc.data());
          }
        }
      } else {
        console.log("No such document!");
      }
    });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onOpenClearChat = (receiverId) => {
    let tmp = db
      .collection("messageBox")
      .doc(senderId)
      .collection("inbox")
      .doc(receiverId);
    setOpenClearChat(true);
    setActivityRef(tmp);
    handleMenuClose();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #80808061",
        paddingRight: "15px",
      }}
    >
      <div
        className={classes.inboxRoot}
        style={{ padding: "10px", width: "fit-content" }}
      >
        <Hidden mdUp>
          <div className={classes.backIconRoot}>
            <ArrowBackIcon
              className={classes.backIcon}
              onClick={() => setNameMobileChat(false)}
            />
          </div>
        </Hidden>
        <div
          onClick={() => {
            router.history.push(`/groupDetail/${objSelected.group_slug}`);
          }}
        >
          <img
            src={
              objSelected.group_profile_pic
                ? objSelected.group_profile_pic
                : "/images/male_defualt.svg"
            }
            alt="Img"
            className={classes.inboxImage}
          />
        </div>
        <div
          onClick={() => {
            router.history.push(`/groupDetail/${objSelected.group_slug}`);
          }}
          className={classes.inboxNameRoot}
        >
          <div className={classes.inboxName}>{objSelected.group_name}</div>
          <div
            className={
              exitAt
                ? classes.inboxLastMsg
                : typing.length
                ? classes.inboxTyping
                : classes.inboxLastMsg
            }
          >
            {exitAt ? (
              <>{objSelected?.group_members?.length} members</>
            ) : (
              <>
                {typing.length ? (
                  <>
                    {typing.length == 1 && `${typing[0]} is typing...`}
                    {typing.length == 2 &&
                      `${typing[0]} and ${typing[1]} are typing...`}
                    {typing.length > 2 &&
                      `${typing.length} members are typing...`}
                  </>
                ) : (
                  <>{objSelected?.group_members?.length} members</>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className={classes.menuRoot}>
        <MoreVertIcon
          style={{ cursor: "pointer", fontSize: "20px" }}
          onClick={(e) => {
            // if (!exitAt) {
            handleClick(e);
            // }
          }}
        />

        <Popover
          id={idMenu}
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{ padding: "10px", borderRadius: "10px" }}
          className={classes.menu}
        >
          <div style={{ fontSize: "14px", padding: "10px", width: "200px" }}>
            <Typography
              className={classes.typographyMenu}
              onClick={() =>
                router.history.push(`/groupDetail/${objSelected.group_slug}`)
              }
            >
              Group Info
            </Typography>
            <Typography
              className={classes.typographyMenu}
              onClick={() => onOpenClearChat(receiverId)}
            >
              Clear chat
            </Typography>
            <Typography
              className={classes.typographyMenu}
              onClick={() => {
                router.history.push({
                  pathname: `/shareGourp/${objSelected.group_slug}`,
                  search: "",
                  state: {
                    objGroup: objSelected,
                  },
                });
              }}
            >
              Share Group
            </Typography>
            {/* <Divider />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "#F24451" }}>
              <Typography
                className={classes.typographyMenu}
              // onClick={() => { setopenExitGroup(true) }}
              >
                Exit Group
              </Typography>
              <ExitToAppIcon />
            </div> */}
          </div>
        </Popover>
      </div>
      <ClearChatDialog
        chatType={"group"}
        receiverId={receiverId}
        // activityRef={activityRef}
        onClose={onCloseClearChat}
        open={openClearChat}
        onLoad={onload}
        setClearAt={setClearAt}
      />
    </div>
  );
};

// Heading Personal
const ChatHeadPersonal = (props) => {
  const classes = useStyles();
  let query = useLocation();
  const [objSelected, setObj] = useState("");
  const [typing, setTyping] = useState(false);
  const {
    setClearAt,
    setNameMobileChat,
    onload,
    chatType,
    receiverId,
    senderId,
    db,
  } = props;
  const router = useRouter();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const idMenu = openMenu ? "simple-popover" : undefined;
  const [openClearChat, setOpenClearChat] = useState(false);
  const [activityRef, setActivityRef] = useState("");
  const [deleteMsgRef, setDeleteMsgRef] = useState("");

  useEffect(() => {
    getTyping();
  }, []);

  const onCloseClearChat = (objTostMessage) => {
    setOpenClearChat(false);
  };

  let getTyping = async () => {
    let senderId = await AsyncLocalStorage.getItem("senderId");
    // get typing status
    if (chatType == "group") {
    } else {
      db.collection("messageBox")
        .doc(senderId)
        .collection("inbox")
        .doc(receiverId)
        .onSnapshot((doc) => {
          var prev_receiverId = Cookies.get("prev_receiverId");
          var curr_receiverId = Cookies.get("curr_receiverId");
          if (doc.data()) {
            if (prev_receiverId) {
              if (prev_receiverId && curr_receiverId == receiverId) {
                setTyping(doc.data().typing);
              }
            } else {
              setTyping(doc.data().typing);
            }
          }
        });
    }
  };

  useEffect(() => {
    getSelectedUser();
  }, [receiverId]);

  let getSelectedUser = async () => {
    // Get Selected User
    var groupRef = db.collection("user").doc(receiverId);
    groupRef.onSnapshot((doc) => {
      if (doc.exists) {
        var prev_receiverId = Cookies.get("prev_receiverId");
        var curr_receiverId = Cookies.get("curr_receiverId");
        if (doc.exists) {
          if (prev_receiverId) {
            if (prev_receiverId && curr_receiverId == receiverId) {
              setObj(doc.data());
            }
          } else {
            setObj(doc.data());
          }
        }
      } else {
        console.log("No such document!");
      }
    });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onOpenClearChat = (receiverId) => {
    let tmp = db
      .collection("messageBox")
      .doc(senderId)
      .collection("inbox")
      .doc(receiverId);
    let tmpMsg = db
      .collection("messageBox")
      .doc(senderId)
      .collection(receiverId);
    setOpenClearChat(true);
    setActivityRef(tmp);
    setDeleteMsgRef(tmpMsg);
    handleMenuClose();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #80808061",
        paddingRight: "15px",
      }}
    >
      <div
        className={classes.inboxRoot}
        style={{ padding: "10px", width: "fit-content" }}
      >
        <Hidden mdUp>
          <div className={classes.backIconRoot}>
            <ArrowBackIcon
              className={classes.backIcon}
              onClick={() => setNameMobileChat(false)}
            />
          </div>
        </Hidden>
        <div
          onClick={() => {
            router.history.push(`/your-matches/view/${objSelected.slug}`);
          }}
        >
          <img
            src={
              objSelected.photoURL
                ? objSelected.photoURL
                : "/images/male_defualt.svg"
            }
            alt="Img"
            className={classes.inboxImage}
          />
        </div>
        <div
          className={classes.inboxNameRoot}
          onClick={() => {
            router.history.push(`/your-matches/view/${objSelected.slug}`);
          }}
        >
          <div className={classes.inboxName}>{objSelected.displayName}</div>
          <div className={typing ? classes.inboxTyping : classes.inboxLastMsg}>
            {typing ? "Typing..." : "Tap for more info"}
          </div>
        </div>
      </div>

      <div className={classes.menuRoot}>
        <MoreVertIcon
          style={{ cursor: "pointer", fontSize: "20px" }}
          onClick={(e) => {
            // if (!exitAt) {
            handleClick(e);
            // }
          }}
        />

        <Popover
          id={idMenu}
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{ padding: "10px", borderRadius: "10px" }}
          className={classes.menu}
        >
          <div style={{ fontSize: "14px", padding: "10px", width: "200px" }}>
            <Typography
              className={classes.typographyMenu}
              // onClick={() => router.history.push(`/groupDetail/${objSelectedGroup.slug}`)}
            >
              Info
            </Typography>
            <Typography
              className={classes.typographyMenu}
              onClick={() => onOpenClearChat(receiverId)}
            >
              Clear chat
            </Typography>
            {/* <Typography
              className={classes.typographyMenu}
            // onClick={() => {
            //   router.history.push({
            //     pathname: `/shareGourp/${objSelectedGroup.slug}`,
            //     search: '',
            //     state: {
            //       objGroup: objSelectedGroup
            //     }
            //   })
            // }}
            >
              Share Group
            </Typography> */}
            {/* <Divider />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "#F24451" }}>
              <Typography
                className={classes.typographyMenu}
              // onClick={() => { setopenExitGroup(true) }}
              >
                Exit Group
              </Typography>
              <ExitToAppIcon />
            </div> */}
          </div>
        </Popover>
      </div>
      <ClearChatDialog
        chatType={"personal"}
        receiverId={receiverId}
        // activityRef={activityRef}
        // deleteMsgRef={deleteMsgRef}
        onClose={onCloseClearChat}
        open={openClearChat}
        onLoad={onload}
        setClearAt={setClearAt}
      />
    </div>
  );
};

export default Message;
