import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import Api from "../../../../Helper/ApiHandler";
import { useParams } from "react-router-dom";
import { object } from "prop-types";
import { Skeleton } from "@material-ui/lab";
import { Helmet } from "react-helmet";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import ShareDialog from "components/ShareModel/ShareModel";
import MuiAlert from "@material-ui/lab/Alert";

var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "28px !important",
    marginTop: "50px",
    fontWeight: 700,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  container: {
    padding: "0 300px",
    [theme.breakpoints.down("lg")]: {
      padding: "0 100px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  containerother: {
    padding: "0 270px",
    marginTop: 50,
    [theme.breakpoints.down("lg")]: {
      padding: "0 100px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 30px",
    },
  },
  images: {
    width: "100%",
    height: "500px",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      height: "250px",
    },
  },
  contantAbout: {
    margin: "25px 0",
  },
  blogsShareing: {
    alignItems: "center",
    marginTop: 40,
    marginBottom: 50,
    padding: "0 250px",
    [theme.breakpoints.down("xs")]: {
      padding: "0 15px",
    },
  },
  avtar_Title: {
    fontSize: "14px !important",
    color: "#282F36",
  },
  avtar_Detail: {
    fontSize: "13px !important",
    color: "#282F36",
    fontWeight: 400,
  },
  avtar: {
    display: "flex",
    alignItems: "center",
  },
}));

const ContactUsDetail = (props) => {
  const classes = useStyles();
  const { id } = useParams();

  const [blogDescription, setBlogDescription] = React.useState("");
  const [data, setData] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [shareOpen, setShareOpen] = React.useState(false);
  const [shareing, setShareing] = React.useState("");
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });

  useEffect(() => {
    onLoad();
  }, []);

  const ShareNow = (sl_ug) => {
    setShareOpen(true);
    setShareing(sl_ug);
  };

  const handleClose = () => {
    setShareOpen(false);
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const onLoad = () => {
    setOnLoading(true);
    api
      .get(`viewblog/${id}`)
      .then((result) => {
        if (result.success === "true") {
          setData(result.data);
          setBlogDescription(result.data.description);
        } else {
          console.log(result, "error");
        }
        setOnLoading(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setOnLoading(false);
      });
  };
  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleErorrClose}
        >
          <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <Helmet>
        <title>{data.meta_title}</title>
        <meta name="description" content={data.meta_description} />
        <meta name="keywords" content={data.meta_keywords} />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={data.meta_title} />
        <meta itemprop="description" content={data.meta_description} />
        <meta itemprop="image" content={data.picture_url} />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://matchupmates.com" />
        <meta property="og:type" content="website" />

        <meta property="og:title" content={data.meta_title} />
        <meta property="og:description" content={data.meta_description} />
        <meta property="og:image" content={data.picture_url} />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={data.meta_title} />
        <meta name="twitter:description" content={data.meta_description} />
        <meta name="twitter:image" content={data.picture_url} />
      </Helmet>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {onLoading ? (
            <Skeleton animation="wave" height={70} className={classes.title} />
          ) : (
            <Typography variant="h3" className={classes.title}>
              {data.title}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.containerother}>
        {onLoading ? (
          <Skeleton variant="rect" className={classes.images} />
        ) : (
          <div>
            <img
              src={data.picture_url}
              alt="BlogDetailImages"
              className={classes.images}
            />
            <div className={classes.blogsShareing}>
              <hr />
              <Grid container style={{ marginTop: 15 }}>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  className={classes.avtar}
                >
                  <Avatar
                    alt="BlogAvtar"
                    // src=
                    src={
                      data?.bloguser?.profile_picture ??
                      "/images/matchUp/BlogsAvtar.png"
                    }
                  />
                  <Box component="div" ml={1}>
                    <Typography variant="h6" className={classes.avtar_Title}>
                      {data?.bloguser?.name}
                    </Typography>
                    <Typography variant="h6" className={classes.avtar_Detail}>
                      {data?.createdAt}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <ShareOutlinedIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      ShareNow(data.slug);
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div
              className={classes.blogsShareing}
              dangerouslySetInnerHTML={{ __html: blogDescription }}
            ></div>
          </div>
        )}
        <ShareDialog
          open={shareOpen}
          onClose={handleClose}
          shareing={shareing}
          setSessionMessage={setSessionMessage}
        // objProperty={objProperty}
        />
      </Grid>
    </>
  );
};

export default ContactUsDetail;
