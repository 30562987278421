import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  Snackbar,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";

import TelegramIcon from "@material-ui/icons/Telegram";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import { Link as browserLink, useLocation } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Checkbox from "@material-ui/core/Checkbox";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import ReactFancyBox from "react-fancybox";
import useRouter from "utils/useRouter";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BlockIcon from "@material-ui/icons/Block";
import MuiAlert from "@material-ui/lab/Alert";
import Api from "../../Helper/ApiHandler";
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Compressor from "compressorjs";
import RemoveTeamUser from "components/RemoveTeamUser/RemoveTeamUser";

var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #c4c4c4",
  },
  indicator: {
    backgroundColor: "#FE4B6B",
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontSize: "18px",
    fontWeight: "500px",
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#FE4B6B",
      opacity: 1,
    },
    "&$selected": {
      color: "#FE4B6B",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#FE4B6B",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#e9edf0",
    padding: "35px 0",
    minHeight: "90vh",
    // marginBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  detailBox: {
    background: "white",
    borderRadius: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    position: "relative",
    paddingBottom: "10px",
  },
  backRoot: {
    maxWidth: "fit-content",
    cursor: "pointer",
    margin: "10px 10px 0px 10px",
    // marginLeft: "10px",
    // marginBottom: "20px",
    // marginTop: "45px",
    alignItems: "center",
    display: "flex",
  },
  headingPage: {
    fontWeight: "600 !important",
    fontSize: "18px",
    margin: "10px 10px 10px 10px",
  },
  nextButton: {
    borderRadius: "5px",
    textTransform: "capitalize",
    minWidth: "0px",
    padding: "5px 10px",
  },
  addUserButton: {
    borderRadius: "5px",
    textTransform: "capitalize",
    minWidth: "0px",
    padding: "5px 10px",
    background: "linear-gradient(347.29deg, #E84A62 1.03%, #F4A360 113.2%)",
    color: "white",
    marginRight: "10px",
  },

  groupNameRoot: {
    display: "flex",
    alignItems: "center",
    "&:hover ": {
      "& .hide": {
        display: "block",
      },
    },
  },
  groupName: {
    fontSize: "24px",
    fontWeight: "600",
    textTransform: "capitalize",
  },
  groupNameEditIcon: {
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
  },
  groupNameEditIconRoot: {
    display: "none",
  },
  groupDescription: {
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "capitalize"
  },
  formHr: {
    width: "100%",
    background: "#FFFFFF",
    opacity: "0.1",
    border: "1px solid #000000",
    margin: "20px 0px",
  },
  memberRoot: {
    background: "white",
    borderRadius: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    position: "relative",
    paddingBottom: "10px",
  },
  acceptButton: {
    padding: "0px 15px",
    borderRadius: "30px",
    fontSize: "12px",
    textTransform: "capitalize",
    color: "white",
    background: "#F24451",
    "&:hover": {
      background: "#F24451",
    },
    borderColor: "#F24451",
  },
  deleteButton: {
    padding: "0px 15px",
    borderRadius: "30px",
    fontSize: "12px",
    textTransform: "capitalize",
    color: "#F24451",
    backgroundColor: "#F9EBEB",
    borderColor: "#F24451",
  },
  menu: {
    cursor: "pointer",
    "& .MuiPaper-rounded": {
      marginTop: "10px",
      borderRadius: "10px",
    },
  },
  typographyMenu: {
    fontSize: "14px",
    padding: "7px",
  },
  adminChip: {
    position: "absolute",
    fontSize: "10px",
    background:
      "linear-gradient(168.55deg, #E84A62 0%, #ED6E61 44.79%, #F4A360 100%)",
    color: "white",
    padding: "3px 10px",
    borderRadius: "5px",
    bottom: "-4px",
  },
  groupImageRoot: {
    position: "relative",
    "&:hover ": {
      "& .hide": {
        display: "block",
      },
    },
  },
  groupImage: {
    width: "100%",
    height: "400px",
    objectFit: "cover",
    borderRadius: "10px",
  },
  groupImageHover: {
    background: "#0000002e",
    top: 0,
    position: "absolute",
    width: "100%",
    height: "400px",
    objectFit: "cover",
    borderRadius: "10px",
    display: "none",
  },
  nameBarRoot: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
    justifyContent: "space-between",
    marginTop: "10px",
  },
  addViaEmailRoot: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },
  removeButton: {
    position: "absolute",
    right: -8,
    color: "#FE4B6B",
    backgroundColor: "white",
    borderRadius: "50%",
    zIndex: 100,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      right: 20,
    },
    [theme.breakpoints.down("sm")]: {
      right: 15,
    },
    [theme.breakpoints.down("xs")]: {
      right: 0,
    },
  },
}));

const GroupDetailDisplay = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const theme = useTheme();
  const {
    onLoad,
    groupData,
    setGroupData,
    userName,
    setUserName,
    firebase_uid,
    setFirebase_uid,
    isLoading,
    setIsLoading,
    isFavourite,
    setIsFavourite,
    isBlock,
    setIsBlock,
    userList,
    setUserList,
    groupName,
    setGroupName,
    groupDescription,
    setGroupDescription,
  } = props;

  const [value, setValue] = React.useState(0);
  const [isNameEdit, setIsNameEdit] = React.useState("");
  const [isDescriptionEdit, setIsDescriptionEdit] = React.useState("");
  const [userSlug, setUserSlug] = React.useState("");
  const [uploadImagePath, setUploadImagePath] = React.useState("");
  const [confirmLoading, setConfirmLoading] = React.useState("");
  const [deleteLoading, setDeleteLoading] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [removeData, setRemoveData] = React.useState("");
  const [onRemoveUserLoading, setOnRemoveUserLoading] = React.useState(false);

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const idMenu = openMenu ? "simple-popover" : undefined;
  const onOpenSetRetack = (object) => {
    setOpen(true);
    setRemoveData(object);
  };
  const handleRetackClose = () => {
    setOpen(false);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let query = useLocation();
  const slug = query.pathname.split("/")[2];
  // const matchPercent = query.pathname.split("/")[4]

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const onEdit = (key, value) => {
    api
      .put(`user/teammate/editgroup/${groupData.slug}`, {
        data: {
          [key]: value,
        },
      })
      .then((result) => {
        if (result.success === "true") {
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
          if (key == "name") {
            setGroupName(value)
          }
          if (key == "group_description") {
            setGroupDescription(value)
          }
          setIsNameEdit("");
          setIsDescriptionEdit("");
          // onLoad()
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const onConfirm = (id, status) => {
    if (status === "confirm") {
      setConfirmLoading(true);
    } else {
      setDeleteLoading(true);
    }
    api
      .put(`user/teammate/confirmrequest/${groupData.slug}`, {
        data: {
          from_id: id,
          status: status,
        },
      })
      .then((result) => {
        if (result.success === "true") {
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
          onLoad();
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setConfirmLoading(false);
        setDeleteLoading(false);
      })
      .catch((err) => {
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        setConfirmLoading(false);
        setDeleteLoading(false);
      });
  };

  const handelStatusChange = (slug, status) => {
    let newSatus = status == "private" ? "public" : "private";
    api
      .put(`user/teammate/changegrouptype/${slug}`, {
        data: { group_type: newSatus },
      })
      .then((result) => {
        if (result.success === "true") {
          // console.log(result, "result")
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });

          // let lstorg = [...lstOrganization];
          // const index = lstorg.findIndex(obj => obj.slug === slug);
          // lstorg[index] = { ...lstorg[index], is_active: !lstorg[index].is_active }
          // setLstOrganization(lstorg);
          let objTmp = { ...groupData, group_type: newSatus };
          setGroupData(objTmp);
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  const comppressImageProfile = (e) => {
    const file = e?.target?.files[0];
    if (!file) {
      return;
    }
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const formData = new FormData();
        formData.append("file", result, result.name);
        console.log(formData, "formData");
        onUploadimageComprace(e, formData, file);
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const onUploadimageComprace = async (e, data, file) => {
    // Base64
    var reader = new FileReader();
    reader.onloadend = function () {
      setUploadImagePath(reader.result);
    };
    reader.readAsDataURL(file);
    // user/teammate/editgroup/${groupData.slug}
    // Upload image
    api
      .post(`user/teammate/uploadprofilepic`, {
        data: data,
      })
      .then((result) => {
        if (result.success === "true") {
          console.log(result.data, "result---");
          onEdit("group_profile_pic", result.data);
          setFormState({
            ...formState,
            values: {
              ...formState.values,
              group_profile_pic: result.data,
            },
          });
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          console.log(result, "success === false");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  // console.log(groupData.slug, "groupData.slug");

  const onClickRemoveUser = (e, userid) => {
    e.preventDefault();
    setOnRemoveUserLoading(true);

    api
      .post(`user/teammate/removemember/${groupData.slug}`, {
        data: {
          remove_id: userid,
        },
      })
      .then((result) => {
        if (result.success === "true") {
          onLoad();
          setOpen(false);
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setOnRemoveUserLoading(false);
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  // console.log(isNameEdit, "isNameEdit");
  // console.log(groupData, "userData");
  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgProfilePage}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid className={classes.container} item>
              {isLoading ? (
                <SkeletonLoading />
              ) : (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    xl={12}
                    className={classes.detailBox}
                  >
                    <Box
                      onClick={() => router.history.goBack()}
                      className={classes.backRoot}
                    >
                      <IconButton
                        aria-label="delete"
                        style={{ color: "black", marginRight: "5px",cursor:'pointer' }}
                        size="small"
                      >
                        <ArrowBackIcon fontSize="inherit" />
                      </IconButton>
                      <h5 className={classes.headingPage}> Message </h5>
                    </Box>
                    <div style={{ padding: "10px 40px" }}>
                      <div className={classes.groupImageRoot}>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          onChange={(e) => {
                            comppressImageProfile(e);
                          }}
                        />
                        <label htmlFor="icon-button-file">
                          <img
                            src={
                              groupData.group_profile_pic
                                ? groupData.group_profile_pic
                                : "/images/matchUp/roommateLandingPaart11.webp"
                            }
                            alt="imageback"
                            className={classes.groupImage}
                          />
                          <div className={`${classes.groupImageHover} hide`}>
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <PhotoCameraIcon
                                style={{ fontSize: "50px", color: "white" }}
                              />
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className={classes.nameBarRoot}>
                        <div>
                          {isNameEdit ? (
                            <div>
                              <TextField
                                className={classes.searchInput}
                                disableUnderline
                                size="small"
                                value={isNameEdit || ""}
                                onChange={(e) => setIsNameEdit(e.target.value)}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <CheckIcon
                                        onClick={() => {
                                          onEdit("name", isNameEdit);
                                        }}
                                        className={classes.groupNameEditIcon}
                                      />
                                    </InputAdornment>
                                  ),
                                  // inputComponent: TextMaskCustom,
                                }}
                              />
                            </div>
                          ) : (
                            <div className={classes.groupNameRoot}>
                              <div className={classes.groupName}>
                                {groupName}
                              </div>
                              <div
                                className={`${classes.groupNameEditIconRoot} hide`}
                                onClick={() => {
                                  setIsNameEdit(groupName);
                                }}
                              >
                                <EditIcon
                                  className={classes.groupNameEditIcon}
                                />
                              </div>
                            </div>
                          )}
                          <div className={classes.groupDescription}>
                            {groupData.group_type} Created by{" "}
                            {groupData.createdBy}, {groupData.createdAt}
                          </div>
                        </div>
                        <div className={classes.addViaEmailRoot}>
                          <Button
                            className={classes.addUserButton}
                            variant="outlined"
                            color="primary"
                            // disabled={formState.isValid ? disabled : true}
                            onClick={() => {
                              router.history.push(
                                `/addNewGroupMember/${groupData.slug}`
                              );
                            }}
                            startIcon={<PersonAddOutlinedIcon />}
                          >
                            Add via Email
                          </Button>
                          <Button
                            className={classes.nextButton}
                            variant="outlined"
                            color="primary"
                            // disabled={formState.isValid ? disabled : true}
                            // onClick={() => { join() }}
                            onClick={handleClick}
                            id={idMenu}
                          >
                            <MoreVertIcon />
                          </Button>
                          <Popover
                            id={idMenu}
                            open={openMenu}
                            anchorEl={anchorEl}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            style={{ padding: "10px", borderRadius: "10px" }}
                            className={classes.menu}
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                padding: "10px",
                                width: "300px",
                              }}
                            >
                              <Typography
                                className={classes.typographyMenu}
                                onClick={() => {
                                  router.history.push({
                                    pathname: `/shareGourp/${groupData.slug}`,
                                    search: "",
                                    state: {
                                      objGroup: groupData,
                                    },
                                  });
                                }}
                              >
                                Share
                              </Typography>
                              <Divider />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography className={classes.typographyMenu}>
                                  Change to private
                                </Typography>
                                <Switch
                                  checked={
                                    groupData.group_type == "private"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    handelStatusChange(
                                      groupData.slug,
                                      groupData.group_type
                                    );
                                  }}
                                  color="primary"
                                />
                              </div>
                              {/* <Divider />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  color: "red",
                                }}
                              >
                                <Typography className={classes.typographyMenu}>
                                  Delete Group
                                </Typography>
                              </div> */}
                            </div>
                          </Popover>
                        </div>
                      </div>
                      <hr className={classes.formHr} />

                      {isDescriptionEdit ? (
                        <div>
                          <TextField
                            className={classes.searchInput}
                            disableUnderline
                            fullWidth
                            multiline
                            size="small"
                            value={isDescriptionEdit || ""}
                            onChange={(e) =>
                              setIsDescriptionEdit(e.target.value)
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CheckIcon
                                    onClick={() => {
                                      onEdit(
                                        "group_description",
                                        isDescriptionEdit
                                      );
                                    }}
                                    className={classes.groupNameEditIcon}
                                  />
                                </InputAdornment>
                              ),
                              // inputComponent: TextMaskCustom,
                            }}
                          />
                        </div>
                      ) : (
                        <div className={classes.groupNameRoot}>
                          <div className={classes.groupDescription}>
                            {groupDescription}
                          </div>
                          <div
                            className={`${classes.groupNameEditIconRoot} hide`}
                            onClick={() => {
                              setIsDescriptionEdit(groupDescription);
                            }}
                          >
                            <EditIcon className={classes.groupNameEditIcon} />
                          </div>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xl={12}
                    xs={12}
                    className={classes.memberRoot}
                  >
                    <h5
                      className={classes.headingPage}
                      style={{ padding: "10px 30px" }}
                    >
                      {" "}
                      Members : {userList.length} Members{" "}
                    </h5>
                    <Box style={{ padding: "0px 40px" }}>
                      <AntTabs
                        value={value}
                        className={classes.tabHade}
                        onChange={handleChange}
                        aria-label="ant example"
                      // style={{ padding: "0px 40px" }}
                      >
                        <AntTab label="Members" />
                        <AntTab label="Requested" />
                        {/* {groupData.allRequest && groupData.allRequest.length && <AntTab label="Requested" />} */}
                      </AntTabs>
                    </Box>

                    <TabPanel value={value} index={0} dir={theme.direction}>
                      <Grid container spacing={2} style={{ padding: "20px 40px" }}>
                        {userList &&
                          userList.map((obj) => {
                            return (
                              <Grid
                                item
                                xs={3}
                                sm={2}
                                md={2}
                                lg={1}
                                style={{
                                  cursor: "pointer",
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    onClick={() => {
                                      if (obj.slug != groupData.slug) {
                                        router.history.push(
                                          `/your-matches/view/${obj.slug}`
                                        );
                                      }
                                    }}
                                    src={
                                      obj.profile_picture
                                        ? obj.profile_picture
                                        : "/images/male_defualt.svg"
                                    }
                                    alt="obj.photo"
                                    style={{
                                      borderRadius: "50%",
                                      width: "60px",
                                      height: "60px",
                                      objectFit: "cover",
                                    }}
                                  />
                                  {obj.is_group_admin && (
                                    <div className={classes.adminChip}>
                                      Admin
                                    </div>
                                  )}
                                  {!obj.is_group_admin === true && (
                                    <RemoveCircleRoundedIcon
                                      // onClick={(e) => {
                                      //   onClickRemoveUser(e, obj.user_id);
                                      // }}
                                      onClick={() => onOpenSetRetack(obj)}
                                      className={classes.removeButton}
                                    />
                                  )}
                                </div>
                                {obj.slug === groupData.slug ? (
                                  <div
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "center",
                                    }}
                                  >
                                    You
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      fontSize: "10px",
                                      textAlign: "center",
                                      overflow: "hidden",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  >
                                    {obj.first_name} {obj.last_name}
                                  </div>
                                )}
                              </Grid>
                            );
                          })}
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                      <Grid container spacing={2} style={{ padding: "20px 40px" }}>
                        {groupData.allRequest && groupData.allRequest.length ? (
                          groupData.allRequest.map((obj) => {
                            return (
                              <Grid item xs={12}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{ display: "flex" }}
                                    onClick={() => {
                                      router.history.push(
                                        `/your-matches/view/${obj.slug}`
                                      );
                                    }}
                                  >
                                    <div>
                                      <img
                                        src={
                                          obj.profile_picture
                                            ? obj.profile_picture
                                            : "/images/male_defualt.svg"
                                        }
                                        alt="obj.photo"
                                        style={{
                                          borderRadius: "50%",
                                          width: "60px",
                                          height: "60px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: "10px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <div style={{ fontSize: "12px" }}>
                                        {obj.first_name} {obj.last_name}
                                      </div>
                                      <div style={{ fontSize: "12px" }}>
                                        {obj.createdAt}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      className={classes.acceptButton}
                                      color="primary"
                                      onClick={() => {
                                        onConfirm(obj.from_id, "confirm");
                                      }}
                                      disabled={confirmLoading}
                                    >
                                      Confirm
                                      {confirmLoading && (
                                        <>
                                          &nbsp;
                                          <CircularProgress size={10} />
                                        </>
                                      )}
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button
                                      variant="outlined"
                                      className={classes.deleteButton}
                                      onClick={() => {
                                        onConfirm(obj.from_id, "delete");
                                      }}
                                      disabled={deleteLoading}
                                    >
                                      Delete
                                      {deleteLoading && (
                                        <>
                                          &nbsp;
                                          <CircularProgress size={10} />
                                        </>
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </Grid>
                            );
                          })
                        ) : (
                          <div style={{ textAlign: "center", width: "100%" }}>
                            <Typography
                              variant="caption"
                              component="div"
                              color="textSecondary"
                              style={{
                                fontSize: "12px",
                                paddingTop: "5px",
                                textAlign: "center",
                              }}
                            >
                              No request found
                            </Typography>
                          </div>
                        )}
                      </Grid>
                    </TabPanel>
                    <Divider style={{ margin: "0px 40px" }} />
                    <h5
                      className={classes.headingPage}
                      style={{ padding: "10px 30px" }}
                    >
                      {" "}
                      Remain: {groupData.remainteammate.length} Members{" "}
                    </h5>
                    <Grid container spacing={2} style={{ padding: "0px 30px" }}>
                      {groupData.remainteammate &&
                        groupData.remainteammate.length ? (
                        groupData.remainteammate.map((obj) => {
                          return (
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              md={2}
                              lg={1}
                              style={{
                                cursor: "pointer",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={
                                    obj.profile_picture
                                      ? obj.profile_picture
                                      : "/images/male_defualt.svg"
                                  }
                                  alt="obj.photo"
                                  style={{
                                    borderRadius: "50%",
                                    width: "60px",
                                    height: "60px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  fontSize: "10px",
                                  textAlign: "center",
                                  overflow: "hidden",
                                  width: "100%",
                                  height: "30px",
                                }}
                              >
                                {obj.value}
                              </div>
                            </Grid>
                          );
                        })
                      ) : (
                        <div style={{ textAlign: "center", width: "100%" }}>
                          <Typography
                            variant="caption"
                            component="div"
                            color="textSecondary"
                            style={{
                              fontSize: "12px",
                              paddingTop: "5px",
                              textAlign: "center",
                              paddingBottom: "20px",
                            }}
                          >
                            No remain found
                          </Typography>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
        <RemoveTeamUser
          open={open}
          removeData={removeData}
          onClose={handleRetackClose}
          onClickRemoveUser={onClickRemoveUser}
          setOpen={setOpen}
          onRemoveUserLoading={onRemoveUserLoading}
        />
      </div>
    </>
  );
};

const SkeletonLoading = () => {
  return (
    <Grid item xs={12} md={12} style={{ marginLeft: "10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rect" height={500} style={{ margin: "5px" }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rect" height={250} style={{ margin: "5px" }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GroupDetailDisplay;
