import React from "react";
import Billing from "./component/Billing";

const BillingHistory = () => {
  return (
    <>
      <Billing />
    </>
  );
};

export default BillingHistory;