import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  Typography,
  IconButton,
  Button,
  Box,
  TextField,
  Checkbox,
  Grid,
  Snackbar,
  CircularProgress,
  InputAdornment,
  useMediaQuery,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import validate from "validate.js";
import firebase from "firebase/app";
import MuiAlert from "@material-ui/lab/Alert";
import useRouter from "utils/useRouter";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import Api from "../../Helper/ApiHandler";
import { Link, Link as RouterLink } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import OtpInput from "react-otp-input";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import SignupDetailForm from "./SignupDetailForm";
import SelecteUniversityForm from "./SelecteUniversityForm";
import PersonalDetailForm from "./PersonalDetailForm";
// import SignupDetailForm from "./SignupDetailForm";
import AppDataContext from "Helper/AppDataContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const lstGender = [
  { id: "male", value: "Male" },
  { id: "female", value: "Female" },
  { id: "intersex", value: "Intersex" },
  { id: "trans", value: "Trans" },
  { id: "noPreference", value: "Other" },
];
const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: { maximum: 254 },
  },
};

const schemaPhone = {
  phone: {
    presence: { allowEmpty: false, message: "is required" },
    length: { minimum: 10 },
  },
  countryCode: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const schemaSignupDetail = {
  first_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  last_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  g: {
    presence: { allowEmpty: false, message: "is required" },
  },
  zodiac: {
    presence: { allowEmpty: false, message: "is required" },
  },
  location: {
    presence: { allowEmpty: false, message: "is required" },
  },
  date_of_birth: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {},
  signUpFormBg: {
    // background:
    //   "linear-gradient(54.75deg, #22346E 0%, #FE4B6B 100%, rgba(0, 113, 206, 0) 70.7%)",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    width: "100%",
    // height: "530px",
    height: "600px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      // height: "250px",
      display: "none",
    },
  },
  dotImageTop: {
    position: "absolute",
    top: "10px",
    left: "275px",
  },
  dotImageBottom: {
    position: "absolute",
    bottom: "10px",
    left: "10px",
  },
  headingSignUp: {
    margin: "0!important",
    color: "white",
  },
  freeMessageUp: {
    textAlign: "center",
    marginBottom: "0px !important",
    fontWeight: 600,
  },
  singUpMessage: {
    color: "#FE4B6B",
    textAlign: "center",
    margin: "10px !important",
    fontWeight: 400,
  },
  gruopLinkButton: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    width: "90%",
  },
  textFiledBox: {
    width: "90%",
    margin: "15px auto",
  },
  forgotText: {
    width: "95%",
    color: "#FE4B6B",
    textAlign: "right",
  },
  checkboxArea: {
    alignItems: "center",
    width: "90%",
    margin: "20px auto",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
    display: "flex",
    "& .MuiFormControlLabel-root": {
      marginRight: "0px",
    },
  },
  startedButtonBox: {
    textAlign: "center",
  },
  startedButton: {
    ".MuiButton-contained.Mui-disabled": {
      color: "rgb(255 255 255) !important",
      boxShadow: "none !important",
      backgroundColor: "rgba(0, 0, 0, 0.12) !important",
    },
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)", // Change this to your desired color
    },
  },
  selectOptionIn: {
    //fontFamily: "Gilroy !important",
    "& .MuiOutlinedInput-notchedOutline": {
      // borderWidth: 0
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
      // fontSize: "12px"
    },
    backgroundColor: "#e9edf0",
  },
  startedButton: {
    width: "246px",
    height: "35px",
    // height: "50px",
    borderRadius: "10px",
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    fontSize: "18px",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    fontWeight: "400",
  },
  memberMessage: {
    textAlign: "center",
    // margin: "20px !important",
    fontWeight: 400,
  },
  socialIcon: {
    position: "absolute",
    right: "0",
    top: 0,
    color: "black",
  },
  backIconRoot: {
    position: "absolute",
    left: "0",
    top: 0,
    color: "black",
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      width: "900px",
      // height: "530px",
      height: "600px",
      overflow: "hidden",
    },
    borderRadius: "5px",
  },
  asterisk: {
    color: "red",
    "&$error": {
      color: "red",
    },
  },
  termsRedirect: {
    cursor: "pointer",
  },
  buttonSignIn: {
    width: "100%",
    margin: "10px auto",
    textTransform: "capitalize",
    fontSize: "15px",
    fontWeight: "500",
  },
  googleSelecte: {
    width: "90%",
    margin: "15px auto",
    "& .css-yk16xz-control": {
      // borderRadius: "10px",
      // padding: "8px"
    },
    "& .css-1pahdxg-control": {
      // borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      // padding: "8px"
    },
    "& .css-1pahdxg-control:hover": {
      // borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      // padding: "8px"
    },
    "& .css-1uccc91-singleValue": {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
    },
    "& .css-1wa3eu0-placeholder": {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
    },
    "& div": {
      fontFamily: "'Poppins', sans-serif",
      backgroundColor: "white",
      zIndex: 11,
    },
  },
  inputePhone: {
    "& .react-tel-input .form-control": {
      width: "100%",
      padding: "8.5px 14px 8.5px 60px",
    },
  },
}));

const SignupModel = ({ setOpenLogin, open, setOpen }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");

  const { appContext, setAppContext } = useContext(AppDataContext);
  const [loading, setLoading] = useState(false);
  const [loadingSignup, setLoadingSignup] = useState(false);
  const [signupType, setSignupType] = useState("notADefine");
  const [disabled, setDisabled] = useState(false);
  const [disabledLogin, setDisabledLogin] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [otpEmail, setOtpEmail] = React.useState(false);
  const [verifyEmail, setVerifyEmail] = React.useState(false);
  const [otpSendEmail, setOtpSendEmail] = React.useState(false);
  const [otpPhone, setOtpPhone] = React.useState(false);
  const [otpSendPhone, setOtpSendPhone] = React.useState(false);
  const [verifyPhone, setVerifyPhone] = React.useState(false);
  const [otpLoader, setOtpLoader] = React.useState(false);
  const [counter, setCounter] = React.useState(null);
  const [access, setAccess] = React.useState(true);
  const [lstZodiac, setLstZodiac] = React.useState([]);
  const [phone, setPhone] = useState("");
  // const [isSignupDetail, setIsSignupDetail] = React.useState(false);

  const [dob, setDob] = React.useState();
  const router = useRouter();
  useEffect(() => {
    getZodiac();
  }, []);

  const handleRedirectPolicy = () => {
    setOpen(false);
    router.history.push("/privacy-policy");
  };
  const getZodiac = async () => {
    // Api Call For Fetch Zodiac List
    api
      .get(`zodiaclist`)
      .then((result) => {
        if (result.success === "true") {
          setLstZodiac(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const handleClickOpenSignUp = () => {
    setOpen(true);
  };

  const handleCloseSignUp = () => {
    // setOpen(false);
    closeHandlerModel();
  };

  const closeHandlerModel = () => {
    logOutHandler();
    setOpen(false);
    setSignupType("notADefine");
    setCounter(null);
    setOtpSendEmail(false);
    setOtpSendPhone(false);
    setFormState({
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    });
  };

  const [formStatephone, setFormStatephone] = useState({
    values: {
      phone: "",
      countryCode: "",
    },
  });
  const logOutHandler = async () => {
    let device_token = await AsyncLocalStorage.getItem("device_token");
    localStorage.clear();
    AsyncLocalStorage.setItem("device_token", device_token ? device_token : "");
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });

  // React.useEffect(() => {
  //   counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  // }, [counter]);

  React.useEffect(() => {
    if (signupType === "notADefine") {
      setCounter(null);
    } else {
      if (counter) {
        counter &&
          counter > 0 &&
          setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        // setCounter(null)
        return;
      }
    }
  }, [counter]);

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    let tmp =
      schemaSignupDetail === "signupDetail"
        ? schemaSignupDetail
        : signupType === "phone"
        ? schemaPhone
        : schema;
    // schemaPhone
    const errors = validate(formState.values, tmp);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values, schemaSignupDetail]);
  // const [disabledLogin, setDisabledLogin] = useState(false);

  const handleFieldChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const handleBack = () => {
    setSignupType("notADefine");
    setCounter(null);
    setOtpSendEmail(false);
    setOtpSendPhone(false);
    setFormState({
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    });
  };
  const dob_Change = (date) => {
    let changeDate = convertDate(date);
    if (moment().subtract(18, "years") >= moment(date)) {
      setDob(date);
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          date_of_birth: changeDate,
        },
      }));
    } else {
      setDob("");
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          date_of_birth: "",
        },
      }));
    }
  };
  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    // return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('/')
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabledLogin(true);
    setLoading(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(
        formState.values.email,
        formState.values.password
      )
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        if (formState.isValid === true) {
          api
            .post("user/signup", {
              data: {
                ...formState.values,
                firebase_uid: user.uid,
                gender: formState.values.g.id,
              },
              skipAuth: true,
            })
            .then((result) => {
              if (result.success === "true") {
                setSessionMessage({
                  message: result.message,
                  type: "success",
                  open: true,
                });
                setFormState({
                  isValid: false,
                  values: {},
                  touched: {},
                  errors: {},
                });
                let userName =
                  result.data.first_name + " " + result.data.last_name;
                AsyncLocalStorage.setItem("authToken", result.data.accessToken);
                AsyncLocalStorage.setItem("senderId", result.data.firebase_uid);
                AsyncLocalStorage.setItem("userName", userName);
                AsyncLocalStorage.setItem("userProfile", "null");
                AsyncLocalStorage.setItem("from_id", result.data.user_id);
                AsyncLocalStorage.setItem("university", result.data.university);
                AsyncLocalStorage.setItem(
                  "puniversity_lat",
                  result.data.puniversity_lat
                );
                AsyncLocalStorage.setItem(
                  "puniversity_long",
                  result.data.puniversity_long
                );
                AsyncLocalStorage.setItem("from_slug", result.data.slug);
                AsyncLocalStorage.setItem("currentModule", "DormMates");
                // router.history.push("/select-university")
                window.location.replace("/select-university");
                // setOpenLogin()
                setOpen(false);
              } else {
                setSessionMessage({
                  message: result.message,
                  type: "error",
                  open: true,
                });
              }
              setDisabledLogin(false);
              setLoading(false);
            })
            .catch((err) => {
              setDisabledLogin(false);
              setLoading(false);
              setSessionMessage({
                message: err.message,
                type: "error",
                open: true,
              });
            });
        }
        // ...
      })
      .catch((error) => {
        setDisabledLogin(false);
        setLoading(false);
        var errorCode = error.code;
        var errorMessage = error.message;
        // ..
      });
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const setLocation = (value) => {
    if (value) {
      geocodeByAddress(value.value.description)
        .then((results) => {
          console.error("valuevaluevalue", results);
          console.error("valuevaluevalue", results[0].geometry.location.lat());
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              location: value,
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng(),
            },
          }));
        })
        .catch((error) => console.error("valuevaluevalue", error));
    } else {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          location: "",
          latitude: "",
          longitude: "",
        },
      }));
    }
  };

  useEffect(() => {
    (async () => {
      let device_token = await AsyncLocalStorage.getItem("device_token");

      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          ["device_token"]: device_token,
        },
      }));
    })();
  }, []);

  const sendOtp = async (event, type, value) => {
    event.preventDefault();
    // dispatch(login());
    let tmpData = {};
    if (type === "email") {
      tmpData = {
        type: type,
        value: value,
      };
    } else {
      tmpData = {
        type: type,
        value: value,
        phonecode: formState.values.countryCode,
      };
    }
    setDisabled(true);
    setOtpLoader(true);
    api
      .post("user/sendcode", {
        data: tmpData,
        skipAuth: true,
      })
      .then((result) => {
        if (result.success === "true") {
          setCounter(60);
          if (type === "email") {
            setOtpSendEmail(true);
          } else {
            setOtpSendPhone(true);
          }
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          // setLoginOpen(false);
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setDisabled(false);
        setOtpLoader(false);
      })
      .catch((err) => {
        console.log(err, "error catch");
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        setDisabled(false);
        // setLoading(false)
        setOtpLoader(false);
      });
  };

  const verifyOtp = async (event, type, value, code) => {
    event.preventDefault();
    // dispatch(login());
    setLoading(true);
    setDisabled(true);
    api
      .post("user/verifycode", {
        data: {
          value: value,
          code: code,
          is_verify: false,
        },
        skipAuth: true,
      })
      .then((result) => {
        if (result.success === "true") {
          if (result.data.is_registered) {
            let userName = result.data.first_name + " " + result.data.last_name;

            AsyncLocalStorage.setItem("authToken", result.data.accessToken);
            AsyncLocalStorage.setItem("senderId", result.data.firebase_uid);
            AsyncLocalStorage.setItem("userName", userName);
            AsyncLocalStorage.setItem(
              "userProfile",
              result.data.profile_picture
            );
            AsyncLocalStorage.setItem("from_id", result.data.user_id);
            AsyncLocalStorage.setItem("university", result.data.university);
            AsyncLocalStorage.setItem(
              "puniversity_lat",
              result.data.puniversity_lat
            );
            AsyncLocalStorage.setItem(
              "puniversity_long",
              result.data.puniversity_long
            );
            AsyncLocalStorage.setItem("from_slug", result.data.slug);
            AsyncLocalStorage.setItem("currentModule", result.data.module_name);
            AsyncLocalStorage.setItem("tabFavValue", 0);
            AsyncLocalStorage.setItem("tabValue", 0);
            AsyncLocalStorage.setItem(
              "signupDeatils",
              result.data.is_registered
            );
            // setAppContext({ ...appContext, currentModule: result.data.module_name })
          } else {
            AsyncLocalStorage.setItem("email", result.data.value);
            AsyncLocalStorage.setItem("authToken", result.data.accessToken);
            AsyncLocalStorage.setItem("senderId", result.data.firebase_uid);
            AsyncLocalStorage.setItem("userName", "");
            AsyncLocalStorage.setItem("userProfile", "");
            AsyncLocalStorage.setItem("from_id", "");
            AsyncLocalStorage.setItem("university", "");
            AsyncLocalStorage.setItem("puniversity_lat", 0);
            AsyncLocalStorage.setItem("puniversity_long", 0);
            AsyncLocalStorage.setItem("from_slug", "");
            AsyncLocalStorage.setItem("currentModule", "Dormmates");
            AsyncLocalStorage.setItem("tabFavValue", 0);
            AsyncLocalStorage.setItem("tabValue", 0);
            AsyncLocalStorage.setItem(
              "signupDeatils",
              result.data.is_registered
            );
            // setAppContext({ ...appContext, currentModule: "Dormmates" })
          }

          if (!result.data.is_registered) {
            // window.location.replace("/signup-details")
            // setIsSignupDetail(true)
            setSignupType("signupDetail");
          } else if (!result.data.has_university) {
            setSignupType("selectUniversity");
            // window.location.replace("/select-university")
          } else if (!result.data.has_personalInfo) {
            // window.location.replace("/personal-detail")
            setSignupType("personalDetail");
          } else if (result.data.quiz) {
            window.location.replace("/your-matches");
            // router.history.push("/your-matches");
          } else {
            window.location.replace("/matching-quiz");
            // router.history.push("/matching-quiz");
          }

          // if (!result.data.has_university) {
          //   router.history.push("/select-university")
          // }
          // else if (!result.data.is_registered) {
          //   router.history.push("/personal-details")
          // }
          // else if (result.data.quiz) {
          //   router.history.push("/your-matches")
          // } else {
          //   router.history.push("/matching-quiz")
          // }

          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          // setLoginOpen(false);
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setDisabled(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error catch");
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        setDisabled(false);
        setLoading(false);
      });
  };
  const handleRedirectTerms = () => {
    setOpen(false);
    router.history.push("/terms-of-use");
  };

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  var provider = new firebase.auth.GoogleAuthProvider();

  const signUpwithGoogle = async (event) => {
    event.preventDefault();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;
        setLoadingSignup(true);

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        let device_token = await AsyncLocalStorage.getItem("device_token");
        let sendObj = {
          email: result.additionalUserInfo.profile.email,
          device_token: device_token ? device_token : null,
          type: "Web",
        };

        // if (formState.isValid === true) {
        setDisabledLogin(true);
        setLoading(true);

        api
          .post("user/verifyemail", {
            data: sendObj,
            skipAuth: true,
          })
          .then((result) => {
            if (result.success === "true") {
              setOpenLogin(false);
              if (result.data.is_registered) {
                let userName =
                  result.data.first_name + " " + result.data.last_name;
                AsyncLocalStorage.setItem("authToken", result.data.accessToken);
                AsyncLocalStorage.setItem("senderId", result.data.firebase_uid);
                AsyncLocalStorage.setItem("userName", userName);
                AsyncLocalStorage.setItem(
                  "userProfile",
                  result.data.profile_picture
                );
                AsyncLocalStorage.setItem("from_id", result.data.user_id);
                AsyncLocalStorage.setItem("university", result.data.university);
                AsyncLocalStorage.setItem(
                  "puniversity_lat",
                  result.data.puniversity_lat
                );
                AsyncLocalStorage.setItem(
                  "puniversity_long",
                  result.data.puniversity_long
                );
                AsyncLocalStorage.setItem("from_slug", result.data.slug);
                AsyncLocalStorage.setItem(
                  "currentModule",
                  result.data.module_name
                );
                AsyncLocalStorage.setItem("tabFavValue", 0);
                AsyncLocalStorage.setItem("tabValue", 0);
                setAppContext({
                  ...appContext,
                  currentModule: result.data.module_name,
                });
              } else {
                AsyncLocalStorage.setItem("email", result.data.value);
                AsyncLocalStorage.setItem("authToken", result.data.accessToken);
                AsyncLocalStorage.setItem("senderId", result.data.firebase_uid);
                AsyncLocalStorage.setItem("userName", "");
                AsyncLocalStorage.setItem("userProfile", "");
                AsyncLocalStorage.setItem("from_id", "");
                AsyncLocalStorage.setItem("university", "");
                AsyncLocalStorage.setItem("puniversity_lat", 0);
                AsyncLocalStorage.setItem("puniversity_long", 0);
                AsyncLocalStorage.setItem("from_slug", "");
                AsyncLocalStorage.setItem("currentModule", "Dormmates");
                AsyncLocalStorage.setItem("tabFavValue", 0);
                AsyncLocalStorage.setItem("tabValue", 0);
                AsyncLocalStorage.setItem(
                  "signupDeatils",
                  result.data.is_registered
                );
                setAppContext({ ...appContext, currentModule: "Dormmates" });
              }

              if (!result.data.is_registered) {
                // window.location.replace("/signup-details")
                // setIsSignupDetail(true)
                setSignupType("signupDetail");
              } else if (!result.data.has_university) {
                // window.location.replace("/select-university")
                setSignupType("selectUniversity");
              } else if (!result.data.has_personalInfo) {
                // window.location.replace("/personal-detail")
                setSignupType("personalDetail");
              } else if (result.data.quiz) {
                // window.location.replace("/your-matches");
                router.history.push("/your-matches");
              } else {
                // window.location.replace("/matching-quiz");
                router.history.push("/matching-quiz");
              }

              // if (result.data.quiz)
              //   // router.history.push("/your-matches")
              //   window.location.replace("/your-matches");
              // else
              //   // router.history.push("/matching-quiz")
              //   window.location.replace("/matching-quiz");
            } else {
              setOpenLogin(false);
            }
            setDisabledLogin(false);
            setLoading(false);
            setLoadingSignup(false);
          })
          .catch((err) => {
            setDisabledLogin(false);
            setLoading(false);
            setLoadingSignup(false);
          });
        // }
      })
      .catch((error) => {});
  };

  const handleAutocompletChange = (event, value, name) => {
    if (value !== null) {
      // handleChangeAuto(event, value);
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          [name]: value,
        },
      }));
    }
  };

  const SignupdetailSubmit = async (event) => {
    event.preventDefault();
    setDisabledLogin(true);
    setLoading(true);
    let device_token = await AsyncLocalStorage.getItem("device_token");

    // firebase.auth().createUserWithEmailAndPassword(formState.values.email, formState.values.password)
    //   .then((userCredential) => {
    // Signed in
    // var user = userCredential.user;
    if (formState.isValid === true) {
      api
        .post("user/singupapp", {
          data: {
            ...formState.values,
            gender: formState.values.g.id,
            device_token,
            location: formState.values.location.label,
            email: formState.values.email ? formState.values.email : "",
            phone: "",
            type: "web",
          },
          skipAuth: true,
        })
        .then((result) => {
          if (result.success === "true") {
            setSessionMessage({
              message: result.message,
              type: "success",
              open: true,
            });
            setFormState({
              isValid: false,
              values: {},
              touched: {},
              errors: {},
            });
            console.log("resultefjeifjeihfei", result);
            let userName = result.data.first_name + " " + result.data.last_name;
            AsyncLocalStorage.setItem("authToken", result.data.accessToken);
            AsyncLocalStorage.setItem("senderId", result.data.firebase_uid);
            AsyncLocalStorage.setItem("userName", userName);
            AsyncLocalStorage.setItem("userProfile", "null");
            AsyncLocalStorage.setItem("from_id", result.data.user_id);
            AsyncLocalStorage.setItem("university", result.data.university);
            AsyncLocalStorage.setItem(
              "puniversity_lat",
              result.data.puniversity_lat
            );
            AsyncLocalStorage.setItem(
              "puniversity_long",
              result.data.puniversity_long
            );
            AsyncLocalStorage.setItem("from_slug", result.data.slug);
            AsyncLocalStorage.setItem("currentModule", "Dormmates");
            // router.history.push("/select-university")
            window.location.replace("/select-university");
            // setOpenLogin()
            // setOpen(false)
          } else {
            setSessionMessage({
              message: result.message,
              type: "error",
              open: true,
            });
          }
          setDisabledLogin(false);
          setLoading(false);
        })
        .catch((err) => {
          setDisabledLogin(false);
          setLoading(false);
          setSessionMessage({
            message: err.message,
            type: "error",
            open: true,
          });
        });
    }
    // ...
    // })
    // .catch((error) => {
    //   setDisabledLogin(false)
    //   setLoading(false)
    //   var errorCode = error.code;
    //   var errorMessage = error.message;
    //   // ..
    // });
  };

  const handlePhoneChange = (value, data) => {
    setFormStatephone((prevState) => ({
      ...prevState,

      values: {
        ...prevState.values,
        phone: value,
        countryCode: data.dialCode,
      },
    }));
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleCloseSignUp}
        aria-labelledby="max-width-dialog-title"
      >
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        <Grid container className={classes.container}>
          <Grid item xl={6} lg={6} xs={12}>
            <div className={classes.signUpFormBg}>
              <h4 className={classes.headingSignUp}>
                Welcome to Match Up Mates
              </h4>

              <img src="/images/matchUp/signUpImg.png" alt={"Alt Image"} />
              <img
                className={classes.dotImageTop}
                src="/images/matchUp/dotsLogin.png"
                alt={"Alt Image"}
              />
              <img
                className={classes.dotImageBottom}
                src="/images/matchUp/dotsLogin.png"
                alt={"Alt Image"}
              />
            </div>
          </Grid>
          <Grid item xl={6} lg={6} xs={12} style={{ position: "relative" }}>
            {loadingSignup ? (
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                {/* loadingSignup */}
                <CircularProgress size={50} />
              </div>
            ) : (
              <>
                <div style={{ display: "flex", position: "relative" }}>
                  {signupType !== "notADefine" && (
                    <IconButton
                      onClick={handleBack}
                      className={classes.backIconRoot}
                    >
                      <ArrowBackIcon className={classes.backIcon} />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={closeHandlerModel}
                    className={classes.socialIcon}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  {signupType !== "personalDetail" && (
                    <>
                      <h4 className={classes.freeMessageUp}>
                        {" "}
                        Start For Free!
                      </h4>
                      <h5 className={classes.singUpMessage}>
                        Signup now and find your soulmate today!
                      </h5>
                    </>
                  )}

                  {signupType === "notADefine" ? (
                    <>
                      <Box className={classes.gruopLinkButton}>
                        <Button
                          variant="outlined"
                          className={classes.buttonSignIn}
                          startIcon={
                            <img
                              src="/images/matchUp/google.png"
                              alt={"Alt Image"}
                            />
                          }
                          onClick={(event) => {
                            signUpwithGoogle(event);
                          }}
                        >
                          Continue with Google
                        </Button>
                        <Button
                          variant="outlined"
                          className={classes.buttonSignIn}
                          startIcon={
                            <img
                              src="/images/matchUp/email.svg"
                              alt={"Alt Image"}
                            />
                          }
                          onClick={() => {
                            setSignupType("email");
                          }}
                        >
                          Continue with Email
                        </Button>
                        <Button
                          variant="outlined"
                          className={classes.buttonSignIn}
                          startIcon={
                            <img
                              src="/images/matchUp/phone.svg"
                              alt={"Alt Image"}
                            />
                          }
                          onClick={() => {
                            setSignupType("phone");
                          }}
                        >
                          Continue with Phone
                        </Button>
                      </Box>

                      <div
                        style={{
                          marginBottom: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <h6
                          style={{
                            color: "black",
                            textAlign: "center",
                            wordBreak: "break-all",
                            width: "80%",
                          }}
                        >
                          <span style={{ fontWeight: "400" }}>
                            By signing in you are agreeing to our
                          </span>
                          {/* {"  "} */}
                          <span
                            className={classes.termsRedirect}
                            // onClick={() => handleRedirectPolicy()}
                          >
                            <u style={{ fontWeight: "900" }}>
                              <a
                                target="black"
                                href="/privacy-policy"
                                style={{ color: "black" }}
                              >
                                Privacy policy
                              </a>
                            </u>
                          </span>{" "}
                          <span style={{ fontWeight: "400" }}>and</span>{" "}
                          <span
                            className={classes.termsRedirect}
                            // onClick={() => handleRedirectTerms()}
                          >
                            <u style={{ fontWeight: "900" }}>
                              <a
                                target="black"
                                href="/terms-of-use"
                                style={{ color: "black" }}
                              >
                                Terms of Service
                              </a>
                            </u>
                          </span>
                        </h6>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <email> */}
                      {signupType === "email" && (
                        <div style={{ width: "90%", marginTop: "30px" }}>
                          <Box my={1} className={classes.textFiledBox}>
                            <TextField
                              id="outlined-basic"
                              label="Email"
                              fullWidth
                              size="small"
                              variant="outlined"
                              onChange={!verifyEmail && handleFieldChange}
                              name="email"
                              InputLabelProps={{
                                classes: {
                                  asterisk: classes.asterisk,
                                },
                                shrink: true,
                              }}
                              required
                              error={hasError("email")}
                              helperText={
                                hasError("email")
                                  ? formState.errors.email[0]
                                  : null
                              }
                            />
                          </Box>
                          {otpSendEmail && !verifyEmail ? (
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <OtpInput
                                value={otpEmail || ""}
                                name="otp"
                                onChange={(otp) =>
                                  !verifyEmail && setOtpEmail(otp)
                                }
                                numInputs={6}
                                className={classes.otp}
                                separator={<span>&nbsp;&nbsp; </span>}
                                inputStyle={{
                                  width: isMobile ? "33px" : "50px",
                                  fontSize: "20px",
                                  height: "40px",
                                  borderRadius: "5px",
                                  border: "1px solid rgba(0,0,0,0.15)",
                                }}
                                // inputStyle={{
                                //   width: "40px",
                                //   fontSize: "20px",
                                //   height: "40px",
                                //   borderRadius: "5px",
                                //   border: "1px solid rgba(0,0,0,0.15)"
                                // }}
                                focusStyle={{
                                  border: "2px solid #2196f3",
                                  outline: "none",
                                }}
                              />
                            </Box>
                          ) : (
                            ""
                          )}
                          {counter > 0 ? (
                            <Box
                              style={{
                                textAlign: "left",
                                marginLeft: "20px",
                                marginTop: "5px",
                                width: "100%",
                              }}
                            >
                              <span>Resend Otp in 00:{counter}</span>
                            </Box>
                          ) : (
                            ""
                          )}
                          {counter === 0 ? (
                            <Box
                              style={{
                                textAlign: "right",
                                paddingRight: "20px",
                                marginTop: "5px",
                                cursor: "pointer",
                                width: "100%",
                              }}
                            >
                              {!otpLoader && (
                                <Link
                                  onClick={(event) => {
                                    sendOtp(
                                      event,
                                      "email",
                                      formState.values.email
                                    );
                                  }}
                                >
                                  Resend Otp
                                </Link>
                              )}
                              {otpLoader && (
                                <>
                                  &nbsp;
                                  <CircularProgress size={15} />
                                </>
                              )}
                            </Box>
                          ) : (
                            ""
                          )}
                          {counter !== 0 && !counter ? (
                            <Box my={3} className={classes.startedButtonBox}>
                              <Button
                                className={classes.startedButton}
                                variant="contained"
                                size="large"
                                style={{
                                  backgroundColor: disabled
                                    ? "blue"
                                    : "rgba(0, 0, 0, 0.12)",
                                  color: disabled ? "white" : "white",
                                  boxShadow: disabled ? "none" : "",
                                }}
                                disabled={formState.isValid ? disabled : true}
                                color="primary"
                                onClick={(event) => {
                                  sendOtp(
                                    event,
                                    "email",
                                    formState.values.email
                                  );
                                }}
                              >
                                Send otp
                                {otpLoader && (
                                  <>
                                    &nbsp;
                                    <CircularProgress
                                      size={20}
                                      style={{ color: "white" }}
                                    />
                                  </>
                                )}
                              </Button>
                            </Box>
                          ) : (
                            // <Box className={classes.forgotText}>
                            //   {!otpLoader && <span
                            //     style={{ cursor: "pointer", color: "#FE4B6B", objectFit: "cover" }}
                            //     onClick={(event) => { sendOtp(event, "email", formState.values.email) }}
                            //   >
                            //     <u>Send Otp?</u>
                            //   </span>}
                            //   {otpLoader && <>&nbsp;<CircularProgress size={15} /></>}
                            // </Box>
                            <Box my={3} className={classes.startedButtonBox}>
                              <Button
                                className={classes.startedButton}
                                variant="contained"
                                disabled={
                                  disabled
                                    ? disabled
                                    : otpEmail.length === 6
                                    ? false
                                    : true
                                }
                                size="large"
                                color="primary"
                                onClick={(event) => {
                                  verifyOtp(
                                    event,
                                    "email",
                                    formState.values.email,
                                    otpEmail
                                  );
                                }}
                              >
                                Sign up
                                {loading && (
                                  <>
                                    &nbsp;
                                    <CircularProgress
                                      size={20}
                                      style={{ color: "white" }}
                                    />
                                  </>
                                )}
                              </Button>
                            </Box>
                          )}
                        </div>
                      )}

                      {/* mobile */}
                      {signupType === "phone" && (
                        <>
                          <Box
                            my={1}
                            className={classes.inputePhone}
                            width={"82%"}
                          >
                            <PhoneInput
                              countryCodeEditable={true}
                              value={formStatephone.values.phone}
                              onChange={handlePhoneChange}
                            />
                            {/* <TextField
                              id="outlined-basic"
                              label="Phone"
                              fullWidth
                              size="small"
                              variant="outlined"
                              onChange={!verifyPhone && handleFieldChange}
                              name="phone"
                              InputLabelProps={{
                                classes: {
                                  asterisk: classes.asterisk
                                },
                                shrink: true,
                              }}
                              required
                              error={hasError('phone')}
                              helperText={hasError('phone') ? formState.errors.phone[0] : null}
                            /> */}
                          </Box>
                          {otpSendPhone && !verifyPhone ? (
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <OtpInput
                                value={otpPhone || ""}
                                name="otp"
                                onChange={(otp) =>
                                  !verifyPhone && setOtpPhone(otp)
                                }
                                numInputs={6}
                                className={classes.otp}
                                separator={<span>&nbsp;&nbsp; </span>}
                                // inputStyle={{ width: "55px", fontSize: "20px", height: "40px", borderRadius: "5px", border: "1px solid rgba(0,0,0,0.15)" }}
                                inputStyle={{
                                  width: isMobile ? "33px" : "50px",
                                  fontSize: "20px",
                                  height: "40px",
                                  borderRadius: "5px",
                                  border: "1px solid rgba(0,0,0,0.15)",
                                }}
                                focusStyle={{
                                  border: "2px solid #2196f3",
                                  outline: "none",
                                }}
                              />
                            </Box>
                          ) : (
                            ""
                          )}
                          {counter > 0 ? (
                            <Box
                              style={{
                                textAlign: "left",
                                marginLeft: "70px",
                                marginTop: "5px",
                                width: "100%",
                              }}
                            >
                              <span>Resend Otp in 00:{counter}</span>
                            </Box>
                          ) : (
                            ""
                          )}
                          {counter === 0 ? (
                            <Box
                              style={{
                                textAlign: "right",
                                paddingRight: "40px",
                                marginTop: "5px",
                                cursor: "pointer",
                                width: "100%",
                              }}
                            >
                              {!otpLoader && (
                                <Link
                                  onClick={(event) => {
                                    sendOtp(
                                      event,
                                      "phone",
                                      formState.values.phone
                                    );
                                  }}
                                >
                                  Resend Otp
                                </Link>
                              )}
                              {otpLoader && (
                                <>
                                  &nbsp;
                                  <CircularProgress size={15} />
                                </>
                              )}
                            </Box>
                          ) : (
                            ""
                          )}
                          {counter !== 0 && !counter ? (
                            <Box my={3} className={classes.startedButtonBox}>
                              <button
                                className={classes.startedButton}
                                style={{
                                  backgroundColor: disabled
                                    ? "blue"
                                    : "rgba(0, 0, 0, 0.12)",
                                  color: disabled ? "white" : "white",
                                  boxShadow: disabled ? "none" : "",
                                }}
                                size="large"
                                disabled={formState.isValid ? disabled : true}
                                color="primary"
                                onClick={(event) => {
                                  sendOtp(
                                    event,
                                    "phone",
                                    formState.values.phone
                                  );
                                }}
                              >
                                Send otp
                                {otpLoader && (
                                  <>
                                    &nbsp;
                                    <CircularProgress
                                      size={20}
                                      style={{ color: "white" }}
                                    />
                                  </>
                                )}
                              </button>
                            </Box>
                          ) : (
                            // <Box className={classes.forgotText}>
                            //   {!otpLoader && <span
                            //     style={{ cursor: "pointer", color: "#FE4B6B", objectFit: "cover" }}
                            //     onClick={(event) => { sendOtp(event, "phone", formState.values.phone) }}
                            //   >
                            //     <u>Send Otp?</u>
                            //   </span>}
                            //   {otpLoader && <>&nbsp;<CircularProgress size={15} /></>}
                            // </Box>
                            <Box my={3} className={classes.startedButtonBox}>
                              <Button
                                className={classes.startedButton}
                                variant="contained"
                                disabled={
                                  disabled
                                    ? disabled
                                    : otpPhone.length === 6
                                    ? false
                                    : true
                                }
                                size="large"
                                color="primary"
                                onClick={(event) => {
                                  verifyOtp(
                                    event,
                                    "phone",
                                    formState.values.phone,
                                    otpPhone
                                  );
                                }}
                              >
                                Sign up
                                {loading && (
                                  <>
                                    &nbsp;
                                    <CircularProgress
                                      size={20}
                                      style={{ color: "white" }}
                                    />
                                  </>
                                )}
                              </Button>
                            </Box>
                          )}
                        </>
                      )}
                      {/* signup */}
                      {signupType === "signupDetail" && (
                        <>
                          {formState.values.email && (
                            <SignupDetailForm
                              email={formState.values.email}
                              setSignupType={setSignupType}
                            />
                          )}
                          {formState.values.phone && (
                            <SignupDetailForm
                              phone={formState.values.phone}
                              setSignupType={setSignupType}
                            />
                          )}
                        </>
                      )}

                      {/* selectUniversity */}
                      {signupType === "selectUniversity" && (
                        <SelecteUniversityForm setSignupType={setSignupType} />
                      )}

                      {/* personalDetail */}
                      {signupType === "personalDetail" && (
                        <PersonalDetailForm />
                      )}
                    </>
                  )}

                  <h6 className={classes.memberMessage}>
                    Already a member?
                    <span
                      style={{ color: "#FE4B6B", cursor: "pointer" }}
                      onClick={() => {
                        setOpenLogin();
                      }}
                    >
                      {" "}
                      <u>Sign in</u>
                    </span>
                  </h6>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default SignupModel;
