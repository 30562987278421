import React, { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import BlockUser from "components/BlockUser/BlockModel";
import MuiAlert from "@material-ui/lab/Alert";
import Switch from "@material-ui/core/Switch";
import { Skeleton } from "@material-ui/lab";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";

import Api from "../../../../Helper/ApiHandler";
import { useHistory } from "react-router-dom";
import DeleteAccountDialog from "components/DeleteAccount/DeleteAccount";
var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  bgGroupPage: {
    background: "#e9edf0",
    padding: "35px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      paddingBottom: "30px",
    },
    minHeight: "calc(100vh - 125px)",
  },
  groupHead: {
    background: "white",
    // padding: "3px",
    // borderRadius: "10px",
    marginLeft: "10px",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
  },
  headingText: {
    fontWeight: "600 !important",
    fontSize: "18px",
    margin: "30px 10px 10px 10px",
  },
  imgeUser: {
    width: "90px",
    height: "90px",
    borderRadius: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "65px",
      height: "65px",
    },
    objectFit: "cover",
  },
  textpeople: {
    margin: "0",
    marginBottom: "5px",
    color: "gray",
  },
  nameGroupPeople: {
    marginBottom: "5px",
    margin: "0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
    fontWeight: "500",
    width: "fit-content",
    cursor: "pointer",
  },
  removeButton: {
    textTransform: "capitalize",
    position: "relative",
    top: "50px",
    width: "100px",
    height: "25px",
    background: "#FFEDF0",
    [theme.breakpoints.down("xs")]: {
      top: "0px",
      height: "30px",
      right: "10px",
      display: "none",
    },
  },
  HrLine: {
    borderTop: "1px solid lightgray",
  },
  memberDiv: {
    padding: "10px 0",
  },
  boxListDetail: {
    marginTop: "10px",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  removeButtonSm: {
    position: "absolute",
    right: "50px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    cursor: "pointer",
  },
  cont: {
    marginLeft: "6px",
  },
}));

const BlockList = (props) => {
  const router = useHistory();

  const classes = useStyles();
  const [lstDormmate, setLstDormMates] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [visiblity, setVisiblity] = React.useState(false);
  const [deleteAccount, setDeleteAccount] = React.useState(false);
  const [objDeleteProps, setObjDeleteProps] = React.useState({
    slug: "",
    open: false,
    componentName: "",
    userType: "",
  });
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  const [selected, setSelected] = React.useState("");

  useEffect(() => {
    onLoad();
    getCurrentModule();
    onCheckCurrentVisiblity();
  }, []);

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const getCurrentModule = async () => {
    let tmp = await AsyncLocalStorage.getItem("currentModule");
    setSelected(tmp);
  };

  const onCheckCurrentVisiblity = () => {
    // Api Call For visiblity
    api
      .get(`user/getvisibilitystatus`)
      .then((result) => {
        if (result.success === "true") {
          setVisiblity(result.is_visible);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const onLoad = () => {
    setLoading(true);
    // Api Call For Fetch The DormMates
    api
      .get(`user/getblocklist`)
      .then((result) => {
        if (result.success === "true") {
          setLstDormMates(result.data.user);
        } else {
          setLstDormMates([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setLoading(false);
      });
  };
  const handleChangeVisiblity = () => {
    api
      .get(`user/updatevisibility`)
      .then((result) => {
        if (result.success === "true") {
          setVisiblity(!visiblity);
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setLoading(false);
      });
  };
  const handleClickOpen = (slug) => {
    setObjDeleteProps({
      slug: slug,
      open: true,
      componentName: "blockprofile",
      userType: "user",
    });
  };

  const handleAccountClose = () => {
    setDeleteAccount(false);
  };

  const handleAccountOpen = () => {
    setDeleteAccount(true);
  };

  const handleClose = (value, objMessage) => {
    setObjDeleteProps({
      slug: "",
      open: false,
      componentName: "",
    });
    onLoad();
    setSessionMessage(objMessage);
  };

  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const onProfile = (slug) => {
    router.push(`/your-matches/view/${slug}`);
  };

  const moduleNameChange = (name) => {
    if (name === "Dormmates") {
      return "DormMates";
    } else if (name === "Roommates") {
      return "RoomMates";
    } else if (name === "Soulmates") {
      return "SoulMates";
    } else if (name === "Teammates") {
      return "TeamMates";
    } else {
      return name;
    }
  };
  return (
    <>
      <div className={classes.bgGroupPage}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid className={classes.container} item>
              <Grid container className={classes.cont}>
                <Grid item xs={12}>
                  <div>
                    <h5 className={classes.headingText}> My Settings </h5>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundColor: "white",
                    margin: "10px",
                    borderRadius: "10px",
                    padding: "5px",
                  }}
                >
                  <div style={{ margin: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "600" }}>
                      Visibility
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        height: "40px",
                        alignItems: "center",
                      }}
                    >
                      <Typography>
                        {moduleNameChange(selected)} Visibility
                      </Typography>
                      <Switch
                        checked={visiblity}
                        onChange={handleChangeVisiblity}
                        color="primary"
                        name="visibility"
                      />
                    </div>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundColor: "white",
                    margin: "0px 10px",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    onClick={() => {
                      handleAccountOpen();
                    }}
                    style={{
                      // width: "fit-content",
                      cursor: "pointer",
                      margin: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontSize: "16px", fontWeight: "600" }}>
                      Delete account
                    </div>
                    <div
                      style={{
                        textTransform: "capitalize",
                        background:
                          "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
                        // fontWeight: "400",
                        height: "35px",
                        width: "7%",
                        borderRadius: "50px",
                        // fontSize: "14px",
                        // boxShadow: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                      }}
                    >
                      <DeleteIcon fontSize="default" />
                    </div>
                  </div>
                </Grid>
                {lstDormmate.length ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundColor: "white",
                      margin: "10px",
                      borderRadius: "10px",
                      padding: "15px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        margin: "10px",
                      }}
                    >
                      Block users
                    </div>
                    {!loading ? (
                      <>
                        {lstDormmate.length ? (
                          lstDormmate.map((dormmates, index) => (
                            <Box className={classes.groupHead}>
                              <div className={classes.memberDiv}>
                                <Grid container>
                                  <Grid item xl={2} lg={2} xs={3}>
                                    <div style={{ textAlign: "center" }}>
                                      <img
                                        className={classes.imgeUser}
                                        src={
                                          dormmates.profile_picture
                                            ? dormmates.profile_picture
                                            : "/images/male_defualt.svg"
                                        }
                                        alt="profile_picture"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xl={4} lg={4} xs={6}>
                                    <div>
                                      <h4
                                        className={classes.nameGroupPeople}
                                        onClick={() =>
                                          onProfile(dormmates.slug)
                                        }
                                      >
                                        {dormmates.first_name}{" "}
                                        {dormmates.last_name}
                                      </h4>
                                      <p className={classes.textpeople}>
                                        {capitalizeFirstLetter(
                                          dormmates.gender
                                        )}
                                      </p>
                                      <p className={classes.textpeople}>
                                        {dormmates.email}
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item xl={4} lg={4} xs={1}></Grid>
                                  <Grid item xl={2} lg={2} xs={2}>
                                    <Button
                                      className={classes.removeButton}
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        handleClickOpen(dormmates.slug);
                                      }}
                                    >
                                      unblock
                                    </Button>
                                    <div className={classes.removeButtonSm}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          backgroundColor: "#FFEDF0",
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "10px",
                                        }}
                                      >
                                        <ClearIcon
                                          style={{
                                            fontSize: "14px",
                                            color: "#FD3576",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              {lstDormmate.length - 1 !== index && (
                                // <hr className={classes.HrLine} />
                                <Divider />
                              )}
                            </Box>
                          ))
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Typography
                              variant="caption"
                              component="primary"
                              color="primary"
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                fontWeight: "400",
                              }}
                            >
                              No User found
                            </Typography>
                          </div>
                        )}
                      </>
                    ) : (
                      <SkeletonLoading />
                    )}
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <DeleteAccountDialog
          open={deleteAccount}
          onClose={handleAccountClose}
        />

        <BlockUser
          // selectedValue={selectedValue}
          value={false}
          slug={objDeleteProps.slug}
          componentName={objDeleteProps.componentName}
          open={objDeleteProps.open}
          onClose={handleClose}
          userType={objDeleteProps.userType}
          message={"Are you sure want to unblock it?"}
        />
      </div>
    </>
  );
};

const SkeletonLoading = () => {
  return (
    <Grid item xs={12} md={12} style={{ marginLeft: "10px" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} xl={12} lg={12}>
          <Skeleton
            variant="rect"
            width={"100%"}
            height={"120px"}
            style={{ borderRadius: "10px" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} xl={12} lg={12}>
          <Skeleton
            variant="rect"
            width={"100%"}
            height={"120px"}
            style={{ borderRadius: "10px" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} xl={12} lg={12}>
          <Skeleton
            variant="rect"
            width={"100%"}
            height={"120px"}
            style={{ borderRadius: "10px" }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default BlockList;
