import React, { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Api from "../../../../Helper/ApiHandler";
import { useHistory } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import MuiAlert from "@material-ui/lab/Alert";
import { Skeleton } from "@material-ui/lab";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import Slider from "@material-ui/core/Slider";
import { ContactSupportOutlined } from "@material-ui/icons";
import DateType from "./DateType";
import RangeType from "./RangeType";
import LocationType from "./LocationType";
import RadioType from "./RadioType";
import CheckboxType from "./CheckboxType";
import RadioImgType from "./RadioImgType";
import MultiChoiceType from "./MultiChoiceType";
import TextType from "./TextType";
import useRouter from "utils/useRouter";
import DropdownType from "./DropdownType";
import DropdownType2 from "./DropdownType2";

var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#E5E5E5",
    padding: "35px 0",
    // height: "100vh",
    minHeight: "calc(100vh - 125px)",
    // background: `url("/images/matchUp/solQueBack.jpg")`
    // backgroundImage:"/images/matchUp/solQueBack.png"
  },
  questionsText: {
    fontSize: "20px",
    padding: "20px",
    color: "white",
    fontWeight: "500",
    margin: "0",
  },
  questionsBox: {
    // background: "#0071CE",
    backgroundImage:
      "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    borderRadius: "10px 10px 0px 0px",
  },
  startQuizBox: {
    background: "#fff",
    borderRadius: "10px 10px 0px 0px",
  },
  startText: {
    color: "black",
    fontSize: "20px",
    padding: "20px",
    fontWeight: "600",
    margin: "0",
  },
  answerBox: {
    background: "white",
    borderRadius: "0px 0px 10px 10px",
  },
  headingAns: {
    margin: "0",
    padding: "20px",
    paddingBottom: "0",
    fontSize: "18px",
    fontWeight: "500",
    marginLeft: "10px",
  },
  chipUserDetail: {
    margin: "6px 5px",
    fontSize: "14px",
    padding: "0 10px",
    //fontFamily: "Gilroy !important",
    [theme.breakpoints.down("xs")]: {
      padding: "0 7px",
      fontSize: "14px",
      margin: "4px 3px",
    },
  },
  chipGruop: {
    padding: "10px 20px",
  },
  lineHorizontal: {
    width: "95%",
    // borderBottom: "2px solid #000000",
    opacity: "0.1",
    margin: "0 auto",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 0px",
  },
  nextButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    background: "#d5606045",
    // width: "80px",
    height: "30px",
  },
  skipButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    textDecoration: "underline",
    marginTop: "5px",
    cursor: "pointer",
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
  },
  buttonStart: {
    width: "300px",
    fontSize: "14px",
    backgroundImage:
      "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    textTransform: "capitalize",
    margin: "20px 0px",
  },
  pagination: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundImage:
        "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
      color: "white",
    },
  },
  mainStartQuixBox: {
    textAlign: "center",
    padding: "15px 0",
  },
  textNewStep: {
    fontSize: "16px !important",
    fontWeight: "400",
    width: "60%",
    textAlign: "center",
    margin: "19px auto",
  },
  startQuizMain: {
    marginBottom: "20px",
  },
  autocompletChip: {
    background: "white",
    "& .MuiChip-root": {
      borderRadius: "5px",
      background: "#CCE3F5",
      height: "auto",
    },
    "& .MuiChip-outlined": {
      border: "none",
      color: "#22346E",
      padding: "2px",
    },
    "& .MuiFilledInput-root": {
      background: "white",
    },
  },
  chipIcon: {
    width: "13px",
    height: "13px",
    color: "#22346E",
  },
  sliderPointer: {
    paddingTop: "10px",
    transform: "scale(0.80) translateY(-10px) !important",
  },
}));

const QuizQuestions = (props) => {
  const { sessionPropsMessage } = props;
  const classes = useStyles();
  const router = useRouter();
  const [selected, setSelected] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isNextLoading, setNextIsLoading] = React.useState(false);
  const [current, setCurrent] = React.useState(0);
  const [lstQuiz, setLstQuiz] = React.useState([]);
  const [lstAnswer, setLstAnswer] = React.useState([]);
  const [lstRoommateAnswer, setLstRoommateAnswer] = React.useState([]);
  const [lstCheck, setLstCheck] = React.useState([]);

  const [oldAnswer, setOldAnswer] = React.useState([]);

  const [hideRoommateAnswer, setHideRoommateAnswer] = React.useState(false);

  // const [rangeValue, setRangeValue] = React.useState([20, 37]);
  // const [valueGender, setValueGender] = React.useState('radio1');
  // const [valueBodyType, setValueBodyType] = React.useState('radio31');

  useEffect(() => {
    // Api Call For Retack Quiz
    api
      .get("user/retakequiz")
      .then((result) => {
        if (result.success) {
          setOldAnswer(result.data);
          setLstAnswer(result.data[0].quiz[0].answer);
          setLstRoommateAnswer(result.data[0].quiz[1].roommate_answer);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  }, []);

  useEffect(() => {
  }, [hideRoommateAnswer]);

  useEffect(() => {
    getCurrentModule();
  }, []);

  const getCurrentModule = async () => {
    let tmp = await AsyncLocalStorage.getItem("currentModule");
    setSelected(tmp);
  };

  const getCriteArias = () => {
    setIsLoading(true);
    // Api Call For Fetch The User Data
    api
      .get(`user/criteria`)
      .then((result) => {
        if (result.success === "true") {
          setLstQuiz(result.data);
          setIsLoading(false);
        } else {
          console.log(result, "error");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
        setIsLoading(false);
      });
  };
  // Finish
  const onFinish = (is_skip) => {
    onNext(is_skip);
    AsyncLocalStorage.setItem("quiz", true);
    router.history.push("/your-matches");
    // window.location.replace("/your-matches")
  };

  const onNext = (is_skip) => {
    setHideRoommateAnswer(false);
    // console.log(lstAnswer, "ansewer")
    const updatedOldAnswers = [...(oldAnswer || [])];
    updatedOldAnswers[current] = {
      quiz: [
        { answer: lstAnswer },
        {
          roommate_answer: lstRoommateAnswer,
          option_type: lstQuiz[current]?.quiz[1]?.option_type,
        },
      ],
    };
    setOldAnswer(updatedOldAnswers);

    setNextIsLoading(true);
    let objAnswer = {
      criteria_id: lstQuiz[current].criteria_id,
      answer: lstAnswer,
      roommate_answer: lstRoommateAnswer,
      is_skip: is_skip,
    };
    // setLoading(true)
    // Api Call For Send The User Answers
    api
      .post("user/answer", {
        data: objAnswer,
      })
      .then((result) => {
        setLstAnswer([]);
        setLstRoommateAnswer([]);
        setCurrent(current + 1);
        if (result.success === "true") {
          const updatedQuizData = [...lstQuiz];
          updatedQuizData[current].quiz[0].answer = lstAnswer; // Update answer field
          setLstQuiz(updatedQuizData); // Set updated data

          console.log("submit lstQuiz", lstQuiz);
          if (lstQuiz[current + 1]?.quiz[0].option_type === "text") {
            setLstAnswer(oldAnswer[current + 1].quiz[0].answer);
            // setLstCheck([])
          } else {
            if (oldAnswer[current + 1].quiz[0].answer.length) {
              let filter = oldAnswer[current + 1].quiz[0].answer?.filter(
                (obj) => {
                  return lstQuiz[current + 1].quiz[0].options.includes(obj);
                }
              );
              console.log(filter, "filter");
              setLstAnswer(filter);
              // setLstCheck([])
              // setLstAnswer(filter)
              // setCurrent(current + 1)
            } else {
              setLstAnswer([]);
              // setLstCheck([])
            }

            if (oldAnswer[current + 1].quiz[1].roommate_answer.length) {
              let filter = oldAnswer[
                current + 1
              ].quiz[1].roommate_answer.filter((obj) =>
                lstQuiz[current + 1].quiz[1].options.includes(obj)
              );
              setLstRoommateAnswer(filter);
              if (oldAnswer[current + 1].quiz[1].option_type === "Checkbox") {
                setLstCheck(filter);
              }
              console.log(filter, "filter");
            } else {
              setLstRoommateAnswer([]);
              setLstCheck([]);
            }
          }

          // console.log(oldAnswer[current + 1].quiz[0].answer, "answer---")
          // console.log(oldAnswer[current + 1].quiz[1].roommate_answer, "answer---")
          // setLstRoommateAnswer(oldAnswer[current + 1].quiz[1].roommate_answer)
          // if (oldAnswer[current + 1].quiz[1].option_type === "Checkbox") {
          //   setLstCheck(oldAnswer[current + 1].quiz[1].roommate_answer)
          // }
          // if (lstQuiz[current + 1]?.quiz[0].option_type === "Location") {
          //   setLstAnswer(["Surat"])
          // }
          // else {
          //   setLstAnswer([])
          // }
          // setLstRoommateAnswer([])
        } else {
          console.log(result, "error");
        }
        // setLoading(false)
        setNextIsLoading(false);
      })
      .catch((err) => {
        console.log(err, "error catch");
        // setLoading(false)
        setNextIsLoading(false);
      });
  };

  const moduleNameChange = (name) => {
    if (name === "Dormmates") {
      return "DormMates";
    } else if (name === "Roommates") {
      return "RoomMates";
    } else if (name === "Soulmates") {
      return "SoulMates";
    } else if (name === "Teammates") {
      return "TeamMates";
    } else {
      return name;
    }
  };

  const onPaginationChange = (event, value) => {

    setHideRoommateAnswer(false);

    // console.log(oldAnswer[value - 1].quiz[0].answer, oldAnswer[value - 1].quiz[1].roommate_answer, "lstAnswer[value - 1]");
    // current + 1
    if (current > value - 1) {
      // console.log("ansansansansans_-oldanswer",oldAnswer[value - 1].quiz[0].answer);

      // console.log("oldAnswer[value - 1]?.quiz[0]?.answer", oldAnswer);
      setCurrent(value - 1);
      setLstAnswer(oldAnswer[value - 1]?.quiz[0]?.answer);
      if (oldAnswer[current - 1]?.quiz[1]?.roommate_answer?.length) {
        let filter = oldAnswer[
          value - 1
        ].quiz[1].roommate_answer?.filter((obj) =>
          lstQuiz[value - 1]?.quiz[1].options.includes(obj)
        );
        setLstRoommateAnswer(filter);
        if (oldAnswer[current - 1].quiz[1].option_type === "Checkbox") {
          setLstCheck(filter);
        }
      } else {
        setLstRoommateAnswer([]);
        setLstCheck([]);
      }

      // if (oldAnswer[current - 1].quiz[1].option_type === "Checkbox") {
      //   setLstCheck(filter)
      //   setLstRoommateAnswer(oldAnswer[value - 1].quiz[1].roommate_answer);
      // } else {
      //   setLstRoommateAnswer(oldAnswer[value - 1].quiz[1].roommate_answer);
      // }
    }
  };

  const origincounry = localStorage.getItem("selectedValue");
  let resultorigincounry = origincounry?.replace(/"/g, "");

  let samevariable;
  if (resultorigincounry != undefined) {
    samevariable = lstAnswer[0] === resultorigincounry ? "matching" : "nomatch";
  } else {
    samevariable = "other";
  }
  return (
    <>
      <div className={classes.bgProfilePage}>
        <Container maxWidth="md">
          <Grid container>
            {/* Matching Quiz */}
            {!lstQuiz.length ? (
              <Grid item xl={12} lg={12} xs={12}>
                <Box className={classes.startQuizMain}>
                  <Box className={classes.startQuizBox}>
                    <p className={classes.startText}>Matching Quiz</p>
                  </Box>
                  <div className={classes.hrLine} />
                  <Box className={classes.answerBox}>
                    <Box className={classes.mainStartQuixBox}>
                      <img src="/images/startQuiz.svg" alt="startQuiz" />
                      <p className={classes.textNewStep}>
                        In this step, you’ll answer some questions <br />
                        that will help us match you with potential{" "}
                        {moduleNameChange(selected)}
                      </p>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonStart}
                        disabled={isLoading}
                        endIcon={
                          isLoading ? (
                            <>
                              &nbsp;
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            </>
                          ) : (
                            <img
                              src="/images/matchUp/startAerrow.png"
                              alt="startAerrow"
                            />
                          )
                        }
                        onClick={(e) => {
                          // setCurrent(?current + 1)
                          getCriteArias(e);
                        }}
                      >
                        Start The Quiz
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ) : (
              <Grid item xl={12} lg={12} xs={12}>
                {lstQuiz[current]?.quiz.length && (
                  <div
                    style={{
                      backgroundColor: "white",
                      width: "100%",
                      borderRadius: "10px",
                      paddingTop: "50px",
                    }}
                  >
                    <div style={{ padding: "0px 50px" }}>
                      <div style={{ fontSize: "18px", fontWeight: "500" }}>
                        {/* When were you born? */}
                        {lstQuiz[current]?.quiz[0]?.question}
                      </div>
                      <div style={{ display: "flex", margin: "30px 0px" }}>
                        {lstQuiz[current]?.quiz[0]?.option_type === "Date" && (
                          <DateType setAns={setLstAnswer} ans={lstAnswer} />
                        )}
                        {lstQuiz[current]?.quiz[0]?.option_type ===
                          "Location" && (
                          <LocationType setAns={setLstAnswer} ans={lstAnswer} />
                        )}
                        {lstQuiz[current]?.quiz[0]?.option_type === "Radio" && (
                          <RadioType
                            options={lstQuiz[current]?.quiz[0]?.options}
                            gridNum={
                              lstQuiz[current]?.quiz[0]?.options.length > 4
                                ? 3
                                : 6
                            }
                            setAns={setLstAnswer}
                            ans={lstAnswer[0]}
                          />
                        )}
                        {console.log("lstQuiz[current]?.quiz[0]",lstQuiz[current]?.quiz[0].question)}


                        


                        {lstQuiz[current]?.quiz[0]?.question ===
                          "Country of Origin" && (
                          <DropdownType
                            options={lstQuiz[current]?.quiz[0]?.options}
                            
                            // gridNum={
                            //   lstQuiz[current]?.quiz[0]?.options.length < 4
                            //     ? 6
                            //     : 6
                            // }
                            setAns={setLstAnswer}
                            // lstAns={oldAnswer[current - 1]?.quiz[0]?.answer}
                            currentIndex={current}
                            setHideRoommateAnswer={setHideRoommateAnswer}
                            // ans={lstAnswer[0]}
                            ans={
                              Array.isArray(lstAnswer) && lstAnswer.length > 0
                                ? lstAnswer[0]
                                : null
                            }
                            // selectedValue={selectedValue}
                            // setSelectedValue={setSelectedValue}
                          />
                        )} 

                        {lstQuiz[current]?.quiz[0]?.question ===
                          "Currently Living In Country" && (

                          <DropdownType2
                            options={lstQuiz[current]?.quiz[0]?.options}
                            
                            // gridNum={
                            //   lstQuiz[current]?.quiz[0]?.options.length < 4
                            //     ? 6
                            //     : 6
                            // }
                            setAns={setLstAnswer}
                            // lstAns={oldAnswer[current - 1]?.quiz[0]?.answer}
                            currentIndex={current}
                            setHideRoommateAnswer={setHideRoommateAnswer}
                            // ans={lstAnswer[0]}
                            ans={
                              lstQuiz[1]?.quiz[0]?.answer[0] || lstAnswer[0]
                            }
                            // selectedValue={selectedValue}
                            // setSelectedValue={setSelectedValue}
                          />


                        )}
                        {lstQuiz[current]?.quiz[0]?.option_type ===
                          "RadioImg" && (
                          <RadioImgType
                            options={lstQuiz[current]?.quiz[0]?.options}
                            setAns={setLstAnswer}
                            ans={lstAnswer[0]}
                          />
                        )}
                        {lstQuiz[current]?.quiz[0]?.option_type ===
                          "Multi Choose" && (
                          <MultiChoiceType
                            options={lstQuiz[current]?.quiz[0]?.options}
                            setAns={setLstAnswer}
                            ans={lstAnswer}
                          />
                        )}
                        {lstQuiz[current]?.quiz[0]?.option_type === "text" && (
                          <TextType setAns={setLstAnswer} ans={lstAnswer[0]} />
                        )}
                      </div>

                      {lstQuiz[current]?.quiz[1]?.question?.length ? (
                        <div style={{ borderTop: "1px dashed #E6E6E6" }}></div>
                      ) : (
                        ""
                      )}

                      {/* {lstQuiz[current]?.quiz[1]?.question ===
                      "Type of Visa Status" ? (
                        lstQuiz[0]?.quiz[0]?.answer[0] !== lstAnswer[0] ? (
                          <div
                            style={{ borderTop: "1px dashed #E6E6E6" }}
                          ></div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div style={{ borderTop: "1px dashed #E6E6E6" }}></div>
                      )} */}

                      {!hideRoommateAnswer && (
                        <div data-value={current}>
                          <div
                            style={{
                              fontSize: "18px",
                              fontWeight: "500",
                              marginTop: "10px",
                            }}
                          >
                            {/* When were you born? */}

                            {lstQuiz[current]?.quiz[1]?.question ===
                            "Type of Visa Status"
                              ? lstQuiz[0]?.quiz[0]?.answer[0] !== lstAnswer[0]
                                ? lstQuiz[current]?.quiz[1]?.question
                                : null
                              : lstQuiz[current]?.quiz[1]?.question}
                            {/* {lstQuiz[current]?.quiz[1]?.question} */}
                            {/* {lstAnswer?.length > 0 &&
                            lstAnswer[0] !== resultorigincounry ? (
                              <>{lstQuiz[current]?.quiz[1]?.question}</>
                            ) : null} */}
                          </div>
                          <div style={{ display: "flex", margin: "30px 0px" }}>
                            {lstQuiz[current]?.quiz[1]?.option_type ===
                              "Range" && (
                              <RangeType
                                setAns={setLstRoommateAnswer}
                                ans={lstRoommateAnswer}
                              />
                            )}

                            {lstQuiz[current]?.quiz[1]?.question ===
                            "Type of Visa Status"
                              ? lstQuiz[0]?.quiz[0]?.answer[0] !=
                                  lstAnswer[0] && (
                                  <RadioType
                                    options={lstQuiz[current]?.quiz[1]?.options}
                                    gridNum={
                                      lstQuiz[current]?.quiz[0]?.options
                                        .length > 4
                                        ? 3
                                        : 6
                                    }
                                    setAns={setLstRoommateAnswer}
                                    ans={lstRoommateAnswer[0]}
                                  />
                                )
                              : lstQuiz[current]?.quiz[1]?.option_type ===
                                  "Radio" && (
                                  <RadioType
                                    options={lstQuiz[current]?.quiz[1]?.options}
                                    gridNum={
                                      lstQuiz[current]?.quiz[0]?.options
                                        .length > 4
                                        ? 3
                                        : 6
                                    }
                                    setAns={setLstRoommateAnswer}
                                    ans={lstRoommateAnswer[0]}
                                  />
                                )}

                            {/* {lstQuiz[current]?.quiz[1]?.option_type ===
                              "Radio" && (
                              <RadioType
                                options={lstQuiz[current]?.quiz[1]?.options}
                                gridNum={
                                  lstQuiz[current]?.quiz[0]?.options.length > 4
                                    ? 3
                                    : 6
                                }
                                setAns={setLstRoommateAnswer}
                                ans={lstRoommateAnswer[0]}
                              />
                            )} */}
                            {lstQuiz[current]?.quiz[1]?.option_type ===
                              "Checkbox" && (
                              <CheckboxType
                                options={lstQuiz[current]?.quiz[1]?.options}
                                gridNum={
                                  lstQuiz[current]?.quiz[0]?.options.length > 4
                                    ? 3
                                    : 6
                                }
                                setAns={setLstRoommateAnswer}
                                ans={lstRoommateAnswer}
                                selected={lstCheck}
                                setSelected={setLstCheck}
                              />
                            )}
                            {lstQuiz[current]?.quiz[1]?.option_type ===
                              "RadioImg" && (
                              <RadioImgType
                                options={lstQuiz[current]?.quiz[1]?.options}
                                setAns={setLstRoommateAnswer}
                                ans={lstRoommateAnswer[0]}
                              />
                            )}
                          </div>
                        </div>
                      )}

                      <div
                        className={classes.buttonGroup}
                        style={{ borderTop: "1px solid #E6E6E6" }}
                      >
                        <div>
                          {/* <Link className={classes.skipButton} onClick={() => { }}>Skip</Link> */}
                        </div>

                        <Pagination
                          count={lstQuiz.length}
                          hidePrevButton
                          hideNextButton
                          shape="rounded"
                          // color="primary"
                          size="small"
                          defaultPage={1}
                          page={current + 1}
                          className={classes.pagination}
                          onChange={onPaginationChange}
                        />
                        {lstQuiz.length > current + 1 ? (
                          <Button
                            className={classes.nextButton}
                            variant="outlined"
                            color="primary"
                             disabled={
                              !lstQuiz[current]?.quiz[1]?.question?.length
                                ? lstAnswer.length
                                  ? false
                                  : true
                                : lstRoommateAnswer?.length && lstAnswer?.length
                                ? false
                                : (lstQuiz[current]?.quiz[1]?.question === "Type of Visa Status" && lstQuiz[0]?.quiz[0]?.answer[0] == lstAnswer[0]) ? false : true
                            }
                            
                            
                            // disabled={

                            //   !lstQuiz[current]?.quiz[1]?.question?.length
                            //     ? lstAnswer.length
                            //       ? false
                            //       : true
                            //     : (lstQuiz[current]?.quiz[1]?.question ===
                            //         "Type of Visa Status" &&
                            //       lstQuiz[0]?.quiz[0]?.answer[0] != lstAnswer[0])
                            //     ? lstRoommateAnswer.length && lstAnswer.length
                            //       ? false
                            //       : true
                            //     : lstAnswer.length
                            //     ? false
                            //     : true
                            // }
                            onClick={() => {
                              // setCurrent(current + 1)
                              onNext(false);
                            }}
                          >
                            {isNextLoading ? (
                              <>
                                &nbsp;
                                <CircularProgress size={15} />
                              </>
                            ) : (
                              "Next"
                            )}
                          </Button>
                        ) : (
                          <Button
                            className={classes.nextButton}
                            variant="outlined"
                            color="primary"
                            disabled={
                              !lstQuiz[current]?.quiz[1]?.question?.length
                                ? lstAnswer.length
                                  ? false
                                  : true
                                : lstRoommateAnswer.length && lstAnswer.length
                                ? false
                                : true
                            }
                            onClick={() => {
                              // setCurrent(current + 1)
                              onFinish(false);
                              // router.history.push("/your-matches")
                            }}
                          >
                            {isNextLoading ? (
                              <>
                                &nbsp;
                                <CircularProgress size={15} />
                              </>
                            ) : (
                              "Finish"
                            )}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </Container>
      </div>
    </>
  );
};

const SkeletonLoading = () => {
  return (
    <Grid item xs={12} md={12} style={{ marginLeft: "10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} xl={12} lg={12}>
          <Skeleton
            variant="rect"
            width={"100%"}
            height={"260px"}
            style={{ borderRadius: "10px" }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default QuizQuestions;
