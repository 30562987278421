import React from "react";
import YourCurrentPlan from "./component/YourBoostCurrentPlan";

const CurrentPlan = () => {
  return (
    <>
      <YourCurrentPlan />
    </>
  );
};

export default CurrentPlan;
