import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import useRouter from "utils/useRouter";
const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;
  const router = useRouter();
  // const notArray = [
  //   "HomeView",
  //   "PropertyInfoSingle",
  //   "Comminsoon",
  //   "HowItWorkDetail",
  //   "ContactUs",
  //   "AboutUs",
  //   "TermsOfUse",
  //   "PrivacyPolicy",
  //   "Unsubscribe",
  //   "Faq",
  //   "FaqRoommate",
  //   "FaqTeammate",
  //   "FaqSoulmate",
  //   "FaqMain",
  //   "NotFoundView"
  // ]

  const inArray = [
    "/edit-profile",
    "/messages/property/:id",
    "/my-favourite",
    "/who-likesyou",
    "/Billing-History",
    "/Your-Subscription",
    "/Your-Boost-Subscription",
    "/your-matches",
    "/Current-Plan",
    "/Cancellation-Policy",
    "/addNewGroupMember",
    "/addNewGroupMemberNew",
    "/addNewGroupMember/:id",
    "/shareGourp/:id",
    "/addNewGroupInfo",
    "/addNewGroupInfoNew",
    "/groupDetail/:id",
    "/your-matches/view/:id",
    "/favourit/view/:id",
    "/subscription-plan",
    "/my-profile",
    "/matching-quiz",
    "/chatroom",
    "/chatroom/:id",
    "/chatroomTest",
    "/chatroomTest/:id",
    "/messages",
    "/messages/:uid",
    "/reset-password",
    "/notifications",
    "/resetpwd/:token",
    "/my-group",
    "/settings",
    "/property",
  ];
  const notArray = [
    "/group/:id",
    "/teammate-verify/:id",
    "/home/:token",
    "/property/:id",
    "/comminsoon",
    "/how-it-work",
    "/contact-us",
    "/about-us",
    "/terms-of-use",
    "/privacy-policy",
    "/newsletter-unsubscribe",
    "/faq",
    "/property",
    "/dormmate",
    "/roommate",
    "/soulmate",
    "/teammate",
    "/selecte-module",
    "/signup-details",
    "/disclaimer",
    "/selecte-roommate-module",
    "/personal-details",
    "/select-university",
    "/property-signup-details",
    "/blog",
    "/blog/:id",
    "/",
  ];
  useEffect(() => {
    if (!notArray.includes(props.path)) {
      if (!localStorage.getItem("authToken")) {
        if (inArray.includes(props.path)) {
          console.log(
            "redirect to home page",
            !localStorage.getItem("authToken"),
            !notArray.includes(props.component.name)
          );
          router.history.push("/");
        }
      }
    } else {
      console.log(
        "redirect to home page bar",
        !localStorage.getItem("authToken"),
        !notArray.includes(props.component.name)
      );
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
