import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, TextField, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import validate from "validate.js";
import Api from "../../../../Helper/ApiHandler";
import Blogs from "../Blogs";
import BlogsDetails from "../BlogsDetails";
import { Skeleton } from "@material-ui/lab";

var api = new Api();

const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: { maximum: 254 },
  },
  phone: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 15, message: "is too long (maximum is 15 digit)" },
    format: {
      pattern: "[0-9]+",
      flags: "i",
      message: "Enter only number",
    },
  },
  message: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "30px !important",
    marginTop: "20px",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  container: {
    // padding: "0 300px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      // padding: "0 100px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  containerother: {
    // padding: "0 270px",
    [theme.breakpoints.down("lg")]: {
      // padding: "0 100px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 30px",
    },
  },
  form__input: {
    marginTop: 14,
    background: "#FFFFFF",
    borderRadius: 9,
    height: 45,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "0 30px",
    },
  },
  searchbar: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 20px",
    },
  },
}));

const ContactUsDetail = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [searchValue, setsearchValue] = useState("");
  const [rowsPerPage, setrowsPerPage] = React.useState(10);
  const [totalRecored, setTotalRecored] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [loadingScroll, setLoadingScroll] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);

  const [onLoadingInitial, setOnLoadingInitial] = useState(false);
  const [onLoading, setOnLoading] = useState(false);
  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (page !== 0) onMore();
  }, [page]);

  const fetchMoreMatches = () => {
    if (data.length >= totalRecored) {
      setHasMore(false);
      return;
    }

    // setTimeout(() => {
    // setItems(items.concat(Array.from({ length: 20 })))
    setPage(page + 1);
    // onMore()
    // }, 500);
  };

  const onLoad = (updatedDate) => {
    setOnLoading(true);
    api
      .get(
        `getbloglist?s=${
          updatedDate === undefined ? "" : updatedDate
        }&page=${page}&size=${rowsPerPage}`
      )
      .then((result) => {
        if (result.success === "true") {
          setData(result.data.blogs);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
        }
        setOnLoading(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setOnLoading(false);
      });
  };
  const onMore = (updatedDate) => {
    setLoadingScroll(true);
    api
      .get(
        `getbloglist?s=${
          updatedDate === undefined ? "" : updatedDate
        }&page=${page}&size=${rowsPerPage}`
      )
      .then((result) => {
        if (result.success === "true") {
          setData(result.data.blogs);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
        }
        setLoadingScroll(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setLoadingScroll(false);
      });
  };

  const handleSearch = (sValue) => {
    onLoad(sValue);
    setsearchValue(sValue);
  };

  const handleChange = (event) => {
    handleSearch(event.target.value);
  };

  return (
    <Container maxWidth="lg" style={{marginTop:'10px',marginBottom:'20px'}}>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography variant="h3" className={classes.title}>
            Blogs of MatchUpMates
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} className={classes.searchbar}>
          {/* <Typography variant="h3" className={classes.title}> */}
          <TextField
            fullWidth
            type="text"
            placeholder="Search"
            variant="outlined"
            onChange={(e) => {
              handleChange(e);
            }}
            InputProps={{
              className: classes.form__input,
            }}
          />
          {/* </Typography> */}
        </Grid>
      </Grid>

      {data.length ? (
        <Grid container className={classes.containerother}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Blogs
              data={data}
              onLoading={onLoading}
              fetchMoreMatches={fetchMoreMatches}
              hasMore={hasMore}
              loadingScroll={loadingScroll}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
            <BlogsDetails />
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.containerother}>
          {onLoading ? (
            <SkeletonLoading />
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  margin: "50px",
                }}
              >
                <img src="/images/matchUp/notFoundMatches.svg" alt="favAlt" />
                <Typography
                  variant="caption"
                  component="primary"
                  color="primary"
                  style={{
                    fontSize: "20px",
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  No Blogs Yet!
                </Typography>
              </div>
            </Grid>
          )}
        </Grid>
      )}
    </Container>
  );
};
const SkeletonLoading = () => {
  return (
    <>
      <Grid container item xs={12} sm={12} md={12} lg={12} alignItems="center">
        <Grid item xs={12} sm={6} md={5} lg={5}>
          {/* img */}
          <Skeleton
            variant="rect"
            height={400}
            style={{ margin: "5px", borderRadius: 6 }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={7} style={{ padding: "0 35px" }}>
          <Skeleton animation="wave" width={200} />
          <Skeleton animation="wave" width={250} />
          <Skeleton animation="wave" width={250} />
          <Skeleton animation="wave" width={300} />
          <Skeleton animation="wave" width={300} />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />

          <Grid container>
            <Grid
              item
              xs={11}
              sm={11}
              md={11}
              lg={11}
              style={{ display: "flex", marginTop: 20 }}
            >
              <Skeleton
                animation="wave"
                variant="circle"
                width={40}
                height={40}
              />
              <div style={{ padding: "0 20px" }}>
                <Skeleton animation="wave" height={20} width={200} />
                <Skeleton animation="wave" height={20} width={150} />
              </div>
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {/* <ShareOutlinedIcon /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} sm={12} md={12} lg={12} alignItems="center">
        <Grid item xs={12} sm={6} md={5} lg={5}>
          {/* img */}
          <Skeleton
            variant="rect"
            height={350}
            style={{ margin: "5px", borderRadius: 6 }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={7} style={{ padding: "0 35px" }}>
          <Skeleton animation="wave" width={200} />
          <Skeleton animation="wave" width={250} />
          <Skeleton animation="wave" width={250} />
          <Skeleton animation="wave" width={300} />
          <Skeleton animation="wave" width={300} />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />

          <Grid container>
            <Grid
              item
              xs={11}
              sm={11}
              md={11}
              lg={11}
              style={{ display: "flex", marginTop: 20 }}
            >
              <Skeleton
                animation="wave"
                variant="circle"
                width={40}
                height={40}
              />
              <div style={{ padding: "0 20px" }}>
                <Skeleton animation="wave" height={20} width={200} />
                <Skeleton animation="wave" height={20} width={150} />
              </div>
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {/* <ShareOutlinedIcon /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactUsDetail;
