import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  colors,
  Container,
  Grid,
  Hidden,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import SliderModel from "components/Slider/SliderModel";
import "react-fancybox/lib/fancybox.css";

import { Alert, Skeleton } from "@material-ui/lab";

import SolQuizeDialog from "components/SolQuizeModel/SolQuizeModel";
import { useGeolocated } from "react-geolocated";
import { Link } from "react-router-dom";
import ReactFancyBox from "react-fancybox";
import AddIcon from "@material-ui/icons/Add";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import AppDataContext from "Helper/AppDataContext";
import Compressor from "compressorjs";

import Api from "../../../../Helper/ApiHandler";
import Fancybox from "components/fancybox";
var api = new Api();

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    // background: "#E5E5E5",
    background: "#E9EDF0",
    minHeight: "90vh",
    padding: "35px 0",
    // marginBottom: "60px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0",
    },
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  space: {
    padding: "10px",
    paddingLeft: "16px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0px",
    },
  },
  detailGridBox: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    display: "flex",
    gap: theme.spacing(1),
    flexWrap: "wrap",
  },
  directMessageBtn: {
    // position: "absolute",
    // bottom: 10,
    textTransform: "capitalize",
    background: "#d5606045",
    borderRadius: "10px",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      bottom: -10,
      position: "relative",
    },
  },
  directMessageBtndata: {
    textTransform: "capitalize",
    background: "#d5606045",
    borderRadius: "10px",
    marginTop: "10px",
  },
  headingPage: {
    fontWeight: "600 !important",
    fontSize: "18px",
    // marginLeft: "25px",
    margin: 0,
    padding: "15px 0px",
    paddingLeft: "16px",
    backgroundColor: "white",
    borderRadius: "10px 10px 0px 0px",
  },
  profileImage: {
    height: "200px",
    width: "200px",
    borderRadius: "10px",
    objectFit: "cover",
    objectPosition: "top",
  },
  plandetails: {
    border: "1px solid #FE4B6B",
    // padding: "10px 10px",
    marginTop: "10px",
    borderRadius: "10px",
    flex: 1,
    padding: theme.spacing(1),
    border: "1px solid #ccc",
    borderRadius: "4px",
    minWidth: "0",
  },
  profileImageRoot: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
    position: "relative",
    width: "fit-content",
  },
  personalInfoRoot: {
    backgroundColor: "white",
    borderRadius: "0px 0px 10px 10px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // height: "410px",
      alignItems: "center",
    },
    padding: "0px 15px 15px 15px",
    width: "100%",
  },
  nameRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  name: {
    fontSize: "17px",
    fontWeight: "500",
    textTransform: "capitalize",
  },
  name2: {
    fontSize: "17px",
    fontWeight: "500",
    textTransform: "capitalize",
    color: "#FE4B6B",
  },
  qulification: {
    fontSize: "12px",
    fontWeight: "400",
  },
  messageIcon: {
    height: "40px",
    width: "40px",
  },
  aboutmeTitle: {
    fontSize: "13px",
    fontWeight: "500",
    color: "#FE4B6B",
    margin: "10px 0px",
  },
  aboutme: {
    fontSize: "12px",
    fontWeight: "400",
    wordBreak: "break-all",
    [theme.breakpoints.down("xs")]: {
      overflow: "hidden",
      display: "-webkit-box !important",
      "-webkitLineClamp": 4,
      "-webkitBoxOrient": "vertical",
    },
  },
  solMatchRoot: {
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    // paddingLeft:"10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    // padding: "35px"
  },
  solMatchImage: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  solMatchSeeMore: {
    backgroundColor: "white",
    borderRadius: "20px",
    padding: "10px 10px",
    display: "flex",
    justifyContent: "center",
    color: "#FD3576",
    fontWeight: "500",
    fontSize: "13px",
  },
  solImage1: {
    height: "85px",
    width: "85px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "2px solid white",
  },
  solRingRoot: {
    position: "absolute",
    left: "30%",
    display: "flex",
  },
  solRing: {
    height: "85px",
    width: "85px",
    position: "absolute",
    left: "-7px",
  },
  logo: {
    height: "22px",
    width: "22px",
    left: "25px",
    position: "absolute",
    top: "25px",
  },
  textMatching: {
    left: "24px",
    position: "absolute",
    top: "45px",
    fontSize: "12px",
    fontWeight: "600",
  },
  solImage2: {
    height: "85px",
    width: "85px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "2px solid white",
    marginLeft: "10px",
  },
  photosRoot: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "8px",
    overflow: "auto",
  },
  solphotos: {
    height: "90px",
    width: "90px",
    borderRadius: "10px",
    objectFit: "cover",
    padding: "5px",
  },
  photosTitle: {
    fontSize: "13px",
    fontWeight: "500",
    color: "#FE4B6B",
    margin: "0px 8px 10px 8px",
  },
  lifestyleRoot: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "8px",
    marginTop: "20px",
  },
  lifestyleTitle: {
    fontSize: "13px",
    fontWeight: "500",
    color: "#FE4B6B",
    margin: "0px 8px 10px 8px",
  },
  lifestyleCard: {
    background: "#F2F2F2",
    borderRadius: "10px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "124px",
    // width: "124px",
  },
  lifestylePhotos: {
    height: "50px",
    objectFit: "contain",
    // width: "50px",
  },
  lifestyleText: {
    textAlign: "center",
    fontSize: "12px",
    marginTop: "10px",
  },
  AddIcon: {
    textAlign: "center",
    fontSize: "24px",
    color: "#FE4B6B",
    fontWeight: "500",
  },
  factfileRoot: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "8px",
    height: "100%",
  },
  spacingBottom: {
    marginBottom: 15,
  },
  factfileTitle: {
    fontSize: "13px",
    fontWeight: "500",
    color: "#FE4B6B",
    margin: "8px",
  },
  factfilephotos: {
    height: "35px",
    width: "35px",
    marginRight: "10px",
  },
  factfileCardRoot: {
    display: "flex",
    margin: "18px 10px",
    cursor: "pointer",
  },
  question: {
    color: "#808080",
    fontSize: "12px",
  },
  answer: {
    fontWeight: "600",
    fontSize: "14px",
  },
  imageLifStyleRoot: {
    width: "100px",
    height: "100px",
    borderRadius: "10px",
    border: "2px solid #FE4B6B",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "5px",
  },
  imageLifStyle: {
    "& .react-fancybox .thumbnail img": {
      borderRadius: "10px",
      width: "100px",
      height: "100px",
      marginTop: "4px",
      marginLeft: "8px",
      objectFit: "cover",
      // boxShadow: "0px 0px 5px 0px #000000",
      boxShadow: "6px 6px 12px #b8b9be,-6px -6px 12px #fff",
      [theme.breakpoints.down("xs")]: {
        overflowX: "scroll",
      },
      cursor: "pointer",
    },
    // borderRadius: "100%",
    // width: "100%",
    // height: "100%",
  },
  iconTapBorder: {
    width: "100px",
    height: "100px",
    border: "2px solid #FE4B6B",
    borderRadius: "10px",
    // margin: "5px 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  imageBoxStyle: {
    border: "2px solid #22346E",
    borderRadius: "100%",
    textAlign: "center",
    padding: "3px",
    width: "90px",
    height: "90px",
    [theme.breakpoints.down("xs")]: {
      width: "70px",
      height: "70px",
    },
  },
  changeImageTap: {
    marginLeft: "10px",
  },
  editIconPro: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
  imgGeighestMatch1: {
    width: "85px",
    height: "85px",
    position: "absolute",
    left: "30px",
    borderRadius: "50%",
  },
  imgGeighestMatch2: {
    width: "85px",
    height: "85px",
    // position:"absolute"
  },
  imgGeighestMatch3: {
    width: "85px",
    height: "85px",
    position: "absolute",
    right: "30px",
    borderRadius: "50%",
  },
  textUserDetail: {
    // margin: "0",
    color: "gray",
    margin: "5px 0",
    wordBreak: "break-all",
    display: "-webkit-box",
    "-webkitLineClamp": 3,
    "-webkitBoxOrient": "vertical",
    [theme.breakpoints.down("sm")]: {
      "-webkitLineClamp": 2,
    },
    [theme.breakpoints.down("xs")]: {
      "-webkitLineClamp": 4,
    },
    overflow: "hidden",
  },
  showtext: {
    color: "gray",
    margin: "5px 0",
    wordBreak: "break-all",
    overflow: "hidden",
  },

  viewMore: {
    textAlign: "end",
    color: "#FE4B6B",
    margin: "5px 0",
    fontSize: 12,
    wordBreak: "break-all",
    overflow: "hidden",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const lstMasterLifeStyle = {
  "Listening Music": "/images/matchUp/lifeStyle1.svg",
  "Study Reaserch": "/images/matchUp/StudyReaserch.svg",
  Wellness: "/images/matchUp/Wellness.svg",
  Cinema: "/images/matchUp/Cinema.svg",
  Beauty: "/images/matchUp/Beauty.svg",
  Holidays: "/images/matchUp/Holidays.svg",
  Swimming: "/images/matchUp/Swimming.svg",
  "Cruise Trips": "/images/matchUp/CruiseTrips.svg",
  Cycling: "/images/matchUp/Cycling.svg",
  Italian: "/images/matchUp/Italian.svg",
  Playing: "/images/matchUp/Playing.svg",
};
const lstLifeStyle = [
  "Listening Music",
  "Study Reaserch",
  "Wellness",
  "Cinema",
  "Beauty",
  "Holidays",
  "Swimming",
  "Cruise Trips",
  "Cycling",
  "Italian",
  "Playing",
];

const quizMaster = {
  "20": "/images/matchUp/19.svg",
  "21": "/images/matchUp/20.svg",
  "22": "/images/matchUp/21.svg",
  "23": "/images/matchUp/22.svg",
  "24": "/images/matchUp/25.svg",
  "25": "/images/matchUp/23.svg",
  "26": "/images/matchUp/24.svg",
  "27": "/images/matchUp/27.svg",
  "28": "/images/matchUp/26.svg",
  "49": "/images/matchUp/Language.svg",
  "50": "/images/matchUp/Current living country.svg",
  "51": "/images/matchUp/Country Origin.svg",
  "52": "/images/matchUp/Employement Status.svg",
};

// const quiz = [
//   {
//     question: [
//       "When were you born?",
//       "I’m looking for someone in age between...",
//     ],
//     answer: "Computer Engineer",
//     id: 19,
//   },
//   {
//     question: ["Where do you live?", "How far will you go to meet your match?"],
//     answer: "Computer Engineer",
//     id: 20,
//   },
//   {
//     question: ["What is your body type?", "My partner body type should be..."],
//     answer: "Computer Engineer",
//     id: 21,
//   },
//   {
//     question: ["I’am", "My partner race type should be..."],
//     answer: "Computer Engineer",
//     id: 22,
//   },
//   {
//     question: [
//       "What is your religious, beliefs or spirituality?",
//       "Match me with someone whose religion or spirituality is",
//     ],
//     answer: "Hindu",
//     id: 23,
//   },
//   {
//     question: ["How often do you smoke?", "How often should you match smoke?"],
//     answer: "Non-Smoker",
//     id: 24,
//   },
//   {
//     question: [
//       "What is your marital status?",
//       "I’m looking for someone in age between...",
//     ],
//     answer: "Single",
//     id: 25,
//   },
//   // {
//   //   question: ["What kinds of hobbies do you have?", "I’m looking for someone in age between..."],
//   //   answer: "Computer Engineer",
//   //   id: 26
//   // },
//   // {
//   //   question: ["What is your occupation?", "I’m looking for someone in age between..."],
//   //   answer: "Computer Engineer",
//   //   id: 27
//   // },
// ];

const MyProfileDetail = (props) => {
  const { sessionPropsMessage } = props;
  const classes = useStyles();
  const { appContext, setAppContext } = useContext(AppDataContext);
  const [userData, setUserData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [sliderOpen, setSliderOpen] = useState(false);
  const [sessionMessage, setSessionMessage] = React.useState(
    sessionPropsMessage
  );
  const [title, setTitle] = React.useState("");
  const [boosttitle, setBoostTitle] = React.useState("");
  const [lstLifeStyleImage, setLstLifeStyleImage] = React.useState([]);
  const [lstQuiz, setLstQuiz] = React.useState([]);
  const [objQuiz, setObjQuiz] = React.useState({});
  const [ocupation, setOcupation] = React.useState("");
  const [objHighestMatch, setObjHighestMatch] = React.useState({});
  const [lstIntrestImage, setLstIntrestImage] = React.useState([]);
  const [uploadImagePath, setUploadImagePath] = React.useState("");
  const [objIntrestImageMaster, setObjIntrestImageMaster] = React.useState({});
  const [loadingLifestyle, setLoadingLifestyle] = useState(false);
  const [expired, setexpired] = React.useState(0);
  const [boostexpired, setBoostExpired] = useState(null);
  const [isBoostSuccess, setIsBoostSuccess] = useState(false);

  const [hideText, setHideText] = React.useState(false);

  const handleShowText = () => {
    setHideText(true);
  };
  const handleHideText = () => {
    setHideText(false);
  };

  console.log(lstQuiz, "lstQuizlstQuiz");

  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    // userDecisionTimeout: 5000,
  });

  const [objDeleteProps, setObjDeleteProps] = React.useState({
    slug: "",
    open: false,
    componentName: "",
    userType: "",
  });
  useEffect(() => {
    onLoad();
    onLifestyleimage();
  }, []);
  useEffect(() => {
    console.log(coords, "coords");
    onHighestMatch();
  }, [coords]);

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const handleClickOpen = (obj) => {
    setObjDeleteProps({
      // slug: slug,
      open: true,
      componentName: "deletefromgroup",
      userType: "user",
    });
    setObjQuiz(obj);
  };

  const handleCloseSolModel = (value, objMessage) => {
    setObjDeleteProps({
      slug: "",
      open: false,
      componentName: "",
    });
    setSessionMessage(objMessage);
  };

  const onHighestMatch = () => {
    if (coords) {
      setIsLoading(true);
      // api.get(`user/yourhighsoulmatematch?latitude=${coords?.latitude}&longitude=${coords?.longitude}&radius=25`)
      api
        .get(
          `user/yourhighsoulmatematch?latitude=undefined&longitude=undefined&radius=25&page=0&size=10`
        )
        .then((result) => {
          console.log("first result", result);
          if (result.success === "true") {
            setObjHighestMatch(result.data);
            setIsLoading(false);
          } else {
            console.log(result, "error");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err, "catch error");
          setIsLoading(false);
        });
    }
  };

  const onLoadd = async () => {
    api
      .get(`user/getcurrentplan`)
      .then((result) => {
        if (result.success === "true") {
          setexpired(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });

    api
      .get("user/getcurrentboosterplan")
      .then((result) => {
        if (result.success === "true") {
          setBoostExpired(result.data);
          setIsBoostSuccess(true); // Set success to true
        } else {
          console.log(result, "error");
          setIsBoostSuccess(false); // Set success to false
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
        setIsBoostSuccess(false); // Handle error scenario
      });
  };
  useEffect(() => {
    onLoadd();
  }, []);

  console.log(boostexpired, "expiredddd");

  const onLifestyleimage = async () => {
    // Api Call For Fetch The User Data
    api
      .get(`user/soulmate/lifestyleimage`)
      .then((result) => {
        if (result.success === "true") {
          console.log(result.data, "result");
          setObjIntrestImageMaster(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const onLoad = () => {
    setIsLoading(true);
    // Api Call For Fetch The User Data
    api
      .get(`user/viewprofile`)
      .then((result) => {
        console.log(result, "resultcfvch");
        if (result.success === "true") {
          setUserData(result.data);
          if (result.data.lifestyle_photos) {
            setLstLifeStyleImage(result.data.lifestyle_photos);
          }
          setUploadImagePath(result.data.profile_picture);
          // if (result.data.userCriteria) {
          const lstInTmp = result.data.interests.concat(
            result.data.planned_activities
          );
          setLstIntrestImage(lstInTmp);
          let lstOcupation = result.data.userCriteria.filter(
            (obj) => obj.id == 28
          );
          console.log(result?.data?.userCriteria, "call");
          setOcupation(lstOcupation[0].answer[0]);
          console.log(lstOcupation[0].answer[0], "lstOcupation.answer[0]");
          let lstTmp = result.data.userCriteria.map((obj) => {
            return {
              ...obj,
              answer: obj.answer[0],
              question: JSON.parse(obj.question)[0],
            };
          });
          setLstQuiz(lstTmp);
          console.log(lstTmp, "lstTmp");
          // }
          setIsLoading(false);
        } else {
          console.log(result, "error");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
        setIsLoading(false);
      });

    api
      .get(`user/getcurrentplan`)
      .then((result) => {
        console.log("result22222222", result);
        if (result.success === "true") {
          setTitle(result.data.subscription_data.title);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
    api
      .get(`user/getcurrentboosterplan`)
      .then((result) => {
        console.log("565474", result);
        if (result.success === "true") {
          setBoostTitle(result.data.subscription_data.title);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };
  const handleSliderClose = () => {
    setSliderOpen(false);
  };
  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getAge = (date) => {
    if (!date) {
      return;
    }
    let fullYear = new Date().getFullYear();
    const myArray = date.split("-");
    return fullYear - parseInt(myArray[0]);
  };

  const onLifeStyleChange = async (event) => {
    if (event.target.files.length + lstLifeStyleImage.length > 5) {
      setSessionMessage({
        message: `You can select maximum ${5 - lstLifeStyleImage.length} image`,
        type: "error",
        open: true,
      });
      return;
    }
    const lengthUploadFile = event.target.files.length;
    // let lstTmp = lstLifeStyleImage?.length ? [...lstLifeStyleImage] : []
    const data = new FormData();
    for (let i = 0; i < lengthUploadFile; i++) {
      const file = event.target.files[i];
      data.append("files", file);
    }

    api
      .post("user/uploadlifestylepic", {
        data: data,
      })
      .then((result) => {
        console.log(result, "result989898");
        if (result.success === "true") {
          let lstTmp = lstLifeStyleImage.concat(result.data);
          setLstLifeStyleImage([]);
          setLstLifeStyleImage(lstTmp);
          // onLoad()
        } else {
          console.log(result, "success === false");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  const onUploadimageChange = async (event, name) => {
    const file = event.target.files[0];
    // Base64
    var reader = new FileReader();
    reader.onloadend = function() {
      setUploadImagePath(reader.result);
    };
    reader.readAsDataURL(file);

    const data = new FormData();
    data.append("file", file);
    // Upload image
    api
      .post("user/uploadprofile", {
        data: data,
      })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem("userProfile", result.data);
          setAppContext({ ...appContext, userProfile: result.data });
        } else {
          console.log(result, "success === false");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  const comppressImageProfile = (e) => {
    const file = e?.target?.files[0];
    if (!file) {
      return;
    }
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const formData = new FormData();
        formData.append("file", result, result.name);
        onUploadimageComprace(e, formData, file);
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const onUploadimageComprace = async (e, data, file) => {
    // Base64
    var reader = new FileReader();
    reader.onloadend = function() {
      setUploadImagePath(reader.result);
    };
    reader.readAsDataURL(file);

    // Upload image
    api
      .post("user/uploadprofile", {
        data: data,
      })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem("userProfile", result.data);
          setAppContext({ ...appContext, userProfile: result.data });
        } else {
          console.log(result, "success === false");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  const newUpload = async (event) => {
    const files = event.target.files;
    if (event.target.files.length + lstLifeStyleImage.length > 5) {
      setSessionMessage({
        message: `You can select maximum ${5 - lstLifeStyleImage.length} image`,
        type: "error",
        open: true,
      });
      return;
    }
    setLoadingLifestyle(true);
    const formData = new FormData();
    async function compress(files) {
      for (const file of files) {
        await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.6,
            success(result) {
              formData.append("files", result);
              resolve();
            },
            error(err) {
              reject();
            },
          });
        });
      }
    }

    compress(files)
      .then((result) => {
        console.log(formData, "formData");
        console.log(result, "Compress success");
      })
      .catch((err) => {
        console.log("Compress error");
      })
      .finally((result) => {
        api
          .post("user/uploadlifestylepic", {
            data: formData,
          })
          .then((result) => {
            if (result.success === "true") {
              console.log(lstLifeStyleImage, "lstLifeStyleImage");
              console.log(
                lstLifeStyleImage.concat(result.data),
                "result.data[0]"
              );
              let lstTmp = lstLifeStyleImage.concat(result.data);
              setLstLifeStyleImage([]);
              setLstLifeStyleImage(lstTmp);
              // onLoad()
            } else {
              console.log(result, "success === false");
            }
            setLoadingLifestyle(false);
          })
          .catch((err) => {
            console.log(err, "error catch");
            setLoadingLifestyle(false);
          });
        // console.log(result, "Compress complete");
      });
  };

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgProfilePage}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid className={classes.container} item>
              {isLoading ? (
                <SkeletonLoading />
              ) : (
                <>
                  {userData && userData.first_name && (
                    <Grid spacing={2} container className={classes.space}>
                      <Grid item xs={12} sm={12} md={12} lg={8}>
                        <h5 className={classes.headingPage}> My Profile</h5>
                        <div className={classes.personalInfoRoot}>
                          <div className={classes.profileImageRoot}>
                            <Fancybox>
                              <a
                                data-fancybox="Images"
                                href={
                                  userData.profile_picture
                                    ? userData.profile_picture
                                    : "/images/male_defualt.svg"
                                }
                              >
                                <img
                                  className={classes.profileImage}
                                  src={
                                    userData.profile_picture
                                      ? userData.profile_picture
                                      : "/images/male_defualt.svg"
                                  }
                                  alt="userPhoto Load"
                                />
                              </a>
                            </Fancybox>
                            {/* <img
                            src={
                              uploadImagePath
                                ? uploadImagePath
                                : "/images/male_defualt.svg"
                            }
                            alt="Img"
                            className={classes.profileImage}
                          /> */}
                            <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="icon-button-file"
                              type="file"
                              onChange={(e) => {
                                // onUploadimageChange(e)
                                comppressImageProfile(e);
                              }}
                            />
                            <label htmlFor="icon-button-file">
                              <img
                                className={classes.editIconPro}
                                src="/images/matchUp/editIcon.svg"
                                alt="image"
                              />
                            </label>
                          </div>
                          <div style={{ marginLeft: "20px", width: "100%" }}>
                            <div className={classes.nameRoot}>
                              <div>
                                <div className={classes.name}>
                                  {userData.first_name} {userData.last_name},{" "}
                                  {getAge(userData.date_of_birth)}
                                </div>
                                <div className={classes.qulification}>
                                  {ocupation}
                                </div>
                              </div>
                              {/* <div>
                              <img src="/images/matchUp/msgSol.png" alt="Img" className={classes.messageIcon} />
                            </div> */}
                            </div>
                            {userData.bio && (
                              <div className={classes.aboutmeTitle}>
                                About me
                              </div>
                            )}
                            {userData.bio && (
                              <p
                                className={
                                  hideText === true
                                    ? classes.showtext
                                    : classes.textUserDetail
                                }
                              >
                                <Hidden smUp>
                                  {hideText === false
                                    ? userData.bio.slice(0, 40)
                                    : userData.bio}
                                </Hidden>
                                <Hidden xsDown>
                                  {hideText === false
                                    ? userData.bio.slice(0, 100)
                                    : userData.bio}
                                </Hidden>
                                {hideText ? (
                                  <p
                                    className={classes.viewMore}
                                    onClick={handleHideText}
                                  >
                                    Show Less
                                  </p>
                                ) : (
                                  <span
                                    className={classes.viewMore}
                                    onClick={handleShowText}
                                  >
                                    {userData.bio.length >= 40
                                      ? "...View More"
                                      : ""}
                                  </span>
                                )}
                              </p>
                            )}
                            {/* <Button
                            variant="outlined"
                            color="primary"
                            to="edit-profile"
                            component={Link}
                            className={classes.directMessageBtn}
                          >
                            <img
                              src="/images/matchUp/EditporfileIcon.svg"
                              alt="userPhotoLoad"
                              style={{ width: "15px", marginRight: "5px" }}
                            />
                            Edit Profile
                          </Button> */}
                            <div className={classes.detailGridBox}>
                              <Button
                                variant="outlined"
                                color="primary"
                                to="edit-profile"
                                component={Link}
                                className={
                                  hideText === false
                                    ? classes.directMessageBtn
                                    : classes.directMessageBtndata
                                }
                              >
                                <img
                                  src="/images/matchUp/EditporfileIcon.svg"
                                  alt="userPhotoLoad"
                                  style={{ width: "15px", marginRight: "5px" }}
                                />
                                Edit Profile
                              </Button>
                            </div>

                            <div className={classes.detailGridBox}>
                              <div
                                variant="outlined"
                                color="primary"
                                to="edit-profile"
                                component={Link}
                                className={classes.plandetails}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <h6
                                    style={{
                                      paddingRight: "0px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Subscription Plan:{" "}
                                  </h6>
                                  <div
                                    style={{
                                      fontSize: "15px",
                                    }}
                                  >
                                    {expired?.subscription_data?.title
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      expired?.subscription_data?.title?.slice(
                                        1
                                      )}
                                  </div>
                                </div>
                                {/* <span>
                                  ${expired?.subscription_data?.amount}
                                </span> */}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <h6 style={{ paddingRight: "0px" }}>
                                    Expiry Date:{" "}
                                  </h6>
                                  <div style={{ fontSize: "15px" }}>
                                    {expired?.subscription_date &&
                                    !isNaN(
                                      new Date(
                                        expired.subscription_date
                                      ).getTime()
                                    )
                                      ? new Date(
                                          expired.subscription_date
                                        ).toLocaleDateString("en-GB", {
                                          day: "2-digit",
                                          month: "2-digit",
                                          year: "numeric",
                                        })
                                      : null}
                                  </div>
                                </div>
                              </div>

                              {isBoostSuccess && (
                                <div
                                  variant="outlined"
                                  color="primary"
                                  to="edit-profile"
                                  component={Link}
                                  className={classes.plandetails}
                                >
                                  <h6>Boost Profile Plan: </h6>
                                  <div>
                                    {/* {boostexpired?.subscription_data?.title} */}

                                    {boostexpired?.subscription_data?.title
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      expired?.subscription_data?.title?.slice(
                                        1
                                      )}
                                  </div>
                                  <span>
                                    ${boostexpired?.subscription_data?.amount}
                                  </span>
                                  <div>
                                    {boostexpired?.subscription_date &&
                                    !isNaN(
                                      new Date(
                                        boostexpired.subscription_date
                                      ).getTime()
                                    )
                                      ? new Date(
                                          boostexpired.subscription_date
                                        ).toLocaleDateString("en-GB", {
                                          day: "2-digit",
                                          month: "2-digit",
                                          year: "numeric",
                                        })
                                      : null}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={4}>
                      <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center", position: "relative" }}>
                        <img src={objHighestMatch && objHighestMatch.yourMatchProfile ? objHighestMatch.yourMatchProfile : "/images/male_defualt.svg"} alt="Img" className={classes.imgGeighestMatch1} />
                        <div className={classes.imgGeighestMatch2}>
                          <img src="/images/matchUp/solRing.svg" alt="Img" className={classes.imgGeighestMatch2} />
                        </div>
                        <img src={objHighestMatch && objHighestMatch.yourProfile ? objHighestMatch.yourProfile : "/images/male_defualt.svg"} alt="Img" className={classes.imgGeighestMatch3} />
                      </div>
                    </Grid> */}
                      <Grid item xs={12} sm={12} md={12} lg={4}>
                        <div className={classes.solMatchRoot}>
                          <Box maxWidth={"276px"} p="35px">
                            {/* <div className={classes.solMatchImage}>
                              <img
                                src={
                                  objHighestMatch &&
                                  objHighestMatch.yourMatchProfile
                                    ? objHighestMatch.yourMatchProfile
                                    : "/images/male_defualt.svg"
                                }
                                alt="Img"
                                className={classes.solImage1}
                              />
                              <div className={classes.solRingRoot}>
                                <div>
                                  <img
                                    src="/images/matchUp/solRing.svg"
                                    alt="Img"
                                    className={classes.solRing}
                                  />
                                </div>
                                <img
                                  src="/images/matchUp/smallLogo.svg"
                                  alt="Img"
                                  className={classes.logo}
                                />
                                <div className={classes.textMatching}>
                                  {objHighestMatch &&
                                  objHighestMatch.matchPercent
                                    ? objHighestMatch.matchPercent
                                    : 0}
                                  %
                                </div>
                              </div>
                              <img
                                src={
                                  objHighestMatch && objHighestMatch.yourProfile
                                    ? objHighestMatch.yourProfile
                                    : "/images/male_defualt.svg"
                                }
                                alt="Img"
                                className={classes.solImage2}
                              />
                            </div> */}
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/your-matches`}
                            >
                              <div className={classes.solMatchSeeMore}>
                                {/* See More */}
                                Find Your Matches
                              </div>
                            </Link>
                          </Box>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={8}>
                        <Grid spacing={3} container>
                          <Grid item xs={12}>
                            <div className={classes.photosRoot}>
                              <Grid item xs={12} xl={12} lg={12}>
                                <div className={classes.photosTitle}>
                                  Photos
                                </div>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  className={classes.lifeStyleBox}
                                >
                                  {/* <ReactFancyBox > */}
                                  {lstLifeStyleImage ? (
                                    <>
                                      {lstLifeStyleImage.map((value, index) => {
                                        return (
                                          <div
                                            className={
                                              classes.imageLifStyleRoot
                                            }
                                          >
                                            <div
                                              className={classes.imageLifStyle}
                                            >
                                              <Fancybox>
                                                <a
                                                  data-fancybox="gallery"
                                                  href={value}
                                                >
                                                  <img
                                                    src={value}
                                                    alt="Property Image"
                                                    style={{
                                                      width: "96px",
                                                      height: "96px",
                                                      borderRadius: "8px",
                                                      top: 2,
                                                      position: "relative",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                </a>
                                              </Fancybox>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography
                                        variant="caption"
                                        component="primary"
                                        color="primary"
                                        style={{
                                          fontSize: "14px",
                                          color: "gray",
                                          fontWeight: "400",
                                        }}
                                      >
                                        No Lifestyle Photo
                                      </Typography>
                                    </div>
                                  )}
                                  {lstLifeStyleImage.length <= 4 && (
                                    <Box className={classes.changeImageTap}>
                                      <Box className={classes.iconTapBorder}>
                                        <input
                                          accept="image/*"
                                          style={{ display: "none" }}
                                          id="life-style-1"
                                          type="file"
                                          multiple
                                          disabled={loadingLifestyle}
                                          // onChange={(e) => { onLifeStyleChange(e) }}
                                          onChange={(e) => {
                                            newUpload(e);
                                          }}
                                        />
                                        <label htmlFor="life-style-1">
                                          <Box
                                            className={classes.iconTapBorder}
                                          >
                                            {loadingLifestyle ? (
                                              <CircularProgress size={20} />
                                            ) : (
                                              <AddIcon
                                                style={{ color: "#FE4B6B" }}
                                              />
                                            )}
                                          </Box>
                                        </label>
                                      </Box>
                                    </Box>
                                  )}
                                  {/* </ReactFancyBox> */}
                                </Box>
                              </Grid>
                              {/* <div className={classes.photosTitle}>
                              Photos
                            </div>
                            <div className={classes.photos}>
                              {lstLifeStyleImage.length > 0 ? lstLifeStyleImage.map((value) => {
                                return (
                                  <img src={value ? value : "/images/matchUp/sol1.jpg"} alt="Img" className={classes.solphotos} />
                                )
                              })
                                :
                                <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100px", alignItems: "center" }}>
                                  <Typography
                                    variant="caption"
                                    component="primary"
                                    color="primary"
                                    style={{ fontSize: "12px", color: "gray", fontWeight: "400" }}
                                  >
                                    You have no photos
                                  </Typography>
                                </div>
                              }
                            </div> */}
                            </div>

                            <div className={classes.lifestyleRoot}>
                              <div className={classes.lifestyleTitle}>
                                Lifestyle
                              </div>

                              <Grid spacing={1} container>
                                {lstIntrestImage.map((value) => {
                                  let src =
                                    objIntrestImageMaster.length &&
                                    objIntrestImageMaster?.filter((obj) => {
                                      return (
                                        obj?.title.toLowerCase() ==
                                        value.toLowerCase()
                                      );
                                    });
                                  return (
                                    <Grid item xs={4} sm={4} md={3}>
                                      <div className={classes.lifestyleCard}>
                                        <img
                                          src={
                                            src && src.length && src[0].image
                                          }
                                          alt="Img"
                                          className={classes.lifestylePhotos}
                                        />
                                        <div className={classes.lifestyleText}>
                                          {value}
                                        </div>
                                      </div>
                                    </Grid>
                                  );
                                })}
                                {/* <Grid item xs={4} sm={4} md={3}>
                                <div className={classes.lifestyleCard} style={{ cursor: "pointer" }}>
                                  <div className={classes.AddIcon}>
                                    +
                                  </div>
                                </div>
                              </Grid> */}
                              </Grid>
                            </div>
                          </Grid>
                          {/* <Grid item xs={12}>

                        </Grid> */}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={4}
                        className={classes.spacingBottom}
                      >
                        <div className={classes.factfileRoot}>
                          <div className={classes.factfileTitle}>Factfile</div>
                          <div>
                            {lstQuiz.map((obj) => {
                              return (
                                <div
                                  className={classes.factfileCardRoot}
                                  onClick={(e) => {
                                    handleClickOpen(obj);
                                  }}
                                  style={{ position: "relative" }}
                                >
                                  <div>
                                    <img
                                      src={quizMaster[obj.id]}
                                      alt="Img"
                                      className={classes.factfilephotos}
                                    />
                                  </div>
                                  <div style={{ flexGrow: 1 }}>
                                    <div className={classes.question}>
                                      {obj.question}
                                    </div>
                                    <div className={classes.answer}>
                                      {obj.answer}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      justifySelf: "flex-end",
                                      position: "absolute",
                                      top: "-11px",
                                      left: "30px",
                                    }}
                                  >
                                    <img
                                      src={"/images/matchUp/editIcon.svg"}
                                      alt="Img"
                                      width={"10px"}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <SliderModel
        open={sliderOpen}
        onClose={handleSliderClose}
        lifestyle_photos={userData.lifestyle_photos}
      />
      <SolQuizeDialog
        open={objDeleteProps.open}
        onClose={handleCloseSolModel}
        objQuiz={objQuiz}
        onLoad={onLoad}
      />
    </>
  );
};

const SkeletonLoading = () => {
  return (
    <Grid
      item
      xs={12}
      md={12}
      style={{ marginLeft: "16px", marginTop: "10px" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Skeleton variant="rect" height={200} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Skeleton variant="rect" height={200} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={100} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={350} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Skeleton variant="rect" height={473} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MyProfileDetail;
