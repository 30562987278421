import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  Typography,
  IconButton,
  Button,
  Container,
  Avatar,
  Box,
  Tooltip,
  Select,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TelegramIcon from "@material-ui/icons/Telegram";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import { withStyles } from "@material-ui/core/styles";
import { Link, useHistory, useLocation } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import ModuleMates from "./ModuleMates";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Divider from "@material-ui/core/Divider";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import moment from "moment";
import Api from "../../../../Helper/ApiHandler";
import { async } from "validate.js";
import useRouter from "utils/useRouter";
import AppDataContext from "Helper/AppDataContext";
var api = new Api();

const useStyles = makeStyles((theme) => ({
  root: {},
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: "100%",
  },
  toolbar: {
    // maxWidth: theme.layout.contentWidth,
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(0, 2),
    // background: "white",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    [theme.breakpoints.down("xs")]: {
      height: "70px",
    },
  },
  listItemText: {
    flex: "0 0 auto",
    whiteSpace: "nowrap",
    textDecoration: "none",
    //fontFamily: "Gilroy !important",
    color: "white",
    fontSize: "14px",
  },
  detailTopBar: {
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    height: "65px",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      marginRight: "10px",
    },
  },
  avtarImage: {
    marginLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      // width: "100%",
      display: "none",
    },
    "& .MuiAvatar-root": {
      width: "50px",
      height: "50px",
    },
    cursor: "pointer",
  },
  iconMessage: {
    color: "white",
    fontSize: "26px",
    marginLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px",
    },
    cursor: "pointer",
  },
  profileMenuImg: {
    width: "50px",
    height: "50px",
    borderRadius: "50px",
    objectFit: "cover",
    objectPosition: "top",
  },
  nameHeadingMenu: {
    margin: "0",
    fontSize: "18px",
    marginLeft: "10px",
    fontWeight: "600",
    //fontFamily: "Gilroy !important",
  },
  universitytext: {
    margin: "0",
    fontSize: "12px",
    marginLeft: "10px",
    color: "gray",
  },
  editProfileLink: {
    margin: "0",
    marginLeft: "10px",
  },
  linkMenu: {
    textDecoration: "none",
    color: "black",
  },
  nameHeadingNotification: {
    //fontFamily: "Gilroy !important",
    margin: "0",
    fontSize: "14px",
    fontWeight: "600",
  },
  imageNotification: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  imageNotificationRoot: {
    minWidth: 0,
  },
  name: {
    fontWeight: "600",
  },
  timeMessage: {
    fontWeight: "400",
    fontSize: "12px",
  },
  timeNotifiction: {
    // marginLeft: "5px",
    color: "gray",
    opacity: 0.5,
    fontWeight: "400",
  },
  seeAllNotification: {
    textAlign: "center",
    width: "100%",
    //fontFamily: "Gilroy !important",
    margin: "0",
    fontSize: "14px",
    marginLeft: "10px",
    color: "#0071CE",
    textDecoration: "underline",
    fontWeight: "600",
  },
  moduleHeading: {
    color: "white",
    marginLeft: "3px",
    fontSize: "14px !important",
    fontWeight: "400",
  },
  moduleBox: {
    display: "flex",
    alignItems: "center",
    color: "white",
    marginRight: "10px",
    [theme.breakpoints.down("xs")]: {
      // width: "100%",
      display: "none",
    },
  },
  textChange: {
    margin: "0",
    fontSize: "13px",
    fontWeight: 400,
  },
  notificationDetail: {
    margin: "0",
    fontSize: "12px",
    whiteSpace: "normal",
    width: "100%",
    fontWeight: "900",
    padding: "0px 0px 0px 10px",
  },
  moduleItem: {
    margin: "0",
    //fontFamily: "Gilroy !important",
  },
  logoMatchup: {
    [theme.breakpoints.down("sm")]: {
      width: "130%",
      "& img": {
        width: "35%",
      },
    },
    [theme.breakpoints.up("sm")]: {
      width: "180px",
      "& img": {
        width: "75%",
      },
    },
    marginTop: "5px",
  },
  iconButton: {
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      right: "0",
      top: "18px",
      color: "white",
      marginRight: "5px",
    },
  },
  LogoGrid: {
    alignSelf: "center",
  },
  notificationCount: {
    [theme.breakpoints.down("xs")]: {
      top: "15px",
      right: "-14px",
    },
    background: "white",
    color: "red",
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    right: "-24px",
    top: "15px",
    fontSize: "11px",
  },
  selectRoot: {
    "& .MuiSelect-select:focus": {
      backgroundColor: "rgb(0 0 0 / 0%)",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      color: "white",
      fontSize: "14px",
    },
    "& .MuiSelect-icon": {
      color: "white",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  selectItem: {
    fontSize: "14px",
  },
  popoverClasses: {
    "& .Mui-selected:hover ": {
      backgroundColor: "black",
    },
  },
}));

const notificationTest = [
  {
    message: "John Smith send dormmate request you.",
    image: "/images/matchUp/matchesMen.png",
  },
  {
    message: "Emaily Thamos send dormmate request you.",
    image: "/images/matchUp/dormmates1.png",
  },
  {
    message: "Isabella Taylor send dormmate request you.",
    image: "/images/matchUp/dormmates2.png",
  },
  {
    message: "John Smith send dormmate request you.",
    image: "/images/matchUp/matchesMen.png",
  },
  {
    message: "Jacson Allen send dormmate request you.",
    image: "/images/matchUp/dormmates3.png",
  },
];

const StyledMenu = withStyles({
  paper: {
    // border: "1px solid #d3d4d5",
    marginTop: "8px",
    borderRadius: "5px",
    boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.2)",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    // "&:focus": {
    //   backgroundColor: "#e5e5e5",
    //   "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
    //     color: "black",
    //   },
    // },
    // borderBottom: `1px solid #dedede`,
  },
}))(MenuItem);

const LoginTopBar = (props) => {
  const { onLoginSidebarOpen, pages, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();
  const router = useRouter();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notification, setNotification] = React.useState(null);
  const [module, setModule] = React.useState(null);
  const [userName, setUserName] = React.useState("");
  const [isOrgUser, setIsOrgUser] = React.useState(false);
  const [userNameUniversityName, setUserNameUniversityName] = React.useState(
    ""
  );
  const [profile_picture, setProfile_picture] = React.useState("");
  const [lstNotification, setLstNotification] = React.useState([]);
  const [unreadNotification, setUnreadNotification] = React.useState(0);
  const [selected, setSelected] = React.useState("");
  const isSignupDeatils = localStorage.getItem("signupDeatils");
  const { appContext, setAppContext } = useContext(AppDataContext);

  //
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickNotification = (event) => {
    setNotification(event.currentTarget);
  };

  const handleClickModule = (event) => {
    setModule(event.currentTarget);
  };

  const handleCloseModule = (e) => {
    setModule(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseNotification = () => {
    setNotification(null);
  };

  const logOutHandler = async () => {
    let device_token = await AsyncLocalStorage.getItem("device_token");
    let objData = { device_token: device_token ? device_token : "" };
    localStorage.clear();
    history.push("/");
    // {live-url}}api/user/logout
    api
      .post(`user/logout`, {
        data: objData,
      })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem(
            "device_token",
            device_token ? device_token : ""
          );
          handleClose();
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };
  const onProfile = (slug) => {
    handleCloseNotification();
    history.push(`/your-matches/view/${slug}`);
  };
  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    let userProfile = await AsyncLocalStorage.getItem("userProfile");
    let Username = capitalizeFirstLetter(
      await AsyncLocalStorage.getItem("userName")
    );
    let University = await AsyncLocalStorage.getItem("university");
    let tmp = await AsyncLocalStorage.getItem("currentModule");
    let isOrgUser1 = await AsyncLocalStorage.getItem("isOrgUser");
    setSelected(tmp);
    setProfile_picture(userProfile);
    setUserName(Username);
    setUserNameUniversityName(University);
    setIsOrgUser(isOrgUser1);

    api
      .get(`user/notificationlist?page=${0}&size=${5}`)
      .then((result) => {
        if (result.success === "true") {
          setLstNotification(result.data.user);
          // setUnreadNotification()
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });

    api
      .get(`user/unreadnotification`)
      .then((result) => {
        if (result.success === "true") {
          setUnreadNotification(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };
  function onSeeAll() {
    // Api Call For Read notification
    api
      .get(`user/readallnotification`)
      .then((result) => {
        if (result.success === "true") {
          handleCloseNotification();
          history.push("/notifications");
          setUnreadNotification(0);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  }
  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }
  const siwtchModule = (e) => {
    handleCloseModule(e);
    // user/switchmodule
    api
      .put("user/switchmodule", {
        data: {
          module_name: e.target.value,
        },
      })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem("currentModule", e.target.value);
          AsyncLocalStorage.setItem("tabValue", 0);
          AsyncLocalStorage.setItem("tabFavValue", 0);
          if (e.target.value === "Dormmates") {
            // if (process.env.REACT_APP_DORMMATES == "true") {
            if (appContext.objAuth.DORMMATE) {
              if (!result.data.hasUniversity) {
                // router.history.push("/select-university");
                router.history.push({
                  pathname: `/select-university`,
                  search: "",
                  state: {
                    step3: true,
                  },
                });
              } else if (!result.data.quiz) {
                router.history.push("/matching-quiz");
              } else {
                router.history.push("/my-profile");
              }
            } else {
              router.history.push("/comminsoon");
            }
          } else if (e.target.value === "Roommates") {
            if (appContext.objAuth.ROOMMATE) {
              if (!result.data.hasUniversity) {
                // router.history.push("/select-university");
                router.history.push({
                  pathname: `/select-university`,
                  search: "",
                  state: {
                    step3: true,
                  },
                });
              } else if (!result.data.quiz) {
                router.history.push("/matching-quiz");
              } else {
                router.history.push("/my-profile");
              }
            } else {
              router.history.push("/comminsoon");
            }
          } else if (e.target.value === "Teammates") {
            if (appContext.objAuth.TEAMMATE) {
              router.history.push("/my-profile");
              // if (!result.data.quiz) {
              //   router.history.push("/matching-quiz");
              // } else {
              //   router.history.push("/your-profile");
              // }
            } else {
              router.history.push("/comminsoon");
            }
          } else if (e.target.value === "Soulmates") {
            if (appContext.objAuth.SOULMATE) {
              if (!result.data.quiz) {
                router.history.push("/matching-quiz");
              } else {
                router.history.push("/my-profile");
              }
            } else {
              router.history.push("/comminsoon");
            }
          } else {
            router.history.push("/comminsoon");
          }
        } else {
        }
      })
      .catch((err) => {});
  };

  let query = useLocation();
  const currantPath = query?.pathname;
  return (
    <>
      <Toolbar disableGutters className={classes.toolbar} {...rest}>
        <Container maxWidth="lg">
          <Grid
            container
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xl={2} lg={2} xs={6} sm={6} className={classes.LogoGrid}>
              <a to="/">
                <img
                  className={classes.logoMatchup}
                  src={"/images/matchUp/loginBarLogo.svg"}
                  // width={'75%'}
                  alt={"Alt Image"}
                />
              </a>
            </Grid>
            <Grid item xl={4} lg={6} xs={0} sm={0}>
              <div className={classes.flexGrow} />
              <Hidden smDown>
                <List className={classes.navigationContainer}>
                  <li>
                    <a
                      color="textSecondary"
                      className={classes.listItemText}
                      // component="a"
                      to="/"
                      style={
                        currantPath === "/"
                          ? { color: "white", borderBottom: "2px solid white" }
                          : {}
                      }
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      color="textSecondary"
                      className={classes.listItemText}
                      to="/how-it-work"
                      style={
                        currantPath === "/how-it-work"
                          ? { color: "white", borderBottom: "2px solid white" }
                          : {}
                      }
                    >
                      How it Works
                    </a>
                  </li>
                  <li>
                    <a
                      color="textSecondary"
                      className={classes.listItemText}
                      to="/contact-us"
                      style={
                        currantPath === "/contact-us"
                          ? { color: "white", borderBottom: "2px solid white" }
                          : {}
                      }
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      color="textSecondary"
                      className={classes.listItemText}
                      href="/about-us"
                      style={
                        currantPath === "/about-us"
                          ? { color: "white", borderBottom: "2px solid white" }
                          : {}
                      }
                    >
                      About us
                    </a>
                  </li>
                </List>
              </Hidden>
            </Grid>
            {isSignupDeatils ? (
              <Grid item xl={4} lg={4} xs={4}>
                <div className={classes.detailTopBar}>
                  {/* {appContext.objAuth?.LIVE_MODULE_COUNT > 1 && <div className={classes.selectRoot}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selected}
                      disableUnderline
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                        PopoverClasses: classes.popoverClasses,
                      }}
                      onChange={(e) => siwtchModule(e)}
                    >
                      {appContext.objAuth?.DORMMATE && (
                        <MenuItem
                          value={"Dormmates"}
                          className={classes.selectItem}
                        >
                          DormMates
                        </MenuItem>
                      )}
                      {appContext.objAuth?.TEAMMATE && (
                        <MenuItem
                          value={"Teammates"}
                          className={classes.selectItem}
                        >
                          TeamMates
                        </MenuItem>
                      )}
                      {appContext.objAuth?.ROOMMATE && (
                        <MenuItem
                          value={"Roommates"}
                          className={classes.selectItem}
                        >
                          RoomMates
                        </MenuItem>
                      )}
                      {appContext.objAuth?.SOULMATE && (
                        <MenuItem
                          value={"Soulmates"}
                          className={classes.selectItem}
                        >
                          SoulMates
                        </MenuItem>
                      )}
                    </Select>
                  </div>} */}
                  {/* <Box onClick={handleClickModule} className={classes.moduleBox}>
                  <h6 className={classes.moduleHeading}> Module </h6>
                  <ExpandMoreIcon />
                </Box> */}
                  <Box onClick={handleClickNotification}>
                    <Tooltip title="Notification" placement="top">
                      <div style={{ position: "relative", bottom: "6px" }}>
                        <div className={classes.notificationCount}>
                          {unreadNotification}
                        </div>
                        <NotificationsNoneIcon
                          className={classes.iconMessage}
                        />
                      </div>
                    </Tooltip>
                  </Box>
                  <a
                    href={
                      router.match.path == "/messages/:uid"
                        ? router.match.url
                        : "/messages"
                    }
                  >
                    <Tooltip title="Message" placement="top">
                      <TelegramIcon className={classes.iconMessage} />
                    </Tooltip>
                  </a>
                  <div className={classes.avtarImage}>
                    <Tooltip title="Profile" placement="top">
                      <Avatar
                        alt={userName}
                        src={profile_picture}
                        onClick={handleClick}
                      />
                    </Tooltip>
                  </div>
                  <ModuleMates
                    module={module}
                    handleCloseModule={handleCloseModule}
                  />
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={notification}
                    keepMounted
                    open={Boolean(notification)}
                    onClose={handleCloseNotification}
                    style={{ width: "325px" }}
                  >
                    {/* <ArrowDropUpIcon style={{position:"absolute",left:"200px"}}/> */}
                    <StyledMenuItem
                      style={{ backgroundColor: "white", width: "334px" }}
                    >
                      <p className={classes.nameHeadingNotification}>
                        Notification
                      </p>
                    </StyledMenuItem>
                    {lstNotification.length ? (
                      lstNotification.map((item, index) => (
                        <>
                          <Divider />
                          <StyledMenuItem
                            style={{ marginRight: "5px" }}
                            onClick={() => {
                              onProfile(item.slug);
                            }}
                          >
                            <ListItemIcon
                              className={classes.imageNotificationRoot}
                            >
                              <img
                                className={classes.imageNotification}
                                src={
                                  item.profile_picture
                                    ? item.profile_picture
                                    : "/images/male_defualt.svg"
                                }
                                alt={"Alt Image"}
                              />
                            </ListItemIcon>
                            {/* <ListItemText primary={item.message} /> */}
                            <p className={classes.notificationDetail}>
                              <span className={classes.name}>
                                {" "}
                                {item.name}{" "}
                              </span>
                              <span className={classes.timeMessage}>
                                {" "}
                                {item.message}{" "}
                              </span>
                              <div className={classes.timeNotifiction}>
                                {" "}
                                {moment(new Date(item.createdAt)).fromNow()}
                              </div>
                            </p>
                          </StyledMenuItem>
                        </>
                      ))
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          height: "200px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          component="primary"
                          color="primary"
                          style={{
                            fontSize: "12px",
                            color: "gray",
                            fontWeight: "400",
                          }}
                        >
                          You have no notifications
                        </Typography>
                      </div>
                    )}
                    <Divider />
                    <StyledMenuItem>
                      <p
                        className={classes.seeAllNotification}
                        onClick={() => {
                          onSeeAll();
                        }}
                      >
                        See All
                      </p>
                    </StyledMenuItem>
                  </StyledMenu>

                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    {isSignupDeatils ? (
                      <>
                        <StyledMenuItem
                          style={{ backgroundColor: "white" }}
                          onClick={handleClose}
                        >
                          <ListItemIcon>
                            <Avatar
                              className={classes.profileMenuImg}
                              src={
                                !profile_picture && profile_picture != "null"
                                  ? profile_picture
                                  : "/images/male_defualt.svg"
                              }
                              alt={"Alt Image"}
                            />
                            {/* <img
                              className={classes.profileMenuImg}
                              src={
                                !profile_picture && profile_picture != "null"
                                  ? profile_picture
                                  : "/images/male_defualt.svg"
                              }
                              alt={"Alt Image"}
                            /> */}
                          </ListItemIcon>
                          <Grid>
                            <a className={classes.linkMenu} to="/my-profile">
                              <h5 className={classes.nameHeadingMenu}>
                                {userName}
                              </h5>
                              <p className={classes.universitytext}>
                                {isOrgUser == "true" && "Org: "}
                                {userNameUniversityName !== "undefined" &&
                                  userNameUniversityName}
                              </p>
                            </a>
                          </Grid>
                        </StyledMenuItem>
                        <Divider style={{ margin: "5px 0px" }} />
                        <a
                          className={classes.linkMenu}
                          to="/your-matches"
                          onClick={handleClose}
                        >
                          <StyledMenuItem>
                            <ListItemIcon>
                              <img
                                src="/images/matchUp/Handshake.svg"
                                alt={"Alt Image"}
                              />
                            </ListItemIcon>
                            <p className={classes.textChange}>Your Matches</p>
                          </StyledMenuItem>
                        </a>
                        <Divider style={{ margin: "5px 10px" }} />
                        {/* <a className={classes.linkMenu} to="/reset-password" onClick={handleClose}>
                          <StyledMenuItem>
                            <ListItemIcon>
                              <img src="/images/matchUp/changePassword.svg" />
                            </ListItemIcon>
                            <p className={classes.textChange}>Change Password</p>
                          </StyledMenuItem>
                        </a>
                        <Divider style={{ margin: "5px 10px" }} /> */}
                      </>
                    ) : (
                      ""
                    )}
                    <StyledMenuItem onClick={() => logOutHandler()}>
                      <ListItemIcon>
                        <img
                          src="/images/matchUp/logout.svg"
                          alt={"Alt Image"}
                        />
                      </ListItemIcon>
                      {/* <ListItemText primary="Log Out" /> */}
                      <p className={classes.textChange}>Log Out</p>
                    </StyledMenuItem>
                  </StyledMenu>
                </div>
              </Grid>
            ) : (
              <Grid item xl={4} lg={4} xs={4}>
                <div className={classes.detailTopBar}>
                  {/* <div className={classes.selectRoot}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selected}
                      disableUnderline
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: { vertical: "bottom", horizontal: "center" },
                        transformOrigin: { vertical: "top", horizontal: "center" },
                        PopoverClasses: classes.popoverClasses
                      }}
                      onChange={(e) => siwtchModule(e)}
                    >
                      <MenuItem value={"Dormmates"} className={classes.selectItem}>DormMates</MenuItem>
                      <MenuItem value={"Teammates"} className={classes.selectItem}>TeamMates</MenuItem>
                      <MenuItem value={"Roommates"} className={classes.selectItem}>RoomMates</MenuItem>
                      <MenuItem value={"Soulmates"} className={classes.selectItem}>SoulMates</MenuItem>
                    </Select>
                  </div> */}

                  {/* <Box onClick={handleClickNotification}>
                    <Tooltip title="Notification" placement="top">
                      <div style={{ position: "relative", bottom: "6px" }}>
                        <div className={classes.notificationCount}>
                          {unreadNotification}
                        </div>
                        <NotificationsNoneIcon className={classes.iconMessage} />
                      </div>
                    </Tooltip>
                  </Box>
                  <a to="/messages">
                    <Tooltip title="Message" placement="top">
                      <TelegramIcon className={classes.iconMessage} />
                    </Tooltip>
                  </a> */}
                  <div className={classes.avtarImage}>
                    <Tooltip title="Profile" placement="top">
                      <Avatar
                        alt={userName}
                        src={profile_picture}
                        onClick={handleClick}
                      />
                    </Tooltip>
                  </div>
                  <ModuleMates
                    module={module}
                    handleCloseModule={handleCloseModule}
                  />
                  {/* <StyledMenu
                    id="customized-menu"
                    anchorEl={notification}
                    keepMounted
                    open={Boolean(notification)}
                    onClose={handleCloseNotification}
                    style={{ width: "325px" }}
                  >
                  
                    <StyledMenuItem style={{ backgroundColor: "white", width: "334px" }}>
                      <p className={classes.nameHeadingNotification}>
                        Notification
                      </p>
                    </StyledMenuItem>
                    {lstNotification.length ?
                      lstNotification.map((item, index) => (
                        <>
                          <Divider />
                          <StyledMenuItem style={{ marginRight: "5px" }} onClick={() => { onProfile(item.slug) }}>
                            <ListItemIcon className={classes.imageNotificationRoot}>
                              <img
                                className={classes.imageNotification}
                                src={item.profile_picture ? item.profile_picture : "/images/male_defualt.svg"}
                              />
                            </ListItemIcon>
                            
                            <p className={classes.notificationDetail}>
                              <span className={classes.name}> {item.name} </span>
                              <span className={classes.timeMessage}> {item.message} </span>
                              <div className={classes.timeNotifiction}> {moment(new Date(item.createdAt)).fromNow()}</div>
                            </p>
                          </StyledMenuItem>
                        </>
                      )) :
                      <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "200px", alignItems: "center" }}>
                        <Typography
                          variant="caption"
                          component="primary"
                          color="primary"
                          style={{ fontSize: "12px", color: "gray", fontWeight: "400" }}
                        >
                          You have no notifications
                        </Typography>
                      </div>
                    }
                    <Divider />
                    <StyledMenuItem>
                      <p
                        className={classes.seeAllNotification}
                        onClick={() => {
                          onSeeAll()
                        }}
                      >
                        See All
                      </p>
                    </StyledMenuItem>
                  </StyledMenu> */}

                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    {/* <StyledMenuItem style={{ backgroundColor: "white" }} onClick={handleClose}>
                      <ListItemIcon>
                        <img
                          className={classes.profileMenuImg}
                          src={profile_picture != "null" ? profile_picture : "/images/male_defualt.svg"}
                        />
                      </ListItemIcon>
                      <Grid>
                        <a className={classes.linkMenu} to="/my-profile">
                          <h5 className={classes.nameHeadingMenu}>{userName}</h5>
                          <p className={classes.universitytext}>
                            {userNameUniversityName !== "undefined" && userNameUniversityName}
                          </p>
                        </a>
                      </Grid>
                    </StyledMenuItem>
                    <Divider style={{ margin: "5px 0px" }} /> */}
                    <a
                      className={classes.linkMenu}
                      to="/signup-details"
                      onClick={handleClose}
                    >
                      <StyledMenuItem>
                        <ListItemIcon>
                          <img
                            src="/images/matchUp/Handshake.svg"
                            alt={"Alt Image"}
                          />
                        </ListItemIcon>
                        <p className={classes.textChange}>Signup details</p>
                      </StyledMenuItem>
                    </a>
                    <Divider style={{ margin: "5px 10px" }} />
                    {/* <a className={classes.linkMenu} to="/reset-password" onClick={handleClose}>
                      <StyledMenuItem>
                        <ListItemIcon>
                          <img src="/images/matchUp/changePassword.svg" />
                        </ListItemIcon>
                        <p className={classes.textChange}>Change Password</p>
                      </StyledMenuItem>
                    </a>
                    <Divider style={{ margin: "5px 10px" }} /> */}

                    <StyledMenuItem onClick={() => logOutHandler()}>
                      <ListItemIcon>
                        <img
                          src="/images/matchUp/logout.svg"
                          alt={"Alt Image"}
                        />
                      </ListItemIcon>
                      {/* <ListItemText primary="Log Out" /> */}
                      <p className={classes.textChange}>Log Out</p>
                    </StyledMenuItem>
                  </StyledMenu>
                </div>
              </Grid>
            )}
          </Grid>
          <Hidden mdUp>
            <IconButton
              className={classes.iconButton}
              onClick={onLoginSidebarOpen}
              aria-label="Menu"
            >
              <MenuIcon style={{ fontSize: "30px" }} />
            </IconButton>
          </Hidden>
        </Container>
      </Toolbar>
    </>
  );
};

LoginTopBar.propTypes = {
  onLoginSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired,
};

export default LoginTopBar;

// <StyledMenu
//                   id="customized-menu"
//                   anchorEl={module}
//                   keepMounted
//                   open={Boolean(module)}
//                   onClose={handleCloseModule}
//                 >
//                   <StyledMenuItem>
//                     {/* <ListItemText primary="DormMates" /> */}
//                     <p className={classes.moduleItem}> DormMates </p>
//                   </StyledMenuItem>
//                   <StyledMenuItem>
//                     {/* <ListItemText primary="TeamMates" /> */}
//                     <p className={classes.moduleItem}> TeamMates </p>
//                   </StyledMenuItem>
//                   <StyledMenuItem>
//                     {/* <ListItemText primary="RoomMates" /> */}
//                     <p className={classes.moduleItem}> RoomMates </p>
//                   </StyledMenuItem>
//                   <StyledMenuItem>
//                     {/* <ListItemText primary="SoulMates" /> */}
//                     <p className={classes.moduleItem}> SoulMates </p>
//                   </StyledMenuItem>
//                 </StyledMenu>
