import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Api from "../../../../Helper/ApiHandler";
import useRouter from "utils/useRouter";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Payment from "views/SubscriptionPlan/component/Subscription/Payment";
import YourCurrentPlan from "views/CurrentPlan/component/YourCurrentPlan";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import { useLocation } from "react-router-dom";
import moment from "moment";

var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const schema = {
  first_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  last_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: { maximum: 254 },
  },
  zodiac: {
    presence: { allowEmpty: false, message: "is required" },
  },
  p_university: {
    presence: { allowEmpty: false, message: "is required" },
  },
  location: {
    presence: { allowEmpty: false, message: "is required" },
  },
  bio: {
    // presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 250 },
  },
  date_of_birth: {
    presence: { allowEmpty: false, message: "is required" },
  },
  gender: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  bgEditProfile: {
    minHeight: "calc(100vh - 125px)",
    background: "#E9EDF0",
    padding: "35px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      marginBottom: "10px",
    },
    "& .MuiContainer-root": {
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        overflowX: "hidden",
      },
    },
  },
  whiteCardProfile: {
    background: "white",
    borderRadius: "10px",
    // marginLeft: "16px",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "8px",
    },
  },
  changeHeading: {
    color: "gray",
    fontSize: "15px !important",
    margin: "5px 15px 25px 15px",
    fontWeight: "400",
  },
  changeHeadingsummry: {
    color: "gray",
    fontSize: "14px",
    margin: "5px 5px",
    fontWeight: "400",
  },
  textRoot: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
  },
  formControl: {
    width: "100%",
  },
  chip: {
    background: "#d5606045",
    borderRadius: "5px",
    margin: "5px 5px",
    padding: "0 0",
    height: "28px",
  },
  confirmButton: {
    margin: "0 5px",
    textTransform: "capitalize",
    padding: "5px 70px",
    fontSize: "16px",
    borderRadius: "50px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 20px",
      margin: "5px",
    },
    width: "100px",
    height: "48px",
    boxShadow: "none",
  },
  buttonGroupConfirm: {
    textAlign: "center",
    margin: "10px",
    display: "flex",
    justifyContent: "end",
  },
  hrLine: {
    borderBottom: "2px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "30% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "25%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "25%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "53%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "53%",
    },
  },
  summrydata: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "20%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "22%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "22%",
    },
  },
  googleSelecte: {
    "& .css-yk16xz-control": {
      borderRadius: "10px",
      padding: "8px",
    },
    "& .css-1pahdxg-control": {
      borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      padding: "8px",
    },
    "& .css-1pahdxg-control:hover": {
      borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      padding: "8px",
    },
    "& .css-1uccc91-singleValue": {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
    },
    "& .css-1wa3eu0-placeholder": {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
    },
    "& div": {
      fontFamily: "'Poppins', sans-serif",
      backgroundColor: "white",
      zIndex: 11,
      marginLeft: "-1px",
    },
  },
  EditProfilLable: {
    padding: "0 0 0px 10px",
    fontSize: "17px",
    fontWeight: "600 !important",
    margin: "15px 0px",
  },
  Paymentheader: {
    fontSize: "17px",
    fontWeight: "600 !important",
    margin: "10px 0px",
  },
  EditProfilLableorder: {
    fontSize: "17px",
    fontWeight: "600 !important",
    padding: "10px 0px",
  },
  EditCrentpaln: {
    fontSize: "17px",
    fontWeight: "600 !important",
    padding: "10px 0px",
  },
  backRoot: {
    maxWidth: "fit-content",
    cursor: "pointer",
    marginLeft: "10px",
    alignItems: "center",
    display: "flex",
  },
  googleAutoLabel: {
    color: "#f44336",
    marginLeft: "14px",
    marginRight: "14px",
    margin: 0,
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: " left",
    fontFamily: "Poppins",
    fontweight: "400",
    lineHeight: "1.66",
  },
  dialogContent: {
    padding: "0px 24px 24px 24px",
    // textAlign: "center",
  },
}));

const YourSubscriptionsplan = (props) => {
  const { className, ...rest } = props;
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();
  const [userData, setUserData] = React.useState({});
  const [uploadImagePath, setUploadImagePath] = React.useState("");
  const [lstLifeStyleImage, setLstLifeStyleImage] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [discount, setdiscount] = useState("");

  const [loadingStart, setLoadingStart] = useState(false);
  const [isOrgUser, setIsOrgUser] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState(false);
  const [currant_module, setCurrant_module] = React.useState("");
  const [paymentAmount, setPaymentAmount] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [openPayment, setOpenPayment] = React.useState(false);
  const [lstPaymentLog, setLstPaymentLog] = React.useState([]);
  const [isFormVisible, setFormVisible] = useState(true);
  const [finalpayment, setFinalpayment] = useState(false);
  const [isPaynow, setIsPaynow] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [invoicesopen, setInvoicesopen] = useState(false);
  const location = useLocation();
  const obj = location.state?.obj;
  const [data, setData] = useState({});
  const formattedDate = moment(obj?.createdAt).format("MMM Do YY");
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });

  // useEffect(() => {
  //   onLoad();
  // }, []);

  useEffect(() => {
    onLoad();
  }, []);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClosemodel = (data) => {
    api
      .post("user/cancelusersubscr", {
        // data: data,
      })
      .then((result) => {
        // Check if the API call was successful
        if (result.success === "true") {
          console.log("Subscription successfully canceled:", result);
        } else {
          console.log("Failed to cancel subscription. Result:", result);
        }
      })
      .catch((err) => {
        // Log any errors encountered during the API call
        console.error("Error occurred while canceling subscription:", err);
      });
    setDialogOpen(false);
    // Close the dialog regardless of API call outcome
  };

  const handleClickInvoicesOpen = () => {
    setInvoicesopen(true);
  };

  const handleInvoicesmodul = () => {
    setInvoicesopen(false);
  };

  const GetUpgradePlan = () => {
    router.history.push("/subscription-plan");
  };

  const handleRedirect = () => {
    setFormVisible(true); // your existing logic
    router.history.push("/subscription-plan");
  };

  const handleSubmit = async () => {
    setLoading(true);

    api.put("user/editprofile", {
      data: {
        ...formState.values,
        location: formState.values.location.label,
        address: formState.values.address,
      },
    });
    setLoading(false);
    setTimeout(() => {
      setLoading(false);
      setFormVisible(false);
    }, 2000);
  };
  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const handleClosePayment = (value) => {
    setOpenPayment(false);
    setSelectedValue(value);
    onLoad();
    router.history.push("/subscription-plan");
  };
  // const hendelpaynow = (value) => {
  //   setFinalpayment(true);
  //   let data = {
  //     // module_id: 1,
  //     // subscription_type: type,
  //     // is_popular: 1,

  //     subscription_id: 1,
  //     subscription_type: 1,
  //     paymentIntentId: 1,
  //     promocode: 1,
  //   };
  //   // user/createusersubscr
  //   api
  //     .post("user/createusersubscr", {
  //       data: data,
  //     })
  //     .then((result) => {
  //       if (result.success === "true") {
  //         console.log(result, "result1558546556");
  //       } else {
  //         console.log(result, "success === false");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "error catch");
  //     });
  // };
  const getEditing = async () => {
    setFormVisible(true);
    await api
      .get(`user/viewprofile`)
      .then((result) => {
        if (result.success === "true") {
          console.log("result565656", result);
          setUserData(result.data);
          // set location
          geocodeByAddress(result.data.location)
            .then((results) => {
              // let location = result.data.city + " " + result.data.state + " " + result.data.country
              setFormState((formState) => ({
                ...formState,
                values: {
                  ...formState.values,
                  location: {
                    label: result.data.location,
                    value: results[0],
                  },
                },
              }));
            })
            .catch((error) => console.error(error, "new result error"));

          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              first_name: result.data.first_name,
              email: result.data.email,
              phone: result.data.phone,
              address: result.data.address,
            },
          }));
          if (result.data.lifestyle_photos)
            setLstLifeStyleImage(result.data.lifestyle_photos);
        } else {
          console.log(result, "error");
        }
        setLoadingStart(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setLoadingStart(false);
      });
  };
  const onLoad = async () => {
    setLoadingStart(true);
    let currantModule = await AsyncLocalStorage.getItem("currentModule");
    let isOrgUser1 = await AsyncLocalStorage.getItem("isOrgUser");
    setIsOrgUser(isOrgUser1);
    setCurrant_module(currantModule);
    await api
      .get(`user/viewprofile`)
      .then((result) => {
        if (result.success === "true") {
          setUserData(result.data);
          // set location
          geocodeByAddress(result.data.location)
            .then((results) => {
              // let location = result.data.city + " " + result.data.state + " " + result.data.country
              setFormState((formState) => ({
                ...formState,
                values: {
                  ...formState.values,
                  location: {
                    label: result.data.location,
                    value: results[0],
                  },
                },
              }));
            })
            .catch((error) => console.error(error, "new result error"));

          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              first_name: result.data.first_name,
              email: result.data.email,
              phone: result.data.phone,
              address: result.data.address,
            },
          }));
          if (result.data.lifestyle_photos)
            setLstLifeStyleImage(result.data.lifestyle_photos);
        } else {
          console.log(result, "error");
        }
        setLoadingStart(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setLoadingStart(false);
      });
    api
      .get(`user/paymentlog`)
      .then((result) => {
        if (result.success === "true") {
          console.log("result", result);
          setLstPaymentLog(result.data.user);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };
  const setLocation = (value) => {
    if (value) {
      geocodeByAddress(value.value.description)
        .then((results) => {
          console.error("valuevaluevalue", results);
          console.error("valuevaluevalue", results[0].geometry.location.lat());
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              location: value,
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng(),
            },
          }));
        })
        .catch((error) => console.error("valuevaluevalue", error));
    } else {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          location: "",
          latitude: "",
          longitude: "",
        },
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: value,
      },
    }));
  };

  const hasError = (field) => (formState.errors[field] ? true : false);
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgEditProfile}>
        <Container maxWidth="lg">
          <Grid spacing={2} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>

            <Grid className={classes.container} item>
              {/* {props.succ === "succ" && (
                <Grid className={classes.whiteCardProfile} container>
                  <Grid
                    item
                    xs={12}
                    xl={12}
                    lg={12}
                    style={{
                      border: "2px solid #E5E5EA",
                      borderRadius: "10px",
                      padding: "10px",
                      margin: "10px",
                    }}
                  >
                    <Grid>
                      <Box>
                        <Typography className={classes.EditCrentpaln}>
                          Current Plan
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            margin: "0px",
                            fontSize: "25px",
                            fontWeight: "700",
                            color: "#FE4B6B",
                          }}
                        >
                          {" "}
                          {obj?.title} Plan{" "}
                        </Typography>
                        <Typography
                          style={{
                            color: "#FE4B6B",
                            fontSize: "16px",
                            cursor: "pointer",
                            fontweight: "700",
                          }}
                          onClick={GetUpgradePlan}
                        >
                          Upgrade Plan
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#8E8E93",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Next payment ${obj?.amount} on  {formattedDate}{" "}
                        </Typography>
                        <Typography
                          style={{
                            color: "#FE4B6B",
                            fontSize: "16px",
                            cursor: "pointer",
                            fontweight: "700",
                          }}
                          onClick={handleClickOpen}
                        >
                          Cancel Plan
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )} */}
              <Grid className={classes.whiteCardProfile} container>
                <Grid
                  item
                  xs={12}
                  xl={12}
                  lg={12}
                  style={{
                    border: "2px solid #E5E5EA",
                    borderRadius: "10px",
                    padding: "10px",
                    margin: "10px",
                  }}
                >
                  {isFormVisible ? (
                    <Grid>
                      <Box
                        onClick={() => router.history.goBack()}
                        className={classes.backRoot}
                      >
                        <IconButton
                          aria-label="delete"
                          style={{
                            color: "black",
                            marginRight: "5px",
                            cursor: "pointer",
                          }}
                          size="small"
                        >
                          <ArrowBackIcon fontSize="inherit" />
                        </IconButton>
                        <h5 className={classes.EditProfilLable}>
                          {" "}
                          Billing Information{" "}
                        </h5>
                      </Box>
                      <Box>
                        <Typography className={classes.changeHeading}>
                          MatchupMates collects and uses personal data in
                          accordance with our{" "}
                          <span
                            style={{
                              color: "#FD3576",
                              fontWeight: "500px",
                              textDecoration: "underline",
                            }}
                          >
                            <a
                              target="black"
                              href="/privacy-policy"
                              style={{ color: "red" }}
                            >
                              Privacy Policy
                            </a>
                          </span>
                          .
                        </Typography>
                        <div className={classes.hrLine} />
                      </Box>
                      <Grid container style={{ padding: "10px" }} spacing={3}>
                        <Grid
                          item
                          lg={12}
                          xl={12}
                          xs={12}
                          className={classes.textRoot}
                        >
                          {/* <TextField
                            fullWidth
                            label="Full Name"
                            placeholder="Enter Fully Name"
                            variant="outlined"
                            value={formState.values.first_name || ""}
                            onChange={(e) =>
                              setFormState((formState) => ({
                                ...formState,
                                values: {
                                  ...formState.values,
                                  first_name: e.target.value,
                                },
                              }))
                            }
                            error={hasError("first_name")}
                            helperText={formState.errors.first_name}
                          /> */}
                          <TextField
                            fullWidth
                            label="Full Name"
                            name="first_name"
                            placeholder="Enter Full Name"
                            variant="outlined"
                            value={formState.values.first_name || ""}
                            onChange={handleChange}
                            error={hasError("first_name")}
                            helperText={formState.errors.first_name}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          xl={12}
                          xs={12}
                          className={classes.textRoot}
                        >
                          <TextField
                            fullWidth
                            label="Address"
                            name="address"
                            placeholder="Enter Address"
                            variant="outlined"
                            value={formState.values.address || ""}
                            onChange={handleChange}
                            error={hasError("address")}
                            helperText={formState.errors.address}
                          />
                        </Grid>
                        {/* <Grid
                          item
                          lg={6}
                          xl={6}
                          xs={12}
                          className={classes.textRoot}
                        >
                          <TextField
                            fullWidth
                            label="City"
                            placeholder="Enter your city"
                            variant="outlined"
                          />
                        </Grid> */}
                        <Grid
                          item
                          lg={12}
                          xl={12}
                          xs={12}
                          className={classes.googleSelecte}
                          style={{ position: "relative" }}
                        >
                          <div
                            style={{
                              backgroundColor: "white",
                              fontSize: "12px",
                              position: "absolute",
                              left: "23px",
                              top: "4px",
                              zIndex: "12",
                              padding: "0px 5px",
                              color: "rgba(0, 0, 0, 0.54)",
                            }}
                          >
                            Country
                          </div>

                          {/* <GooglePlacesAutocomplete
                            autocompletionRequest={{
                              types: ["(cities)"],
                            }}
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            label="Country"
                            placeholder="Countr"
                            selectProps={{
                              isClearable: true,
                              value: formState.values.location || "",
                              onChange: (val) => {
                                setLocation(val);
                                console.log("close", val);
                              },
                            }}
                          /> */}
                          <GooglePlacesAutocomplete
                            autocompletionRequest={{
                              types: ["(cities)"],
                            }}
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            selectProps={{
                              isClearable: true,
                              value: formState.values.location || "",
                              onChange: (val) => {
                                setFormState((prevState) => ({
                                  ...prevState,
                                  values: {
                                    ...prevState.values,
                                    location: val,
                                  },
                                }));
                                console.log("Selected location:", val);
                              },
                            }}
                          />

                          {hasError("location") && (
                            <label className={classes.googleAutoLabel}>
                              {formState.errors.location[0]}
                            </label>
                          )}
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          xl={12}
                          xs={12}
                          className={classes.textRoot}
                        >
                          <TextField
                            type="tel"
                            fullWidth
                            label="Mobile Number"
                            name="phone"
                            placeholder="Enter Mobile Number"
                            variant="outlined"
                            value={formState.values.phone || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*$/.test(value)) {
                                handleChange(e);
                              }
                            }}
                            error={hasError("phone")}
                            helperText={formState.errors.phone}
                          />
                        </Grid>
                        <Grid lg={12} xl={12} xs={12}>
                          <div className={classes.buttonGroupConfirm}>
                            <Button
                              className={classes.confirmButton}
                              variant="contained"
                              color="#000000"
                              style={{
                                background: "#E5E5EA",
                              }}
                              onClick={handleRedirect}
                            >
                              Cancel
                              {loading && (
                                <>
                                  &nbsp;
                                  <CircularProgress
                                    size={20}
                                    style={{ color: "white" }}
                                  />
                                </>
                              )}
                            </Button>
                            <Button
                              className={classes.confirmButton}
                              variant="contained"
                              color="primary"
                              style={{
                                background:
                                  "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
                              }}
                              onClick={handleSubmit}
                            >
                              Save
                              {loading && (
                                <>
                                  &nbsp;
                                  <CircularProgress
                                    size={20}
                                    style={{ color: "black" }}
                                  />
                                </>
                              )}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5 className={classes.EditProfilLable}>
                          {" "}
                          Billing Information{" "}
                        </h5>
                        {finalpayment === false ? (
                          <IconButton
                            style={{
                              color: "#8E8E93",
                              marginRight: "5px",
                              fontSize: "18px",
                            }}
                            onClick={getEditing}
                          >
                            <BorderColorIcon />
                          </IconButton>
                        ) : (
                          <Typography
                            style={{
                              color: "#FE4B6B",
                              fontSize: "15px",
                              padding: "10px 0px",
                              cursor: "pointer",
                            }}
                            onClick={handleClickInvoicesOpen}
                          >
                            Billing History
                          </Typography>
                        )}
                      </Box>
                      <Box style={{ marginLeft: "10px" }}>
                        <Typography
                          style={{ fontWeight: "500", fontSize: "15px" }}
                        >
                          {formState.values?.first_name}
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            paddingTop: "5px",
                          }}
                        >
                          {formState.values?.address}
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            paddingTop: "5px",
                          }}
                        >
                          {formState.values?.phone}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid className={classes.whiteCardProfile}>
                <Box
                  style={{
                    // padding: "10px",
                    margin: "10px",
                  }}
                >
                  {/* <form>
                    <Grid
                      item
                      lg={6}
                      xl={6}
                      xs={12}
                      className={classes.textRoot}
                    >
                      <TextField
                        id="outlined-basic"
                        size="small"
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.asterisk,
                          },
                          shrink: true,
                        }}
                        required
                        error={hasError("first_name")}
                        helperText={
                          hasError("first_name")
                            ? formState.errors.first_name[0]
                            : null
                        }
                        className={classes.inputProfileEdit}
                        label="First name"
                        name="first_name"
                        value={formState.values.first_name || ""}
                        // onChange={handleFieldChange}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid> */}

                  {/* <Grid
                      item
                      lg={6}
                      xl={6}
                      xs={12}
                      className={classes.buttonRoot}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.applyButton}
                      >
                        Apply
                      </Button>
                    </Grid>
                  </form> */}
                </Box>
              </Grid>
              {finalpayment === false && (
                <Grid className={classes.whiteCardProfile}>
                  <Box
                    style={{
                      // padding: "10px",
                      margin: "10px",
                    }}
                  >
                    {/* <Box>
                      <h4 className={classes.Paymentheader}>
                        {" "}
                        Select Payment Method{" "}
                      </h4>
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          margin: "0px 0px 10px",
                        }}
                      >
                        Credit Card Details
                      </Typography>
                      <div className={classes.hrLine} />
                    </Box> */}

                    <Payment
                      paymentType={paymentType}
                      palnid={obj?.id}
                      amount={obj?.amount}
                      subscription_type={obj?.subscription_type}
                      paymentAmount={paymentAmount}
                      currency={obj.currency}
                      handleClosePayment={handleClosePayment}
                      setdiscount={setdiscount}
                      // hendelpaynow={hendelpaynow}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid className={classes.summrydata} item>
              <Box className={classes.whiteCardProfile} container>
                <Box
                  style={{
                    padding: "5px 10px",
                  }}
                >
                  <Typography className={classes.EditProfilLableorder}>
                    Order Summary{" "}
                  </Typography>
                  <div className={classes.hrLine} />
                </Box>
                <Grid
                  style={{
                    margin: "0px 10px",
                  }}
                >
                  <Box
                    className={classes.textRoot}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography className={classes.changeHeadingsummry}>
                      {capitalizeFirstLetter(obj?.title)} Monthly Plan{" "}
                    </Typography>
                    <Typography style={{ fontSize: "18px", fontWeight: "500" }}>
                      {obj.currency === "INR" ? "₹" : "$"} {`${obj?.amount}`}
                      {/* ${obj?.amount} */}
                    </Typography>
                  </Box>
                  {/* <Box
                    className={classes.textRoot}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography className={classes.changeHeadingsummry}>
                      Charges{" "}
                    </Typography>
                    <Typography style={{ fontSize: "18px", fontWeight: "500" }}>
                      $0{" "}
                    </Typography>
                  </Box> */}
                  {discount && (
                    <Box
                      className={classes.textRoot}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className={classes.changeHeadingsummry}>
                        Discount{" "}
                      </Typography>
                      <Typography
                        style={{ fontSize: "18px", fontWeight: "500" }}
                      >
                        {obj.currency === "INR" ? "₹" : "$"} (-){" "}
                        {`${(obj?.amount * (discount / 100)).toFixed(2)}`}
                        {/* (-) ${obj?.amount * (discount / 100)} */}
                      </Typography>
                    </Box>
                  )}
                  <div className={classes.hrLine} />
                  <Box
                    className={classes.textRoot}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography className={classes.EditProfilLableorder}>
                      Overall Total{" "}
                    </Typography>
                    <Typography className={classes.EditProfilLableorder}>
                      {obj.currency === "INR" ? "₹" : "$"}{" "}
                      {discount
                        ? obj?.amount - obj?.amount * (discount / 100)
                        : obj?.amount}
                    </Typography>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleClosemodel}
        style={{ borderRadius: "10px" }}
        maxWidth="sm"
        fullWidth
      >
        <div className={classes.dialogContent}>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Typography className={classes.EditProfilLableorder}>
              cancel
            </Typography>
            <Button onClick={handleClosemodel}>
              <CancelPresentationIcon />
            </Button>
          </DialogActions>
          <div className={classes.hrLine} />
          <Typography style={{ fontSize: "16px" }}>
            Your subscription plan has been successfully canceled. If you have
            any further questions or need assistance, feel free to reach out to
            our support team.
          </Typography>
          <DialogActions style={{ justifyContent: "end", marginTop: "10px" }}>
            <Button
              // onClick={handleClosemodel}
              onClick={() => handleClosemodel(data)}
              className={`${classes.confirmButton} ${classes.gradientButton}`}
              variant="contained"
            >
              cancel
              {loading && (
                <>
                  &nbsp;
                  <CircularProgress size={20} style={{ color: "white" }} />
                </>
              )}
            </Button>
            <Button
              onClick={GetUpgradePlan}
              style={{
                background:
                  "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
              }}
              className={`${classes.confirmButton} ${classes.gradientButton}`}
              variant="contained"
            >
              Done
              {loading && (
                <>
                  &nbsp;
                  <CircularProgress size={20} style={{ color: "white" }} />
                </>
              )}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={invoicesopen}
        onClose={handleInvoicesmodul}
        style={{ borderRadius: "10px" }}
        maxWidth="sm"
        fullWidth
      >
        <div className={classes.dialogContent}>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Typography className={classes.EditProfilLableorder}>
              Invoices
            </Typography>
            <Button onClick={handleInvoicesmodul}>
              <CancelPresentationIcon />
            </Button>
          </DialogActions>
          <div className={classes.hrLine} />
          <Box>
            <YourCurrentPlan />
          </Box>
        </div>
      </Dialog>
    </>
  );
};

export default YourSubscriptionsplan;
