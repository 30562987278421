import React, { useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Container,
  Grid,
  Box,
  Button,
  Typography,
  Snackbar,
  Hidden,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import useRouter from "utils/useRouter";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import AppDataContext from "Helper/AppDataContext";
import MuiAlert from "@material-ui/lab/Alert";

import Api from "../../../../Helper/ApiHandler";
import { useEffect } from "react";
import { useState } from "react";
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {},
  // Hidden xs up 
  containRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // paddingLeft: "70px"
  },
  imageRoot: {
    position: "relative",
    width: "100%"
  },
  imageModuleRoot: {
    position: "absolute",
    left: "20%",
    top: "17%",
    padding: "30px",
    background: "#FFF7F8",
    borderRadius: "10px",
    boxShadow: "0px 0px 20px rgb(0 0 0 / 10%)",
    width: "50%"
  },
  imageModule: {
    // height: "350px",
    width: "100%",
  },
  imageBack: {
    // height: "700px",
    width: "50%"
  },
  mainHead: {
    fontSize: "38px !important",
    fontWeight: 500,
    marginBottom: "10x"
  },
  mainHeadSub: {
    marginBottom: "10x",
    fontSize: "18px !important",
    fontWeight: 400,
    wordBreak: "break-word",
    paddingRight: "100px"
  },

  // Hidden xs down
  containRootMobile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "40px"
  },
  imageRootMobile: {
    position: "relative",
    width: "100%"
  },
  imageModuleRootMobile: {
    // position: "absolute",
    // top: "17%",
    padding: "30px",
    background: "#FFF7F8",
    borderRadius: "10px",
    boxShadow: "0px 0px 20px rgb(0 0 0 / 10%)",
    width: "90%",
    bottom: "20px",
    zIndex: 1
  },
  imageModuleMobile: {
    // height: "350px",
    width: "80%",
  },
  imageBackMobile: {
    height: "390px",
    width: "40%",
  },
  mainHeadMobile: {
    fontSize: "24px !important",
    fontWeight: 500,
    marginBottom: "10x"
  },
  mainHeadSubMobile: {
    marginBottom: "10x",
    fontSize: "16px !important",
    fontWeight: 400,
    wordBreak: "break-word",
    // paddingRight: "100px"
  }

}));

const OneModuleLiveOurServices = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();
  const objContain = {
    DORMMATE: {
      heading: "Find your next perfect Dormmate!",
      subheading: [
        "Tired of looking for a Dormmate? We will find you the perfect Dormmate Match that fits your Lifestyle & Preferences with a Verified ID.",
        "Make a real connection via text or video call. It's fast, safe, secure & stress-free. MatchUpMates will do it all for you!",
      ],
      image: "https://dev-matchupmates.s3.amazonaws.com/file/samples/1664261816274_Dormmates%201.png"
    },
    ROOMMATE: {
      heading: "heading heading heading heading heading heading heading heading heading heading heading ",
      subheading: [
        "subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading ",
        "subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading ",
      ],
      image: "https://dev-matchupmates.s3.amazonaws.com/file/samples/1664261816274_Dormmates%201.png"
    },
    SOULMATE: {
      heading: "heading heading heading heading heading heading heading heading heading heading heading ",
      subheading: [
        "subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading ",
        "subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading ",
      ],
      image: "https://dev-matchupmates.s3.amazonaws.com/file/samples/1664261816274_Dormmates%201.png"
    },
    TEAMMATE: {
      heading: "heading heading heading heading heading heading heading heading heading heading heading ",
      subheading: [
        "subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading ",
        "subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading ",
      ],
      image: "https://dev-matchupmates.s3.amazonaws.com/file/samples/1664261816274_Dormmates%201.png"
    },
  }
  const { appContext, setAppContext } = useContext(AppDataContext);
  const [selectData, setSelectData] = useState({})
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });

  useEffect(() => {
    onLoad()
  }, [])

  const onLoad = () => {
    if (appContext.objAuth?.DORMMATE) {
      setSelectData(objContain.DORMMATE)
    } else if (appContext.objAuth?.ROOMMATE) {
      setSelectData(objContain.ROOMMATE)
    } else if (appContext.objAuth?.SOULMATE) {
      setSelectData(objContain.SOULMATE)
    } else if (appContext.objAuth?.TEAMMATE) {
      setSelectData(objContain.TEAMMATE)
    }
  }

  const siwtchModule = (value) => {
    // handleCloseModule(e)
    // user/switchmodule
    api
      .put("user/switchmodule", {
        data: {
          module_name: value,
        },
      })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem("currentModule", value);
          AsyncLocalStorage.setItem("tabValue", 0);
          AsyncLocalStorage.setItem("tabFavValue", 0);
          // setSelected(result?.data?.module_name)
          setAppContext({
            ...appContext,
            currentModule: result?.data?.module_name,
          });
          if (value === "DormMates") {
            if (!result.data.quiz) {
              router.history.push("/matching-quiz");
            } else {
              router.history.push("/my-profile");
            }
          } else {
            router.history.push("/comminsoon");
          }
        } else {
          console.log("call");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };
  console.log(selectData, "selectData")
  return (
    // <Container maxWidth="lg">
    <div>
      <Hidden smDown>
        <Grid container>
          <Grid item xs={12} md={6} lg={6} xl={6} className={classes.containRoot}>
            <h1 className={classes.mainHead}> {selectData.heading} </h1>
            {selectData.subheading && selectData.subheading.map((value) => {
              return (
                <p className={classes.mainHeadSub}> {value} </p>
              )
            })}
          </Grid>
          {/* <Grid item xs={12} md={6} lg={6} xl={6} className={classes.imageRoot}>
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <div className={classes.imageModuleRoot}>
                <img src={selectData.image} alt="imageModulae" className={classes.imageModule} />
              </div>
              <img src={selectData.image} alt="imageModulae" className={classes.imageModule} />
            </div>
          </Grid> */}
        </Grid>
      </Hidden>

      {/* mobile */}
      <Hidden mdUp>
        <Grid container>
          <Grid item xs={12} xl={6} lg={6} className={classes.containRootMobile}>
            <h1 className={classes.mainHeadMobile}> {selectData.heading} </h1>
            {selectData.subheading && selectData.subheading.map((value) => {
              return (
                <h1 className={classes.mainHeadSubMobile}> {value} </h1>
              )
            })}
          </Grid>
          <Grid item xs={12} xl={6} lg={6} className={classes.imageRootMobile}>
            <div style={{ display: "flex", justifyContent: "center", width: "100%", paddingBottom: "30px" }}>
              <div className={classes.imageModuleRootMobile}>
                <img src={selectData.image} alt="imageModulae" className={classes.imageModuleMobile} />
              </div>
              {/* <img src={"/images/matchUp/homeBack.svg"} alt="imageBacak" className={classes.imageBackMobile} /> */}
            </div>
          </Grid>
        </Grid>
      </Hidden>
    </div>
    // </Container>
  );
};

export default OneModuleLiveOurServices;

OneModuleLiveOurServices.propTypes = {
  className: PropTypes.string,
};
