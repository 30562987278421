import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import ClearIcon from "@material-ui/icons/Clear";
import "react-fancybox/lib/fancybox.css";
import { Skeleton } from "@material-ui/lab";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import useRouter from "utils/useRouter";

import Api from "../../../../Helper/ApiHandler";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import UserSkeletonSolmateCard from "components/Skeleton/UserSkeletonSolmateCard";
var api = new Api();

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    // backgroundColor: "E9EDF0 !important",
    minHeight: "calc(120vh - 125px)",
    // minHeight: "90vh",
    background: "#E9EDF0",
    padding: "35px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  root: {
    width: "100%",
    height: "110px",
    background: "white",
    paddingBottom: "10px !important",
  },
  labelSearch: {
    margin: "0",
    marginTop: "10px",
    marginBottom: "5px",
    fontWeight: "bold",
  },
  searchInput: {
    width: "290px",
    marginRight: "10px",
    background: "#E9EDF0",
  },
  formControl: {
    width: "100%",
    background: "#E9EDF0",
  },
  dateInput: {
    width: "200px",
    background: "#E9EDF0",
  },
  buttonFilter: {
    textAlign: "center",
    marginTop: "35px",
    textTransform: "capitalize",
  },
  filterIconBtn: {
    marginLeft: "10px",
  },
  listBg: {
    background: "#E9EDF0",
  },
  matchesHead: {
    margin: "0",
    padding: "30px 10px 10px 18px",
    paddingBottom: "10px",
    fontWeight: "600 !important",
    fontSize: "18px",
  },
  cardUser: {
    background: "white",
    padding: "25px 10px",
    textAlign: "center",
    paddingBottom: "10px",
    margin: "7px",
    borderRadius: "15px",
    height: "260px",
  },
  nameTextCard: {
    color: "black",
    marginBottom: "0",
    marginTop: "0",
    fontSize: "18px",
    fontWeight: "400",
  },
  // textCityName: {
  //   marginTop: "5px",
  //   color: "#6C6C6C",
  //   fontSize: "14px",
  //   maxHeight: "40px",
  //   minHeight: "22px",
  //   width: "100%",
  //   overflow: "hidden",
  //   textOverflow: "ellipsis",
  //   whiteSpace: "nowrap"
  // },
  chipUserDetail: {
    whiteSpace: "nowrap",
    width: "70px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "2px 5px",
    color: "#FE4B6B",
    fontWeight: "500",
    height: "20px",
    fontSize: "12px",
    background: "#FFEDF0",
    //fontFamily: "Gilroy !important",
  },
  cardMatched: {
    position: "relative",
  },
  cardBoxGrid: {
    paddingBottom: "60px",
    padding: "8px 16px",
  },
  imageMatches: {
    width: "120px",
    height: "120px",
    [theme.breakpoints.down("xs")]: {
      width: "80px",
      height: "80px",
    },
    borderRadius: "50%",
    objectFit: "cover",
  },
  bottom: {
    color: theme.palette.grey[300],
  },
  top: {
    // color: '#1a90ff',
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },

  solRoot: {
    backgroundColor: "white",
    height: "300px",
    width: "100%",
    borderRadius: "10px",
    position: "relative",
    "&:hover ": {
      "& .hide": {
        display: "none",
      },
      "& .hover": {
        display: "block",
      },
    },
  },
  solImage: {
    height: "100%",
    width: "100%",
    borderRadius: "10px",
    objectFit: "cover",
    objectPosition: "top",
  },
  solBottomBorder: {
    position: "absolute",
    // background: "red",
    height: "45px",
    width: "100%",
    bottom: "0",
    borderRadius: "0px 0px 10px 10px",
    backdropFilter: "blur(5px)",
    backgroundColor: "#00000030",

    // padding: "10px"
  },
  solBottomBorderName: {
    color: "white",
    paddingLeft: "10px",
    marginTop: "13px",
    width: "75%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  checkRootLabel: {
    margin: 0,
  },
  solBottomHover: {
    position: "absolute",
    // height: "170px",
    width: "100%",
    bottom: "0",
    borderRadius: "0px 0px 10px 10px",
    backdropFilter: "blur(5px)",
    display: "none",
    backgroundColor: "#00000030",
    // justifyContent: "space-between",
    // alignItems: "center",
  },
  solBottomHoverName: {
    color: "white",
    fontSize: "18px",
    paddingLeft: "10px",
    paddingBottom: "10px",
  },
  solBottomHoverSubTital: {
    color: "white",
    fontSize: "13px",
    paddingLeft: "10px",
  },
  solBottomHoverFooter: {
    height: "45px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  carosalImageBox: {
    height: "100%",
    width: "100%",
    margin: "auto",
    backgroundColor: "gray",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "15px",
    // [theme.breakpoints.down("xs")]: {
    //   width: 80,
    //   height: 80,
    // },
  },
}));

const Linkyou = (props) => {
  const router = useRouter();

  const classes = useStyles();
  const [favourite, setFavourite] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  let [over, setOver] = React.useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = () => {
    setLoading(true);
    // Api Call For Fetch The Our Matches
    api
      // .get("user/yourfavouritesoulmate")
      .get("user/myfavouritesoulmate")
      .then((result) => {
        if (result.success === "true") {
          setFavourite(result.data);
        } else {
          console.log(result, "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setLoading(false);
      });
  };

  const removeFav = (slug) => {
    api
      .post("user/addfavouritesoulmate", {
        data: { slug: slug, isFavourite: false },
      })
      .then((result) => {
        if (result.success === "true") {
          onLoad();
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };
  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // const getAge = (date) => {
  //   let fullYear = new Date().getFullYear();
  //   const myArray = date.split("-");
  //   return fullYear - parseInt(myArray[0]);
  // };

  const onMessageClick = (
    firebase_uid,
    userName,
    profile_picture,
    user_id,
    slug
  ) => {
    // // Api Call For Fetch The Our Matches
    api
      .get(`user/addmessagetosoulmate/${slug}`)
      .then((result) => {
        console.log("result", result.success);
        if (result.success === "true") {
          router.history.push(`/messages/${firebase_uid}`);
          AsyncLocalStorage.setItem("receiverName", userName);
          AsyncLocalStorage.setItem("receiverProfilePicuture", profile_picture);
          AsyncLocalStorage.setItem("request_status", "pending");
          AsyncLocalStorage.setItem("to_id", user_id);
          AsyncLocalStorage.setItem("to_slug", slug);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
    // userconver: {status: "pending", request_id: null}
    // user/addmessage
  };

  return (
    <>
      <div className={classes.mainRoot}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid item className={classes.container} spaccing={2}>
              <div className={classes.listBg}>
                {/* <Container maxWidth="lg"> */}
                <h5 className={classes.matchesHead}> Who Likes you</h5>
                <Grid
                  // justifyContent="center"
                  // alignItems="center"
                  className={classes.cardBoxGrid}
                  container
                  spacing={2}
                >
                  {!loading ? (
                    <>
                      {favourite?.length ? (
                        favourite?.map((item, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              sm={6}
                              xl={3}
                              lg={3}
                              className={classes.cardMatched}
                            >
                              <div
                                className={classes.solRoot}
                                onMouseOver={() => setOver(true)}
                                onMouseOut={() => setOver(false)}
                              >
                                <Box position="absolute" top={10} right={10}>
                                  <CircularProgress
                                    variant="static"
                                    value={100}
                                    size={35}
                                    thickness={4}
                                    className={classes.bottom}
                                  />
                                  <CircularProgress
                                    variant="static"
                                    initialAnimation={true}
                                    // value={80}
                                    value={
                                      item.matchPercent ||
                                      item.matchPercent !== "NaN"
                                        ? item.matchPercent
                                        : 0
                                    }
                                    size={35}
                                    thickness={4}
                                    className={classes.top}
                                    style={{
                                      transform: "rotate(270deg)",
                                    }}
                                  />
                                  <Box
                                    top={0}
                                    left={0}
                                    bottom={5}
                                    right={0}
                                    position="absolute"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <Typography
                                      variant="caption"
                                      component="div"
                                      color="textSecondary"
                                      style={{
                                        fontSize: "10px",
                                        color: "white",
                                      }}
                                    >
                                      {item.matchPercent &&
                                      item.matchPercent !== "NaN"
                                        ? item.matchPercent
                                        : 0}
                                      %{/* {80}% */}
                                    </Typography>
                                  </Box>
                                </Box>
                                {/* <img src="/images/matchUp/sol1.jpg" className={classes.solImage} /> */}
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={`/your-matches/view/${item.user.slug}`}
                                >
                                  <div className={classes.carosalImageBox}>
                                    <img
                                      src={
                                        item.user.user_details.profile_picture
                                          ? item.user.user_details
                                              .profile_picture
                                          : "/images/male_defualt.svg"
                                      }
                                      alt="profile_picture"
                                      className={classes.solImage}
                                    />
                                  </div>
                                  {/* <img
                                    src={
                                      item.profile_picture
                                        ? item.profile_picture
                                        : "/images/male_defualt.svg"
                                    }
                                    className={classes.solImage}
                                    alt={"Alt Image"}
                                  /> */}
                                </Link>
                                {/* {over ? */}
                                {/* // hover  */}
                                <div
                                  className={`${classes.solBottomHover} hover`}
                                >
                                  <Link
                                    style={{ textDecoration: "none" }}
                                    to={`/your-matches/view/${item.user.slug}`}
                                  >
                                    <div className={classes.solBottomHoverName}>
                                      {item.user.first_name}{" "}
                                      {item.user.last_name},{" "}
                                      {/* {getAge(item.date_of_birth)} */}
                                    </div>
                                    <div
                                      className={classes.solBottomHoverSubTital}
                                    >
                                      <Tooltip
                                        title={item.user.user_details.location}
                                        placement="top"
                                      >
                                        <div
                                          style={{
                                            paddingBottom: "10px",
                                            width: "100%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          Lives in{" "}
                                          {item.user.user_details.location}
                                        </div>
                                      </Tooltip>
                                      <div
                                        style={{
                                          paddingBottom: "10px",
                                          width: "100%",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        Work as a{" "}
                                        {item.user.occupation
                                          ? item.user.occupation
                                          : ""}
                                      </div>
                                      <div style={{ paddingBottom: "10px" }}>
                                        {item.nation ? item.nation : ""}
                                      </div>
                                    </div>
                                  </Link>
                                  <Divider style={{ background: "white" }} />
                                  <div className={classes.solBottomHoverFooter}>
                                    <div
                                      style={{
                                        width: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        src="/images/matchUp/solMessage.svg"
                                        onClick={(e) => {
                                          // firebase_uid, userName, profile_picture, user_id, slug
                                          onMessageClick(
                                            item.firebase_uid,
                                            item.first_name + item.last_name,
                                            item.profile_picture,
                                            item.user_id,
                                            item.slug
                                          );
                                        }}
                                        alt={"Alt Image"}
                                      />
                                    </div>
                                    <Divider
                                      orientation="vertical"
                                      flexItem
                                      style={{
                                        background: "white",
                                        margin: "5px",
                                        opacity: "30%",
                                      }}
                                    />
                                    <div
                                      style={{
                                        width: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FormControlLabel
                                        classes={{
                                          root: classes.checkRootLabel,
                                        }}
                                        control={
                                          <Checkbox
                                            icon={<Favorite color="primary" />}
                                            checkedIcon={
                                              <FavoriteBorder
                                                style={{ color: "white" }}
                                              />
                                            }
                                            name="checkedH"
                                            onChange={(e) => {
                                              removeFav(item.slug);
                                            }}
                                          />
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* // : */}
                                {/* //  over  */}
                                <div
                                  className={`${classes.solBottomBorder} hide`}
                                >
                                  <Box style={{ display: "flex" }}>
                                    <div
                                      className={classes.solBottomBorderName}
                                    >
                                      {item.user.first_name}{" "}
                                      {item.user.last_name},{" "}
                                      {/* {getAge(item.date_of_birth)} */}
                                    </div>
                                    <div></div>
                                  </Box>
                                </div>
                                {/* // } */}
                              </div>
                            </Grid>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            marginTop: "50px",
                          }}
                        >
                          <img
                            src="/images/matchUp/notFoundMatches.svg"
                            alt="favAlt"
                          />
                          <Typography
                            variant="caption"
                            component="primary"
                            color="primary"
                            style={{
                              fontSize: "20px",
                              color: "black",
                              fontWeight: "600",
                            }}
                          >
                            No Favourites Yet!
                          </Typography>
                          <Typography
                            variant="caption"
                            component="primary"
                            color="primary"
                            style={{
                              fontSize: "14px",
                              color: "gray",
                              fontWeight: "400",
                            }}
                          >
                            Mark your favorite profile and always have them
                            here.
                          </Typography>
                        </div>
                      )}
                    </>
                  ) : (
                    <SkeletonLoading />
                  )}
                </Grid>
                {/* </Container> */}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
const SkeletonLoading = () => {
  const classes = useStyles();
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      // className={classes.cardBoxGrid}
      container
    >
      {[0, 1, 2, 3, 4, 5, 6, 7].map((obj) => {
        return (
          <Grid item xs={12} sm={6} xl={3} lg={3}>
            <UserSkeletonSolmateCard />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default Linkyou;

// import React from 'react'

// export default function Linkyou() {
//   return (
//     <div>Linkyou</div>
//   )
// }
