import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import PropTypes from "prop-types";
import moment from "moment";
import useRouter from "utils/useRouter";
import Api from "../../../../Helper/ApiHandler";
import AppDataContext from "Helper/AppDataContext";
import InfiniteScroll from "react-infinite-scroll-component";
import AsyncLocalStorage from "@createnextapp/async-local-storage";

var api = new Api();

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#E9EDF0",
    padding: "35px 0",
    // marginBottom: "60px",
    minHeight: "90vh",
    // minHeight: "calc(100vh - 125px)",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
    // minHeight: "86vh"
  },
  headingPage: {
    fontWeight: "900 !important",
    fontSize: "18px",
  },
  mainAppTab: {
    backgroundColor: "#E9EDF0",
    color: "black",
    boxShadow: "none",
    textTransform: "capitalize",
    borderBottom: `1px solid gray`,
  },
  tabNameLabel: {
    textTransform: "capitalize",
    fontWeight: "bold",
    minWidth: "100px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "120px",
    },
  },
  mainCardDetail: {
    background: "white",
    position: "relative",
    height: "350px",
    borderRadius: "10px",
  },
  halfColourDiv: {
    background: "#138FCE",
    height: "80px",
    textAlign: "center",
    color: "white",
    borderRadius: "10px 10px 0 0",
  },
  headingPlan: {
    paddingTop: "10px",
    fontSize: "17px",
    //fontFamily: "Gilroy !important",
  },
  whitePaperDetail: {
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    width: "85%",
    margin: "0 auto",
    height: "230px",
    position: "absolute",
    top: "50px",
    left: "50%",
    transform: "translateX(-50%)",
    borderRadius: "5px",
    background: "white",
    textAlign: "center",
  },
  priceMates: {
    margin: "0",
    marginTop: "5px",
    marginBottom: "5px",
    //fontFamily: "Gilroy !important",
  },
  timeValueSubs: {
    margin: "0",
    color: "gray",
    fontSize: "14px",
    //fontFamily: "Gilroy !important",
  },
  detailCardSubs: {
    margin: "0",
    fontSize: "13px",
    paddingTop: "5px",
    //fontFamily: "Gilroy !important",
  },
  deatilBox: {
    textAlign: "left",
    paddingTop: "10px",
    paddingLeft: "8px",
  },
  imageDetail: {
    marginRight: "5px",
  },
  lastBtnFlex: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    bottom: "5px",
    width: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    justifyContent: "center",
    color: "#36D860",
  },
  buttonCardSelect: {
    position: "absolute",
    bottom: "15px",
    width: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    color: "#36D860",
    textAlign: "center",
  },
  buttonSelect: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      paddingLeft: "20px !important",
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      paddingLeft: "20px !important",
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      paddingLeft: "20px !important",
      width: "72%",
    },
  },
  bottomLastCard: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
    },
  },
  imageNotificationRoot: {
    minWidth: 0,
  },
  imageNotification: {
    width: "45px",
    height: "45px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  name: {
    fontWeight: "600",
  },
  timeMessage: {
    fontSize: "13px",
  },
  matchesHead: {
    margin: "0",
    padding: "30px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "18px 15px",
    },
    // marginTop: "10px",
    paddingBottom: "10px",
    fontWeight: "600 !important",
    fontSize: "18px",
  },
  unreadCount: {
    backgroundColor: "red",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    marginTop: "-5px",
    marginLeft: "-24px",
    border: "3px solid white",
    position: "absolute",
  },
}));

const Notification = (props) => {
  // const { className } = props;
  const classes = useStyles();
  // const theme = useTheme();
  const router = useRouter();
  const { appContext, setAppContext } = useContext(AppDataContext);
  const [loadingScroll, setLoadingScroll] = React.useState(false);
  const [lstNotification, setLstNotification] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setrowsPerPage] = React.useState(12);
  const [totalRecored, setTotalRecored] = React.useState(0);
  const [matches, setMatches] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);

  useEffect(() => {
    onLoad();
  }, []);
  useEffect(() => {
    if (page !== 0) onMore();
  }, [page]);
  useEffect(() => {
    appContext?.isNewNotification && onLoad();
  }, [appContext]);

  const fetchMoreMatches = () => {
    if (matches.length >= totalRecored) {
      setHasMore(false);
      return;
    }

    // setTimeout(() => {
    // setItems(items.concat(Array.from({ length: 20 })))
    setPage(page + 1);
    // onMore()
    // }, 500);
  };

  const onLoad = () => {
    setLoading(true);
    // Api Call For Fetch The User Data
    api
      .get(`user/notificationlist?page=${page}&size=${rowsPerPage}`)
      .then((result) => {
        if (result.success === "true") {
          console.log(result.data.user, "call");
          setMatches(result.data.user);
          setTotalRecored(result.data.totalItems);
          // setAppContext({ ...appContext, isNewNotification: true })
        } else {
          console.log(result, "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setLoading(false);
      });
  };
  const onMore = () => {
    setLoadingScroll(true);
    // Api Call For Fetch The Our Matches

    api
      .get(`user/notificationlist?page=${page}&size=${rowsPerPage}`)
      .then((result) => {
        if (result.success === "true") {
          const lstTmp = matches.concat(result.data.user);
          setMatches(lstTmp);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
        }
        setLoadingScroll(false);
      })
      .catch((err) => {
        setLoadingScroll(false);
        console.log(err, "catch error");
      });
  };
  const onRead = (id, slug, module_name, intentType, f_uid, item) => {
    api
      .put("user/switchmodule", {
        data: {
          module_name: module_name,
        },
      })
      // .then((result) => {
      //   if (result.success === "true") {
      //     AsyncLocalStorage.setItem("currentModule", module_name);
      //     AsyncLocalStorage.setItem("tabValue", 0);
      //     AsyncLocalStorage.setItem("tabFavValue", 0);
      //     setAppContext({
      //       ...appContext,
      //       currentModule: result?.data?.module_name,
      //     });
      //     console.log(module_name, "DormMates");
      //     if (module_name === "Dormmates") {
      //       // if (process.env.REACT_APP_DORMMATES == "true") {
      //       if (appContext.objAuth.DORMMATE) {
      //         if (!result.data.hasUniversity) {
      //           // router.history.push({
      //           //   pathname: `/select-university`,
      //           //   search: '',
      //           //   state: {
      //           //     step3: true
      //           //   }
      //           // })
      //           // router.history.push("/select-university");
      //         } else if (!result.data.quiz) {
      //           // router.history.push("/matching-quiz");
      //         } else {
      //           if (intentType == "profile") {
      //             // router.history.push(`/your-matches/view/${slug}`)
      //           } else if (intentType == "inbox") {
      //             AsyncLocalStorage.setItem("receiverName", item.name);
      //             AsyncLocalStorage.setItem(
      //               "receiverProfilePicuture",
      //               item.profile_picture
      //             );
      //             AsyncLocalStorage.setItem("to_id", item.to_id);
      //             AsyncLocalStorage.setItem("to_slug", item.slug);

      //             // router.history.push(`/messages/${f_uid}`)
      //           }
      //           // router.history.push("/your-profile");
      //         }
      //       } else {
      //         // router.history.push("/comminsoon");
      //       }
      //     } else if (module_name === "Roommates") {
      //       if (appContext.objAuth.ROOMMATE) {
      //         if (!result.data.hasUniversity) {
      //           // router.history.push("/select-university");
      //           // router.history.push({
      //           //   pathname: `/select-university`,
      //           //   search: '',
      //           //   state: {
      //           //     step3: true
      //           //   }
      //           // })
      //         } else if (!result.data.quiz) {
      //           // router.history.push("/matching-quiz");
      //         } else {
      //           // router.history.push("/your-profile");
      //           if (intentType == "profile") {
      //             // router.history.push(`/your-matches/view/${slug}`)
      //           } else if (intentType == "inbox") {
      //             AsyncLocalStorage.setItem("receiverName", item.name);
      //             AsyncLocalStorage.setItem(
      //               "receiverProfilePicuture",
      //               item.profile_picture
      //             );
      //             AsyncLocalStorage.setItem("to_id", item.to_id);
      //             AsyncLocalStorage.setItem("to_slug", item.slug);
      //             // AsyncLocalStorage.setItem("request_status", "pending")

      //             // router.history.push(`/messages/${f_uid}`)
      //           } else if (intentType == "property") {
      //             // router.history.push(`/property/${slug}`)
      //           }
      //         }
      //       } else {
      //         // router.history.push("/comminsoon");
      //       }
      //     } else if (module_name === "Teammates") {
      //       if (appContext.objAuth.TEAMMATE) {
      //         // router.history.push(`/your-matches/view/${slug}`)
      //         // router.history.push("/your-profile");
      //         // if (!result.data.quiz) {
      //         //   router.history.push("/matching-quiz");
      //         // } else {
      //         //   router.history.push("/your-profile");
      //         // }
      //       } else {
      //         // router.history.push("/comminsoon");
      //       }
      //     } else if (module_name === "Soulmates") {
      //       if (appContext.objAuth.SOULMATE) {
      //         if (!result.data.quiz) {
      //           // router.history.push("/matching-quiz");
      //         } else {
      //           // router.history.push("/your-profile");
      //           if (intentType == "profile") {
      //             // router.history.push(`/your-matches/view/${slug}`)
      //           } else if (intentType == "inbox") {
      //             AsyncLocalStorage.setItem("receiverName", item.name);
      //             AsyncLocalStorage.setItem(
      //               "receiverProfilePicuture",
      //               item.profile_picture
      //             );
      //             AsyncLocalStorage.setItem("to_id", item.to_id);
      //             AsyncLocalStorage.setItem("to_slug", item.slug);

      //             // router.history.push(`/messages/${f_uid}`)
      //           }
      //         }
      //       } else {
      //         // router.history.push("/comminsoon");
      //       }
      //     } else {
      //       // router.history.push("/comminsoon");
      //     }
      //   } else {
      //   }
      // })
      .catch((err) => {});

    // // Api Call For Read notification
    // api.get(`user/readnotification/${id}`)
    //   .then((result) => {
    //     if (result.success === "true") {
    //       setAppContext({ ...appContext, isNewNotification: true })
    //     } else {
    //       console.log(result, "error")
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err, "catch error")
    //   });
  };

  function timeAgo(time) {
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: (number) => number + "s ago",
        ss: "%ds ago",
        m: "1m ago",
        mm: "%dm ago",
        h: "1h ago",
        hh: "%dh ago",
        d: "1d ago",
        dd: "%dd ago",
        M: "a month ago",
        MM: "%d months ago",
        y: "a year ago",
        yy: "%d years ago",
      },
    });

    let secondsElapsed = moment().diff(time, "seconds");
    let dayStart = moment("2018-01-01")
      .startOf("day")
      .seconds(secondsElapsed);

    if (secondsElapsed > 300) {
      return moment(time).fromNow(true);
    } else if (secondsElapsed < 60) {
      return dayStart.format("s") + " sec ago";
    } else {
      return dayStart.format("m:ss") + "min ago";
    }
  }

  return (
    <>
      <div className={classes.bgProfilePage}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid item className={classes.container}>
              <h5 className={classes.matchesHead}> Notifications </h5>
              {/* style={{overflowY:"scroll"}} */}
              <InfiniteScroll
                dataLength={matches.length}
                // next={fetchMoreData}
                next={fetchMoreMatches}
                hasMore={true}
                loader={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "95%",
                      margin: "10px",
                    }}
                  >
                    {loadingScroll && <CircularProgress size={20} />}
                  </div>
                }
                endMessage={
                  <p style={{ paddingBottom: "60px" }}>{/* <p> */}</p>
                }
                style={{ overflow: "hidden" }}
              >
                <Grid
                  spacing={3}
                  container
                  style={{ marginBottom: "15px", marginTop: "0px" }}
                >
                  {matches.length ? (
                    matches.map((item) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            backgroundColor: "white",
                            margin: "5px 20px",
                            borderRadius: "10px",
                            justifyContent: "space-between",
                          }}
                          onClick={() => {
                            onRead(
                              item.id,
                              item.slug,
                              item.module_name,
                              item.intentType,
                              item.receiverId,
                              item
                            );
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <div style={{ height: "45px", cursor: "pointer" }}>
                              <Avatar
                                className={classes.imageNotification}
                                src={
                                  item.profile_picture
                                    ? item.profile_picture
                                    : "/images/male_defualt.svg"
                                }
                                alt={item.name}
                              />
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <div>
                                    <span className={classes.name}>
                                      {" "}
                                      {item.name}
                                    </span>
                                    <span className={classes.timeMessage}>
                                      {" "}
                                      {item.message}{" "}
                                    </span>
                                  </div>
                                  &nbsp;&nbsp;&nbsp;
                                  {!item.is_read && (
                                    <div className={classes.unreadCount}></div>
                                  )}
                                </div>
                              </div>
                              <div>
                                <span className={classes.timeMessage}>
                                  {/* {moment(new Date(item.createdAt)).fromNow()} */}
                                  {timeAgo(new Date(item.createdAt))}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <span
                              className={classes.timeMessage}
                              style={{ color: "#FD3576" }}
                            >
                              {" "}
                              {item.module_name}{" "}
                            </span>
                          </div>
                        </Grid>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        backgroundColor: "white",
                        margin: "25px",
                        borderRadius: "5px",
                        height: "125px",
                        marginTop: "5px",
                      }}
                    >
                      <Typography
                        variant="caption"
                        component="primary"
                        color="primary"
                        style={{
                          fontSize: "14px",
                          color: "gray",
                          fontWeight: "400",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        You have no notifications
                        {/* <NotificationsOutlinedIcon style={{ fontSize: "100px" }} /> */}
                      </Typography>
                    </div>
                  )}
                </Grid>
              </InfiniteScroll>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Notification;
