import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Paper,
  IconButton,
  Typography,
  colors,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Grid,
  TextField,
  Avatar,
  Divider,
  Snackbar,
  CircularProgress,
  Drawer,
  Box,
  Container,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutlined";
import ErrorIcon from "@material-ui/icons/ErrorOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import WarningIcon from "@material-ui/icons/WarningOutlined";
import { ExitToApp, VpnKey } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import validate, { async } from "validate.js";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import useRouter from "utils/useRouter";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from 'js-cookie';

import Api from "../../Helper/ApiHandler";
import AuthanticateRouter from "AuthanticateRouter";
import SignupModel from "components/AuthModel/SignupModel";
import LoginModel from "components/AuthModel/LoginModel";
import Forgotpassword from "components/AuthModel/Forgotpassword";

var api = new Api();
const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const schema = {
  message: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "6px 16px",
    borderRadius: theme.shape.borderRadius,
  },
  default: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  action: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    paddingLeft: 16,
    marginRight: -8,
  },
  dialogRoot: {
    padding: "10px",
    "& .MuiDialog-paper": {
      borderRadius: "10px",
    },
    "& .MuiDialog-paperWidthSm": {
      width: "250px",
      height: "180px",
      position: "absolute",
      top: "20px",
      right: "0",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgb(0 0 0 / 49%)",
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiDialog-paperWidthSm": {
        width: "350px",
        height: "200px",
      },
    },
  },
  dialogRootMobile: {
    "& .MuiDialog-paperWidthSm": {
      // width: '285px',
      // height: '180px',
      position: "absolute",
      // top: '105px',
      // left: '0'
      right: "0",
      bottom: "0",
    },
  },
  addContainer: {
    // backgroundColor: '#0177FD',
    color: "black",
    // margin: "px 0",
    height: "50px",
    display: "flex",
    alignItems: "center",
    padding: "0 24px",
    padding: "30px 25px",
    borderBottom: "1px solid #dbd7d7",
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '14px'
    // }
  },
  avtar: {
    marginRight: "15px",
  },
  mobileAvtar: {
    marginRight: "15px",
    width: "35px",
    height: "35px",
  },
  avtarName: {
    fontSize: "14px",
  },
  tokenContainer: {
    padding: "0 25px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
  addBandWidthLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      [theme.breakpoints.down("sm")]: {
        width: "25px",
      },
    },
  },
  content: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: "none",
    padding: "0 24px",
    marginTop: "15px",
  },
  changePasswordLogo: {
    marginRight: "15px",
    color: "#0177FD",
  },
  mobileChangePasswordLogo: {
    marginRight: "15px",
    color: "#0177FD",
    "& .MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
    },
  },
  passwordTitle: {
    fontSize: "15px",
    color: "black",
    marginBottom: "15px",
  },
  mobilePasswordTitle: {
    color: "black",
    marginBottom: "15px",
    fontSize: "14px",
  },
  logoutLogo: {
    marginRight: "15px",
    color: "#0177FD",
  },
  mobileLogoutLogo: {
    marginRight: "15px",
    color: "#0177FD",
    "& .MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
    },
  },
  logoutTitle: {
    fontSize: "15px",
    color: "black",
    marginBottom: "15px",
  },
  mobileLogoutTitle: {
    color: "black",
    marginBottom: "15px",
    fontSize: "14px",
  },
  rootPaper: {
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },
  },
  messageRoot: {
    "& .MuiOutlinedInput-inputMultiline": {
      fontSize: "12px",
    },
  },
  textRoot: {
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    marginBottom: "10px",
  },
}));

const icons = {
  default: <InfoIcon />,
  success: <CheckCircleIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
};

function ContactDialog(props) {
  const {
    className,
    onOpen,
    onCloseHandle,
    icon,
    variant,
    message,
    onClose,
    mobileDropdown,
    pushTo,
    objProperty,
    setObjProperty,
    setContactOpen,
    ...rest
  } = props;

  const history = useHistory();
  const classes = useStyles();
  const router = useRouter();

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [callback, setCallback] = useState("not fired");
  const [captchValue, setCaptchaValue] = useState("");
  const [load, setLoad] = useState(false);
  const [expired, setExpired] = useState(false);
  const [username, setUsername] = React.useState("");
  const [forgotpasswordOpen, setForgotpasswordOpen] = React.useState(false);
  const [url, setUrl] = React.useState(
    window.location.host + history.location.pathname
  );

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });

  const onLoginOpen = () => {
    setLoginOpen(true);
    setForgotpasswordOpen(false);
    setOpen(false);
  };

  const onForgotpasswordOpen = () => {
    setLoginOpen(false);
    setForgotpasswordOpen(true);
  };

  const onSignupOpen = () => {
    setLoginOpen(false);
    setOpen(true);
  };

  const handleClickOpenLogin = (e) => {
    setLoginOpen(true);
    Cookies.remove('teammateRedirectTo')
    Cookies.set('roommateRedirectTo', history.location.pathname, { expires: 365 });
    // e.preventDefault();
    // history.push({
    //   pathname: "/auth/details1",
    //   search: "",
    //   state: { propsData: url },
    //   target: "_blank",
    // });
  };

  let _reCaptchaRef = React.createRef();

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);
  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    let Username = await AsyncLocalStorage.getItem("userName");
    setUsername(Username);
  };

  const handleFieldChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const onContact = async () => {
    // let from_id = await AsyncLocalStorage.getItem("from_id")
    setDisabled(true);
    setLoading(true);
    api
      .post("user/property/sendrequesttoowner", {
        data: {
          to_id: objProperty.property_owner_id,
          property_id: objProperty.id,
          message: formState.values.message,
        },
      })
      .then((result) => {
        if (result.success === "true") {
          // onLoad()
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
          setObjProperty({ ...objProperty, request_status: true });
          setContactOpen(false);
        } else {
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setDisabled(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error catch");
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });

        setDisabled(false);
        setLoading(false);
      });
  };
  const handleChange = (value) => {
    setCaptchaValue(value);
    // if value is null recaptcha expired
    if (value === null) setExpired("true");
  };

  const asyncScriptOnLoad = () => {
    setCallback("called!");
    // console.log("scriptLoad - reCaptcha Ref-", _reCaptchaRef);
  };
  // console.log(expired, load, captchValue, callback, "value");
  return (
    // <Drawer
    //   anchor={"bottom"}
    //   className={mobileDropdown ? classes.dialogRootMobile : classes.dialogRoot}
    //   open={onOpen}
    //   onClose={onCloseHandle}
    //   aria-labelledby="alert-dialog-title"
    //   aria-describedby="alert-dialog-description"
    //   classes={{ root: classes.rootPaper }}
    // >
    <div>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <Container maxWidth="lg">
        <Box
          ml="auto"
          mr="0"
          width={"350px"}
          style={{
            padding: "20px",
            backgroundColor: "white",
            position: "relative",
            boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              cursor: "pointer",
            }}
          >
            <CloseIcon
              onClick={() => {
                onCloseHandle();
              }}
            />
          </div>
          {AuthanticateRouter(false) ? (
            <div>
              <div
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "500",
                  marginBottom: "5px",
                }}
              >
                Contact to owner
              </div>
              <div
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                  fontWeight: "500",
                  marginBottom: "15px",
                }}
              >
                We'd love to hear from you
              </div>

              <div className={classes.textRoot}>
                <TextField
                  value={username || ""}
                  variant="outlined"
                  fullWidth
                  label="Name"
                  disabled
                />
              </div>

              <div className={classes.messageRoot}>
                <TextField
                  id="outlined-basic"
                  label="Message"
                  fullWidth
                  size="small"
                  variant="outlined"
                  onChange={handleFieldChange}
                  name="message"
                  rows={5}
                  multiline={true}
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                    shrink: true,
                  }}
                  required
                  placeholder="Tell us about your specific need or other queries so we can help you more quickly. Let's talk"
                  error={hasError("message")}
                  helperText={
                    hasError("message") ? formState.errors.message[0] : null
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                <ReCAPTCHA
                  style={{ display: "inline-block" }}
                  theme="dark"
                  ref={_reCaptchaRef}
                  sitekey={TEST_SITE_KEY}
                  onChange={handleChange}
                  asyncScriptOnLoad={asyncScriptOnLoad}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <Button
                  style={{
                    width: "100%",
                    background:
                      "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
                    textTransform: "capitalize",
                    color: "white",
                    borderRadius: "10px",
                    height: "30px",
                  }}
                  variant="contained"
                  disabled={
                    captchValue !== ""
                      ? formState.isValid
                        ? disabled
                        : true
                      : true
                  }
                  size="large"
                  color="primary"
                  onClick={(event) => {
                    onContact(event);
                  }}
                >
                  Contact
                  {loading && (
                    <>
                      &nbsp;
                      <CircularProgress size={15} style={{ color: "white" }} />
                    </>
                  )}
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  fontWeight: "500",
                  marginBottom: "15px",
                }}
              >
                Please login contact to owner
              </div>
              <div style={{ marginTop: "20px" }}>
                <Button
                  style={{
                    width: "100%",
                    background:
                      "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
                    textTransform: "capitalize",
                    color: "white",
                    borderRadius: "10px",
                    height: "30px",
                  }}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleClickOpenLogin}
                // onClick={(event) => {
                //   router.history.push("/")
                // }}
                >
                  Log in
                  {loading && (
                    <>
                      &nbsp;
                      <CircularProgress size={15} style={{ color: "white" }} />
                    </>
                  )}
                </Button>
              </div>
            </div>
          )}
        </Box>
        <SignupModel open={open} setOpen={setOpen} setOpenLogin={onLoginOpen} />
        {/* <RegModel loginOpen={open} setLoginOpen={setOpen} setOpenSignUp={onLoginOpen} setForgotpasswordOpen={onForgotpasswordOpen}/> */}
        <LoginModel
          loginOpen={loginOpen}
          setLoginOpen={setLoginOpen}
          setOpenSignUp={onSignupOpen}
          setForgotpasswordOpen={onForgotpasswordOpen}
          url={url}
        />
        <Forgotpassword
          loginOpen={forgotpasswordOpen}
          setLoginOpen={onLoginOpen}
          setOpenSignUp={onSignupOpen}
        />
      </Container>
    </div>
  );
}

export default ContactDialog;
