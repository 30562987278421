import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Container, Grid } from "@material-ui/core";
import { Helmet } from "react-helmet";
import Api from '../../../../Helper/ApiHandler';

var api = new Api();

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
    // minHeight: "90vh"
    paddingBottom: "35px"
  },
  aboutStep: {
    textAlign: "center",
    margin: "0",
    paddingTop: "25px",
    //fontFamily: "Gilroy !important",
    color: "white"
  },
  contantAbout: {
    fontSize: "16px",
    color: "gray",
    textAlign: "justify"
  },
  contantBoxAbout: {
    marginTop: "50px",
    // height:"380px",
    [theme.breakpoints.down('xs')]: {
      height: "auto",
    },
  },
  topPart: {
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    height: "400px",
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
}));

const TermsOfUseDetail = (props) => {
  const classes = useStyles();
  const [tAndC, setTAndC] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [meta, setMeta] = React.useState({});

  useEffect(() => {
    onLoad()
    // onLoadMeta()
  }, []);


  const onLoad = () => {
    setLoading(true)
    // Api Call For Fetch The Terms Of Use
    api.get(`user/cms/term5sp`)
      .then((result) => {
        if (result.success === "true") {
          setTAndC(result.data.details)
        } else {
          console.log(result, "error")
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err, "catch error")
        setLoading(false)
      });
  };
  const onLoadMeta = () => {
    // For meta
    api.post('staticmetadata', {
      data: { type: "terms" }
    })
      .then((result) => {
        if (result.success === "true") {
          console.log(result.data, "result")
          setMeta(result.data, 'result.data')
        } else {
          console.log(result, "success === false")
        }
      })
      .catch((err) => {
        console.log(err, "error catch")
      });
  }
  return (
    <>
      <Helmet>
        <title>Terms</title>
        {/* <meta name="description" content={meta.meta_description} />
        <meta name="keywords" content={meta.meta_keyword} />

        <meta itemprop="name" content={meta.page_name} />
        <meta itemprop="description" content={meta.meta_description} />
        <meta itemprop="image" content={meta.meta_image} />

        <meta property="og:url" content="https://matchupmates.com" />
        <meta property="og:type" content="website" />

        <meta property="og:title" content={meta.meta_title} />
        <meta property="og:description" content={meta.meta_description} />
        <meta property="og:image" content={meta.meta_image} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={meta.meta_title} />
        <meta name="twitter:description" content={meta.meta_description} />
        <meta name="twitter:image" content={meta.meta_image} /> */}

      </Helmet>
      <div className={classes.mainRoot}>
        <div className={classes.topPart}>
          {/* <img src="/images/matchUp/faq.png" alt="" className={classes.topPartImage1} />
          <img src="/images/matchUp/faq2.png" alt="" className={classes.topPartImage2} /> */}
          <h1 className={classes.aboutStep}> Terms Of Service </h1>
        </div>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xl={12} lg={12} xs={12}>
              <div className={classes.contantBoxAbout}>
                {!loading ?
                  <div className={classes.contantAbout} dangerouslySetInnerHTML={{ __html: tAndC }}>
                  </div>
                  :
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress size={25} />
                  </div>
                }
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default TermsOfUseDetail;
