import React from "react";
import Linkyou from "./component/Likesyou";

const WhoLikesyou = () => {
  return (
    <>
      <Linkyou />
    </>
  );
};

export default WhoLikesyou;