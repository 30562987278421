import {
  Box,
  Link,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  makeStyles,
  TextField,
  useMediaQuery,
  Snackbar,
} from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import validate from "validate.js";
import PhoneInput from "react-phone-input-2";
import OtpInput from "react-otp-input";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import Api from "../../Helper/ApiHandler";
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const schema = {
  phone: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 15, message: "is too long (maximum is 15 digit)" },
    format: {
      pattern: "[0-9]+",
      flags: "i",
      message: "Enter only number",
    },
  },
};

const useStyles = makeStyles(() => ({
  root: {},
  dialogButtonHeader: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 10px 10px 10px",
    "& .MuiButton-root": {
      textTransform: "capitalize",
    },
  },
  dialogRoot: {
    padding: "10px",
    "& .MuiDialog-paper": {
      // overflow: "visible"
      borderRadius: "10px",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgb(0 0 0 / 54%)",
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "none",
    },
  },
  startedButton: {
    // fontSize: "20px",
    // height: "50px",
    height: "35px",
    fontSize: "18px",
    width: "100%",
    // background: "#0071CE",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    borderRadius: "10px",
    textTransform: "capitalize",
    fontWeight: "400",
  },
  socialIcon: {
    right: "5px",
    top: "5px",
    position: "absolute",
    cursor: "pointer",
  },
  inputePhone: {
    "& .react-tel-input .form-control": {
      width: "100%",
      padding: "8.5px 14px 8.5px 60px",
    },
  },
}));

function EditDialog(props) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const {
    handlePasswordChange,
    onClose,
    open,
    phoneno,
    phonecode,
    setCountrycode,
  } = props;
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [disabledLogin, setDisabledLogin] = useState(false);
  const [otpPhone, setOtpPhone] = React.useState(false);
  const [otpSendPhone, setOtpSendPhone] = React.useState(false);
  const [verifyPhone, setVerifyPhone] = React.useState(false);
  const [counter, setCounter] = React.useState(null);
  const [phone, setPhone] = useState("");
  const [otpLoader, setOtpLoader] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [formState, setFormState] = useState({
    values: {
      phone: "",
      countryCode: "",
    },
  });
  // const [phonecode, setPhonecode] = useState('IN'); // Example country code, set this dynamically as needed
  // const [phoneno, setPhoneno] = useState('');
  console.log(phoneno, "phonenophoneno");

  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });

  const handlePhoneChange = (value, data) => {
    // setPhoneno(value);

    console.log(data.dialCode, "data.dialCodedata.dialCode");
    setFormState((prevState) => ({
      ...prevState,

      values: {
        ...prevState.values,
        phone: value,
        countryCode: data.dialCode,
      },
    }));
  };

  const handleClose = () => {
    onClose(false);
    // logOutHandler()
    // setLoginOpen(false);
    // setOtpSendEmail(false)
    setCounter(null);
    setOtpSendPhone(false);
    setFormState({
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    });
  };
  const handleFieldChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const hasError = (field) => (formState.errors[field] ? true : false);

  const sendOtp = async (event, type, value) => {
    event.preventDefault();
    // dispatch(login());
    setDisabled(true);
    setOtpLoader(true);
    let tmpData = {};
    setCountrycode(formState.values.countryCode);

    if (type === "email") {
      tmpData = {
        type: type,
        value: value,
      };
    } else {
      tmpData = {
        type: type,
        value: phoneno || value.slice(-10),
        phonecode: parseInt(formState.values.countryCode),
      };
    }
    api
      .post("user/sendOtpMobile", {
        data: tmpData,
      })
      .then((result) => {
        if (result.success === "true") {
          setCounter(30);
          if (type === "email") {
            // setOtpSendEmail(true)
          } else {
            setOtpSendPhone(true);
          }
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          // setLoginOpen(false);
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setDisabled(false);
        setOtpLoader(false);
      })
      .catch((err) => {
        console.log(err, "error catch");
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        // setLoading(false)
        setDisabled(false);
        setOtpLoader(false);
      });
  };
  React.useEffect(() => {
    if (counter) {
      counter && counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      // :
      // setCounter(null)
    } else {
      // setCounter(null)
      return;
    }
  }, [counter]);
  const verifyOtp = async (event, type, value, code) => {
    event.preventDefault();
    setLoading(true);
    setDisabledLogin(true);
    // dispatch(login());
    api
      .post("user/verifyOtpMobile", {
        data: {
          // value: value,
          value: phoneno || value.slice(-10),
          code: code,
          is_verify: false,
        },
      })
      .then((result) => {
        if (result.success === "true") {
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
          handlePasswordChange(event, value);
          setTimeout(() => {
            handleClose();
          }, 1500);
        } else {
          // setLoginOpen(false);
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setDisabledLogin(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error catch");
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        setDisabledLogin(false);
        setLoading(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleAlertClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const fullPhoneNumber = `+${phonecode}${phoneno}`;

  return (
    <Dialog className={classes.dialogRoot} open={open} maxWidth={"md"}>
      <Grid container style={{ padding: "20px", position: "relative" }}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleAlertClose}
          >
            <Alert onClose={handleAlertClose} severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        <CloseIcon className={classes.socialIcon} onClick={handleClose} />
        <form onSubmit={(event) => handleSubmit(event)}>
          {/* <Box my={1} className={classes.inputePhone}>
            <PhoneInput
              countryCodeEditable={true}
              value={formState.values.phone}
              onChange={handlePhoneChange}
              country={91 || "in"}
            />
          </Box> */}

          <Box my={1} className={classes.inputePhone}>
            <PhoneInput
              country="in"
              countryCodeEditable={true}
              value={fullPhoneNumber}
              onChange={handlePhoneChange}
              // onCountryChange={handleCountryChange}
            />
          </Box>
          {otpSendPhone && !verifyPhone ? (
            <Box display={"flex"} alignItems={"center"} justifyContent="center">
              <OtpInput
                value={otpPhone || ""}
                name="otp"
                onChange={(otp) => !verifyPhone && setOtpPhone(otp)}
                numInputs={6}
                className={classes.otp}
                separator={<span>&nbsp;&nbsp; </span>}
                // inputStyle={{ width: "40px", fontSize: "20px", height: "40px", borderRadius: "5px", border: "1px solid rgba(0,0,0,0.15)" }}
                inputStyle={{
                  width: isMobile ? "40px" : "42px",
                  fontSize: "20px",
                  height: "40px",
                  borderRadius: "5px",
                  border: "1px solid rgba(0,0,0,0.15)",
                }}
                focusStyle={{ border: "2px solid #2196f3", outline: "none" }}
              />
            </Box>
          ) : (
            ""
          )}
          {counter > 0 ? (
            <Box
              style={{
                textAlign: "left",
                marginLeft: "20px",
                marginTop: "5px",
              }}
            >
              <span>Resend Otp in 00:{counter}</span>
            </Box>
          ) : (
            ""
          )}
          {counter === 0 ? (
            <Box
              style={{
                textAlign: "right",
                marginRight: "20px",
                marginTop: "5px",
                cursor: "pointer",
              }}
            >
              {!otpLoader && (
                <Link
                  onClick={(event) => {
                    sendOtp(event, "phone", formState.values.phone);
                  }}
                >
                  Resend Otp
                </Link>
              )}
              {otpLoader && (
                <>
                  &nbsp;
                  <CircularProgress size={15} />
                </>
              )}
            </Box>
          ) : (
            ""
          )}
          {counter !== 0 && !counter ? (
            <Box my={3} className={classes.startedButtonBox}>
              <Button
                className={classes.startedButton}
                variant="contained"
                style={{
                  backgroundColor: disabled ? "blue" : "rgba(0, 0, 0, 0.12)",
                  color: disabled ? "white" : "white",
                  boxShadow: disabled ? "none" : "",
                }}
                disabled={formState.isValid ? disabled : true}
                size="large"
                color="primary"
                onClick={(event) => {
                  sendOtp(event, "phone", formState.values.phone);
                }}
              >
                Send otp
                {otpLoader && (
                  <>
                    &nbsp;
                    <CircularProgress size={20} style={{ color: "white" }} />
                  </>
                )}
              </Button>
            </Box>
          ) : (
            <Box my={3} className={classes.startedButtonBox}>
              <Button
                className={classes.startedButton}
                variant="contained"
                disabled={
                  disabledLogin
                    ? disabledLogin
                    : otpPhone.length === 6
                    ? false
                    : true
                }
                size="large"
                color="primary"
                onClick={(event) => {
                  verifyOtp(event, "phone", formState.values.phone, otpPhone);
                }}
                style={{
                  color: disabled ? "white" : "white",
                  boxShadow: disabled ? "none" : "",
                }}
              >
                Update Number
                {loading && (
                  <>
                    &nbsp;
                    <CircularProgress size={20} style={{ color: "white" }} />
                  </>
                )}
              </Button>
            </Box>
          )}
        </form>
      </Grid>
    </Dialog>
  );
}

export default EditDialog;
