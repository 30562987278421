import React, { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./Payment.css";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import Api from "../../../../Helper/ApiHandler";
import YourSubscriptionsplan from "views/YourSubscription/component/YourSubscriptionsplan";
import moment from "moment";
import useRouter from "utils/useRouter";
import { useLocation } from "react-router-dom";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import YourCurrentPlan from "views/CurrentPlan/component/YourCurrentPlan";
import YourBoostCurrentPlan from "views/BoostCurrentPlan/component/YourBoostCurrentPlan";
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Payment(props) {
  const promise = loadStripe(
    "pk_test_51KwJYHSIkcBh1eHUbFw5Saae8mgHC9DhTl8QOfNWuljtyhu9gUB4QQHMlLQif9LvfQLxEpKQJNLdL5nTN1z4nWyC00nnywgGg7"
  );
  // REACT_APP_STRIP_PK=pk_test_51KwJYHSIkcBh1eHUbFw5Saae8mgHC9DhTl8QOfNWuljtyhu9gUB4QQHMlLQif9LvfQLxEpKQJNLdL5nTN1z4nWyC00nnywgGg7
  return (
    <div className="strape">
      <Elements stripe={promise}>
        <CheckoutForm
          paymentType={props.paymentType}
          subscription_type={props.subscription_type}
          paymentAmount={props.paymentAmount}
          amount={props.amount}
          handleClosePayment={props.handleClosePayment}
          currency={props.currency}
          // hendelpaynow={props.hendelpaynow}
          palnid={props.palnid}
        />
      </Elements>
    </div>
  );
}
function CheckoutForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [succ, setSucc] = useState("none");
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const router = useRouter();
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [invoicesopen, setInvoicesopen] = useState(false);
  const location = useLocation();
  // const [dialogOpen, setDialogOpen] = useState(false);
  const obj = location.state?.obj;
  const formattedDate = moment(obj?.createdAt).format("MMM Do YY");
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    // window
    //   .fetch("/create-payment-intent", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({items: [{ id: "xl-tshirt" }]})
    //   })
    //   .then(res => {
    //     return res.json();
    //   })
    //   .then(data => {
    //   });
    api
      .post("user/subscrpayment", {
        data: {
          amount: props.amount,
          currency: props.currency,
        },
      })
      .then((result) => {
        if (result.success === "true") {
          console.log(result, "result222222222222");
          console.log(
            result.data.paymentIntent.client_secret,
            "result.data.clientSecret"
          );
          setClientSecret(result.data.paymentIntent.client_secret);
        } else {
          console.log(result, "result");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
      Paymentheader: {
        fontSize: "17px",
        fontWeight: "600 !important",
        margin: "10px 0px",
      },
      buttonGroupConfirm: {
        textAlign: "center",
        margin: "10px",
        display: "flex",
        justifyContent: "end",
      },
      hrLine: {
        // borderBottom: "2px solid #000000",
        width: "100%",
        height: "1px",
        backgroundColor: "#000000",
        // opacity: "0.1",
        marginBottom: "8px",
      },
      EditProfilLable: {
        padding: "0 0 0px 10px",
        fontSize: "17px",
        fontWeight: "600 !important",
        margin: "15px 0px",
      },
      Paymentheader: {
        fontSize: "17px",
        fontWeight: "600 !important",
        margin: "10px 0px",
      },
      EditProfilLableorder: {
        fontSize: "17px",
        fontWeight: "700 !important",
        padding: "10px 10px",
      },
      dialogContent: {
        // padding: "0px 24px 24px 24px",
        // textAlign: "center",
      },
    },
  };

  const handleInvoicesmodul = () => {
    setInvoicesopen(false);
  };
  const handleClickInvoicesOpen = () => {
    setInvoicesopen(true);
  };
  const handleClosemodel = () => {
    setDialogOpen(false);
  };
  const handlecencelplan = () => {
    api
      .post("user/cancelusersubscr")
      .then((result) => {
        // Check if the API call was successful
        if (result.success === "true") {
          setDialogOpen(false);

          console.log("Subscription successfully canceled:", result);
        } else {
          console.log("Failed to cancel subscription. Result:", result);
        }
      })
      .catch((err) => {
        // Log any errors encountered during the API call
        console.error("Error occurred while canceling subscription:", err);
      });
  };
  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const GetUpgradePlan = () => {
    router.history.push("/Boostsubscriptions-list");
  };
  const handleSubmit = async (ev) => {
    console.log("1");
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);

      // const onBuy = async (subscriptionType) => {
      api
        .post("user/createuserboostersubscr", {
          data: {
            paymentIntentId:
              payload && payload.paymentIntent && payload.paymentIntent.id,
            subscription_type: "",

            promocode: "",
            subscription_id: props.palnid,

            currency: props.currency,
          },
        })
        .then((result) => {
          if (result.success === "true") {
            console.log(result, "result");
            setSessionMessage({
              message: result.message,
              type: "success",
              open: true,
            });
            setSucc("succ");
            // props.handleClosePayment()
          } else {
            console.log(result, "result");
            setSessionMessage({
              message: result.message,
              type: "error",
              open: true,
            });
            setSucc("err");
          }
        })
        .catch((err) => {
          console.log(err, "error catch");
          setSessionMessage({
            message: err.message,
            type: "error",
            open: true,
          });
          setSucc("err");
        });
      // }
    }
  };
  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };
  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }
  useEffect(() => {
    if (succ === "succ") {
      const timer = setTimeout(() => {
        router.history.push(`/your-matches`);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [router, succ]);
  return (
    <form id="payment-form" className="form" onSubmit={handleSubmit}>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleErorrClose}
        >
          <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}

      {succ === "none" && (
        <div>
          <Box>
            <h4 className={classes.Paymentheader}>
              {" "}
              Add Your Payment Information
            </h4>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "14px",
                margin: "0px 0px 10px",
              }}
            >
              Credit Card Details
            </Typography>
            <div className={classes.hrLine} />
          </Box>

          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              disabled={processing || disabled || succeeded}
              className={classes.confirmButton}
              variant="contained"
              color="primary"
              style={{
                background:
                  "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
                color: disabled ? "white" : "white",
                // boxShadow: disabled ? "none" : "",
                borderRadius: "100px",
                marginTop: "20px",
                width: "120px",
                height: "40px",
                fontSize: "14px",
                fontWeight: "400",
                border: "none",
                cursor: "pointer",
              }}
            >
              {/* <span
                style={{
                  paddingTop: "7px",
                }}
              >
                {!processing ? (
                  // <div className="spinner" id="spinner"></div>
                  <CircularProgress size={20} style={{ color: "white" }} />  
                  
                ) : (
                  "Pay now"
                )}
                  
              </span> */}

              {processing ? (
                <span style={{ display: "flex", alignItems: "center" }}>
                  Pay now
                  <CircularProgress
                    size={15}
                    style={{ color: "white", marginLeft: "5px" }}
                  />
                </span>
              ) : (
                "Pay now"
              )}
            </Button>
          </div>
        </div>
      )}

      {/* {/*  */}
      {succ === "err" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/images/errorPayment.svg" alt="" />
          <div style={{ marginTop: "30px" }}>
            <Typography
              component="h3"
              variant="h3"
              // className={classes.step7Heading}
              style={{ marginLeft: "5px" }}
            >
              Payment Failed
            </Typography>
          </div>
          <div style={{ marginBottom: "30px" }}>
            <Typography
              component="h6"
              variant="h6"
              // className={classes.step7Heading}
              style={{ marginLeft: "5px" }}
            >
              It seems we have not received moqwqwqney
            </Typography>
          </div>
          <Button
            variant="outlined"
            style={{ color: "red" }}
            onClick={() => props.handleClosePayment()}
          >
            Try Again
          </Button>
        </div>
      )}

      {succ === "succ" && (
        <Grid container>
          <Grid
            item
            xs={12}
            xl={12}
            lg={12}
            style={{
              border: "2px solid #E5E5EA",
              borderRadius: "10px",
              padding: "10px",
              // margin: "10px",
            }}
          >
            <Grid>
              <Box>
                <Typography>Current Plan</Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    margin: "0px",
                    fontSize: "25px",
                    fontWeight: "700",
                    color: "#FE4B6B",
                  }}
                >
                  {" "}
                  {capitalizeFirstLetter(obj?.title)} Plan{" "}
                </Typography>
                <Typography
                  style={{
                    color: "#FE4B6B",
                    fontSize: "16px",
                    cursor: "pointer",
                    fontweight: "700",
                  }}
                  onClick={GetUpgradePlan}
                >
                  Upgrade Plan
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    color: "#8E8E93",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Next payment ${obj?.amount} on  {formattedDate}{" "}
                </Typography>
                <Typography
                  style={{
                    color: "#FE4B6B",
                    fontSize: "15px",
                    // padding: "10px 0px",
                    cursor: "pointer",
                    textAlign: "end",
                  }}
                  onClick={handleClickInvoicesOpen}
                >
                  Billing History
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={dialogOpen}
        onClose={handleClosemodel}
        style={{ borderRadius: "10px" }}
        maxWidth="sm"
        fullWidth
      >
        <div className={classes.dialogContent}>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Typography variant="h4" className={classes.EditProfilLableorder}>
              cancel Plan
            </Typography>
            <Button onClick={handleClosemodel}>
              <CancelPresentationIcon />
            </Button>
          </DialogActions>
          <div className={classes.hrLine} />
          <Typography style={{ fontSize: "16px", padding: "10px" }}>
            Your subscription plan has been successfully canceled. If you have
            any further questions or need assistance, feel free to reach out to
            our support team.
          </Typography>
          <DialogActions style={{ justifyContent: "end", marginTop: "10px" }}>
            <Button
              // onClick={handleClosemodel}
              onClick={() => handleClosemodel()}
              className={`${classes.confirmButton} ${classes.gradientButton}`}
              variant="contained"
            >
              cancel
            </Button>
            <Button
              onClick={handlecencelplan}
              style={{
                background:
                  "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
              }}
              className={`${classes.confirmButton} ${classes.gradientButton}`}
              variant="contained"
            >
              Done
              {loading && (
                <>
                  &nbsp;
                  <CircularProgress size={20} style={{ color: "white" }} />
                </>
              )}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={invoicesopen}
        onClose={handleInvoicesmodul}
        style={{ borderRadius: "10px" }}
        maxWidth="md"
        fullWidth
      >
        <div className={classes.dialogContent}>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Typography variant="h5" className={classes.EditProfilLableorder}>
              Invoices
            </Typography>
            <Button onClick={handleInvoicesmodul}>
              <CancelPresentationIcon />
            </Button>
          </DialogActions>
          <div className={classes.hrLine} />
          <Box>
            <YourBoostCurrentPlan />
          </Box>
        </div>
      </Dialog>
      {/*       
      {
        error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )
      }
      
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded, see the result in your
        <a
          href={`https://dashboard.stripe.com/test/payments`}
        >
          {" "}
          Stripe dashboard.
        </a> Refresh the page to pay again.
      </p> */}
    </form>
  );
}
