import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  CircularProgress,
  Container,
  Grid,
  Box,
  Button,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import Api from "../../../../Helper/ApiHandler";
import SignupModel from "components/AuthModel/SignupModel";
import LoginModel from "components/AuthModel/LoginModel";
import Forgotpassword from "components/AuthModel/Forgotpassword";

var api = new Api();
const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#FFF0F2",
    // minHeight: "90vh"
  },
  aboutStep: {
    textAlign: "center",
    margin: "0",
    paddingTop: "25px",
    //fontFamily: "Gilroy !important",
  },
  contantAbout: {
    fontSize: "16px",
    color: "gray",
    textAlign: "justify",
  },
  contantBoxAbout: {
    marginTop: "50px",
    // height:"380px",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    marginTop: "30px",
    padding: "30px",
    height: "375px",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      overflow: "hidden",
    },
  },
  cardHeading: {
    fontSize: "20px",
    color: "#FD3576",
    fontWeight: "500",
    textAlign: "center",
    padding: "10px",
  },
  cardHeadingSub: {
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "center",
    padding: "10px",
    display: "-webkit-box !important",
    "-webkitLineClamp": 4,
    "-webkitBoxOrient": "vertical",
  },
  font: {
    position: "absolute",
    right: "10px",
    fontSize: "200px",
    color: "#FD3576",
    opacity: "10%",
    fontFamily: "system-ui",
    fontWeight: "500",
  },
  bottomLine: {
    backgroundColor: "white",
    display: "flex",
    borderRadius: "10px",
    justifyContent: "space-between",
    marginTop: "15px",
    marginBottom: "90px",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
    padding: "20px",
  },
  heading1: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
    },
  },
  headingImage: {
    [theme.breakpoints.down("xs")]: {
      height: "160px",
    },
  },
  propertyImageRoot: {
    height: "400px",
    backgroundColor: "rgb(232 238 241)",
  },
}));

const HowItWorkDetail = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [howItsWork, setHowItsWork] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [meta, setMeta] = React.useState({});
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [forgotpasswordOpen, setForgotpasswordOpen] = React.useState(false);

  useEffect(() => {
    onLoad();
    onLoadMeta();
  }, []);

  const onLoginOpen = () => {
    setLoginOpen(true);
    setForgotpasswordOpen(false);
    setOpen(false);
  };

  const onForgotpasswordOpen = () => {
    setLoginOpen(false);
    setForgotpasswordOpen(true);
  };

  const onSignupOpen = () => {
    setLoginOpen(false);
    setOpen(true);
  };

  const handleClickOpenSignUp = () => {
    setOpen(true);
  };

  const handleClickOpenLogin = () => {
    setLoginOpen(true);
  };
  const onLoad = () => {
    setLoading(true);
    // Api Call For Fetch The About
    api
      .get(`user/cms/howwork2sp`)
      .then((result) => {
        if (result.success === "true") {
          setHowItsWork(result.data.details);
        } else {
          console.log(result, "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setLoading(false);
      });
  };

  const onLoadMeta = () => {
    // For meta
    api
      .post("staticmetadata", {
        data: { type: "howitworks" },
      })
      .then((result) => {
        if (result.success === "true") {
          console.log(result.data, "result");
          setMeta(result.data, "result.data");
        } else {
          console.log(result, "success === false");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };
  return (
    <>
      <Helmet>
        <title>How its works</title>
        {/* <meta name="description" content={meta.meta_description} />
        <meta name="keywords" content={meta.meta_keyword} />

        <meta itemprop="name" content={meta.page_name} />
        <meta itemprop="description" content={meta.meta_description} />
        <meta itemprop="image" content={meta.meta_image} />

        <meta property="og:url" content="https://matchupmates.com" />
        <meta property="og:type" content="website" />

        <meta property="og:title" content={meta.meta_title} />
        <meta property="og:description" content={meta.meta_description} />
        <meta property="og:image" content={meta.meta_image} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={meta.meta_title} />
        <meta name="twitter:description" content={meta.meta_description} />
        <meta name="twitter:image" content={meta.meta_image} /> */}
      </Helmet>
      <div className={classes.mainRoot}>
        <div
          className={classes.propertyImageRoot}
          style={{
            backgroundImage: "url(/images/matchUp/howit.jpg)",
            position: "relative",
          }}
        >
          <Box
            position="absolute"
            height={"100%"}
            width={"100%"}
            style={{
              top: "0",
              backgroundColor: "#00000036",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                fontWeight: "500",
              }}
            >
              {" "}
              How Matchupmates Works{" "}
            </h1>
          </Box>
        </div>
        {/* <div style={{ position: "relative" }}>
          <img className={classes.headingImage} src="/images/HowItsWorks.jpg" alt={"Alt Image"} style={{ width: "100%" }} />
          <Box position="absolute" height={"100%"} width={"100%"} style={{ top: "0", backgroundColor: "#00000036", display: "flex", alignItems: "center" }}>
            <h1 className={classes.heading1} style={{ color: "white", display: "flex", justifyContent: "center", width: "100%" }}> How Matchupmates Works </h1>
          </Box>
        </div> */}
        <Container maxWidth="lg" style={{ paddingTop: "60px" }}>
          {/* <h1 className={classes.aboutStep}> How It Work </h1> */}
          <Grid container spacing={2}>
            <Grid item xl={4} lg={4} xs={12}>
              <div
                className={classes.cardRoot}
                style={{ position: "relative" }}
              >
                <img src="/images/matchUp/HowItsWork1.svg" alt={"Alt Image"} />
                {/* <div style={{ position: "relative" }}> */}
                <div className={classes.cardHeading}>
                  {" "}
                  Create your profile by providing your personal details, Select
                  your college 👋{" "}
                </div>
                <div className={classes.cardHeadingSub}>
                  Signing up is super easy. Just enter your name, upload a
                  picture, add a couple more details, and you're off to the
                  races.
                </div>
                <div className={classes.font} style={{ bottom: "-60px" }}>
                  1
                </div>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} xs={12}>
              <div
                className={classes.cardRoot}
                style={{ position: "relative" }}
              >
                <img src="/images/matchUp/HowItsWork2.svg" alt={"Alt Image"} />
                {/* <div style={{ position: "relative" }}> */}
                <div className={classes.cardHeading}>
                  {" "}
                  Take the mate matching quiz for your selected modules 🧪{" "}
                </div>
                <div className={classes.cardHeadingSub}>
                  Answer about 20 questions and we use our techno-super brain to
                  match you with potential mates.
                </div>
                <div className={classes.font} style={{ bottom: "-60px" }}>
                  2
                </div>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} xs={12}>
              <div
                className={classes.cardRoot}
                style={{ position: "relative" }}
              >
                <img src="/images/matchUp/HowItsWork3.svg" alt={"Alt Image"} />
                {/* <div style={{ position: "relative" }}> */}
                <div className={classes.cardHeading}> Meet your matches </div>
                <div className={classes.cardHeadingSub}>
                  Chat and Communicate with your matches 😍 When you see someone
                  you like, it's easy to send them a message and get connected
                  with your mate!🎉
                </div>
                <div className={classes.font} style={{ bottom: "-58px" }}>
                  3
                </div>
              </div>
            </Grid>
            <Grid item xl={12} lg={12} xs={12}>
              <Box className={classes.bottomLine}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "18px",
                    color: "#FD3576",
                    fontWeight: "500",
                  }}
                >
                  Ready to find your mate?
                </div>
                <div>
                  <Button
                    variant="contained"
                    size="medium"
                    style={{
                      background:
                        "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
                      color: "white",
                      textTransform: "capitalize",
                      borderRadius: "10px",
                    }}
                    onClick={handleClickOpenSignUp}
                  >
                    Create an Account
                  </Button>
                </div>
              </Box>
            </Grid>
            {/* <Grid item xl={12} lg={12} xs={12}>
              <div className={classes.contantBoxAbout}>
                {!loading ?
                  <div className={classes.contantAbout} dangerouslySetInnerHTML={{ __html: howItsWork }}>
                  </div>
                  :
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress size={50} />
                  </div>
                }
              </div>
            </Grid> */}
          </Grid>
        </Container>
        <SignupModel open={open} setOpen={setOpen} setOpenLogin={onLoginOpen} />
        <LoginModel
          loginOpen={loginOpen}
          setLoginOpen={setLoginOpen}
          setOpenSignUp={onSignupOpen}
          setForgotpasswordOpen={onForgotpasswordOpen}
        />
        <Forgotpassword
          loginOpen={forgotpasswordOpen}
          setLoginOpen={onLoginOpen}
          setOpenSignUp={onSignupOpen}
        />
      </div>
    </>
  );
};

export default HowItWorkDetail;
