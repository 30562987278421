import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { IconButton, List, ListItem } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Container, Grid, Box } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { Link, Link as RouterLink } from "react-router-dom";
import DownloadApp from "views/Home/components/DownloadApp";
import { useContext } from "react";
import AppDataContext from "Helper/AppDataContext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8, 0),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(11, 0),
    },
    // background: "#0071CE",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
  },
  textFooter: {
    color: "#FFFFFF;",
    // marginLeft: "10px",
    //fontFamily: "Gilroy !important",
    fontSize: "16px",
    fontWeight: 300,
  },
  socialIcon: {
    color: "white",
    // '& .MuiIconButton-root':{
    padding: "5px !important",
    // }
  },
  icon: {
    fontSize: "30px",
  },
  linksHeading: {
    marginBottom: "15px !important",
    color: "white",
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  itemList: {
    fontSize: "14px",
    color: "#FFFFFF",
    padding: "8px 0",
    margin: "2px !important",
    padding: "0px !important",
    // fontWeight: "lighter"

    fontWeight: 300,
  },
  BoxListLink: {
    hieght: "100%",
    width: "100%",
    marginTop: "5px !important",
    // textAlign: "start",
  },
  subLinkGrid: {
    marginTop: "5px !important",
    // height: "180px",
  },
  lastFooter: {
    background: "#000000",
    // background: "black",
    width: "100%",
    // height: "60px",
    fontWeight: "500",
    color: "white",
    textAlign: "center",
    lineHeight: "60px",
    borderTop: "1px solid",
  },
  footerLogo: {
    width: "238px",
    height: "55px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  itemListLink: {
    textDecoration: "none",
    color: "white",
  },
  appStore: {
    marginRight: "10px",
    // [theme.breakpoints.up("sm")]: {
    // },
    // [theme.breakpoints.down("sm")]: {
    //   marginBottom: "10px",
    // },
    cursor: "pointer",
    width: "120px",
  },
  playStore: {
    cursor: "pointer",
    width: "120px",
  },
}));

const Footer = (props) => {
  const { pages, className, ...rest } = props;

  const { appContext, setAppContext } = useContext(AppDataContext);

  const classes = useStyles();
  {
    /* <div>
                <ListItem disableGutters>
                  <IconButton className={classes.socialIcon}>
                    <FacebookIcon className={classes.icon} />
                  </IconButton>
                  <IconButton className={classes.socialIcon}>
                    <InstagramIcon className={classes.icon} />
                  </IconButton>
                  <IconButton className={classes.socialIcon}>
                    <TwitterIcon className={classes.icon} />
                  </IconButton>
                  <IconButton className={classes.socialIcon}>
                    <LinkedInIcon className={classes.icon} />
                  </IconButton>
                </ListItem>
              </div> */
  }
  return (
    <>
      {/* <DownloadApp /> */}
      <div {...rest} className={clsx(classes.root, className)}>
        <Container>
          <Grid
            direction="row"
            justifyContent="center"
            // alignItems="center"
            container
          // spacing={6}
          >
            <Grid
              item
              xs={12}
              xl={5}
              md={5}
              lg={4}
              style={{ marginTop: "20px" }}
            >
              <Box
                // display="flex"
                // flexDirection="column"
                // justifyContent="center"
                // alignItems="center"
                p={2}
              >
                <img
                  className={classes.footerLogo}
                  src="/images/matchUp/loginBarLogo.svg"
                  alt={"Alt Image"}
                />
                <div>
                  <p className={classes.textFooter}>
                    Matchupmates is a social networking resource that helps high
                    school and college students identify and connect with
                    compatible matches.
                  </p>
                </div>
              </Box>
            </Grid>

            <Grid item xs={6} xl={2} md={2} lg={2}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mt={"40px"}
              // alignItems="center"
              >
                <Box ml={2}>
                  <h4 className={classes.linksHeading}> Links </h4>
                  <Grid className={classes.subLinkGrid}>
                    <a href="/" className={classes.itemListLink}>
                      <p className={classes.itemList}> Home </p>
                    </a>
                    <a href="/about-us" className={classes.itemListLink}>
                      <p className={classes.itemList}> About us </p>
                    </a>
                    <a href="/how-it-work" className={classes.itemListLink}>
                      <p className={classes.itemList}> How It Works </p>
                    </a>
                    {/* appContext.objAuth?.DORMMATE */}
                    {/* appContext.objAuth?.LIVE_MODULE_COUNT < 1 */}
                    <a href={
                      appContext.objAuth?.LIVE_MODULE_COUNT > 1 ?
                        "/faq" :
                        appContext.objAuth?.DORMMATE ?
                          "/faq#dormmate"
                          :
                          appContext.objAuth?.ROOMMATE ?
                            "/faq#roommate"
                            :
                            appContext.objAuth?.SOULMATE ?
                              "/faq#soulmate"
                              :
                              appContext.objAuth?.TEAMMATE ?
                                "/faq#teammate"
                                :
                                "/"
                    }
                      className={classes.itemListLink}>
                      <p className={classes.itemList}> FAQ</p>
                    </a>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} xl={2} md={2} lg={3}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mt={"40px"}
              // alignItems="center"
              >
                <Box>
                  <h4 className={classes.linksHeading}> Links </h4>
                  <Grid className={classes.subLinkGrid}>
                    <a href="/privacy-policy" className={classes.itemListLink}>
                      <p className={classes.itemList}> Privacy policy</p>
                    </a>
                    <a href="/terms-of-use" className={classes.itemListLink}>
                      <p className={classes.itemList}> Terms of service</p>
                    </a>
                    <a href="/contact-us" className={classes.itemListLink}>
                      <p className={classes.itemList}> Contact us</p>
                    </a>
                    <a href="/blog" className={classes.itemListLink}>
                      <p className={classes.itemList}> Blog</p>
                    </a>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} xl={3} md={3} lg={3}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                // alignItems="center"
                mt={"40px"}
              >
                <Box>
                  <h4 className={classes.linksHeading}>Stay connected</h4>
                  <Grid className={classes.subLinkGrid}>
                    <div>
                      <ListItem disableGutters>
                        <a
                          href="https://www.facebook.com/matchupmates"
                          target="_blank"
                        >
                          <IconButton className={classes.socialIcon}>
                            <FacebookIcon className={classes.icon} />
                          </IconButton>
                        </a>
                        <a
                          href="https://www.instagram.com/matchupmates/"
                          target="_blank"
                        >
                          <IconButton className={classes.socialIcon}>
                            <InstagramIcon className={classes.icon} />
                          </IconButton>
                        </a>
                        <a
                          href="https://twitter.com/matchupmates"
                          target="_blank"
                        >
                          <IconButton className={classes.socialIcon}>
                            <TwitterIcon className={classes.icon} />
                          </IconButton>
                        </a>
                        <a href="https://www.linkedin.com/company/matchupmates/" target="_blank">
                          <IconButton className={classes.socialIcon}>
                            <LinkedInIcon className={classes.icon} />
                          </IconButton>
                        </a>
                      </ListItem>
                    </div>
                    <div className={classes.downloadIcon}>
                      {/* <Link
                        href={"https://apps.apple.com/us/app/matchupmates-like-minded-pair/id1625071306"}
                      > */}
                      <a
                        href="https://apps.apple.com/us/app/matchupmates-like-minded-pair/id1625071306"
                        target="_blank"
                      >
                        <img
                          src={"/images/matchUp/AppStore.png"}
                          className={classes.appStore}
                          alt={"Alt Image"}
                        />
                      </a>
                      {/* </Link> */}
                      {/* <Link
                        href={"https://play.google.com/store/apps/details?id=com.matchupmates"}
                      > */}
                      <a
                        href="https://play.google.com/store/apps/details?id=com.matchupmates"
                        target="_blank"
                      >
                        <img
                          src={"/images/matchUp/PlayStore.png"}
                          className={classes.playStore}
                          alt={"Alt Image"}
                        />
                      </a>
                      {/* </Link> */}
                    </div>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.lastFooter}>
        <a href="/terms-of-use" className={classes.itemListLink}>
          Terms Of Service
        </a>{" "}
        &nbsp;&nbsp;{" "}
        <a href="/privacy-policy" className={classes.itemListLink}>
          {" "}
          Privacy Policy
        </a>{" "}
        &nbsp; &copy; Copyright 2022
      </div>
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
};

export default Footer;
