import React from "react";
import YourSubscriptionsplan from "./component/YourSubscriptionsplan";

const YourSubscription = () => {
  return (
    <>
      <YourSubscriptionsplan />
    </>
  );
};

export default YourSubscription;