import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Container, FormControlLabel, Grid, IconButton, InputAdornment, Snackbar, TextField, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Api from '../../Helper/ApiHandler';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import MuiAlert from '@material-ui/lab/Alert';
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import validate from "validate.js";
import AppDataContext from "Helper/AppDataContext";
import firebase from 'firebase/app';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import useRouter from "utils/useRouter";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId
} from "react-google-places-autocomplete";
import clsx from "clsx";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
var api = new Api();


var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const lstGender = [
//   { id: "male", value: "Male" },
//   { id: "female", value: "Female" },
//   { id: "intersex", value: "Intersex" },
//   { id: "trans", value: "Trans" },
//   { id: "noPreference", value: "No Preference" },
// ]


const lstGender = [
  "male",
  "female",
  "intersex",
  "trans",
  "other",
]

const schema = {
  first_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character"
    }
  },
  last_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character"
    }
  },
  g: {
    presence: { allowEmpty: false, message: "is required" },
  },
  zodiac: {
    presence: { allowEmpty: false, message: "is required" },
  },
  location: {
    presence: { allowEmpty: false, message: "is required" },
  },
  date_of_birth: {
    presence: { allowEmpty: false, message: "is required" },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: { maximum: 254 }
  }
};

const useStyles = makeStyles(theme => ({
  bgProfilePage: {
    background: "#E5E5E5",
    padding: "35px 0",
    height: "100vh"
  },
  questionsText: {
    fontSize: "20px",
    padding: "20px",
    color: "white",
    fontWeight: "bold",
    margin: "0",
  },
  questionsBox: {
    background: "#0071CE",
    borderRadius: "10px 10px 0px 0px",
  },
  startQuizBox: {
    background: "#fff",
    borderRadius: "10px 10px 0px 0px",
    // padding: "20px"
  },
  startText: {
    color: "black",
    fontSize: "16px",
    // padding: "10px",
    fontWeight: "500",
    margin: "0",
    textAlign: "center"
  },
  answerBox: {
    background: "white",
    borderRadius: "0px 0px 10px 10px",
    overflowY: "auto",
    height: "480px",
    width: "100%"
    // padding: "20px"
  },
  selectOptionIn: {
    //fontFamily: "Gilroy !important",
    '& .MuiOutlinedInput-notchedOutline': {
      // borderWidth: 0
    },
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon": {
      padding: "10.5px",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: "3px",
      // fontSize: "12px"
    },
    // backgroundColor: "#e9edf0"
  },
  headingAns: {
    margin: "0",
    padding: "20px",
    paddingBottom: "0",
    fontSize: "18px",
    fontWeight: "600",
    marginLeft: "10px",
  },
  chipUserDetail: {
    margin: "6px 5px",
    fontSize: "14px",
    padding: "0 10px",
    //fontFamily: "Gilroy !important",
    [theme.breakpoints.down("xs")]: {
      padding: "0 7px",
      fontSize: "14px",
      margin: "4px 3px",
    },
  },
  chipInputEdit: {
    // margin: "3px 20px",
    '& .MuiAutocomplete-tag': {
      borderRadius: "5px !important",
      background: "rgb(204 227 245)"
    },
    //fontFamily: "Gilroy !important",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
    '& .MuiOutlinedInput-root': {
      // borderRadius: "10px"
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: 3
    }
  },
  chip: {
    background: "#d5606045",
    //fontFamily: "Gilroy !important",
    borderRadius: "5px",
    margin: "5px 5px",
    padding: "0 0",
    height: "28px"
  },
  chipGruop: {
    padding: "10px 20px",
  },
  // autocompletChip: {
  //   background: "white",
  //   '& .MuiChip-root': {
  //     borderRadius: "5px",
  //     background: "#d5606045",
  //     height: "auto",
  //   },
  //   '& .MuiChip-outlined': {
  //     border: "none",
  //     color: "#FE4B6B",
  //     padding: "2px"
  //   },
  //   '& .MuiFilledInput-root': {
  //     background: "white"
  //   }
  // },
  lineHorizontal: {
    width: "95%",
    // borderBottom: "2px solid #000000",
    opacity: "0.1",
    margin: "0 auto",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 25px",
  },
  nextButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    background: "rgba(0, 113, 206, 0.2)",
    width: "80px",
    height: "30px",
  },
  skipButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    textDecoration: "underline",
    marginTop: "5px",
    cursor: "pointer"
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
    marginTop: "10px"
  },
  buttonStart: {
    width: "100%",
    fontSize: "14px",
    textTransform: "capitalize",
    margin: "10px 0px",
    background: 'linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)',
    // margin: "0px 10px"
  },
  mainStartQuixBox: {
    textAlign: "center",
    // padding: "15px 15px",
  },
  textNewStep: {
    fontSize: "16px !important",
    fontWeight: "900",
    width: "60%",
    textAlign: "center",
    margin: "19px auto",
  },
  startQuizMain: {
    // marginBottom: "20px",
    // padding: "20px"
    width: "100%"
  },
  autocompletChip: {
    textTransform: "capitalize",
    padding: "10px",
    background: "white",
    '& .MuiChip-root': {
      borderRadius: "5px",
      background: "#d5606045",
      height: "auto",
    },
    '& .MuiChip-outlined': {
      border: "none",
      color: "#FE4B6B",
      padding: "2px",
      textTransform: "capitalize"
    },
    '& .MuiFilledInput-root': {
      background: "white"
    },
  },
  optionAuto: {
    textTransform: "capitalize",
  },
  chipIcon: {
    width: "13px",
    height: "13px",
    color: "#FE4B6B"
  },
  editIconPro: {
    position: "absolute",
    top: "80px",
    right: "5px",
    [theme.breakpoints.down("xs")]: {
      right: "5px",
    },
    cursor: "pointer"
  },
  profilePhotoEdit: {
    position: "relative",
    margin: "10px auto",
    width: "fit-content"
  },
  checkboxDetail: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "35px",
    },
  },
  genderPrefranceHeading: {
    fontSize: "18px",
    fontWeight: "500",
    marginLeft: "12px",
    marginRight: "18px",
  },
  genderSelectBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  textFiledBox: {
    width: "97%",
    margin: "10px auto",
    "& .MuiFormControl-marginNormal": {
      marginTop: "0px",
      marginBottom: "0px",
    }
  },

  googleSelecte: {
    width: "90%",
    margin: "15px auto",
    '& .css-yk16xz-control': {
      // borderRadius: "10px",
      // padding: "8px"
    },
    '& .css-1pahdxg-control': {
      // borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      // padding: "8px"
    },
    '& .css-1pahdxg-control:hover': {
      // borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      // padding: "8px"
    },
    '& .css-1uccc91-singleValue': {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
      fontWeight: 400
    },
    '& .css-1wa3eu0-placeholder': {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
    },
    '& div': {
      fontFamily: "'Poppins', sans-serif",
      backgroundColor: "white",
      zIndex: 11
    }
  },
  startedButton: {
    // fontSize: "20px",
    // height: "50px",
    height: "35px",
    fontSize: "18px",
    width: "246px",
    // background: "#0071CE",
    background: 'linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)',
    borderRadius: "10px",
    textTransform: "capitalize",
    fontWeight: "400"
  },
}));

const PersonalDetailForm = props => {
  const classes = useStyles();
  const history = useHistory();

  const [p_answer, set_P_Answer] = React.useState([]);
  const [s_answer, set_S_Answer] = React.useState([]);
  const [sessionMessage, setSessionMessage] = React.useState(props.sessionPropsMessage);
  const [lstP_University, setLst_P_University] = React.useState([]);
  const [lstS_University, setLst_S_University] = React.useState([]);
  const [lstPlannedActivities, setLstPlannedActivities] = React.useState([]);
  const [lstInterests, setLstInterests] = React.useState([]);
  const [uploadImagePath, setUploadImagePath] = React.useState('');
  const [preferenceGender, setPreferenceGender] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const { appContext, setAppContext } = useContext(AppDataContext);
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);


  useEffect(() => {
    getPlannedActivites()
    getInterests()
  }, []);


  // Get Interests
  const getInterests = async () => {
    // Api Call For Fetch Interests List
    api.get(`interestlist`)
      .then((result) => {
        if (result.success === "true") {
          setLstInterests(result.data)
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  }

  const getPlannedActivites = async () => {
    // Api Call For Fetch Planned activites List
    api.get(`planactvlist`)
      .then((result) => {
        if (result.success === "true") {
          setLstPlannedActivities(result.data)
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  }

  // Start The Quiz
  const StartTheQuizHandler = () => {
    if (!formState.values.planned_activities?.length) {
      setSessionMessage({
        message: 'Please selecte liked Activities',
        type: 'error',
        open: true
      });
      return
    }
    if (!formState.values.interests?.length) {
      setSessionMessage({
        message: 'Please selecte interests',
        type: 'error',
        open: true
      });
      return
    }
    if (!formState.values.gender?.length) {
      setSessionMessage({
        message: 'Please selecte preference gender',
        type: 'error',
        open: true
      });
      return
    }

    let planned_activities = formState.values.planned_activities
    let interests = formState.values.interests
    let preference_gender = formState.values.gender

    let data = {
      planned_activities: planned_activities,
      interests: interests,
      preference_gender: preference_gender
    }
    // Api Call For personal details
    setLoading(true)
    api.post('user/addactivitiesandinterests', {
      data: data
    })
      .then((result) => {
        if (result.success === "true") {
          // history.push("/matching-quiz")
          window.location.replace("/selecte-module");
        } else {
          setSessionMessage({
            message: result.message,
            type: 'error',
            open: true
          });
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err, "catch error")
        setLoading(false)
      });
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }

  const handleAutocompletChange = (event, value, name) => {
    if (name === "p_university") {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value.value
          }
        }));
      }
    } else if (name === "s_university") {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value.value
          }
        }));
      }
    } else if (name === "gender") {
      if (value !== null) {
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value
          },
          touched: {
            ...formState.touched,
            [name]: true
          }
        }));
      }
    } else {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value
          }
        }));
      }
    }
  }


  const onUploadimageChange = async (event, name) => {
    const file = event.target.files[0];
    // Base64
    var reader = new FileReader();
    reader.onloadend = function () {
      setUploadImagePath(reader.result)
    }
    reader.readAsDataURL(file);

    const data = new FormData();
    data.append('file', file);
    // Upload image
    api.post('user/uploadprofile', {
      data: data
    })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem("userProfile", result.data)
          // setAppContext({ ...appContext, userProfile: result.data })
        } else {
          console.log(result, "success === false")
        }
      })
      .catch((err) => {
        console.log(err, "error catch")
      });
  }

  const handle_Prefrance_GenderChange = (value) => {
    let preference_gender = [...preferenceGender]
    let index = preference_gender.indexOf(value)
    if (index > -1) {
      preference_gender.splice(index, 1)
    } else {
      preference_gender.push(value)
    }
    setPreferenceGender(preference_gender)
  };
  return (
    <>
      <div className={classes.fields}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert
              onClose={handleErorrClose}
              severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
      </div>
      <Box className={classes.startQuizMain}>
        <Box className={classes.startQuizBox}>
          <p className={classes.startText}>Personal details</p>
        </Box>
        <div className={classes.hrLine} />
        <Box className={classes.answerBox}>
          <Box className={classes.mainStartQuixBox}>

            <div className={classes.imageEditProfile}>
              <div className={classes.profilePhotoEdit}>
                {/* <img src="/images/matchUp/editProfile.png" alt="image" /> */}
                <img
                  src={uploadImagePath ? uploadImagePath : "/images/male_defualt.svg"}
                  alt="image"
                  style={{ width: "115px", height: "115px", borderRadius: "50%", objectFit: "cover" }}
                />

                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="icon-button-file"
                  type="file"
                  onChange={(e) => {
                    onUploadimageChange(e)
                  }}
                />
                <label htmlFor="icon-button-file">
                  <img
                    className={classes.editIconPro}
                    src="/images/matchUp/editIcon.svg"
                    alt="image"
                  />
                </label>

              </div>
              <div className={classes.hrLine} />
            </div>

            <Autocomplete
              multiple
              noOptionsText={"Type for search..."}
              id="tags-filled"
              options={lstPlannedActivities.map((option) => option)}
              name="planned_activities"
              value={lstPlannedActivities.length && formState.values.planned_activities ? formState.values.planned_activities : []}
              classes={{
                root: classes.chipInputEdit,
                chip: classes.chip,
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    deleteIcon={<CloseIcon className={classes.chipIcon} />}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Liked Activities"
                  name="planned_activities"
                  className={classes.inputProfileEdit}
                  autoFocus={false}
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk
                    },
                    shrink: true,
                  }}
                />
              )}
              className={classes.autocompletChip}
              onChange={(e, value) => {
                handleAutocompletChange(e, value, "planned_activities")
              }}
            />


            <Autocomplete
              multiple
              id="tags-filled"
              options={lstInterests.map((option) => option)}
              // freeSolo
              noOptionsText={"Type for search..."}
              value={lstInterests.length && formState.values.interests ? formState.values.interests : []}
              name="interests"
              classes={{
                root: classes.chipInputEdit,
                chip: classes.chip,
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    deleteIcon={<CloseIcon className={classes.chipIcon} />}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Interests"
                  name="interests"
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk
                    },
                    shrink: true,
                  }}
                />
              )}
              className={classes.autocompletChip}
              onChange={(e, value) => {
                handleAutocompletChange(e, value, "interests")
              }}
            />
            <Autocomplete
              multiple
              id="tags-filled"
              options={lstGender.map((option) => option)}
              // freeSolo
              noOptionsText={"Type for search..."}
              value={lstGender.length && formState.values.gender ? formState.values.gender : []}
              name="gender"
              classes={{
                root: classes.chipInputEdit,
                chip: classes.chip,
                option: classes.optionAuto
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    deleteIcon={<CloseIcon className={classes.chipIcon} />}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Preference Gender"
                  name="gender"
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk
                    },
                    shrink: true,
                  }}
                />
              )}
              className={classes.autocompletChip}
              onChange={(e, value) => {
                handleAutocompletChange(e, value, "gender")
              }}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStart}
              endIcon={loading ? <>&nbsp;<CircularProgress size={20} style={{ color: "white" }} /></> : <img src="/images/matchUp/startAerrow.png" alt="startAerrow" />}
              onClick={StartTheQuizHandler}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

// SignupDetailForm.propTypes = {
//   className: PropTypes.string
// };

export default PersonalDetailForm;
