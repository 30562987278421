import React, { useEffect } from "react";
import { makeStyles, styled } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import PropTypes from "prop-types";
import { CheckCircle, AcUnit, Check, Spa } from "@material-ui/icons";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Payment from "./Payment.js";
import moment from "moment";
import Api from "../../../../Helper/ApiHandler";
import NoRecordFound from "views/NoRecordFound/index.js";
import PerfectScrollbar from "react-perfect-scrollbar";
import useRouter from "utils/useRouter.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { pad } from "lodash-es";
// import AcUnitIcon from '@material-ui/icons/AcUnit';
var api = new Api();

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#e9edf0",
    minHeight: "90vh",
    padding: "35px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  headingPage: {
    fontWeight: "600 !important",
    fontSize: "18px",
    margin: "30px 10px 1px 10px",
  },
  mainAppTab: {
    backgroundColor: "#E5E5E5",
    color: "black",
    boxShadow: "none",
    textTransform: "capitalize",
    borderBottom: `1px solid gray`,
  },
  tabNameLabel: {
    textTransform: "capitalize",
    fontWeight: "bold",
    minWidth: "100px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "120px",
    },
  },
  mainCardDetail: {
    background: "white",
    position: "relative",
    // height: "350px",
    width: "100%",
    height: "100%",
    border: "1px solid #E5E5EA",
    borderRadius: "10px",
    padding: "20px",
  },
  halfColourDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "25px",
    // margin: "10px 10px",
  },
  headingPlan: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#FE4B6B",
    textTransform: "capitalize",
    // marginTop: "0px",
  },
  headingtime: {
    textTransform: "capitalize",
    fontWeight: "400",
    padding: "4px 14px",
    // height: "25px",
    Width: "100%",
    textWrap: "nowrap",
    borderRadius: "50px",
    border: "none",
    backgroundColor: "#FE4B6B1A",
    color: "#FE4B6B",
  },
  whitePaperDetail: {
    margin: "10px 0px 0",
  },
  priceMates: {
    margin: "0",
    marginTop: "5px",
    marginBottom: "5px",
    display: "flex",
  },
  buttonSelectcurrentplan: {
    textTransform: "capitalize",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    fontWeight: "400",
    height: "45px",
    width: "100%",
    borderRadius: "50px",
    fontSize: "14px",
    boxShadow: "none",
    color: "white !important",
  },
  timeValueSubs: {
    margin: "0",
    color: "gray",
    fontSize: "14px",
  },
  detailCardSubs: {
    marginTop: "20px",
    fontSize: "14px",
    padding: "5px 0px",
  },
  deatilBox: {
    textAlign: "left",
    paddingTop: "10px",
    paddingLeft: "8px",
  },
  imageDetail: {
    marginRight: "5px",
  },
  lastBtnFlex: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    bottom: "-7px",
    width: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    justifyContent: "center",
    color: "#36D860",
  },
  buttonCardSelect: {
    width: "100%",
    color: "#36D860",
    textAlign: "center",
    marginTop: "25px",
  },
  buttonSelect: {
    textTransform: "capitalize",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    fontWeight: "400",
    height: "45px",
    width: "100%",
    borderRadius: "50px",
    fontSize: "14px",
    boxShadow: "none",
  },
  buttonBilling: {
    textTransform: "capitalize",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    fontWeight: "400",
    height: "30px",
    Width: "100%",
    textWrap: "nowrap",
    top: "25px",
  },
  Cancel: {
    textTransform: "capitalize",
    background: "#e9edf0",
    fontWeight: "400",
    height: "30px",
    Width: "100%",
    textWrap: "nowrap",
    border: "1px solid black",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "30% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "25%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "25%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      // paddingLeft: "28px !important",
      width: "69%",
    },
    [theme.breakpoints.only("lg")]: {
      // paddingLeft: "28px !important",
      width: "74%",
    },
    [theme.breakpoints.only("xl")]: {
      // paddingLeft: "28px !important",
      width: "74%",
    },
  },
  bottomLastCard: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
    },
  },
  socialIcon: {
    position: "absolute",
    right: "-10px",
    top: "-24px",
    cursor: "pointer",
  },
  tableRoot: {
    "& .MuiTable-root": {
      border: "1px solid gray",
      width: "100%",
      display: "table",
      // borderRadius: "10px",
      borderSpacing: "inherit",
      borderCollapse: "inherit",
    },
  },
  Subscription_heading: {
    color: "#FF695B",
    textAlign: "center",
    margin: "0px",
  },
  title: {
    color: "#FE4B6B",
    fontWeight: "700",
    marginTop: "10px",
  },
  card: {
    minHeight: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardActions: {
    justifyContent: "center",
  },
  planPrice: {
    color: "red",
  },
  timebutton: {
    borderRadius: "20px",
  },
  speretsection: {
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "8px",
  },
  hrLine: {
    borderBottom: "2px solid #E5E5EA",
    // opacity: "0.1",
    margin: "20px 0px",
  },
}));

const PricingCard = styled(Card)(({ theme, highlighted }) => ({
  border: highlighted ? `2px solid ${theme.palette.primary.main}` : "",
  minWidth: 275,
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const Boostsubscriptions = (props) => {
  const router = useRouter();
  const classes = useStyles();
  const [objSubscription, setLstSubscription] = React.useState([]);
  const [lstSub, setLstSub] = React.useState([]);
  const [lstPaymentLog, setLstPaymentLog] = React.useState([]);
  const [openPayment, setOpenPayment] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState(false);
  const [paymentAmount, setPaymentAmount] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [planExpire, setPlanExpire] = React.useState(undefined);
  const [selectedPlan, setSelectedPlan] = React.useState("monthly");
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();

  const handleSelect = (plan) => {
    onLoadplanlist(plan);
  };

  const onLoadplanlist = (plan) => {
    // api
    //   .get(`user/getsubscrlist?subscription_type=${plan ? plan : selectedPlan}`)
    //   .then((result) => {
    //     console.log("resultcfvggdfgh", result);
    //     if (result.success === "true") {
    //       const filteredSubscriptions = result.data.filter((sub) =>
    //         sub.subscription_type === plan ? plan : selectedPlan
    //       );

    //       setLstSub(filteredSubscriptions);
    //       setLstSubscription(result.data);
    //       setPlanExpire(result.data.subscription_date);
    //       setSelectedPlan(plan ? plan : selectedPlan);
    //     } else {
    //       console.log(result, "error");
    //     }
    //   });
    setLoading(true);
    api
      .get(`user/getboostersubscrlist`)
      .then((result) => {
        if (result.success === "true") {
          if (Array.isArray(result.data)) {
            setLstSub(result.data);
            setLoading(false);
          } else {
            console.error(
              "Expected result.data to be an array, but got:",
              typeof result.data
            );
          }

          setLstSubscription(result.data);
          setPlanExpire(result.data.subscription_date);
          setSelectedPlan(plan ? plan : selectedPlan);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
        setLoading(false);
      });
  };
  useEffect(() => {
    onLoad();
    onLoadplanlist();
  }, [selectedPlan]);

  const onLoad = () => {
    // api/user/paymentlog
    api
      .get(`user/paymentlog`)
      .then((result) => {
        if (result.success === "true") {
          setLstPaymentLog(result.data.user);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });

    // Api Call For Fetch The User Data
    // api
    //   .get(`user/getsubscrlist`)
    //   .then((result) => {
    //     if (result.success === "true") {
    //       let lstTmp = [];
    //       lstTmp.push({
    //         title: "free_amount",
    //         amount: result.data.free_amount,
    //         time: "7 day",
    //         is_current:
    //           result.data.subscription_type === "free_amount" ? true : false,
    //       });
    //       // day30_amount: 500
    //       // day90_amount: 800
    //       // day180_amount: 1000
    //       // day365_amount: 1200
    //       // id: 1368
    //       // module_id: 4
    //       // module_name: "Soulmates"
    //       // subscription_date: "trial"
    //       // subscription_type: "trial"
    //       // lstTmp.push({
    //       //   plan_name: "day30_amount",
    //       //   price: result.data.day30_amount,
    //       //   time: "1 Month",
    //       //   is_current:
    //       //     result.data.subscription_type === "day30_amount" ? true : false,
    //       // });
    //       // lstTmp.push({
    //       //   plan_name: "day90_amount",
    //       //   price: result.data.day90_amount,
    //       //   time: "3 Month",
    //       //   is_current:
    //       //     result.data.subscription_type === "day90_amount" ? true : false,
    //       // });
    //       // lstTmp.push({
    //       //   plan_name: "day180_amount",
    //       //   price: result.data.day180_amount,
    //       //   time: "6 Month",
    //       //   is_current:
    //       //     result.data.subscription_type === "day180_amount" ? true : false,
    //       // });
    //       // lstTmp.push({
    //       //   plan_name: "day365_amount",
    //       //   price: result.data.day365_amount,
    //       //   time: "1 Year",
    //       //   is_current:
    //       //     result.data.subscription_type === "day365_amount" ? true : false,
    //       // });
    //       setLstSub(lstTmp);
    //       setLstSubscription(result.data);
    //       setPlanExpire(result.data.subscription_date);
    //     } else {
    //       console.log(result, "error");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err, "catch error");
    //   });
    api
      .get(`user/getboostersubscrlist`)
      .then((result) => {
        if (result.success === "true") {
          console.log("result", result);
          // handleSelect(selectedPlan, result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const onSelectePlan = async (event, type) => {
    let data = {
      module_id: 1,
      subscription_type: type,
      // is_popular:1
    };
    // user/createusersubscr
    api
      .post("user/createusersubscr", {
        data: data,
      })
      .then((result) => {
        if (result.success === "true") {
          console.log(result, "result");
        } else {
          console.log(result, "success === false");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  // const hendlerGetStarted = (obj) => {
  //   history.push({
  //     pathname: "/Your-Boost-Subscription",
  //     state: { obj },
  //     // setPaymentAmount(obj.price),
  //     // setPaymentType(obj.plan_name)
  //   });
  // };
  const hendlerGetStarted = (obj) => {
    history.push({
      pathname: "/Your-Boost-Subscription",
      state: { obj },
    });
  };

  const handleClosePayment = (value) => {
    setOpenPayment(false);
    setSelectedValue(value);
    onLoad();
  };

  return (
    <>
      <div className={classes.bgProfilePage}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid
              className={classes.container}
              item
              style={{
                marginTop: "22px",
                backgroundColor: "white",
                borderRadius: "10px",
              }}
            >
              <div>
                <Typography
                  variant="h3"
                  align="center"
                  className={classes.title}
                >
                  Profile Sponsorship
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    align="center"
                    style={{
                      color: "gray",
                      width: "43%",
                    }}
                    className={classes.detailCardSubs}
                    gutterBottom
                  >
                    Select from the best plans, ensuring a perfect match.
                    Customize your subscription for a seamless fit!
                  </Typography>
                </div>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                <div style={{ width: "43%" }}>
                  <div
                    style={{
                      backgroundColor: "#F5F0F1",
                      padding: "5px",
                      width: "maxContent",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "10px",
                    }}
                  >
                    <Button
                      onClick={() => handleSelect("monthly")}
                      style={{
                        textTransform: "none",
                        color: selectedPlan === "monthly" ? "#FE4B6B" : "gray",
                        backgroundColor:
                          selectedPlan === "monthly" ? "white" : "transparent",
                        padding: "7px 0px",
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    >
                      Monthly
                    </Button>
                    <Button
                      onClick={() => handleSelect("yearly")}
                      style={{
                        textTransform: "none",
                        color: selectedPlan === "yearly" ? "#FE4B6B" : "gray",
                        backgroundColor:
                          selectedPlan === "yearly" ? "white" : "transparent",
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    >
                      Yearly
                    </Button>
                  </div>
                </div>
              </div> */}
              {!loading ? (
                <Grid spacing={2} container>
                  {lstSub.map((obj, index) => {
                    const items = obj.description.split(",");
                    return (
                      <Grid item xl={4} lg={4} xs={12} sm={6} key={index}>
                        <div className={classes.mainCardDetail}>
                          <div
                            className={classes.whitePaperDetail}
                            style={{
                              height: "130px",
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "column",
                            }}
                          >
                            <Grid className={classes.halfColourDiv}>
                              <div className={classes.priceMates}>
                                <Typography
                                  variant="h1"
                                  style={{ fontWeight: "600" }}
                                >
                                  {obj.currency === "INR" ? "₹" : "$"}{" "}
                                  {`${obj.amount}`}
                                </Typography>
                                {/* <small
                                style={{
                                  color: "#8E8E93",
                                  marginTop: "17px",
                                  textWrap: "nowrap",
                                }}
                              >
                                per{" "}
                                {selectedPlan === "monthly" ? "month" : "year"}
                              </small> */}
                              </div>
                              {/* {obj.is_popular && ( */}
                              <Box
                                variant="outlined"
                                // size="small"
                                className={classes.headingtime}
                              >
                                {`${obj.duration_days}`} Days
                              </Box>
                              {/* )} */}
                            </Grid>
                            <div
                              style={{
                                height: "65px",
                              }}
                            >
                              <Typography
                                variant="h4"
                                className={classes.headingPlan}
                              >
                                {obj.title}
                              </Typography>
                              <small style={{ color: "#8E8E93" }}>
                                {obj.sub_title}
                              </small>
                            </div>
                          </div>
                          <div className={classes.buttonCardSelect}>
                            {obj?.amount !== 0 ? (
                              <>
                                {" "}
                                {obj?.current_plan === 1 ? (
                                  <>
                                    <Button
                                      className={
                                        classes.buttonSelectcurrentplan
                                      }
                                      variant="contained"
                                      color="primary"
                                      disabled
                                    >
                                      Current Plan
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    className={classes.buttonSelect}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => hendlerGetStarted(obj)}
                                  >
                                    Get Started
                                  </Button>
                                )}
                              </>
                            ) : (
                              <>
                                {" "}
                                <Button
                                  className={classes.buttonSelectcurrentplan}
                                  variant="contained"
                                  color="primary"
                                  disabled
                                >
                                  Free Plan
                                </Button>
                              </>
                            )}
                          </div>
                          <div className={classes.hrLine} />
                          <div>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: "600" }}
                            >
                              FEATURES
                            </Typography>
                            <small style={{ color: "#8E8E93" }}>
                              Everything is free in this plans...
                            </small>
                          </div>

                          {/* <Box style={{ marginTop: "23px" }}>
                            {items?.length > 0 &&
                              items.map((item, index) => (
                                <Box
                                  key={index}
                                  display="flex"
                                  alignItems="center"
                                  my={1}
                                >
                                  <CheckCircle
                                    style={{
                                      color: "#FE4B6B",
                                      fontSize: "20px",
                                      paddingRight: "10px",
                                    }}
                                  />
                                  <Typography variant="body2" ml={1}>
                                    {item.trim()}
                                  </Typography>
                                </Box>
                              ))}
                          </Box> */}

                          <Box style={{ marginTop: "23px" }}>
                            {items.map((item, index) => (
                              <Box
                                key={index}
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                  margin: "10px 0px",
                                }}
                              >
                                <div>
                                  {" "}
                                  <CheckCircle
                                    style={{
                                      color: "#FE4B6B",
                                      fontSize: "20px",
                                      // marginRight: "8px",
                                    }}
                                  />
                                </div>
                                <Typography
                                  style={{ marginLeft: "10px" }}
                                  variant="body2"
                                >
                                  {item.trim()}
                                </Typography>{" "}
                              </Box>
                            ))}
                          </Box>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "300px",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size={25} />
                </div>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Boostsubscriptions;
