import React from "react";
import CancellationPolicyDoc from "./component/CancellationPolicyDoc";

const CancellationPolicy = () => {
  return (
    <>
      <CancellationPolicyDoc />
    </>
  );
};

export default CancellationPolicy;