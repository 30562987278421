/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import validate from "validate.js";
import clsx from "clsx";
import PropTypes from "prop-types";
// import { useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  TextField,
  Link,
  Snackbar,
  CircularProgress,
  Divider,
  Typography,
  IconButton,
  InputAdornment,
  Box,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import useRouter from "utils/useRouter";
// import { login } from 'actions';
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import MuiAlert from "@material-ui/lab/Alert";
import Api from "../../../../Helper/ApiHandler";
import {
  SignalCellularConnectedNoInternet1BarOutlined,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// import Api from '../../Helper/ApiHandler';
import OtpInput from "react-otp-input";

var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: { maximum: 254 },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: { minimum: 6 },
  },
  first_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  last_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  phone: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "30px",
    background: "white",
    // '& .makeStyles-card-10':{
    // }
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
  },
  submitButton: {
    marginTop: theme.spacing(3),
    width: "100%",
    borderRadius: "10px",
    // padding: "15px 0px",
    //backgroundImage: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    color: "white",
    fontSize: "20px",
  },
  forgotPssword: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5px",
    "& .MuiLink-underlineAlways": {
      textDecoration: "none",
      fontWeight: 500,
    },
    "& .MuiTypography-colorSecondary": {
      color: "#FE4B6B",
    },
  },
  title: {
    fontWeight: "400",
  },
  already: {
    display: "flex",
    justifyContent: "center",
  },
  // otp: {
  //   "& input": {
  //     width: "50px",
  //     fontSize: "20px",
  //     height: "40px"
  //   }
  // }
  inputePhone: {
    width: "78%",
    "& .react-tel-input .form-control": {
      padding: "1.5px 14px 1.5px 60px",
      border: "none",
      width: "100%",
    },
    "& .react-tel-input .form-control:focus": {
      border: "none",
      boxShadow: "none",
    },
    "& .react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before": {
      border: "none",
      boxShadow: "none",
    },
  },
}));

const SignupForm = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();
  // const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [emailLoading, setEmailLoading] = React.useState(false);
  const [emailDisabled, setEmailDisabled] = React.useState(false);
  const [verifyEmailLoading, setVerifyEmailLoading] = React.useState(false);
  const [verifyEmailDisabled, setVerifyEmailDisabled] = React.useState(false);

  const [phoneLoading, setPhoneLoading] = React.useState(false);
  const [phoneDisabled, setPhoneDisabled] = React.useState(false);
  const [verifyPhoneLoading, setVerifyPhoneLoading] = React.useState(false);
  const [verifyPhoneDisabled, setVerifyPhoneDisabled] = React.useState(false);

  const [disabled, setDisabled] = React.useState(false);
  const [otpEmail, setOtpEmail] = React.useState(false);
  const [otpSendEmail, setOtpSendEmail] = React.useState(false);
  const [verifyEmail, setVerifyEmail] = React.useState(false);
  const [otpPhone, setOtpPhone] = React.useState(false);
  const [otpSendPhone, setOtpSendPhone] = React.useState(false);
  const [verifyPhone, setVerifyPhone] = React.useState(false);
  const [showPass, setShowPass] = useState(false);
  const [phone, setPhone] = useState("");
  const [emailCounter, setEmailCounter] = React.useState(null);
  const [phoneCounter, setPhoneCounter] = React.useState(null);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [formStatephone, setFormStatephone] = useState({
    values: {
      phone: "",
      countryCode: "",
    },
  });
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });

  React.useEffect(() => {
    console.log(emailCounter, "counter--");
    // if (signupType === "notADefine") {
    //   setCounter(null)
    // } else {
    if (emailCounter) {
      // (signupType === "email" || signupType === "phone") &&
      //   setCounter(null)

      emailCounter &&
        emailCounter > 0 &&
        setTimeout(() => setEmailCounter(emailCounter - 1), 1000);
    } else {
      // setCounter(null)
      return;

      // }
    }
  }, [emailCounter]);

  React.useEffect(() => {
    console.log(phoneCounter, "counter--");
    // if (signupType === "notADefine") {
    //   setCounter(null)
    // } else {
    if (phoneCounter) {
      // (signupType === "phone" || signupType === "phone") &&
      //   setCounter(null)

      phoneCounter &&
        phoneCounter > 0 &&
        setTimeout(() => setPhoneCounter(phoneCounter - 1), 1000);
    } else {
      // setCounter(null)
      return;

      // }
    }
  }, [phoneCounter]);

  useEffect(() => {
    const errors = validate(formState.values, schema);
    console.log("call");
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    console.log(event.target.value, "event.target.value");
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const sendOtp = async (event, type, value) => {
    event.preventDefault();
    // dispatch(login());
    if (type === "email") {
      // setOtpSendEmail(true)
      setEmailLoading(true);
      setEmailDisabled(true);
    } else {
      // setOtpSendPhone(true)
      setPhoneLoading(true);
      setPhoneDisabled(true);
    }
    api
      .post("propertyinfo/sendcode", {
        data: {
          type: type,
          value: value,
        },
        skipAuth: true,
      })
      .then((result) => {
        if (result.success === "true") {
          if (type === "email") {
            setEmailCounter(10);
            setOtpSendEmail(true);
          } else {
            setPhoneCounter(10);
            setOtpSendPhone(true);
          }
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          // setLoginOpen(false);
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        // dispatch(login());
        if (type === "email") {
          setEmailLoading(false);
          setEmailDisabled(false);
        } else {
          setPhoneLoading(false);
          setPhoneDisabled(false);
        }
        // setDisabled(false)
        // setLoading(false)
      })
      .catch((err) => {
        console.log(err, "error catch");
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        if (type === "email") {
          setEmailLoading(false);
          setEmailDisabled(false);
        } else {
          setPhoneLoading(false);
          setPhoneDisabled(false);
        }
        // setDisabled(false)
        // setLoading(false)
      });
  };
  const handlePhoneChange = (value, data) => {
    setFormStatephone((prevState) => ({
      ...prevState,

      values: {
        ...prevState.values,
        phone: value,
        countryCode: data.dialCode,
      },
    }));
  };

  const verifyOtp = async (event, type, value, code) => {
    event.preventDefault();

    if (type === "email") {
      setVerifyEmailDisabled(true);
      setVerifyEmailLoading(true);
    } else {
      setVerifyPhoneDisabled(true);
      setVerifyPhoneLoading(true);
    }

    // dispatch(login());
    api
      .post("propertyinfo/verifycode", {
        data: {
          value: value,
          code: code,
        },
        skipAuth: true,
      })
      .then((result) => {
        if (result.success === "true") {
          if (type === "email") {
            setVerifyEmail(true);
          } else {
            setVerifyPhone(true);
          }
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          // setLoginOpen(false);
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        if (type === "email") {
          setVerifyEmailDisabled(false);
          setVerifyEmailLoading(false);
        } else {
          setVerifyPhoneDisabled(false);
          setVerifyPhoneLoading(false);
        }

        // setDisabled(false)
        // setLoading(false)
      })
      .catch((err) => {
        console.log(err, "error catch");
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        if (type === "email") {
          setVerifyEmailDisabled(false);
          setVerifyEmailLoading(false);
        } else {
          setVerifyPhoneDisabled(false);
          setVerifyPhoneLoading(false);
        }
        // setDisabled(false)
        // setLoading(false)
      });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // dispatch(login());
    // router.history.push('/admin/manageOrganization');

    if (formState.isValid === true) {
      setDisabled(true);
      setLoading(true);

      api
        .post("propertyinfo/ownersignup", {
          data: formState.values,
          skipAuth: true,
        })
        .then((result) => {
          if (result.success === "true") {
            // setLoginOpen(false);
            console.log(result, "result");
            let name = result.data.first_name + " " + result.data.last_name;
            AsyncLocalStorage.setItem("authToken", result.data.accessToken);
            AsyncLocalStorage.setItem("userName", name);
            AsyncLocalStorage.setItem(
              "userProfile",
              result.data.profile_picture
            );
            AsyncLocalStorage.setItem("tabValue", 0);
            window.location.replace(process.env.REACT_APP_PROPERTY_LOGIN);
            // router.history.push({
            //   pathname: `/manageProperty`,
            //   search: '',
            //   state: {
            //     sessionPropsMessage: {
            //       message: result.message,
            //       type: 'success',
            //       open: true
            //     }
            //   }
            // })
          } else {
            // setLoginOpen(false);
            console.log(result, "error");
            setSessionMessage({
              message: result.message,
              type: "error",
              open: true,
            });
          }
          setDisabled(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "error catch");
          setSessionMessage({
            message: err.message,
            type: "error",
            open: true,
          });
          setDisabled(false);
          setLoading(false);
        });
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;
  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };
  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <form
      {...rest}
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
    >
      <div className={classes.fields}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        <TextField
          error={hasError("first_name")}
          fullWidth
          InputLabelProps={{
            classes: {
              asterisk: classes.asterisk,
            },
            shrink: true,
          }}
          helperText={
            hasError("first_name") ? formState.errors.first_name[0] : null
          }
          label="First name"
          name="first_name"
          onChange={handleChange}
          value={formState.values.first_name || ""}
          variant="outlined"
        />
        <TextField
          error={hasError("last_name")}
          fullWidth
          InputLabelProps={{
            classes: {
              asterisk: classes.asterisk,
            },
            shrink: true,
          }}
          helperText={
            hasError("last_name") ? formState.errors.last_name[0] : null
          }
          label="Last name"
          name="last_name"
          onChange={handleChange}
          value={formState.values.last_name || ""}
          variant="outlined"
        />
        <TextField
          error={hasError("email")}
          fullWidth
          helperText={hasError("email") ? formState.errors.email[0] : null}
          label="Email address"
          name="email"
          InputLabelProps={{
            classes: {
              asterisk: classes.asterisk,
            },
            shrink: true,
          }}
          onChange={!verifyEmail && handleChange}
          value={formState.values.email || ""}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {verifyEmail ? (
                  <Box>
                    <img src="/images/verifyOtp.svg" alt="" />
                  </Box>
                ) : (
                  <>
                    {emailCounter === 0 ? (
                      <Button
                        variant="contained"
                        color={"primary"}
                        size="small"
                        style={{ textTransform: "capitalize" }}
                        disabled={
                          emailDisabled
                            ? true
                            : formState.values.email && !hasError("email")
                            ? false
                            : true
                        }
                        onClick={(event) => {
                          sendOtp(event, "email", formState.values.email);
                        }}
                      >
                        Resend Otp
                        {emailLoading && (
                          <>
                            &nbsp;
                            <CircularProgress size={10} />
                          </>
                        )}
                      </Button>
                    ) : (
                      <>
                        {emailCounter > 0 ? (
                          `00 : ${emailCounter}`
                        ) : (
                          <Button
                            variant="contained"
                            color={"primary"}
                            size="small"
                            style={{
                              backgroundColor: disabled
                                ? "blue"
                                : "rgba(0, 0, 0, 0.12)",
                              color: disabled ? "white" : "white",
                              boxShadow: disabled ? "none" : "",
                              textTransform: "capitalize",
                            }}
                            disabled={
                              emailDisabled
                                ? true
                                : formState.values.email && !hasError("email")
                                ? false
                                : true
                            }
                            onClick={(event) => {
                              sendOtp(event, "email", formState.values.email);
                            }}
                          >
                            Send Otp
                            {emailLoading && (
                              <>
                                &nbsp;
                                <CircularProgress size={10} />
                              </>
                            )}
                          </Button>
                        )}
                      </>
                    )}
                  </>
                )}
              </InputAdornment>
            ),
          }}
        />
        {otpSendEmail && !verifyEmail ? (
          <Box display={"flex"} alignItems={"center"}>
            <OtpInput
              value={otpEmail || ""}
              name="otp"
              onChange={(otp) => !verifyEmail && setOtpEmail(otp)}
              numInputs={6}
              className={classes.otp}
              separator={<span>&nbsp;&nbsp; </span>}
              inputStyle={{
                width: "50px",
                fontSize: "20px",
                height: "40px",
                borderRadius: "5px",
                border: "1px solid rgba(0,0,0,0.15)",
              }}
              focusStyle={{ border: "2px solid #2196f3", outline: "none" }}
            />
            &nbsp;&nbsp;&nbsp;
            {!verifyEmail && (
              <Button
                variant="contained"
                color={"primary"}
                style={{ textTransform: "capitalize" }}
                disabled={
                  verifyEmailDisabled ? true : otpEmail.toString().length < 6
                }
                onClick={(event) => {
                  verifyOtp(event, "email", formState.values.email, otpEmail);
                }}
              >
                Verify
                {verifyEmailLoading && (
                  <>
                    &nbsp;
                    <CircularProgress size={10} />
                  </>
                )}
              </Button>
            )}
          </Box>
        ) : (
          ""
        )}
        <div style={{ position: "relative" }}>
          <Typography
            variant="h6"
            style={{
              position: "absolute",
              color: "#FE4B6B",
              fontSize: "11px",
              backgroundColor: "white",
              width: "fit-content",
              top: "-11px",
              left: "12px",
              padding: "0px 4px",
              fontWeight: "400",
            }}
          >
            Phone number
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              border: "2px solid #FE4B6B",
              borderRadius: "5px",
            }}
          >
            <Box my={1} className={classes.inputePhone}>
              <PhoneInput
                countryCodeEditable={true}
                value={formStatephone.values.phone}
                onChange={handlePhoneChange}
              />
            </Box>
            <div>
              {verifyPhone ? (
                <Box>
                  <img src="/images/verifyOtp.svg" alt="" />
                </Box>
              ) : (
                <>
                  {phoneCounter === 0 ? (
                    <>
                      <Button
                        variant="contained"
                        color={"primary"}
                        size="small"
                        style={{
                          textTransform: "capitalize",
                          fontSize: phoneLoading ? "10px" : "12px",
                        }}
                        disabled={
                          phoneDisabled
                            ? true
                            : formState.values.phone && !hasError("phone")
                            ? false
                            : true
                        }
                        onClick={(event) => {
                          sendOtp(event, "phone", formState.values.phone);
                        }}
                      >
                        Resend Otp
                        {phoneLoading && (
                          <>
                            &nbsp;
                            <CircularProgress size={10} />
                          </>
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      {phoneCounter > 0 ? (
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          00 : {phoneCounter}
                        </Typography>
                      ) : (
                        <Button
                          variant="contained"
                          color={"primary"}
                          size="small"
                          style={{
                            backgroundColor: disabled
                              ? "blue"
                              : "rgba(0, 0, 0, 0.12)",
                            color: disabled ? "white" : "white",
                            boxShadow: disabled ? "none" : "",
                            textTransform: "capitalize",
                            fontSize: "12px",
                            marginRight: "10px",
                            width: "85px",
                          }}
                          disabled={
                            phoneDisabled
                              ? true
                              : formState.values.phone && !hasError("phone")
                              ? false
                              : true
                          }
                          onClick={(event) => {
                            sendOtp(event, "phone", formState.values.phone);
                          }}
                        >
                          Send Otp
                          {phoneLoading && (
                            <>
                              &nbsp;
                              <CircularProgress size={10} />
                            </>
                          )}
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {otpSendPhone && !verifyPhone ? (
          <Box display={"flex"}>
            <OtpInput
              value={otpPhone || ""}
              name="otp"
              onChange={(otp) => setOtpPhone(otp)}
              numInputs={6}
              className={classes.otp}
              separator={<span>&nbsp;&nbsp; </span>}
              inputStyle={{
                width: "50px",
                fontSize: "20px",
                height: "40px",
                borderRadius: "5px",
                border: "1px solid rgba(0,0,0,0.15)",
              }}
              focusStyle={{ border: "2px solid #2196f3", outline: "none" }}
            />
            &nbsp;&nbsp;&nbsp;
            {!verifyPhone && (
              <Button
                variant="contained"
                color={"primary"}
                style={{ textTransform: "capitalize" }}
                disabled={
                  verifyPhoneDisabled ? true : otpPhone.toString().length < 6
                }
                onClick={(event) => {
                  verifyOtp(event, "phone", formState.values.phone, otpPhone);
                }}
              >
                Verify
                {verifyPhoneLoading && (
                  <>
                    &nbsp;
                    <CircularProgress size={10} />
                  </>
                )}
              </Button>
            )}
          </Box>
        ) : (
          ""
        )}
        <TextField
          error={hasError("password")}
          helperText={
            hasError("password") ? formState.errors.password[0] : null
          }
          label="Password"
          name="password"
          InputLabelProps={{
            classes: {
              asterisk: classes.asterisk,
            },
            shrink: true,
          }}
          onChange={handleChange}
          type={showPass ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {!showPass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={formState.values.password || ""}
          variant="outlined"
          fullWidth
        />
      </div>

      <Button
        className={classes.submitButton}
        color="primary"
        size="small"
        type="submit"
        variant="contained"
        disabled={
          formState.isValid && verifyEmail && verifyPhone ? disabled : true
        }
      >
        Sign Up
        {loading && (
          <>
            &nbsp;
            <CircularProgress size={20} />
          </>
        )}
      </Button>
      <Divider style={{ margin: "20px 0px" }} />
      {/* <div className={classes.already}>
        <Typography
          variant="h6"
          className={classes.title}
        >
          Already a member?
        </Typography>&nbsp;
        <Link
          align="center"
          color="primary"
          component={RouterLink}
          to="/login"
          underline="always"
          variant="subtitle2"
        // className={classes.forgotPssword}
        >
          Sign in
        </Link>
      </div> */}
    </form>
  );
};

SignupForm.propTypes = {
  className: PropTypes.string,
};

export default SignupForm;
