import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Snackbar,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";

import { Check } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import Api from "../../../../Helper/ApiHandler";
import useRouter from "utils/useRouter";

import axios from "axios";
import Compressor from "compressorjs";

var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  bgEditProfile: {
    //fontFamily: "Gilroy !important",
    minHeight: "calc(100vh - 125px)",
    background: "#E9EDF0",
    padding: "35px 0",
    // marginBottom: "60px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      marginBottom: "10px",
    },
    "& .MuiContainer-root": {
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        overflowX: "hidden",
      },
    },
  },
  whiteCardProfile: {
    background: "white",
    borderRadius: "10px",
    // paddingBottom: "60px",
    marginLeft: "16px",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "8px",
    },
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  textsubdata: {
    color: "#8E8E93",
    padding: "10px 0px",
  },
  buttonGroupConfirm: {
    textAlign: "center",
    margin: "10px 0px",
  },
  confirmButton: {
    margin: "0 5px",
    textTransform: "capitalize",
    padding: "5px 70px",
    fontSize: "15px",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 40px",
      margin: "5px",
    },
    width: "220px",
    
  },
}));

const CancellationPolicyDoc = (props) => {
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  const { className, ...rest } = props;
  const router = useRouter();
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClosemodel = () => {
    setDialogOpen(false);
  };
  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgEditProfile}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>

            <Grid className={classes.container} item>
              <Grid
                className={classes.whiteCardProfile}
                style={{ padding: "10px" }}
                container
              >
                <Typography variant="h3" className={classes.textheding}>
                  Cancellation Policy
                </Typography>
                <Typography variant="h6" className={classes.textsubdata}>
                  Per month You can cancel your plan at any time; your access
                  will end immediately, and no further charges will be applied.
                </Typography>

                <Box>
                  <Grid
                    style={{ display: "flex", marginTop: "10px", gap: "20px" }}
                  >
                    <Check style={{ color: "#FE4B6B" }} />
                    <Typography variant="subtitle2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </Typography>
                  </Grid>
                  <Grid
                    style={{ display: "flex", marginTop: "10px", gap: "20px" }}
                  >
                    <Check style={{ color: "#FE4B6B" }} />
                    <Typography variant="subtitle2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </Typography>
                  </Grid>
                  <Grid
                    style={{ display: "flex", marginTop: "10px", gap: "20px" }}
                  >
                    <Check style={{ color: "#FE4B6B" }} />
                    <Typography variant="subtitle2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </Typography>
                  </Grid>
                  <Grid
                    style={{ display: "flex", marginTop: "10px", gap: "20px" }}
                  >
                    <Check style={{ color: "#FE4B6B" }} />
                    <Typography variant="subtitle2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </Typography>
                  </Grid>
                </Box>

                <Grid lg={12} xl={12} xs={12} style={{ marginTop: "20px" }}>
                  <div className={classes.buttonGroupConfirm}>
                    <Button
                      className={classes.confirmButton}
                      variant="contained"
                      color="primary"
                      style={{
                        background:
                          "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
                      }}
                      onClick={handleClickOpen}
                    >
                      I Agree
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleClosemodel}
        style={{ borderRadius: "10px" ,textAlign:'center'}}
      >
        <Typography variant="h4">Your plan has been successfully canceled.</Typography>
        <DialogContent>
          <Typography variant="body2">
            Thank you for being with us. If you change your mind, you can
            reactivate your plan anytime to enjoy our full range of features."
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosemodel}
            className={classes.confirmButton}
            variant="contained"
            color="primary"
            style={{
              background:
                "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CancellationPolicyDoc;
