import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import TelegramIcon from "@material-ui/icons/Telegram";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import { Link as browserLink, useLocation } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Checkbox from "@material-ui/core/Checkbox";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import ReactFancyBox from "react-fancybox";
import useRouter from "utils/useRouter";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BlockIcon from "@material-ui/icons/Block";
import MuiAlert from "@material-ui/lab/Alert";
import Api from "../../Helper/ApiHandler";
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "firebase/app";

var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const schema = {
  first_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  last_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: { maximum: 254 },
  },
  // phone: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: { maximum: 15, message: "is too long (maximum is 15 digit)" },
  //   format: {
  //     pattern: "[0-9]+",
  //     flags: "i",
  //     message: "Enter only number"
  //   }
  // },
  zodiac: {
    presence: { allowEmpty: false, message: "is required" },
  },
  p_university: {
    presence: { allowEmpty: false, message: "is required" },
  },

  // country: {
  //   presence: { allowEmpty: false, message: "is required" },
  // },
  // state: {
  //   presence: { allowEmpty: false, message: "is required" },
  // },
  location: {
    presence: { allowEmpty: false, message: "is required" },
  },
  bio: {
    // presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 250 },
  },
  date_of_birth: {
    presence: { allowEmpty: false, message: "is required" },
  },
  // planned_activities: {
  //   presence: { allowEmpty: false, message: "is required" },
  // },
  // interests: {
  //   presence: { allowEmpty: false, message: "is required" },
  // },
  // room_type: {
  //   presence: { allowEmpty: false, message: "is required" },
  // },
  gender: {
    presence: { allowEmpty: false, message: "is required" },
  },
  preference_gender: {
    presence: { allowEmpty: false, message: "is required" },
  },
};
const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#e9edf0",
    padding: "35px 0",
    minHeight: "90vh",
    // marginBottom: "60px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  detailBox: {
    background: "white",
    borderRadius: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    position: "relative",
    paddingBottom: "10px",
  },
  textUserDetail: {
    // margin: "0",
    color: "gray",
    margin: "5px",
    wordBreak: "break-all",
    display: "-webkit-box",
    "-webkitLineClamp": 3,
    "-webkitBoxOrient": "vertical",
    [theme.breakpoints.down("sm")]: {
      "-webkitLineClamp": 2,
    },
    overflow: "hidden",
  },
  directMessageBtn: {
    bottom: "10px",
    position: "absolute",
    // marginTop: "30px",
    textTransform: "capitalize",
    background: "#d5606045",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      // width: "100px",
    },
  },
  varticalLine: {
    height: "180px",
    width: "1px",
    background: "gray",
    borderBottom: "1px solid #000000",
    opacity: "0.3",
    marginBottom: "15px",
  },
  detailGridBox: {
    position: "relative",
  },
  educationDetail: {
    display: "flex",
    fontSize: "14px !important",
  },
  textEducation: {
    margin: "0",
    marginLeft: "10px",
    fontSize: "16px",
  },
  imgBoxView: {
    marginTop: "10px",
  },
  picsGrid: {
    background: "white",
    borderRadius: "10px",
    padding: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
    },
  },
  lifeStyleHeading: {
    margin: "5px 10px",
    color: "gray",
    fontSize: "15px !important",
    fontWeight: "400",
  },
  imageLifStyle: {
    "& .react-fancybox .thumbnail img": {
      borderRadius: "50%",
      width: "100px",
      height: "100px",
      margin: "5px",
      objectFit: "cover",
      cursor: "pointer",
    },
  },
  imageBoxStyle: {
    border: "2px solid #FE4B6B",
    borderRadius: "100%",
    textAlign: "center",
    padding: "3px",
    width: "90px",
    height: "90px",
    [theme.breakpoints.down("xs")]: {
      width: "70px",
      height: "70px",
    },
  },
  aboutHeading: {
    color: "grey",
    margin: "8px 0",
    marginLeft: "10px",
    fontSize: "15px !important",
    fontWeight: "400",
  },
  textParagraph: {
    marginLeft: "10px",
    fontSize: "16px",
    fontWeight: "600",
  },
  imageQuote: {
    textAlign: "right",
  },
  quotedImage: {
    width: "80px",
    height: "50px",
  },
  chipUserDetail: {
    margin: "6px 5px",
    color: "#FE4B6B",
    background: "#d5606045",
    //fontFamily: "Gilroy !important",
  },
  chipGruop: {
    marginBottom: "20px",
  },
  gridProfile: {
    marginTop: "10px",
  },
  userDetailMain: {
    position: "relative",
    padding: "0px 10px 0px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
  favouriteIcon: {
    position: "absolute",
    top: "130px",
    right: "30px",
    color: "	#DC143C",
    border: "1px solid gray",
    padding: "2px",
    borderRadius: "6px",
    height: "30px",
    width: "30px",
    [theme.breakpoints.down("xs")]: {
      top: "120px",
      right: "10px",
    },
  },
  headingPage: {
    fontWeight: "600 !important",
    fontSize: "18px",
    margin: "10px 10px 10px 10px",
  },
  nameUserView: {
    fontWeight: "600",
    fontSize: "22px",
    margin: "0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    },
  },
  hrLine: {
    borderBottom: "2px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  yourImageFix: {
    width: "150px",
    height: "150px",
    [theme.breakpoints.down("xs")]: {
      width: "120px",
      height: "120px",
    },
    borderRadius: "50%",
    objectFit: "cover",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  smallHeightChange: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  sidelistAns: {
    margin: "0px",
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
  sidelistlabel: {
    margin: "0px",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    width: "80px",
  },
  heading: {
    fontSize: "18px !important",
    fontWeight: "600",
    // textAlign: "center"
  },
  headingAddress: {
    fontSize: "12px !important",
    fontWeight: "400",
  },
  chip: {
    border: "1px solid #E84A62",
    borderRadius: "25px",
    color: "#E84A62",
    width: "fit-content",
    fontSize: "12px",
    padding: "0px 5px",
    alignSelf: "center",
  },
  headindRoomate: {
    fontSize: "20px !important",
    fontWeight: "400",
    margin: 0,
    // textAlign: "center"
  },
  headindgender: {
    fontSize: "16px !important",
    fontWeight: "400",
    color: "#7E7E7E",
    margin: 0,
    // textAlign: "center"
  },
  suggetionText: {
    fontSize: "12px",
    color: "black",
    fontWeight: "400",
    marginLeft: "48px",
    paddingTop: "10px",
    color: "#E84A62",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "16px",
    },
  },
  backRoot: {
    maxWidth: "fit-content",
    cursor: "pointer",
    marginLeft: "10px",
    // marginBottom: "20px",
    // marginTop: "45px",
    alignItems: "center",
    display: "flex",
  },
  university: {
    margin: "0px",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    width: "150px",
  },
  imgViewDetail: {
    width: "20px",
  },
  chipIcon: {
    width: "13px",
    height: "13px",
    // color: "#FE4B6B"
  },
  autocompletChip: {
    background: "white",
    "& .MuiChip-root": {
      borderRadius: "5px",
      // background: "#d5606045",
      height: "auto",
    },
    "& .MuiChip-outlined": {
      // border: "none",
      // color: "#FE4B6B",
      padding: "2px",
    },
    "& .MuiFilledInput-root": {
      background: "white",
    },
  },
  nextButton: {
    width: "320px",
    borderRadius: "20px",
  },
}));

const AddGroupMem = (props) => {
  const classes = useStyles();
  const router = useRouter();

  const [lstInterests, setLstUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  let query = useLocation();
  const slugGroup = query.pathname.split("/")[2];
  // const matchPercent = query.pathname.split("/")[4]

  // firebase data base connection
  const db = firebase.firestore();
  const CollectionData = db.collection("teammatesChatRooms");

  useEffect(() => {
    getUsersLogin();
  }, []);

  // Login user
  const getUsersLogin = async () => {
    let email = await AsyncLocalStorage.getItem("email");
    let firebase_uid = await AsyncLocalStorage.getItem("senderId");
    let id = await AsyncLocalStorage.getItem("from_id");
    let profile_picture = await AsyncLocalStorage.getItem("userProfile");
    let slug = await AsyncLocalStorage.getItem("from_slug");
    let value = await AsyncLocalStorage.getItem("userName");
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        usreList: [
          {
            email: email,
            firebase_uid: firebase_uid,
            id: id,
            profile_picture: profile_picture,
            slug: slug,
            value: value,
            // value: "You",
          },
        ],
      },
    }));
  };

  // Get user list
  const getUsers = async (search) => {
    // Api Call For Fetch Interests List
    api
      .get(`user/teammate/usernamelist?s=${search}`)
      .then((result) => {
        if (result.success === "true") {
          setLstUsers(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const changeNew = (e, value) => {
    let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let phoneReg = /^[0-9]{11,15}$/;
    if (e.target.value === 0) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          usreList: value,
        },
      }));
    } else {
      if (emailReg.test(e.target.value)) {
        let objEmail = { value: e.target.value, type: "email" };
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            usreList: formState.values.usreList
              ? [...formState.values.usreList, objEmail]
              : [objEmail],
          },
        }));
      } else if (phoneReg.test(parseInt(e.target.value))) {
        let objPhone = { value: e.target.value, type: "phone" };
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            usreList: formState.values.usreList
              ? [...formState.values.usreList, objPhone]
              : [objPhone],
          },
        }));
      } else {
        console.log(e.target.value, "kai nai");
      }
    }
  };

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const handleNext = async () => {
    let slug = await AsyncLocalStorage.getItem("from_slug");
    let lst_group_members = formState.values.usreList.filter(
      (obj) => obj.slug != slug
    )
    setLoading(true)
    api.post(`user/teammate/addmemberviamail/${slugGroup}`, {
      data: { group_members: lst_group_members }
    })
      .then((result) => {
        if (result.success === "true") {
          router.history.push({
            pathname: `/groupDetail/${slugGroup}`,
            search: "",
            state: {
              sessionPropsMessage: {
                message: result.message,
                type: "success",
                open: true,
              }
            },
          });
        } else {
          console.log(result, "success === false")
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err, "error catch")
        setLoading(false)
      });

    // router.history.push({
    //   pathname: "/addNewGroupInfoNew",
    //   search: "",
    //   state: {
    //     lst_group_members: formState.values.usreList.filter(
    //       (obj) => obj.slug != slug
    //     ),
    //   },
    // });
  };

  const change_p_OptionBaseOnValue = (value) => {
    if (value) getUsers(value);
  };

  const deleteParticipant = async (objUser, index) => {
    if (index == 0) {
      console.log("User Admin delete na thay sake");
    } else {
      let lstTmp = [...formState.values.usreList];
      lstTmp.splice(index, 1);

      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          usreList: lstTmp,
        },
      }));
    }
  };

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgProfilePage}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid className={classes.container} item>
              <Grid className={classes.detailBox} container>
                <Grid item xs={12} lg={12} xl={12}>
                  <Box
                    onClick={() => router.history.goBack()}
                    className={classes.backRoot}
                  >
                    <IconButton
                      aria-label="delete"
                      style={{ color: "black", marginRight: "5px" }}
                      size="small"
                    >
                      <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                    <h5 className={classes.headingPage}>
                      {" "}
                      Add Group Participants New
                    </h5>
                  </Box>
                  <Typography
                    variant="caption"
                    component="primary"
                    color="primary"
                    className={classes.suggetionText}
                  >
                    If you enter phone number then enter it with country code
                  </Typography>
                  {/* {console.log(lstInterests, "lstInterests")} */}
                  <div style={{ padding: "20px" }}>
                    <Autocomplete
                      multiple
                      freeSolo
                      id="tags-filled"
                      onInputChange={(e, value) => {
                        change_p_OptionBaseOnValue(value);
                      }}
                      options={lstInterests}
                      getOptionLabel={(option) => option.value}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                          {...props}
                          style={{ display: "flex" }}
                        >
                          <div style={{ marginRight: "5px" }}>
                            <img
                              src={
                                props.profile_picture ??
                                "/images/male_defualt.svg"
                              }
                              alt="images"
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: "50%",
                                objectFit: "cover",
                                objectPosition: 'top'
                              }}
                            />
                          </div>
                          <div>{props.value}</div>
                          {/* {props.value} */}
                        </Box>
                      )}
                      disableCloseOnSelect
                      // getOptionLabel={(option) => option.value}
                      // options={lstInterests.map((option) => option)}
                      noOptionsText={"Type for search..."}
                      value={formState.values.usreList || []}
                      name="interests"
                      classes={{
                        root: classes.chipInputEdit,
                        chip: classes.chip,
                      }}
                      // renderTags={(value, getTagProps) =>
                      //   value.map((option, index) => (
                      //     <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      //   ))
                      // }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          return (
                            <Chip
                              variant="outlined"
                              label={option.value}
                              // value={option}
                              {...getTagProps({ index })}
                              onDelete={() => {
                                deleteParticipant(option, index);
                              }}
                              deleteIcon={
                                <CloseIcon className={classes.chipIcon} />
                              }
                            />
                          );
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="interests"
                          placeholder="Add participant..."
                        />
                      )}
                      className={classes.autocompletChip}
                      onChange={(e, value) => {
                        changeNew(e, value);
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      className={classes.nextButton}
                      variant="contained"
                      color="primary"
                      disabled={
                        formState?.values?.usreList?.length < 1 || loading ? true : false
                      }
                      style={{
                        background:
                          "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
                      }}
                      onClick={() => {
                        handleNext();
                      }}
                    >
                      Next
                      {loading && <>&nbsp;<CircularProgress size={20} style={{ color: "white" }} /></>}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default AddGroupMem;
