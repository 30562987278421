import React from "react";
import YourBoostSubscriptionsplan from "./component/YourBoostSubscriptionsplan";

const YourSubscription = () => {
  return (
    <>
      <YourBoostSubscriptionsplan />
    </>
  );
};

export default YourSubscription;