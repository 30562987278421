
import React from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import Api from '../../Helper/ApiHandler';
import { useState } from 'react';
var api = new Api();

const useStyles = makeStyles(() => ({
  root: {},
  dialogButtonHeader: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "30px 10px 10px 10px",
    '& .MuiButton-root': {
      textTransform: "capitalize"
    }
  },
  dialogRoot: {
    padding: "10px",
    '& .MuiDialog-paper': {
      // overflow: "visible"
      borderRadius: "10px"
    },
    '& .MuiBackdrop-root': {
      backgroundColor: "rgb(0 0 0 / 45%)",
    },
    '& .MuiPaper-elevation24': {
      boxShadow: "none"
    }
  },
  btnSave: {
    borderRadius: "5px",
    backgroundImage: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    color: "white"
  },
  btnCancel: {
    borderRadius: "5px",
  },
  flexWithPading: {
    // display: "flex",
    // justifyContent: "center",
    padding: "10px",
  },
  flex: {
    // display: "flex",
    // justifyContent: "center",
  },
  title: {
    fontWeight: 500
  },
  subTitle: {
    opacity: 0.5,
    fontWeight: 500,
    paddingLeft: "10px"
  }
}))
function ClearChatDialog(props) {
  const classes = useStyles();
  const { chatType, receiverId, deleteMsgRef, setClearAt, onLoad, activityRef, onClose, open } = props;
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    onClose();
  };

  const handleClearChat = async () => {
    setLoading(true)
    // Api Call For Delete 
    api.post(`user/teammate/clearchat`, {
      data: {
        receiverId: receiverId,
        chatType: chatType
      }
    })
      .then((result) => {
        if (result.success === "true") {
          onClose({
            message: result.message,
            type: 'success',
            open: true
          });
          setClearAt("")
          onLoad()
        } else {
          console.log(result, "error")
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err, "catch error")
        setLoading(false)
      });






    var timestamp = new Date();
    let objActivity = {}
    // await activityRef.get().then((groupDoc) => {
    //   if (groupDoc.exists) {
    //     console.log(groupDoc.data(), "groupDoc.data()")
    //     objActivity = groupDoc.data()
    //   }
    // }).catch((error) => {
    //   console.log("Error getting document:", error);
    // })


    // await activityRef.update({ clearAt: timestamp, lastMessage: "" })
    // setClearAt(null)
    // onLoad()

    // onClose()
  };

  return (
    <Dialog className={classes.dialogRoot} onClose={handleClose} maxWidth="xs" open={open} >
      <Grid container justify="center" style={{ padding: "20px" }}>
        {/* <Grid item xs={12} className={classes.flex}>
          <img
            alt="Logo"
            src="/images/delete_model.svg"
          />
        </Grid> */}
        <Grid item xs={12} className={classes.flexWithPading}>
          <Typography
            component="h4"
            variant="h4"
            className={classes.title}
          >
            Clear thi chat?
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.flex}>
          <Typography
            component="h6"
            variant="subtitle2"
            className={classes.subTitle}
          >
            You will not able to recover this message!
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.dialogButtonHeader}>
            <Button color="primary" variant='outlined' onClick={() => { handleClose() }} className={classes.btnCancel}>Cancel</Button>&nbsp;&nbsp;
            <Button
              color="primary"
              variant='contained'
              onClick={() => { handleClearChat() }}
              className={classes.btnSave}
              disabled={loading}
            >
              Clear chat
              {loading &&
                <>&nbsp;<CircularProgress size={20} style={{ color: "white" }} /></>
              }
            </Button>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ClearChatDialog;