import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { Helmet } from "react-helmet";

import Api from "../../../../Helper/ApiHandler";
import SignupModel from "components/AuthModel/SignupModel";
import LoginModel from "components/AuthModel/LoginModel";
import Forgotpassword from "components/AuthModel/Forgotpassword";
const fs = require("fs");
const path = require("path");

var api = new Api();

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
    // minHeight: "90vh"
  },
  propertyImageRoot: {
    height: "400px",
    backgroundColor: "rgb(232 238 241)",
  },
  aboutStep: {
    textAlign: "center",
    margin: "0",
    paddingTop: "10px",
    color: "#FD3576",
    fontSize: "30px",
    //fontFamily: "Gilroy !important",
  },
  contantAbout: {
    fontSize: "16px",
    color: "gray",
    textAlign: "justify",
    paddingBottom: "30px",
  },
  contantBoxAbout: {
    marginTop: "50px",
    // height:"380px",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  topPart: {
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    height: "400px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    margin: "40px 0px",
    // padding: "30px",
    height: "200px",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      overflow: "hidden",
    },
  },
  cardHeading: {
    fontSize: "20px",
    color: "Black",
    fontWeight: "500",
    textAlign: "center",
    paddingTop: "10px",
  },
  cardHeading1: {
    fontSize: "25px",
    // color: "#FD3576",
    fontWeight: "500",
    textAlign: "center",
    // padding: "10px",
  },
  cardHeading11: {
    fontSize: "27px",
    // color: "#FD3576",
    fontWeight: "500",
    textAlign: "left",
    padding: "10px",
  },
  cardHeadingSub: {
    fontSize: "12px",
    fontWeight: "300",
    textAlign: "start",
    padding: "10px",
    display: "-webkit-box !important",
    "-webkitLineClamp": 4,
    "-webkitBoxOrient": "vertical",
  },
  cardHeadingSub1: {
    fontSize: "15px",
    fontWeight: "500",
    textAlign: "start",
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      padding: "35px 0px",
    },
    // margin:'0px 0px 10px',
    display: "-webkit-box !important",
    "-webkitLineClamp": 4,
    "-webkitBoxOrient": "vertical",
  },
  cardHeadingSubimg: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "35px 0px",
      marginTop: "50PX",
    },
  },
  bottomLineee: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
    marginBottom: "50px",
    padding: "20px",
  },
  bottomLinee: {
    // backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
    // marginBottom: "50px",
    padding: "20px",
  },
  bottomLine: {
    backgroundColor: "white",
    display: "flex",
    borderRadius: "10px",
    justifyContent: "space-between",
    marginTop: "10px",
    marginBottom: "40px",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
    padding: "20px",
  },
  bottomLineee: {
    backgroundColor: "white",
    display: "flex",
    borderRadius: "10px",
    justifyContent: "space-between",
    marginTop: "15px",
    marginBottom: "50px",
    // boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
    padding: "20px",
  },
  bottomLinee: {
    // backgroundColor: "rgb(232 238 241)",
    display: "flex",
    borderRadius: "10px",
    justifyContent: "space-between",
    marginTop: "15px",
    // marginBottom: "50px",
    // boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
    padding: "20px",
  },
}));

const AboutUsDetail = (props) => {
  const classes = useStyles();
  const [about, setAbout] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [meta, setMeta] = React.useState({});
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [forgotpasswordOpen, setForgotpasswordOpen] = React.useState(false);

  const onLoginOpen = () => {
    setLoginOpen(true);
    setForgotpasswordOpen(false);
    setOpen(false);
  };

  const onForgotpasswordOpen = () => {
    setLoginOpen(false);
    setForgotpasswordOpen(true);
  };

  const onSignupOpen = () => {
    setLoginOpen(false);
    setOpen(true);
  };

  const handleClickOpenSignUp = () => {
    setOpen(true);
  };

  const handleClickOpenLogin = () => {
    setLoginOpen(true);
  };

  // useEffect(() => {
  //   onLoad();
  //   // onLoadMeta()
  // }, []);

  // const onLoad = () => {
  //   setLoading(true);
  //   // Api Call For Fetch The About
  //   api
  //     .get(`user/cms/about1sp`)
  //     .then((result) => {
  //       if (result.success === "true") {
  //         setAbout(result.data.details);
  //       } else {
  //         console.log(result, "error");
  //       }
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err, "catch error");
  //       setLoading(false);
  //     });
  // };

  return (
    <>
      <div className={classes.mainRoot}>
        <div
          className={classes.propertyImageRoot}
          style={{
            backgroundImage: "url(/images/matchUp/aboutbener.png)",
            position: "relative",
          }}
        >
          <Box
            position="absolute"
            height={"100%"}
            width={"100%"}
            style={{
              top: "0",
              backgroundColor: "rgba(0,0,0,0.7)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                fontWeight: "500",
              }}
            >
              {" "}
              About Us
            </h1>
          </Box>
        </div>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div style={{ backgroundColor: "#FFDBE1" }}>
              <Container maxWidth="lg" style={{ paddingTop: "60px" }}>
                <Typography className={classes.aboutStep}>
                  Your No. 1 destination for genuine connections
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xl={3} lg={3} xs={12}>
                    <div className={classes.cardRoot}>
                      <img
                        src="/images/matchUp/fi_14700275.png"
                        alt={"Alt Image"}
                      />
                      <div className={classes.cardHeading}>
                        Awesome Community
                      </div>
                      <div
                        className={classes.cardHeadingSub}
                        style={{ textAlign: "center" }}
                      >
                        Caramels pie cake pie marshmallow souffle donut biscuit
                      </div>
                    </div>
                  </Grid>
                  <Grid item xl={3} lg={3} xs={12}>
                    <div className={classes.cardRoot}>
                      <img src="/images/matchUp/Group.png" alt={"Alt Image"} />
                      <div className={classes.cardHeading}>Million Members</div>
                      <div
                        className={classes.cardHeadingSub}
                        style={{ textAlign: "center" }}
                      >
                        Caramels pie cake pie marshmallow souffle donut biscuit
                      </div>
                    </div>
                  </Grid>
                  <Grid item xl={3} lg={3} xs={12}>
                    <div className={classes.cardRoot}>
                      <img
                        src="/images/matchUp/fi_3595016.png"
                        alt={"Alt Image"}
                      />
                      <div className={classes.cardHeading}>Private Group</div>
                      <div
                        className={classes.cardHeadingSub}
                        style={{ textAlign: "center" }}
                      >
                        Caramels pie cake pie marshmallow souffle donut biscuit
                      </div>
                    </div>
                  </Grid>
                  <Grid item xl={3} lg={3} xs={12}>
                    <div className={classes.cardRoot}>
                      <img
                        src="/images/matchUp/fi_2664106.png"
                        alt={"Alt Image"}
                      />
                      <div className={classes.cardHeading}>Friendly Forums</div>
                      <div
                        className={classes.cardHeadingSub}
                        style={{ textAlign: "center" }}
                      >
                        Caramels pie cake pie marshmallow souffle donut biscuit
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>

            <div>
              <Container maxWidth="lg" style={{ padding: "30px 0px" }}>
                <Grid
                  container
                  spacing={2}
                  style={{ marginBottom: "20px !important" }}
                  className={classes.bottomLineee}
                >
                  <Grid item xl={6} lg={6} xs={12} sm={12} md={6}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // width: "50%",
                      }}
                    >
                      <img
                        src="/images/matchUp/Frame 11520.png"
                        alt={"Alt Image"}
                        width="85%"
                        height="50%"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    xs={12}
                    sm={12}
                    md={6}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div className={classes.cardHeading11}>
                        Why Select Us?
                      </div>
                      <Typography className={classes.cardHeadingSub1}>
                        We offer an anonymous social app where your identity
                        remains private, allowing you to enjoy straightforward
                        speed dating. Unlike most other online dating or social
                        apps, our focus is on facilitating real connections
                        between people
                      </Typography>
                      <Typography className={classes.cardHeadingSub1}>
                        We provide a refined and understated approach to dating,
                        favoring a 'less is more' environment free from typical
                        event trappings. Meeting your special someone should be
                        a comfortable and relaxed experience. That's our
                        philosophy. Simply select someone nearby and suggest a
                        meeting, or confirm one from related suggestions. This
                        is where charm truly pays off!
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>

            <div style={{ backgroundColor: "#FFDBE1" }}>
              <Container
                maxWidth="lg"
                style={{ paddingTop: "60px", paddingBottom: "60px" }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{
                    marginBottom: "20px !important",
                    backgroundColor: "#FFDBE1",
                  }}
                  className={classes.bottomLinee}
                >
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    xs={12}
                    sm={12}
                    md={6}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography className={classes.cardHeadingSub1}>
                        We Are the Ultimate Dating Site
                      </Typography>
                      <div className={classes.cardHeading11}>
                        Dating Redefined Like Never Before
                      </div>
                      <Typography className={classes.cardHeadingSub1}>
                        We provide a refined and understated approach to dating,
                        favoring a 'less is more' environment free from typical
                        event trappings. Meeting your special someone should be
                        a comfortable and relaxed experience. That's our
                        philosophy. Simply select someone nearby and suggest a
                        meeting, or confirm one from related suggestions. This
                        is where charm truly pays off!
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} xs={12} sm={12} md={6}>
                    <Box className={classes.cardHeadingSubimg}>
                      <img
                        src="/images/matchUp/Groupp.png"
                        alt={"Alt Image"}
                        width="85%"
                        height="100%"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </div>

            <Container maxWidth="lg" style={{ paddingTop: "30px" }}>
              <Grid item xl={12} lg={12} xs={12}>
                <Box className={classes.bottomLine}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "18px",
                      color: "#FD3576",
                      fontWeight: "500",
                    }}
                  >
                    Ready to find your mate?
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      size="medium"
                      style={{
                        background:
                          "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
                        color: "white",
                        textTransform: "capitalize",
                        borderRadius: "10px",
                      }}
                      onClick={handleClickOpenSignUp}
                    >
                      Create an Account
                    </Button>
                  </div>
                </Box>
              </Grid>
            </Container>
          </>
        )}
      </div>

      <SignupModel open={open} setOpen={setOpen} setOpenLogin={onLoginOpen} />
      <LoginModel
        loginOpen={loginOpen}
        setLoginOpen={setLoginOpen}
        setOpenSignUp={onSignupOpen}
        setForgotpasswordOpen={onForgotpasswordOpen}
      />
      <Forgotpassword
        loginOpen={forgotpasswordOpen}
        setLoginOpen={onLoginOpen}
        setOpenSignUp={onSignupOpen}
      />
    </>
  );
};

export default AboutUsDetail;
